import { rem } from 'polished';
import Button from '../../Button';
import Icon from '../../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const KeyWordsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const KeyWord = styled.div`padding: ${rem(3)} 0;`;

export const KeyWordButton = styled(Button) `
  text-transform: none;
  &:hover {
    svg {
      fill: red !important;
    }
  }
`;

export const DeleteIcon = styled(Icon) `
  margin-left:${rem(5)};
  margin-bottom: ${rem(3)};
`;

const KeyWords = props => {
  const { value: values, formatFn } = props;
  const handleDelete = index => {
    props.onChange([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  const renderKeyWordList = (item, index) => {
    return (
      <KeyWord key={index}>
        <KeyWordButton sm inverse primary onClick={e => handleDelete(index, e)}>
          {formatFn(item)}
          <DeleteIcon name="delete" sm />
        </KeyWordButton>
        &nbsp;
      </KeyWord>
    );
  };

  return (
    <KeyWordsWrapper>
      {values.map(renderKeyWordList)}
    </KeyWordsWrapper>
  );
};

KeyWords.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  formatFn: PropTypes.func,
};

KeyWords.defaultProps = {
  formatFn: item => item,
};

export default KeyWords;
