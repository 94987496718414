import { createSelector } from "reselect";
import { getProp } from "../../utilities";

const domainSelector = state => state.partners;

export const partnersListSelector = createSelector(
  domainSelector,
  substate => getProp(substate, ['data'], []),
);

export const partnersIsFetchingSelector = createSelector(
  domainSelector,
  substate => getProp(substate, ['isFetching'], true),
);

export const groupsSelector = createSelector(
  domainSelector,
  substate => getProp(substate, ['groups'], {}),
);
