import * as React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { __ } from "../../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { getProductByIdSelector } from "../../Products/selectors";
import { mb } from "@sportnet/ui/Themes/utilities";
import { priceLevelsListSelector } from "../../PriceLevels/selectors";
import { rem } from "polished";
import { required } from "../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import Header from "@sportnet/ui/Header";
import OrderFragments from "./fragments";
import Params from "./params";
import Price from "@sportnet/ui/Price";
import PropTypes from "prop-types";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import styled from "styled-components";

const isFloat = (value) => {
  const floatValue = parseFloat(value);
  if (floatValue && isNaN(floatValue)) {
    return __("Zadajte prosím desatinné alebo celé číslo.");
  }
  return null;
};

const Separator = styled.div`
  width: 100%;
  padding: ${rem(10)};
  display: block;
  ${mb("m")} {
    display: ${({ hidden }) => (hidden ? "none" : "block")};
  }
`;

const VariantDetail = ({ unitprice, priceLevels, productParams }) => {
  const { accessToken, app, ppo } = useAuth();
  const { values } = useFormState();
  const { mutators } = useForm();

  return (
    <Row>
      <Col xs={12}>
        <Segment
          raised
          header={
            <SegmentHeader withSeparator size="s">
              {__("Informácie o variante")}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={6} s={3.5}>
              <Field name="code" validate={required}>
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("Kód")}
                    required
                    error={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Col>
            <Col xs={6} s={3.5}>
              <Field name="ean">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("EAN")}
                    error={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Col>
            <Col xs={4} s={1.25}>
              <Field name="units">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("Jednotka")}
                    error={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Col>
            <Col xs={4} s={1.875}>
              <Field name="weight" validate={isFloat}>
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    type="number"
                    step="0.01"
                    label={__("Hmotnosť [kg]")}
                    error={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Col>
            <Col xs={4} s={1.875}>
              <Field name="volume" validate={isFloat}>
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    type="number"
                    step="0.01"
                    label={__("Objem [m3]")}
                    error={meta.touched && meta.error}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12} s={3}>
              <Field name="onsale.status" type="checkbox">
                {({ input: { checked, name, onChange } }) => (
                  <FormField
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    label={__("Akciový variant")}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field name="picture">
                {({ input: { value, onChange } }) => (
                  <FormField
                    type="image"
                    label={__("Obrázok variantu")}
                    auth={{
                      appId: app || "",
                      token: accessToken || "",
                      appSpace: ppo || "",
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Segment>
      </Col>
      <Col xs={12}>
        <Segment
          raised
          header={
            <SegmentHeader withSeparator size="s">
              {__("Dostupnosť")}
            </SegmentHeader>
          }
        >
          <Row>
            <Col xs={12} s={4}>
              <Field name="availability.managed_stock" type="checkbox">
                {({ input: { checked, name, onChange } }) => (
                  <FormField
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    label={__("Sklad v správe e-shopu")}
                  />
                )}
              </Field>
            </Col>
            {!values?.availability?.managed_stock && (
              <>
                <Col xs={12} s={4}>
                  <Field name="availability.available" type="checkbox">
                    {({ input: { checked, name, onChange } }) => (
                      <FormField
                        type="checkbox"
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        label={__("Objednateľné")}
                        disabled={values?.availability?.managed_stock}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={12} s={4}>
                  <Field name="availability.onstock" type="checkbox">
                    {({ input: { checked, name, onChange } }) => (
                      <FormField
                        type="checkbox"
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        label={__("Skladom")}
                        disabled={
                          values?.availability?.managed_stock ||
                          !values?.availability?.available
                        }
                      />
                    )}
                  </Field>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col xs={12} s={4}>
              <Field name="availability.quantity">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    step="1"
                    min="0"
                    type="number"
                    disabled={!values?.availability?.managed_stock}
                    error={meta.touched && meta.error}
                    label={__("Počet kusov na sklade")}
                    info={__(
                      "Berie sa do úvahy iba ak je nastavené, že je sklad v správe e-shopu.",
                    )}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} s={4}>
              <Field name="availability.max_quantity">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    type="number"
                    info={__(
                      "Ak je nastavené, zákazník môže objednať v rámci objednávky maximálne toto množstvo.",
                    )}
                    error={meta.touched && meta.error}
                    placeholder={__("Zadajte počet...")}
                    label={__("Max počet kusov na objednávku")}
                  />
                )}
              </Field>
            </Col>
            <Col xs={12} s={4}>
              <Field name="availability.label">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    error={meta.touched && meta.error}
                    info={__(
                      "Text, ktorý sa zobrazí pri dostupnosti variantu a zobrazuje sa pre každý stav dostupnosti.",
                    )}
                    placeholder={__("Skladom...")}
                    label={__("Text dostupnosti")}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Segment>
      </Col>
      <Col xs={12}>
        <Params productParams={productParams} />
      </Col>
      <Col xs={12}>
        <Segment
          raised
          header={
            <Header withSeparator size="s">
              {__("Cenník")}
            </Header>
          }
        >
          <Row>
            <Col xs={12} s={9}>
              <Field name="unitprice">
                {({ input: { value, onChange }, meta }) => {
                  return (
                    <Price
                      amount={value.amount}
                      amountVatExcl={value.amount_vat_excl}
                      vatRate={value.vat_rate}
                      priceProp={"unitprice"}
                      onChange={(e) => {
                        onChange({
                          amount: e.amount,
                          amount_vat_excl: e.amountVatExcl,
                          vat_rate: e.vatRate,
                          currency: e.currency,
                        });
                      }}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col xs={12} s={3}>
              <Field name="oldprice">
                {({ input: { value, onChange }, meta }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    error={meta.touched && meta.error}
                    label={__("Pôvodná cena")}
                  />
                )}
              </Field>
            </Col>
            <Separator />
            {priceLevels.map((level) => {
              const baseIndex = values?.pricelevels?.findIndex(
                (i) => i.pricelevel === level._id,
              );
              return (
                <Col key={level._id} xs={12}>
                  <Field name="onsale.status" type="checkbox">
                    {() => (
                      <FormField
                        type="checkbox"
                        checked={baseIndex > -1}
                        label={level.name}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newPriceLevels = [
                              ...(values?.pricelevels || []),
                            ];
                            newPriceLevels.push({
                              pricelevel: level._id,
                              unitprice,
                            });
                            mutators.setPriceLevels(newPriceLevels);
                          } else {
                            const newPriceLevels = [
                              ...(values?.pricelevels || []),
                            ].filter((i) => i.pricelevel !== level._id);
                            mutators.setPriceLevels(newPriceLevels);
                          }
                        }}
                      />
                    )}
                  </Field>

                  {baseIndex > -1 && (
                    <div
                      style={{
                        paddingTop: rem(15),
                        paddingLeft: rem(5),
                      }}
                    >
                      <Col xs={12}>
                        <Field name={`pricelevels.${baseIndex}.unitprice`}>
                          {({ input: { value, onChange }, meta }) => {
                            return (
                              <Price
                                amount={value.amount}
                                amountVatExcl={value.amount_vat_excl}
                                vatRate={value.vat_rate}
                                priceProp={`pricelevels.${baseIndex}.unitprice`}
                                onChange={(e) => {
                                  onChange({
                                    amount: e.amount,
                                    amount_vat_excl: e.amountVatExcl,
                                    vat_rate: e.vatRate,
                                    currency: e.currency,
                                  });
                                }}
                              />
                            );
                          }}
                        </Field>
                      </Col>
                    </div>
                  )}
                </Col>
              );
            })}
          </Row>
        </Segment>
      </Col>
      <OrderFragments />
    </Row>
  );
};

VariantDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  org: PropTypes.string.isRequired,
  available: PropTypes.bool,
  isOnSale: PropTypes.bool,
  variantPriceLevels: PropTypes.arrayOf(PropTypes.shape({})),
  orderfragments: PropTypes.arrayOf(PropTypes.shape({})),
  priceLevels: PropTypes.arrayOf(PropTypes.shape({})),
  unitprice: PropTypes.shape({}),
  params: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({ params: PropTypes.shape({}) }).isRequired,
};

VariantDetail.defaultProps = {
  available: false,
  isOnSale: false,
  variantPriceLevels: [],
  orderfragments: [],
  priceLevels: [],
  unitprice: {},
};

const mapStateToProps = (state, props) => {
  return {
    org: state.selectedOrg,
    priceLevels: priceLevelsListSelector(state),
    product: getProductByIdSelector(state),
  };
};

export default compose(connect(mapStateToProps))(VariantDetail);
