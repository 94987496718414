import {
  RECEIVE_GROUPS_FAILURE,
  RECEIVE_GROUPS_SUCCESS,
  RECEIVE_PARTNERS_FAILURE,
  RECEIVE_PARTNERS_SUCCESS,
  REQUEST_GROUPS,
  REQUEST_PARTNERS,
} from './constants';

const partnersReducer = (state = {
  data: [],
  offset: 0,
  limit: 50,
  nextOffset: null,
  isFetching: false,
  groups: {
    data: [],
    isFetching: false,
  },
}, action) => {
  switch (action.type) {
    case REQUEST_PARTNERS:
      return {
        ...state,
        isFetching: true,
      }
    case RECEIVE_PARTNERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        offset: action.payload.offset,
        limit: action.payload.limit,
        nextOffset: action.payload.nextOffset,
        isFetching: false,
      };
    case RECEIVE_PARTNERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case REQUEST_GROUPS:
      return {
        ...state,
        groups: {
          data: [],
          isFetching: true,
        },
      };
    case RECEIVE_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          data: [...action.payload],
          isFetching: false,
        },
      };
    case RECEIVE_GROUPS_FAILURE:
        return {
          ...state,
          groups: {
            ...state.groups,
            isFetching: false,
          },
        };
    default:
      return state;
  }
};

export default partnersReducer;
