import {
  RECEIVE_SALE_CHANNELS_FAILURE,
  RECEIVE_SALE_CHANNELS_SUCCESS,
  REQUEST_SALE_CHANNELS,
} from './constants';

import Api from '../../service/apiEshop';

const requestSaleChannels = () => ({
  type: REQUEST_SALE_CHANNELS,
});

const receiveSaleChannelsSuccess = data => ({
  type: RECEIVE_SALE_CHANNELS_SUCCESS,
  payload: {
    data,
  },
});

const receiveSaleChannelsFailure = error => ({
  type: RECEIVE_SALE_CHANNELS_FAILURE,
  payload: {
    error,
  },
});

export const fetchSaleChannelsList = appspace => {
  return async dispatch => {
    dispatch(requestSaleChannels());
    try {
      const response = await Api.adminListSaleschannels(appspace);
      dispatch(receiveSaleChannelsSuccess(response.saleschannels));
    } catch (e) {
      dispatch(receiveSaleChannelsFailure(e.description || e));
    }
  };
};
