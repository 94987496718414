import { rem } from "polished";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Icon from "../Icon";

const Button = styled.button.attrs({
  type: "button",
})`
  text-transform: uppercase;
  padding: 0.5em 0.75em;
  border: ${rem(1)} solid transparent;
  ${(props) => {
    const { theme } = props;
    let color = "transparent";
    let fontSize = theme.md;
    let fontColor = theme.white;
    if (props.primary) color = theme.primary;
    if (props.primaryLight) color = theme.primaryLight;
    if (props.secondary) color = theme.secondary;
    if (props.warning) color = theme.warning;
    if (props.success) color = theme.success;
    if (props.danger) color = theme.danger;
    if (props.white) color = theme.white;
    if (props.black) color = theme.black;

    if (props.xs) fontSize = theme.xs;
    if (props.sm) fontSize = theme.sm;
    if (props.md) fontSize = theme.md;
    if (props.lg) fontSize = theme.lg;
    if (props.xl) fontSize = theme.xl;
    if (props.xxl) fontSize = theme.xxl;

    if (props.inverse) fontColor = color;

    return css`
      background-color: ${({ inverse }) => (inverse ? "transparent" : color)};
      border-color: ${color};
      color: ${fontColor};
      font-size: ${fontSize};
    `;
  }}
  color: ${(props) => props.fontColor && props.theme[props.fontColor]};
  outline: 0;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius};
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default Button;

export const ButtonRadius = styled(Button)`
  width: ${rem(30)};
  height: ${rem(30)};
  padding: 0;
  border-radius: 50%;
`;

export const ButtonDelete = ({ onClick, title }) => {
  return (
    <ButtonRadius onClick={onClick} primary lg title={title}>
      <Icon name="delete" white sm />
    </ButtonRadius>
  );
};

ButtonDelete.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ButtonDelete.defaultProps = {
  onClick: () => {},
  title: null,
};

export const ButtonEdit = ({ onClick, title }) => {
  return (
    <ButtonRadius onClick={onClick} warning lg title={title}>
      <Icon name="edit" white sm />
    </ButtonRadius>
  );
};

ButtonEdit.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ButtonEdit.defaultProps = {
  onClick: () => {},
  title: null,
};

export const ButtonExport = ({ onClick, publicUrl, title }) => {
  return (
    <ButtonRadius onClick={onClick} white lg title={title}>
      <a href={publicUrl} target="blank">
        <Icon name="export" lg />
      </a>
    </ButtonRadius>
  );
};

ButtonExport.propTypes = {
  onClick: PropTypes.func,
  publicUrl: PropTypes.string,
  title: PropTypes.string,
};

ButtonExport.defaultProps = {
  onClick: () => {},
  publicUrl: null,
  title: null,
};
