import { Link } from "react-router-dom";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, getImportStatus } from "../../utilities";
import { connect } from "react-redux";
import { fetchDetail } from "./actions";
import { format } from "date-fns";
import {
  importDetailIsFetchingSelector,
  importDetailSelector,
} from "./selectors";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Col, { Row } from "@sportnet/ui/Grid";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Segment from "@sportnet/ui/Segment";

class ImportDetail extends PureComponent {
  componentDidMount() {
    const {
      dispatch,
      selectedOrg,
      match: {
        params: { import_id },
      },
    } = this.props;
    dispatch(fetchDetail(selectedOrg, import_id));
  }

  getCategoryLabel(category) {
    let result = null;
    switch (category) {
      case "prices":
        result = __("Import cien");
        break;
      case "products":
        result = __("Import produktov");
        break;
      default:
        result = __("Import neznámej kategórie");
    }
    return result;
  }

  render() {
    const {
      detail: { created_date, userFileName, status, category, delimiter, log },
      selectedOrg,
    } = this.props;
    const title = __("Detail importu");
    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${selectedOrg}/imports/${category}`}
            />
            <HeaderBar.Header>{title}</HeaderBar.Header>
          </HeaderBar>
        }
      >
        <AppContext title={title} />
        <Segment noBottomGutter>
          <Segment raised>
            <Row>
              <Col xs={12} s={2} md={3}>
                <FormGroup>
                  <Label>{__("Dátum a čas vytvorenia")}</Label>
                  <Input
                    type="text"
                    value={
                      !!created_date
                        ? format(new Date(created_date), "dd.MM.yyyy HH:mm:ss")
                        : ""
                    }
                    name="created_date"
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={2} md={3}>
                <FormGroup>
                  <Label>{__("Súbor")}</Label>
                  <Input
                    type="text"
                    value={userFileName || ""}
                    name="userFileName"
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={2} md={3}>
                <FormGroup>
                  <Label>{__("Status")}</Label>
                  <Input
                    type="text"
                    value={getImportStatus(status) || ""}
                    name="state"
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={2} md={3}>
                <FormGroup>
                  <Label>{__("Kategória")}</Label>
                  <Input
                    type="text"
                    value={this.getCategoryLabel(category) || ""}
                    name="category"
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={2} md={3}>
                <FormGroup>
                  <Label>{__("Oddelovač")}</Label>
                  <Input
                    type="text"
                    value={delimiter || ""}
                    name="delimiter"
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
          </Segment>
          <Segment raised>
            <Row>
              <Col xs={12}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__("Level")}</Th>
                      <Th>{__("Dátum a čas")}</Th>
                      <Th>{__("Správa")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {log &&
                      log.map((item, idx) => {
                        return (
                          <Tr key={idx}>
                            <Td>{item.level}</Td>
                            <Td>
                              {format(
                                new Date(item.date),
                                "dd.MM.yyyy HH:mm:ss",
                              )}
                            </Td>
                            <Td>{item.message}</Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </Col>
            </Row>
          </Segment>
        </Segment>
      </Layout>
    );
  }
}

ImportDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  acl: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // from state
  selectedOrg: PropTypes.string.isRequired,
  detail: PropTypes.shape({}),
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedOrg: state.selectedOrg,
    acl: state.userAcl.data,
    detail: importDetailSelector(state),
    isFetching: importDetailIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(ImportDetail);
