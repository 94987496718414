import { css } from 'styled-components';

export const __ = toTranslate => {
  return toTranslate;
};

export const getProp = (object, path, defaultValue = '') => {
  const val = path.reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};

export const objectToParams = obj => {
  const str = [];
  obj.forEach(p => {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  });
  return str.join('&');
};

export const paramsToObject = params => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach(part => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const getLabelofOpt = (options, type, value) => {
  const items = getProp(
    options.find(option => option.type === type),
    ['items'],
    [],
  );
  const item = items.find(i => i.value === value) || {};
  return getProp(item, ['label'], value);
};

export const removeAccents = text => {
  return text
    .toLowerCase()
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[čç]/gi, 'c')
    .replace(/[ď]/gi, 'd')
    .replace(/[éèê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[ľĺ]/gi, 'l')
    .replace(/[ñň]/gi, 'n')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[ř]/gi, 'r')
    .replace(/[š]/gi, 's')
    .replace(/[ť]/gi, 't')
    .replace(/[úùüûů]/gi, 'u')
    .replace(/[ý]/gi, 'y')
    .replace(/[ž]/gi, 'z')
    .replace(/[^a-zA-Z0-9]/g, ' ');
};

export const slugify = text => {
  return removeAccents(text)
    .toLowerCase()
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
};

export const keyify = text => {
  return slugify(text).replace(/[^a-z0-9]/g, '-');
};

export const formatNumber = (n, c = 2, d = ',', t = ' ') => {
  let b = n;
  const s = b < 0 ? '-' : '';
  const i = String(Number.parseInt((b = Math.abs(+b || 0).toFixed(c)), 10));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(b - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const formatPrice = (
  n,
  currency = 'EUR',
  c = 2,
  d = ',',
  t = '\u00a0',
) => {
  if (currency === 'CZK') {
    return `${formatNumber(n, c, d, t, c)} Kč`;
  } else if (currency === 'EUR') {
    return `${formatNumber(n, c, d, t, c)} €`;
  }
  return `${formatNumber(n, c, d, t, c)} ${currency}`;
};

export const calculateOrderTotalprice = order => {
  return order.items.reduce((tprice, i) => {
    if (!tprice) {
      return Object.assign({}, i.totalprice);
    }
    const newTprice = Object.assign({}, tprice);
    newTprice.amount += i.totalprice.amount;
    newTprice.amount_vat_excl += i.totalprice.amount_vat_excl;
    delete newTprice.vat_rate;
    return newTprice;
  }, null);
};

export const calculateOrderTotalpriceGroups = order => {
  const groups = {};
  order.items.forEach(i => {
    let { type } = i;
    if (!type) {
      type = 'other';
    }
    if (type in groups) {
      groups[type].amount += i.totalprice.amount;
      groups[type].amount_vat_excl += i.totalprice.amount_vat_excl;
    } else {
      groups[type] = Object.assign({}, i.totalprice);
      groups[type].group = type;
      delete groups[type].vat_rate;
    }
  });
  return Object.values(groups);
};

export const calculateOrderTotalpriceWithCredits = order => {
  return order.items.reduce((tprice, i) => {
    if (!tprice) {
      let data = {};
      if (i.paidbycredit) {
        data = {
          ...i.totalprice,
          byCredit:
            typeof i.credits === 'undefined' ? i.totalprice.amount : i.credits,
          delivery: i.type === 'delivery' ? i.totalprice.amount : 0,
          delivery_vat_excl:
            i.type === 'delivery' ? i.totalprice.amount_vat_excl : 0,
        };
      } else {
        data = {
          ...i.totalprice,
          byCredit: 0,
          delivery: i.type === 'delivery' ? i.totalprice.amount : 0,
          delivery_vat_excl:
            i.type === 'delivery' ? i.totalprice.amount_vat_excl : 0,
        };
      }
      return Object.assign({}, data);
    }

    const newTprice = Object.assign({}, tprice);
    if (i.paidbycredit) {
      newTprice.byCredit +=
        typeof i.credits === 'undefined' ? i.totalprice.amount : i.credits;
    }
    newTprice.amount += i.totalprice.amount;
    newTprice.amount_vat_excl += i.totalprice.amount_vat_excl;
    newTprice.delivery += i.type === 'delivery' ? i.totalprice.amount : 0;
    newTprice.delivery_vat_excl +=
      i.type === 'delivery' ? i.totalprice.amount_vat_excl : 0;
    delete newTprice.vat_rate;
    return newTprice;
  }, null);
};

const mediaSizes = {
  xs: 30,
  s: 48,
  m: 60,
  l: 72,
  xl: 90,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(mediaSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${mediaSizes[label]}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export const getRequestStatus = (status, statuses) => {
  if (statuses) {
    const obj = statuses[status];
    if (obj) {
      return obj.label;
    }
  }
  return '';
};

export const getRequestType = req => {
  if (req === 'claim') {
    return 'Reklamácia';
  } else if (req === 'question') {
    return 'Otázka';
  }
  return 'Dotaz';
};

export const getImportStatus = importStatus => {
   let result;
   switch (importStatus) {
     case 'pending':
       result = __('Pripravený na spracovanie');
       break;
     case 'processing':
       result = __('Spracúva sa');
       break;
     case 'done':
       result = __('Spracovaný');
       break;
     case 'error':
       result = __('Chyba spracovania');
       break;
     default: 
       result = __('Neznámy stav');
   }
   return result;
}

export function round(number, precision) {
  const factor = 10 ** precision;
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

export const serializeSearchParameters = parameters => {
  return Object.keys(parameters)
    .map(parameterName => {
      return `${parameterName}=${parameters[parameterName]}`;
    })
    .join('|');
};

export const deserializeSearchParameters = parameters => {
  const arrayOfParameterStrings = parameters.split('|');
  return arrayOfParameterStrings.reduce((acc, next) => {
    const parameter = next.split('=');
    return { ...acc, [parameter[0]]: parameter[1] };
  }, {});
};

export const normalizeEntities = (type, arrayOfEntities, id = '_id') => {
  return {
    entities: {
      [type]: arrayOfEntities.reduce((acc, item) => {
        acc[item[id]] = item;
        return acc;
      }, {}),
    },
    results: arrayOfEntities.map(item => item[id]),
    original: arrayOfEntities,
  };
};

export const formatSmarttags = (
  smarttagIncludeString = '',
  smarttagExludeString = '',
) => {
  let result = '';
  if (smarttagIncludeString) {
    result = smarttagIncludeString;
  }

  if (smarttagExludeString) {
    const smarttagsExclude = smarttagExludeString
      .split('|')
      .map(v => {
        const [key, values] = v.split(':');
        return `${key}:not:${values}`;
      })
      .join('|');
    if (result) {
      result += '|';
    }
    result += smarttagsExclude;
  }
  return result;
};

export const reduxFormKeyify = text => {
  return text.replace(/\./g, '{{dot}}');
};

export const revertReduxFormKeyify = text => {
  return text.replace(/{{dot}}/g, '.');
};
