import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
  text-align: center;
  margin-bottom: ${rem(20)};
  color: ${({ theme }) => theme.primary};
  font-size: ${rem(10)};
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
    animation-delay: 0.32s;
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;

const Wrapper = styled.div`
  width: ${rem(10)};
  text-align: center;
  margin: auto;
`;

const Loader = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default Loader;

export const SmallLoader = styled(Spinner) `
    color: ${({ theme }) => theme.primary};
    font-size: ${rem(10)};
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.5s;

    &,
    &:before,
    &:after {
        width: 1.5em;
        height: 1.5em;
        animation-fill-mode: both;
        animation: load7 1.8s infinite ease-in-out;
    }

    &:before {
        left: -2em;
        animation-delay: -0.32s;
    }
    &:after {
        left: 2em;
        animation-delay: 0.32s;
    }
}
`;
