import { ContentLoader } from "@sportnet/ui/Loader";
import { Link } from "react-router-dom";
import { SubmissionError } from "redux-form";
import { __ } from "../../../utilities";
import { getProp } from "@sportnet/utilities";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Domains from "@sportnet/ui/Domains";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Tabs from "../Tabs";
import apiEshop from "../../../service/apiEshop";

const DomainSettings = ({
  history,
  match: {
    params: { salesChannelId, appspace },
  },
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [salesChannel, setSalesChannel] = React.useState(null);

  const { accessToken } = useAuth();

  const domain = React.useMemo(() => {
    return getProp(salesChannel, ["baseUri"], "")
      .replace(/^https?:\/\//, "")
      .replace(/\/+$/, "");
  }, [salesChannel]);

  const title = React.useMemo(() => {
    return salesChannelId === "new"
      ? __("Vytvorenie predajného kanálu")
      : __(`${__("Predajný kanál:")} ${salesChannelId}`);
  }, [salesChannelId]);

  React.useEffect(() => {
    if (salesChannelId === "new") {
      history.replace(`/admin/${appspace}/sale-channels/${salesChannelId}`);
    }
    loadSalesChannel();
  }, [appspace, salesChannelId, history]);

  const loadSalesChannel = async () => {
    setIsFetching(true);
    try {
      const salesChannel = await apiEshop.adminGetSaleschannelDetail(
        appspace,
        salesChannelId,
      );
      setSalesChannel(salesChannel);
    } catch (e) {
      console.error(e);
    }
    setIsFetching(false);
  };

  const handleSubmit = async (data) => {
    const dataToSend = {
      baseUri: data.baseUri,
    };
    try {
      const salesChannel = await apiEshop.adminUpdateSaleschannel(
        appspace,
        salesChannelId,
        {
          data: dataToSend,
        },
      );
      setSalesChannel(salesChannel);
    } catch (e) {
      const submitError = {
        _error: __("Nastavenie domény pre predajný kanál sa nepodarilo."),
      };
      window.setTimeout(() => {
        window.alert(submitError._error);
      }, 0);
      throw new SubmissionError(submitError);
    }
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/admin/${appspace}/sale-channels`}
            title={__("Zoznam predajných kanálov")}
          />
          <HeaderBar.Header>{title}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext
        title={title}
        breadcrumbs={[
          {
            url: `/admin/${appspace}/sale-channels`,
            name: __("Zoznam predajných kanálov"),
          },
          {
            name: __(
              salesChannelId === "new"
                ? __("Nový")
                : __(`Úprava kanálu: ${salesChannelId}`),
            ),
          },
        ]}
      />
      <Tabs />
      <Segment noBottomGutter>
        {isFetching ? (
          <ContentLoader size="xl" />
        ) : salesChannel ? (
          <Domains
            accessToken={accessToken}
            appId={process.env.REACT_APP_APP_ID}
            appSpace={appspace}
            domain={domain || undefined}
            payload={{
              salesChannel: salesChannelId,
            }}
            testDomainPostfix=".eshop.sportnet.online"
            onChange={async (v) => {
              const baseUri = v ? `https://${v._id}` : null;
              await handleSubmit({ baseUri });
            }}
          />
        ) : null}
      </Segment>
    </Layout>
  );
};

DomainSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      salesChannelId: PropTypes.string.isRequired,
      appspace: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default DomainSettings;
