import * as React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Admin from "../Admin";
import AuthProvider from "@sportnet/auth-react/AuthProvider";
import PpoSelector from "@sportnet/auth-react/PpoSelector";
import config from "../../config";

const AppWrapper = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/admin">
        <AuthProvider app={config.APP_ID}>
          <PpoSelector
            onSelectAppspace={(appSpace) => {
              history.push(`/admin/${appSpace.ppo}/orders`);
            }}
          />
        </AuthProvider>
      </Route>

      <Route path="/_auth">
        <AuthProvider app={process.env.REACT_APP_APP_ID} />
      </Route>

      <Route path="/admin/:appspace?" component={Admin} />
      <Route path="*">
        <Redirect to="/admin" />
      </Route>
    </Switch>
  );
};

export default withRouter(AppWrapper);
