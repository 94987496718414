import { ContentLoader } from "@sportnet/ui/Loader";
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { SubmissionError, isSubmitting, submit } from "redux-form";
import { __ } from "../../../utilities";
import { connect } from "react-redux";
import { getProp, setProp } from "@sportnet/utilities";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import SalesChannelForm, { FORM_NAME } from "./form";
import Segment from "@sportnet/ui/Segment";
import SubmitButton from "@sportnet/ui/Button/Submit";
import Tabs from "../Tabs";
import apiEshop from "../../../service/apiEshop";
import config from "../../../config";
import getCmsUrl from "../../../utilities/getCmsUrl";
import styled, { withTheme } from "styled-components";

const CmsLink = styled.a`
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.primary};
  min-height: ${rem(40)};
  border-radius: ${rem(4)};
  padding: 0 ${rem(22)};
`;

const DEFAULT_UNIT_PRICE = {
  amount: 0.0,
  amount_vat_excl: 0.0,
  vat_rate: 20.0,
  currency: "EUR",
};

const DEFAULT_SALES_CHANNEL = {
  payments: {
    pay_on_delivery: {
      active: false,
      unitprice: DEFAULT_UNIT_PRICE,
    },
    pay_at_store: {
      active: false,
    },
    pay_by_transfer: {
      active: false,
    },
    standard_payment: {
      active: false,
    },
  },
};

const BasicSettings = ({
  submitting,
  match: {
    params: { salesChannelId },
  },
  dispatch,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [salesChannel, setSalesChannel] = React.useState(DEFAULT_SALES_CHANNEL);
  const [deliveryOptions, setDeliveryOptions] = React.useState([]);
  const { ppo } = useAuth();

  const title = React.useMemo(() => {
    return salesChannelId === "new"
      ? __("Vytvorenie predajného kanálu")
      : __(`${__("Predajný kanál:")} ${salesChannelId}`);
  }, [salesChannelId]);

  const handleAdminGetSupplierDeliveryOptions = React.useCallback(async () => {
    try {
      const { items } = await apiEshop.adminGetSupplierDeliveryOptions(ppo);
      setDeliveryOptions(
        items.map((option) => ({
          label: option.name,
          value: option.type,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, [ppo]);

  const formatSalesChannel = React.useCallback((salesChannel) => {
    if (!salesChannel) {
      return DEFAULT_SALES_CHANNEL;
    }
    return setProp(salesChannel, ["payments", "pay_on_delivery", "unitprice"], {
      ...{
        amount: 0.0,
        amount_vat_excl: 0.0,
        vat_rate: 20.0,
        currency: "EUR",
      },
      ...getProp(salesChannel, ["payments", "pay_on_delivery", "unitprice"]),
    });
  }, []);

  const loadSalesChannel = React.useCallback(
    async (salesChannelId) => {
      setIsFetching(true);
      try {
        const salesChannel = await apiEshop.adminGetSaleschannelDetail(
          ppo,
          salesChannelId,
        );
        setSalesChannel(formatSalesChannel(salesChannel));
      } catch (e) {
        console.error(e);
      }
      setIsFetching(false);
    },
    [formatSalesChannel, ppo],
  );

  React.useEffect(() => {
    if (salesChannelId !== "new") {
      loadSalesChannel(salesChannelId);
    }
    handleAdminGetSupplierDeliveryOptions();
  }, [handleAdminGetSupplierDeliveryOptions, loadSalesChannel]);

  const handleSubmit = async (data) => {
    const dataToSend = {
      _id: data._id,
      name: data.name,
      payments: data.payments,
      baseUri: data.baseUri,
      analytics: data.analytics,
      deliveryOptionsTypes: data.deliveryOptionsTypes,
      logo: data.logo,
      logoSecondary: data.logoSecondary,
      favicon: data.favicon,
      theme: data.theme || {},
    };

    try {
      let salesChannel = null;
      if (salesChannelId === "new") {
        salesChannel = await apiEshop.adminCreateSaleschannel(ppo, {
          data: dataToSend,
        });
      } else {
        delete dataToSend._id;
        if (data.catalogs) {
          dataToSend.catalogs = data.catalogs;
        }
        if (data.meta) {
          dataToSend.meta = data.meta;
        }
        salesChannel = await apiEshop.adminUpdateSaleschannel(
          ppo,
          salesChannelId,
          {
            data: dataToSend,
          },
        );
      }

      setSalesChannel(formatSalesChannel(salesChannel));

      if (salesChannelId === "new") {
        history.push(`/admin/${ppo}/sale-channels/${salesChannel._id}`);
      }
    } catch (e) {
      const submitError = {
        _error: __("Uloženie predajného kanálu sa nepodarilo."),
      };
      if (getProp(e, ["details", "name"], "") === "DUPLICATE_SALESCHANNEL_ID") {
        submitError._id = __("Identifikátor už používa iný predajný kanál");
        submitError._error = `${submitError._error}\n${submitError._id}`;
      }
      window.setTimeout(() => {
        window.alert(submitError._error);
      }, 0);
      throw new SubmissionError(submitError);
    }
  };

  const handleSave = () => {
    dispatch(submit(FORM_NAME));
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Action
            icon="back"
            as={Link}
            to={`/admin/${ppo}/sale-channels`}
            title={__("Zoznam predajných kanálov")}
          />
          <HeaderBar.Header>{title}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          {salesChannelId !== "new" && (
            <ContextBarItem>
              <CmsLink
                href={getCmsUrl(config.APP_ID, ppo, salesChannelId, undefined)}
                target="_blank"
              >
                {__("Správa obsahu")}
              </CmsLink>
            </ContextBarItem>
          )}
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              onClick={handleSave}
              disabled={isFetching}
              isSubmitting={submitting}
              successText={__("Uložené")}
            >
              {__("Uložiť")}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext
        title={title}
        breadcrumbs={[
          {
            url: `/admin/${ppo}/sale-channels`,
            name: __("Zoznam predajných kanálov"),
          },
          {
            name: __(
              salesChannelId === "new"
                ? __("Nový")
                : __(`Úprava kanálu: ${salesChannelId}`),
            ),
          },
        ]}
      />
      <Tabs />
      <Segment noBottomGutter>
        {isFetching ? (
          <ContentLoader size="xl" />
        ) : (
          <SalesChannelForm
            initialValues={salesChannel}
            onSubmit={handleSubmit}
            deliveryOptions={deliveryOptions}
            appSpace={ppo}
          />
        )}
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    submitting: isSubmitting(FORM_NAME)(state),
  };
};

BasicSettings.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      salesChannelId: PropTypes.string.isRequired,
      ppo: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  submitting: PropTypes.bool,
};

BasicSettings.defaultProps = {
  submitting: false,
};

export default connect(mapStateToProps)(withTheme(BasicSettings));
