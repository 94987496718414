import { Field } from "react-final-form";
import { useAuth } from "@sportnet/auth-react";
import PhotogalleryWidget from "@sportnet/content/editor/widgets/Photogallery";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import styled from "styled-components";

const Wrapper = styled.div`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
`;

const Photogallery = ({ hidden }) => {
  const { ppo, app, accessToken } = useAuth();

  return (
    <Wrapper $hidden={hidden}>
      <Segment raised>
        <Field name="pictures">
          {({ input: { value, onChange } }) => (
            <PhotogalleryWidget
              data={{
                pictures: value || [],
              }}
              appSpace={ppo}
              appId={app}
              accessToken={accessToken}
              onChange={(v) => {
                if ((v.pictures || []) !== (value || [])) {
                  onChange(
                    v
                      ? (v.pictures || []).map((i) => {
                          i.uri =
                            i.uri ||
                            (i.public_url || "").replace(
                              "https://mediamanager.sportnet.online/media/",
                              "",
                            );
                          return i;
                        })
                      : [],
                  );
                }
              }}
            />
          )}
        </Field>
      </Segment>
    </Wrapper>
  );
};

export default Photogallery;
