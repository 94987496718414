import { __ } from '../../utilities';
import { v1 as uuid } from 'uuid';
import AlertModal from './AlertModal';
import Button from '@sportnet/ui/Button';
import Modal, { ModalActions } from '@sportnet/ui/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import Segment from '@sportnet/ui/Segment';

export const WithPopupsProps = {
  alert: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

const PopupTypes = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
};

const makeHoc = Component => {
  return class MakeHoc extends React.PureComponent {
    promises = {};

    constructor(props) {
      super(props);
      this.state = {
        popups: {},
        isSubmitting: false,
      };
    }

    renderPopup = popupUid => {
      const popup = this.state.popups[popupUid];
      const { type } = popup;
      if (type === PopupTypes.ALERT) {
        const alertPopup = popup;
        return (
          <AlertModal
            {...alertPopup.payload.options}
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve();
              this.close(popupUid);
            }}
          >
            {alertPopup.payload.text}
          </AlertModal>
        );
      }
      if (type === PopupTypes.CONFIRM) {
        const confirmPopup = popup;
        return (
          <Modal
            centered
            size="xs"
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve(false);
              this.close(popupUid);
            }}
          >
            <Segment>{confirmPopup.payload.text}</Segment>
            <ModalActions>
              <Button
                type="button"
                onClick={() => {
                  this.promises[popupUid].resolve(false);
                  this.close(popupUid);
                }}
              >
                {__('Zavrieť')}
              </Button>
              <Button
                loading={this.state.isSubmitting}
                primary
                type="button"
                onClick={async () => {
                  if (confirmPopup.payload.onConfirm) {
                    this.setState({
                      isSubmitting: true,
                    });
                    try {
                      await confirmPopup.payload.onConfirm();
                      this.promises[popupUid].resolve(true);
                    } catch (e) {
                      this.promises[popupUid].resolve(false);
                      throw e;
                    }
                    this.close(popupUid);
                    this.setState({
                      isSubmitting: false,
                    });
                  } else {
                    this.promises[popupUid].resolve(true);
                    this.close(popupUid);
                  }
                }}
              >
                {__('Potvrdiť')}
              </Button>
            </ModalActions>
          </Modal>
        );
      }
      return null;
    };

    close(uid) {
      delete this.promises[uid];
      const popups = { ...this.state.popups };
      delete popups[uid];
      this.setState({
        popups,
      });
    }

    alert = (text, options = {}) => {
      return new Promise(resolve => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const popups = { ...this.state.popups };
        popups[uid] = {
          type: PopupTypes.ALERT,
          payload: {
            text,
            options,
          },
        };

        this.setState({
          popups,
        });
      });
    };

    confirm = (text, onConfirm) => {
      return new Promise(resolve => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const popups = { ...this.state.popups };
        popups[uid] = {
          type: PopupTypes.CONFIRM,
          payload: {
            text,
            onConfirm,
          },
        };

        this.setState({
          popups,
        });
      });
    };

    render() {
      return (
        <>
          <Component
            {...this.props}
            alert={this.alert}
            confirm={this.confirm}
          />
          <>{Object.keys(this.state.popups).map(this.renderPopup)}</>
        </>
      );
    }
  };
};

export default makeHoc;
