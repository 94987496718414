import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import apiEshop from '../../../../service/apiEshop';

export const FORM_NAME = 'SET_BRAND_OPERATION';

const SetBrandOperationForm = ({ handleSubmit, org }) => {
  const [brands, setBrands] = React.useState([]);
  const [isFetchingBrands, setIsFetchingBrands] =
    React.useState(false);

  React.useEffect(() => {
    setIsFetchingBrands(true);
    apiEshop
      .adminListBrands(org)
      .then((data) => {
        setBrands(data.codelist || []);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
      })
      .finally(() => {
        setIsFetchingBrands(false);
      });
  }, [org]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={FormFieldRedux}
        name="brand"
        type="theselectsimple"
        label={__('Značka')}
        loading={isFetchingBrands}
        options={brands}
      />
    </Form>
  );
};

SetBrandOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  org: state.selectedOrg
})

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps)
)(SetBrandOperationForm);
