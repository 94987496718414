import React from 'react';
/* eslint-disable */
export default {
  budget: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
	    viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path d="M135.749,96.44V68.583c5.907,1.936,9.62,5.833,9.62,9.447c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017
            c0-12.784-10.768-23.198-25.654-25.983v-1.274c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017v1.274
            c-14.885,2.786-25.654,13.2-25.654,25.983c0,17.862,14.266,25.369,25.654,29.69v27.858c-5.907-1.936-9.62-5.833-9.62-9.447
            c0-4.427-3.589-8.017-8.017-8.017s-8.017,3.589-8.017,8.017c0,12.784,10.768,23.198,25.654,25.983v1.274
            c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-1.274c14.885-2.786,25.654-13.2,25.654-25.983
            C161.403,108.268,147.137,100.761,135.749,96.44z M119.716,90.229c-7.992-3.917-9.62-7.338-9.62-12.199
            c0-3.614,3.713-7.511,9.62-9.447V90.229z M135.749,135.577v-21.646c7.992,3.917,9.62,7.338,9.62,12.199
            C145.369,129.744,141.656,133.641,135.749,135.577z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,59.858h-68.409c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017H256
            c4.427,0,8.017-3.589,8.017-8.017C264.017,63.448,260.427,59.858,256,59.858z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M196.142,128.268h-8.551c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h8.551
            c4.427,0,8.017-3.589,8.017-8.017S200.569,128.268,196.142,128.268z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M341.512,188.124H102.079c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h239.433
            c4.427,0,8.017-3.589,8.017-8.017S345.939,188.124,341.512,188.124z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M179.039,239.431h-25.654c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h25.654
            c4.427,0,8.017-3.589,8.017-8.017S183.467,239.431,179.039,239.431z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.181,239.431h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
            c4.427,0,8.017-3.589,8.017-8.017S123.608,239.431,119.181,239.431z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M179.039,273.636h-25.654c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h25.654
            c4.427,0,8.017-3.589,8.017-8.017C187.056,277.225,183.467,273.636,179.039,273.636z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.181,273.636h-17.102c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h17.102
            c4.427,0,8.017-3.589,8.017-8.017C127.198,277.225,123.608,273.636,119.181,273.636z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M179.039,307.841h-25.654c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h25.654
            c4.427,0,8.017-3.589,8.017-8.017S183.467,307.841,179.039,307.841z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.181,307.841h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
            c4.427,0,8.017-3.589,8.017-8.017S123.608,307.841,119.181,307.841z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M179.039,342.045h-25.654c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h25.654
            c4.427,0,8.017-3.589,8.017-8.017S183.467,342.045,179.039,342.045z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.181,342.045h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
            c4.427,0,8.017-3.589,8.017-8.017S123.608,342.045,119.181,342.045z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M179.039,376.25h-25.654c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h25.654
            c4.427,0,8.017-3.589,8.017-8.017S183.467,376.25,179.039,376.25z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M119.181,376.25h-17.102c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h17.102
            c4.427,0,8.017-3.589,8.017-8.017S123.608,376.25,119.181,376.25z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M281.654,128.268h-8.551c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h8.551
            c4.427,0,8.017-3.589,8.017-8.017S286.081,128.268,281.654,128.268z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M324.409,128.268h-8.551c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h8.551
            c4.427,0,8.017-3.589,8.017-8.017S328.837,128.268,324.409,128.268z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M238.898,128.268h-8.551c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h8.551
            c4.427,0,8.017-3.589,8.017-8.017S243.325,128.268,238.898,128.268z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M273.102,94.063h-85.512c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h85.512
            c4.427,0,8.017-3.589,8.017-8.017S277.53,94.063,273.102,94.063z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M427.024,222.329h-34.739v-128.8c0-2.126-0.844-4.165-2.348-5.668L304.424,2.348C302.921,0.844,300.882,0,298.756,0
            H76.425C62.574,0,51.306,11.268,51.306,25.119v410.455c0,13.851,11.268,25.119,25.119,25.119h128.802v17.637
            c0,18.566,15.105,33.67,33.67,33.67h188.126c18.566,0,33.67-15.105,33.67-33.67V255.999
            C460.694,237.433,445.589,222.329,427.024,222.329z M306.773,27.37l58.142,58.142h-40.505c-9.725,0-17.637-7.912-17.637-17.637
            V27.37z M205.227,255.999v188.66H76.425c-5.01,0-9.086-4.076-9.086-9.086V25.119c0-5.01,4.076-9.086,9.086-9.086h214.314v51.842
            c0,18.566,15.105,33.67,33.67,33.67h51.842v120.783H238.898C220.332,222.329,205.227,237.433,205.227,255.999z M444.661,478.33
            c0,9.725-7.912,17.637-17.637,17.637H238.898c-9.725,0-17.637-7.912-17.637-17.637V255.999c0-9.725,7.912-17.637,17.637-17.637
            h188.126c9.725,0,17.637,7.912,17.637,17.637V478.33z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M409.921,256.533H256c-9.136,0-16.568,7.432-16.568,16.568v34.205c0,9.136,7.432,16.568,16.568,16.568h153.921
            c9.136,0,16.568-7.432,16.568-16.568v-34.205C426.489,263.965,419.057,256.533,409.921,256.533z M410.456,307.306
            c0,0.295-0.239,0.534-0.534,0.534H256c-0.295,0-0.534-0.239-0.534-0.534v-34.205c0-0.295,0.239-0.534,0.534-0.534h153.921
            c0.295,0,0.534,0.239,0.534,0.534V307.306z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M273.102,350.596H256c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C289.67,358.028,282.238,350.596,273.102,350.596z M273.637,384.267
            c0,0.295-0.239,0.534-0.534,0.534H256c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V384.267z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M409.921,350.596h-17.102c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C426.489,358.028,419.057,350.596,409.921,350.596z M410.456,384.267
            c0,0.295-0.239,0.534-0.534,0.534h-17.102c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V384.267z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M341.512,350.596h-17.102c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C358.08,358.028,350.648,350.596,341.512,350.596z M342.046,384.267
            c0,0.295-0.239,0.534-0.534,0.534h-17.102c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V384.267z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M273.102,419.006H256c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C289.67,426.438,282.238,419.006,273.102,419.006z M273.637,452.676
            c0,0.295-0.239,0.534-0.534,0.534H256c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V452.676z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M409.921,419.006h-17.102c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C426.489,426.438,419.057,419.006,409.921,419.006z M410.456,452.676
            c0,0.295-0.239,0.534-0.534,0.534h-17.102c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V452.676z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M341.512,419.006h-17.102c-9.136,0-16.568,7.432-16.568,16.568v17.102c0,9.136,7.432,16.568,16.568,16.568h17.102
            c9.136,0,16.568-7.432,16.568-16.568v-17.102C358.08,426.438,350.648,419.006,341.512,419.006z M342.046,452.676
            c0,0.295-0.239,0.534-0.534,0.534h-17.102c-0.295,0-0.534-0.239-0.534-0.534v-17.102c0-0.295,0.239-0.534,0.534-0.534h17.102
            c0.295,0,0.534,0.239,0.534,0.534V452.676z"/>
        </g>
      </g>
    </svg>
  ),
  collapseArrow: (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M 12 5.09375 L 11.28125 5.78125 L 2.28125 14.78125 L 3.71875 16.21875 L 12 7.9375 L 20.28125 16.21875 L 21.71875 14.78125 L 12.71875 5.78125 L 12 5.09375 z" color={color} overflow="visible" enableBackground="accumulate" fontFamily="Bitstream Vera Sans"></path>
    </svg>
  ),
  delete: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 357 357"
    >
      <g>
        <g id="clear">
          <polygon points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3 
            214.2,178.5"/>
        </g>
      </g>
    </svg>
    
  ),
  downArrow: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492 492"
    >
      <g>
        <g>
          <path fill="#515151" d="M442.668,268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872c-7.208,0-14.584,2.804-19.644,7.872
            L283.688,355.992V26.924C283.688,12.084,272.856,0,258.02,0h-22.804c-14.832,0-28.404,12.084-28.404,26.924v330.24
            L102.824,252.416c-5.068-5.068-11.444-7.872-18.652-7.872c-7.2,0-13.776,2.804-18.84,7.872l-16.028,16.12
            c-10.488,10.492-10.444,27.56,0.044,38.052l177.576,177.556c5.056,5.056,11.84,7.856,19.1,7.856h0.076
            c7.204,0,13.972-2.8,19.028-7.856l177.54-177.552C453.164,296.104,453.164,279.028,442.668,268.536z"/>
        </g>
      </g>
    </svg>
 
  ),
  edit: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485.219 485.22"
    >
      <g>
        <path d="M467.476,146.438l-21.445,21.455L317.35,39.23l21.445-21.457c23.689-23.692,62.104-23.692,85.795,0l42.886,42.897
          C491.133,84.349,491.133,122.748,467.476,146.438z M167.233,403.748c-5.922,5.922-5.922,15.513,0,21.436
          c5.925,5.955,15.521,5.955,21.443,0L424.59,189.335l-21.469-21.457L167.233,403.748z M60,296.54c-5.925,5.927-5.925,15.514,0,21.44
          c5.922,5.923,15.518,5.923,21.443,0L317.35,82.113L295.914,60.67L60,296.54z M338.767,103.54L102.881,339.421
          c-11.845,11.822-11.815,31.041,0,42.886c11.85,11.846,31.038,11.901,42.914-0.032l235.886-235.837L338.767,103.54z
            M145.734,446.572c-7.253-7.262-10.749-16.465-12.05-25.948c-3.083,0.476-6.188,0.919-9.36,0.919
          c-16.202,0-31.419-6.333-42.881-17.795c-11.462-11.491-17.77-26.687-17.77-42.887c0-2.954,0.443-5.833,0.859-8.703
          c-9.803-1.335-18.864-5.629-25.972-12.737c-0.682-0.677-0.917-1.596-1.538-2.338L0,485.216l147.748-36.986
          C147.097,447.637,146.36,447.193,145.734,446.572z"/>
      </g>
    </svg> 
  ),
  expandArrow: (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M 3.71875 7.78125 L 2.28125 9.21875 L 11.28125 18.21875 L 12 18.90625 L 12.71875 18.21875 L 21.71875 9.21875 L 20.28125 7.78125 L 12 16.0625 L 3.71875 7.78125 z" color={color} overflow="visible" enableBackground="accumulate" fontFamily="Bitstream Vera Sans"></path>
    </svg>
  ),
  export: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.973 384.973"
    >
      <g>
        <path d="M103.751,256.682h24.241c16.866-95.677,100.2-168.424,200.714-168.424c4.211,0,8.217,0.036,12.259,0.072l-44.079,44.067
          c-4.704,4.704-4.704,12.307,0,17.011c2.346,2.346,5.426,3.525,8.505,3.525s6.16-1.179,8.505-3.525l71.075-72.94l-71.063-72.94
          c-4.704-4.704-12.307-4.704-17.011,0c-4.704,4.704-4.704,12.307,0,17.011l43.682,43.682c-3.826-0.012-7.459-0.036-11.369-0.036
          C215.284,64.197,121.099,147.627,103.751,256.682z"/>
        <path d="M348.88,180.458c-6.641,0-12.03,5.39-12.03,12.03v168.424H24.062V120.306h108.273c6.641,0,12.03-5.39,12.03-12.03
          c0-6.641-5.39-12.03-12.03-12.03h-0.361H24.062c-13.281,0-24.061,10.779-24.061,24.061v240.606
          c0,13.281,10.779,24.061,24.061,24.061H336.85c13.281,0,24.061-10.779,24.061-24.061V192.488
          C360.911,185.847,355.521,180.458,348.88,180.458z"/>
      </g>
    </svg>
  ),
  file: (color) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28">
      <path fill="none" stroke={color} stroke-width="2" d="M4.969 24.015H1.991V1.985h11.974c.065 0 6.044 5.974 6.044 6.056v15.974H17"/>
      <path fill="none" stroke={color} stroke-linejoin="round" stroke-width="2" d="M19 9h-6V2"/>
      <path fill={color} fill-rule="evenodd" d="M9 12v8H5l6 7 6-7h-4v-8H9z"/>
    </svg>
  ),
  glassSearcher: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488.139 488.139"
    >
     <g>
       <path fill={color} d="M290.513,0.004C181.378,0.004,92.916,88.466,92.916,197.6c0,46.967,16.477,90.043,43.836,124.03
         L6.156,452.196c-8.208,8.238-8.208,21.553,0,29.761c8.208,8.238,21.553,8.238,29.761,0l130.596-130.566
         c33.926,27.329,77.032,43.806,124.03,43.806c109.134,0,197.597-88.462,197.597-197.597S399.616,0.004,290.513,0.004z
          M290.513,364.797c-92.232,0-167.197-74.996-167.197-167.197S198.341,30.403,290.513,30.403S457.71,105.399,457.71,197.6
         S382.714,364.797,290.513,364.797z"/>
     </g>
    </svg> 
  ),
  checked: () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
    >
      <path d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"/>
    </svg>    
  ),
  lessThan: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path d="M 30.28125 8.28125 L 14.28125 24.28125 L 13.59375 25 L 14.28125 25.71875 L 30.28125 41.71875 L 31.71875 40.28125 L 16.4375 25 L 31.71875 9.71875 L 30.28125 8.28125 z" color={color} overflow="visible" enableBackground="accumulate" fontFamily="Bitstream Vera Sans"></path>
    </svg>
  ),
  logo: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0, 0, 420, 80"
    >
      <g id="Background">
        <rect x="0" y="0" width="420" height="80" fill={color} fillOpacity="0"/>
      </g>
      <g id="Layer_1">
        <path d="M64.857,44.837 Q64.857,47.793 64.11,50.154 Q63.364,52.515 62.139,54.319 Q60.914,56.124 59.268,57.391 Q57.622,58.658 55.861,59.464 Q54.1,60.27 52.282,60.654 Q50.463,61.038 48.855,61.038 L10.459,61.038 L10.459,51.056 L48.855,51.056 Q51.726,51.056 53.315,49.367 Q54.904,47.678 54.904,44.837 Q54.904,43.455 54.483,42.303 Q54.062,41.152 53.277,40.307 Q52.492,39.463 51.363,39.002 Q50.234,38.541 48.855,38.541 L25.963,38.541 Q23.552,38.541 20.757,37.677 Q17.962,36.814 15.57,34.894 Q13.177,32.975 11.589,29.865 Q10,26.755 10,22.264 Q10,17.772 11.589,14.682 Q13.177,11.591 15.57,9.653 Q17.962,7.714 20.757,6.85 Q23.552,5.986 25.963,5.986 L59.842,5.986 L59.842,15.968 L25.963,15.968 Q23.13,15.968 21.542,17.695 Q19.953,19.423 19.953,22.264 Q19.953,25.143 21.542,26.813 Q23.13,28.483 25.963,28.483 L48.855,28.483 L48.932,28.483 Q50.54,28.521 52.339,28.924 Q54.138,29.328 55.918,30.172 Q57.698,31.017 59.306,32.303 Q60.914,33.589 62.158,35.393 Q63.402,37.198 64.13,39.539 Q64.857,41.881 64.857,44.837 z" fill="#fff"/>
        <path d="M117.647,47.025 Q117.647,49.598 116.996,51.651 Q116.345,53.705 115.273,55.26 Q114.201,56.815 112.785,57.909 Q111.369,59.003 109.818,59.694 Q108.268,60.385 106.698,60.711 Q105.129,61.038 103.712,61.038 L85.758,61.038 L85.758,51.056 L103.712,51.056 Q105.703,51.056 106.698,50.02 Q107.694,48.983 107.694,47.025 L107.694,33.589 Q107.694,31.516 106.679,30.518 Q105.665,29.52 103.712,29.52 L85.835,29.52 Q83.806,29.52 82.773,30.537 Q81.739,31.554 81.739,33.512 L81.739,74.014 L71.786,74.014 L71.786,33.512 Q71.786,30.94 72.437,28.905 Q73.087,26.871 74.178,25.316 Q75.269,23.761 76.686,22.667 Q78.102,21.573 79.653,20.882 Q81.203,20.191 82.792,19.864 Q84.38,19.538 85.758,19.538 L103.712,19.538 Q106.277,19.538 108.306,20.191 Q110.335,20.843 111.885,21.918 Q113.436,22.993 114.527,24.414 Q115.618,25.834 116.307,27.389 Q116.996,28.944 117.321,30.518 Q117.647,32.092 117.647,33.512 z" fill="#fff"/>
        <path d="M171.164,47.025 Q171.164,49.598 170.513,51.651 Q169.862,53.705 168.79,55.26 Q167.718,56.815 166.302,57.909 Q164.886,59.003 163.335,59.694 Q161.785,60.385 160.196,60.711 Q158.608,61.038 157.191,61.038 L139.237,61.038 Q137.17,61.038 134.72,60.308 Q132.27,59.579 130.184,57.909 Q128.097,56.239 126.7,53.571 Q125.303,50.903 125.303,47.025 L125.303,33.512 Q125.303,29.673 126.7,27.005 Q128.097,24.337 130.184,22.667 Q132.27,20.997 134.72,20.268 Q137.17,19.538 139.237,19.538 L157.191,19.538 Q161.019,19.538 163.699,20.92 Q166.379,22.302 168.025,24.394 Q169.671,26.487 170.417,28.944 Q171.164,31.401 171.164,33.512 z M161.211,33.589 Q161.211,31.516 160.177,30.518 Q159.143,29.52 157.191,29.52 L139.314,29.52 Q137.323,29.52 136.29,30.537 Q135.256,31.554 135.256,33.512 L135.256,47.025 Q135.256,48.983 136.29,50.02 Q137.323,51.056 139.314,51.056 L157.191,51.056 Q159.22,51.056 160.215,50.02 Q161.211,48.983 161.211,47.025 z" fill="#fff"/>
        <path d="M215.838,29.52 L193.443,29.52 Q191.414,29.52 190.381,30.537 Q189.347,31.554 189.347,33.512 L189.347,61.038 L179.394,61.038 L179.394,33.512 Q179.394,30.94 180.045,28.905 Q180.696,26.871 181.787,25.316 Q182.878,23.761 184.294,22.667 Q185.711,21.573 187.261,20.882 Q188.811,20.191 190.4,19.864 Q191.989,19.538 193.367,19.538 L215.838,19.538 z" fill="#fff"/>
        <path d="M260.014,29.52 L242.635,29.52 L242.635,61.038 L232.567,61.038 L232.567,29.52 L219.628,29.52 L219.628,19.538 L232.567,19.538 L232.567,5.986 L242.635,5.986 L242.635,19.538 L260.014,19.538 z" fill="#fff"/>
        <path d="M312.421,61.038 L302.468,61.038 L302.468,38.004 Q302.468,36.046 301.798,34.491 Q301.128,32.936 299.98,31.823 Q298.831,30.71 297.281,30.115 Q295.731,29.52 293.931,29.52 L276.514,29.52 L276.514,61.038 L266.56,61.038 L266.56,24.49 Q266.56,23.454 266.943,22.552 Q267.326,21.65 268.015,20.978 Q268.704,20.306 269.623,19.922 Q270.542,19.538 271.575,19.538 L294.008,19.538 Q295.884,19.538 297.97,19.96 Q300.056,20.383 302.104,21.323 Q304.153,22.264 306.009,23.703 Q307.866,25.143 309.301,27.197 Q310.737,29.251 311.579,31.938 Q312.421,34.625 312.421,38.004 z" fill="#FF2601"/>
        <path d="M365.67,33.282 Q365.67,35.355 364.943,37.754 Q364.216,40.154 362.589,42.207 Q360.962,44.261 358.34,45.643 Q355.717,47.025 351.966,47.025 L334.012,47.025 L334.012,37.543 L351.966,37.543 Q353.995,37.543 355.105,36.295 Q356.215,35.048 356.215,33.205 Q356.215,31.247 354.971,30.134 Q353.727,29.02 351.966,29.02 L334.012,29.02 Q331.983,29.02 330.873,30.268 Q329.763,31.516 329.763,33.359 L329.763,47.294 Q329.763,49.29 331.007,50.404 Q332.251,51.517 334.088,51.517 L351.966,51.517 L351.966,61.038 L334.012,61.038 Q331.945,61.038 329.552,60.308 Q327.16,59.579 325.112,57.947 Q323.063,56.316 321.685,53.686 Q320.307,51.056 320.307,47.294 L320.307,33.282 Q320.307,31.209 321.035,28.809 Q321.762,26.41 323.389,24.356 Q325.016,22.302 327.638,20.92 Q330.26,19.538 334.012,19.538 L351.966,19.538 Q354.033,19.538 356.426,20.268 Q358.818,20.997 360.866,22.628 Q362.914,24.26 364.292,26.89 Q365.67,29.52 365.67,33.282 z" fill="#FF2601"/>
        <path d="M410,29.52 L392.62,29.52 L392.62,61.038 L382.552,61.038 L382.552,29.52 L369.613,29.52 L369.613,19.538 L382.552,19.538 L382.552,5.986 L392.62,5.986 L392.62,19.538 L410,19.538 z" fill="#FF2601"/>
      </g>
    </svg>
  ),
  menu: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path d="M491.318,235.318H20.682C9.26,235.318,0,244.577,0,256s9.26,20.682,20.682,20.682h470.636
            c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M491.318,78.439H20.682C9.26,78.439,0,87.699,0,99.121c0,11.422,9.26,20.682,20.682,20.682h470.636
            c11.423,0,20.682-9.26,20.682-20.682C512,87.699,502.741,78.439,491.318,78.439z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M491.318,392.197H20.682C9.26,392.197,0,401.456,0,412.879s9.26,20.682,20.682,20.682h470.636
            c11.423,0,20.682-9.259,20.682-20.682S502.741,392.197,491.318,392.197z"/>
        </g>
      </g>
    </svg>
  ),
  next: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 477.175 477.175"
    >
      <g>
        <path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
          c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
          "/>
      </g>
    </svg>
  ),
  plus: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 357 357"
    >
      <g>
        <g id="add">
          <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z"/>
        </g>
      </g>
    </svg>
  ),
  printTool: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485.213 485.212"
    >
      <g>
        <g>
          <path d="M121.305,30.327h242.604v60.651h30.327V30.327C394.236,13.595,380.674,0,363.909,0H121.305
            c-16.731,0-30.327,13.595-30.327,30.327v60.651h30.327V30.327z"/>
          <path d="M454.89,121.304H30.328c-16.731,0-30.327,13.591-30.327,30.324v151.629c0,16.764,13.595,30.327,30.327,30.327h60.651
            v121.302c0,16.764,13.595,30.326,30.327,30.326h242.604c16.765,0,30.327-13.562,30.327-30.326V333.584h60.653
            c16.759,0,30.322-13.563,30.322-30.327V151.628C485.212,134.895,471.648,121.304,454.89,121.304z M363.909,454.886H121.305
            v-212.28h242.604V454.886z M424.562,212.282c-16.764,0-30.326-13.564-30.326-30.327c0-16.731,13.562-30.327,30.326-30.327
            c16.765,0,30.327,13.595,30.327,30.327C454.89,198.717,441.327,212.282,424.562,212.282z"/>
          <rect x="151.629" y="272.93" width="121.304" height="30.327"/>
          <rect x="151.629" y="333.584" width="181.958" height="30.321"/>
          <rect x="151.629" y="394.237" width="181.958" height="30.322"/>
        </g>
      </g>
    </svg>
  ),
  sportnetLogo: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0, 0, 420, 80"
    >
      <g id="Background">
        <rect x="0" y="0" width="420" height="80" fill="#000000" fillOpacity="0"/>
      </g>
      <g id="Layer_1">
        <path d="M64.857,44.837 Q64.857,47.793 64.11,50.154 Q63.364,52.515 62.139,54.319 Q60.914,56.124 59.268,57.391 Q57.622,58.658 55.861,59.464 Q54.1,60.27 52.282,60.654 Q50.463,61.038 48.855,61.038 L10.459,61.038 L10.459,51.056 L48.855,51.056 Q51.726,51.056 53.315,49.367 Q54.904,47.678 54.904,44.837 Q54.904,43.455 54.483,42.303 Q54.062,41.152 53.277,40.307 Q52.492,39.463 51.363,39.002 Q50.234,38.541 48.855,38.541 L25.963,38.541 Q23.552,38.541 20.757,37.677 Q17.962,36.814 15.57,34.894 Q13.177,32.975 11.589,29.865 Q10,26.755 10,22.264 Q10,17.772 11.589,14.682 Q13.177,11.591 15.57,9.653 Q17.962,7.714 20.757,6.85 Q23.552,5.986 25.963,5.986 L59.842,5.986 L59.842,15.968 L25.963,15.968 Q23.13,15.968 21.542,17.695 Q19.953,19.423 19.953,22.264 Q19.953,25.143 21.542,26.813 Q23.13,28.483 25.963,28.483 L48.855,28.483 L48.932,28.483 Q50.54,28.521 52.339,28.924 Q54.138,29.328 55.918,30.172 Q57.698,31.017 59.306,32.303 Q60.914,33.589 62.158,35.393 Q63.402,37.198 64.13,39.539 Q64.857,41.881 64.857,44.837 z" fill="#fff"/>
        <path d="M117.647,47.025 Q117.647,49.598 116.996,51.651 Q116.345,53.705 115.273,55.26 Q114.201,56.815 112.785,57.909 Q111.369,59.003 109.818,59.694 Q108.268,60.385 106.698,60.711 Q105.129,61.038 103.712,61.038 L85.758,61.038 L85.758,51.056 L103.712,51.056 Q105.703,51.056 106.698,50.02 Q107.694,48.983 107.694,47.025 L107.694,33.589 Q107.694,31.516 106.679,30.518 Q105.665,29.52 103.712,29.52 L85.835,29.52 Q83.806,29.52 82.773,30.537 Q81.739,31.554 81.739,33.512 L81.739,74.014 L71.786,74.014 L71.786,33.512 Q71.786,30.94 72.437,28.905 Q73.087,26.871 74.178,25.316 Q75.269,23.761 76.686,22.667 Q78.102,21.573 79.653,20.882 Q81.203,20.191 82.792,19.864 Q84.38,19.538 85.758,19.538 L103.712,19.538 Q106.277,19.538 108.306,20.191 Q110.335,20.843 111.885,21.918 Q113.436,22.993 114.527,24.414 Q115.618,25.834 116.307,27.389 Q116.996,28.944 117.321,30.518 Q117.647,32.092 117.647,33.512 z" fill="#fff"/>
        <path d="M171.164,47.025 Q171.164,49.598 170.513,51.651 Q169.862,53.705 168.79,55.26 Q167.718,56.815 166.302,57.909 Q164.886,59.003 163.335,59.694 Q161.785,60.385 160.196,60.711 Q158.608,61.038 157.191,61.038 L139.237,61.038 Q137.17,61.038 134.72,60.308 Q132.27,59.579 130.184,57.909 Q128.097,56.239 126.7,53.571 Q125.303,50.903 125.303,47.025 L125.303,33.512 Q125.303,29.673 126.7,27.005 Q128.097,24.337 130.184,22.667 Q132.27,20.997 134.72,20.268 Q137.17,19.538 139.237,19.538 L157.191,19.538 Q161.019,19.538 163.699,20.92 Q166.379,22.302 168.025,24.394 Q169.671,26.487 170.417,28.944 Q171.164,31.401 171.164,33.512 z M161.211,33.589 Q161.211,31.516 160.177,30.518 Q159.143,29.52 157.191,29.52 L139.314,29.52 Q137.323,29.52 136.29,30.537 Q135.256,31.554 135.256,33.512 L135.256,47.025 Q135.256,48.983 136.29,50.02 Q137.323,51.056 139.314,51.056 L157.191,51.056 Q159.22,51.056 160.215,50.02 Q161.211,48.983 161.211,47.025 z" fill="#fff"/>
        <path d="M215.838,29.52 L193.443,29.52 Q191.414,29.52 190.381,30.537 Q189.347,31.554 189.347,33.512 L189.347,61.038 L179.394,61.038 L179.394,33.512 Q179.394,30.94 180.045,28.905 Q180.696,26.871 181.787,25.316 Q182.878,23.761 184.294,22.667 Q185.711,21.573 187.261,20.882 Q188.811,20.191 190.4,19.864 Q191.989,19.538 193.367,19.538 L215.838,19.538 z" fill="#fff"/>
        <path d="M260.014,29.52 L242.635,29.52 L242.635,61.038 L232.567,61.038 L232.567,29.52 L219.628,29.52 L219.628,19.538 L232.567,19.538 L232.567,5.986 L242.635,5.986 L242.635,19.538 L260.014,19.538 z" fill="#fff"/>
        <path d="M312.421,61.038 L302.468,61.038 L302.468,38.004 Q302.468,36.046 301.798,34.491 Q301.128,32.936 299.98,31.823 Q298.831,30.71 297.281,30.115 Q295.731,29.52 293.931,29.52 L276.514,29.52 L276.514,61.038 L266.56,61.038 L266.56,24.49 Q266.56,23.454 266.943,22.552 Q267.326,21.65 268.015,20.978 Q268.704,20.306 269.623,19.922 Q270.542,19.538 271.575,19.538 L294.008,19.538 Q295.884,19.538 297.97,19.96 Q300.056,20.383 302.104,21.323 Q304.153,22.264 306.009,23.703 Q307.866,25.143 309.301,27.197 Q310.737,29.251 311.579,31.938 Q312.421,34.625 312.421,38.004 z" fill="#FF2601"/>
        <path d="M365.67,33.282 Q365.67,35.355 364.943,37.754 Q364.216,40.154 362.589,42.207 Q360.962,44.261 358.34,45.643 Q355.717,47.025 351.966,47.025 L334.012,47.025 L334.012,37.543 L351.966,37.543 Q353.995,37.543 355.105,36.295 Q356.215,35.048 356.215,33.205 Q356.215,31.247 354.971,30.134 Q353.727,29.02 351.966,29.02 L334.012,29.02 Q331.983,29.02 330.873,30.268 Q329.763,31.516 329.763,33.359 L329.763,47.294 Q329.763,49.29 331.007,50.404 Q332.251,51.517 334.088,51.517 L351.966,51.517 L351.966,61.038 L334.012,61.038 Q331.945,61.038 329.552,60.308 Q327.16,59.579 325.112,57.947 Q323.063,56.316 321.685,53.686 Q320.307,51.056 320.307,47.294 L320.307,33.282 Q320.307,31.209 321.035,28.809 Q321.762,26.41 323.389,24.356 Q325.016,22.302 327.638,20.92 Q330.26,19.538 334.012,19.538 L351.966,19.538 Q354.033,19.538 356.426,20.268 Q358.818,20.997 360.866,22.628 Q362.914,24.26 364.292,26.89 Q365.67,29.52 365.67,33.282 z" fill="#FF2601"/>
        <path d="M410,29.52 L392.62,29.52 L392.62,61.038 L382.552,61.038 L382.552,29.52 L369.613,29.52 L369.613,19.538 L382.552,19.538 L382.552,5.986 L392.62,5.986 L392.62,19.538 L410,19.538 z" fill="#FF2601"/>
      </g>
    </svg>
  ),
  undoArrow: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 409.151 409.15"
    >
      <g>
        <path d="M212.018,7.461c-60.921,0-118.198,28.518-155.245,75.784L0.122,50.491L0,237.986l162.426-93.645l-52.213-30.21
          c25.648-28.724,62.548-46.02,101.805-46.02c75.233,0,136.475,61.213,136.475,136.465c0,75.25-61.241,136.468-136.475,136.468
          v60.645c108.701,0,197.133-88.423,197.133-197.112C409.151,95.891,320.719,7.461,212.018,7.461z"/>
      </g>
    </svg>
  ),
  upArrow: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492 492"
    >
      <g>
        <g>
          <path fill="#515151" d="M442.627,185.388L265.083,7.844C260.019,2.78,253.263,0,245.915,0c-7.204,0-13.956,2.78-19.02,7.844L49.347,185.388
            c-10.488,10.492-10.488,27.568,0,38.052l16.12,16.128c5.064,5.06,11.82,7.844,19.028,7.844c7.204,0,14.192-2.784,19.252-7.844
            l103.808-103.584v329.084c0,14.832,11.616,26.932,26.448,26.932h22.8c14.832,0,27.624-12.1,27.624-26.932V134.816l104.396,104.752
            c5.06,5.06,11.636,7.844,18.844,7.844s13.864-2.784,18.932-7.844l16.072-16.128C453.163,212.952,453.123,195.88,442.627,185.388z"
            />
        </g>
      </g>
    </svg>
  ),
  user: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M 12 4 C 9.790861 4 8 5.790861 8 8 C 8 10.209139 9.790861 12 12 12 C 14.209139 12 16 10.209139 16 8 C 16 5.790861 14.209139 4 12 4 z M 12 14 C 5.9 14 4 18 4 18 L 4 20 L 20 20 L 20 18 C 20 18 18.1 14 12 14 z"></path>
    </svg>
  ),
};
/* eslint-enable */