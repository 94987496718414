import { NumberParam } from '@sportnet/query-hoc/useQuery';

export const IMPORTS_LIST = 'IMPORTS_LIST';

export const IMPORTS_LIST_CONFIG = {
  parameters: {
    limit: NumberParam(100),
    offset: NumberParam(0),
  }
};

export const SIDEBAR_CLOSE = 'IMPORTS_SIDEBAR_CLOSE';
export const SIDEBAR_OPEN = 'IMPORTS_SIDEBAR_OPEN';
export const TOGGLE_SIDEBAR = 'IMPORTS_TOGGLE_SIDEBAR';
