import React from 'react';
import apiEshop from '../service/apiEshop';

export const useCodelistRequestStatus = () => {
  const [statusesByValue, setStatusesByValue] = React.useState({});
  const [statuses, setStatuses] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const { codelist } = await apiEshop.getCodelistRequestStatus();
        setStatuses(codelist);
        setStatusesByValue(
          codelist.reduce((acc, status) => {
            acc[status.value] = status;
            return acc;
          }, {}),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return {
    statusesByValue,
    statuses,
  };
};
