import { ArrayParam, NumberParam, StringParam } from '@sportnet/query-hoc/useQuery';

export const RECEIPT_ORDERS_LIST = 'RECEIPT_ORDERS_LIST';

export const RECEIPT_ORDERS_LIST_CONFIG = {
  parameters: {
    limit: NumberParam(30),
    offset: NumberParam(0),
    q: StringParam(''),
    saleschannels: ArrayParam(StringParam(''), []),
  },
};
