import { objectToParams, paramsToObject } from "@sportnet/utilities";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const create = (config, WrappedComponent) => {
  class PaginationHoc extends PureComponent {
    handleChangeParams(params) {
      const queryParams = { ...params };
      queryParams.page = 1;
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?${objectToParams(queryParams)}`,
      });
    }

    handleChangePage(page) {
      const queryParams = paramsToObject(
        this.props.location.search.replace(/^\?/, "")
      );
      queryParams.page = page;

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?${objectToParams(queryParams)}`,
      });
    }

    render() {
      const queryParams = paramsToObject(
        this.props.location.search.replace(/^\?/, "")
      );
      let { page } = queryParams;
      if (!page) {
        page = config.defaultPage;
      }

      const params = queryParams;

      page = parseInt(page, 10);

      const newProps = {
        pagination: {
          page,
          params,
          handleChangePage: (newPage) => this.handleChangePage(newPage),
          handleChangeParams: (newParams) => this.handleChangeParams(newParams),
        },
      };
      return <WrappedComponent {...this.props} {...newProps} />;
    }
  }

  PaginationHoc.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  return PaginationHoc;
};

function connectPagination(
  config = {
    defaultPage: 1,
  }
) {
  return (WrappedComponent) => create(config, WrappedComponent);
}

export default connectPagination;
