import {
  RECEIVE_RECEIPTS_ERROR_ADMIN,
  RECEIVE_RECEIPTS_SUCCESS_ADMIN,
  REQUEST_RECEIPTS_ADMIN,
} from './constants';
import Api from '../../service/apiEshop';

export const requestAdminReceipts = () => ({
  type: REQUEST_RECEIPTS_ADMIN,
});

export const receiveReceiptsAdminSuccess = payload => ({
  type: RECEIVE_RECEIPTS_SUCCESS_ADMIN,
  payload,
});

export const receiveReceiptsAdminError = payload => ({
  type: RECEIVE_RECEIPTS_ERROR_ADMIN,
  payload,
});

export const fetchAdminReceipts = (
  query,
  isAdmin = false,
  supplier_id = null,
) => dispatch => {
  dispatch(requestAdminReceipts());
  if (isAdmin) {
    return Api.adminListReceipts(supplier_id, query)
      .then(data => {
        const status = null;
        dispatch(receiveReceiptsAdminSuccess(data, status));
      })
      .catch(error => {
        dispatch(receiveReceiptsAdminError(error));
      });
  }
  return Api.adminListSupplierReceipts(supplier_id, query)
    .then(data => {
      const status = null;
      dispatch(receiveReceiptsAdminSuccess(data, status));
    })
    .catch(error => {
      dispatch(receiveReceiptsAdminError(error));
    });
};
