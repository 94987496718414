import * as React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { __ } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { required } from "../../utilities/validations";
import FormFieldRedux from "@sportnet/ui/FormField/redux-form";
import PropTypes from "prop-types";

const C = ({ searchParams, parameters }) => {
  return (
    <>
      <Field
        required
        validate={[required]}
        component={FormFieldRedux}
        name="name"
        label={__("Názov kategórie")}
      />
      <Field
        component={FormFieldRedux}
        name="priority"
        type="number"
        step="1"
        label={__("Priorita")}
      />
      <Field
        placeholder={__('Bude vyplnené automaticky (príklad: "url")')}
        info={__(
          'Relatívna URL, na ktorej bude kategória dostupná (príklad: "url-novej-kategorie"). Hodnota bude predvyplnená automaticky po prvom uložení.'
        )}
        label={__("URL")}
        component={FormFieldRedux}
        name="url"
      />
      <Field
        component={FormFieldRedux}
        name="meta.title"
        counter={{ characters: true }}
        label={__("Meta title")}
      />
      <Field
        component={FormFieldRedux}
        type="textarea"
        name="meta.description"
        counter={{ characters: true }}
        label={__("Meta description")}
      />
      <Field
        name="searchParams"
        label={__("Parametre")}
        component={FormFieldRedux}
        multiple
        type="theselectsimple"
        options={parameters
          .map((item) => {
            const exists = searchParams.includes(item);
            if (!exists) {
              return { label: item.label, value: item.label };
            }
            return null;
          })
          .filter((i) => i)}
      />
    </>
  );
};

C.propTypes = {
  parameters: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string }))
    .isRequired,
  searchParams: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  return {
    searchParams: selector(state, "searchParams") || [],
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({ destroyOnUnmount: true })
)(C);
