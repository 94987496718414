import {
  FAILURE_SALES_CHANNELS_CATEGORIES,
  RECEIVE_SALES_CHANNELS_CATEGORIES,
  REQUEST_SALES_CHANNELS_CATEGORIES,
} from './constants';

export const salesChannelsCategoriesReducer = (
  state = {
    data: [],
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_SALES_CHANNELS_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_SALES_CHANNELS_CATEGORIES:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
      };
    case FAILURE_SALES_CHANNELS_CATEGORIES:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
