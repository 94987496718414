import { Field } from "react-final-form";
import { __ } from "@sportnet/ui/utilities";
import { connect } from "react-redux";
import { rem } from "polished";
import FormField from "@sportnet/ui/FormField";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Skeleton from "@sportnet/ui/Skeleton";
import apiEshop from "../../../service/apiEshop";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(8)};

  > .separator {
    width: ${rem(20)};
  }
`;

const SeparatorWrapper = styled.div`
  ${(props) => {
    return css`
      width: ${rem(20 * props.indent)};
    `;
  }}
  float: left;
  display: flex;
  align-self: center;
`;

const Separator = styled.div`
  float: left;
  height: ${rem(1)};
  border: ${rem(1)} dashed #ebebeb;
  width: 100%;
`;

const FormFieldWrapper = styled.div`
  > div {
    margin: 0 !important;
  }

  > div > div {
    padding: 0 !important;
  }
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

const CategoriesWrapper = styled.div``;

const Categories = ({ org, hidden }) => {
  const [isFetchingCategories, setIsFetchingCategories] = React.useState(false);
  const [categories, setCategories] = React.useState();

  React.useEffect(() => {
    setIsFetchingCategories(true);
    apiEshop
      .adminGetCategoriesTree(org)
      .then((data) => {
        setCategories(data.categories || []);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam kategórií"));
      })
      .finally(() => {
        setIsFetchingCategories(false);
      });
  }, [org]);

  const renderCategories = (items = [], indent = 0) => {
    return (
      <CategoriesWrapper>
        {items.map((category, idx) => {
          return (
            <Category key={idx}>
              <FlexRow>
                {indent > 0 && (
                  <SeparatorWrapper indent={indent}>
                    <Separator />
                  </SeparatorWrapper>
                )}
                <Field
                  name={`globalCategories.k${category._id}`}
                  type="checkbox"
                >
                  {({ input: { checked, onChange } }) => (
                    <FormFieldWrapper>
                      <FormField
                        checked={checked}
                        onChange={onChange}
                        label={category.name}
                        type="checkbox"
                      />
                    </FormFieldWrapper>
                  )}
                </Field>
              </FlexRow>
              {category.categories &&
                renderCategories(category.categories, indent + 1)}
            </Category>
          );
        })}
      </CategoriesWrapper>
    );
  };

  return (
    <Wrapper $hidden={hidden}>
      <Segment raised>
        {isFetchingCategories ? (
          <SkeletonWrapper>
            <FlexRow>
              <Skeleton height={16} width={16} />
              <Skeleton height={12} width={32} borderRadius={12} />
            </FlexRow>
            <FlexRow>
              <Separator className="separator" />
              <Skeleton height={16} width={16} />
              <Skeleton height={12} width={48} borderRadius={12} />
            </FlexRow>
            <FlexRow>
              <Separator className="separator" />
              <Skeleton height={16} width={16} />
              <Skeleton height={12} width={42} borderRadius={12} />
            </FlexRow>
            <FlexRow>
              <Skeleton height={16} width={16} />
              <Skeleton height={12} width={64} borderRadius={12} />
            </FlexRow>
            <FlexRow>
              <Separator className="separator" />
              <Skeleton height={16} width={16} />
              <Skeleton height={12} width={42} borderRadius={12} />
            </FlexRow>
          </SkeletonWrapper>
        ) : (
          renderCategories(categories || [])
        )}
      </Segment>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  org: state.selectedOrg,
});

export default connect(mapStateToProps)(Categories);
