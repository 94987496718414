import { SIDEBAR_CLOSE, SIDEBAR_OPEN, TOGGLE_SIDEBAR } from './constants';

export const importsReducer = (
  state = {
    showSidebar: false
  },
  action
) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        showSidebar: true
      }
   case SIDEBAR_CLOSE:
     return {
       ...state,
       showSidebar: false
     }

   case TOGGLE_SIDEBAR:
     return {
       ...state,
       showSidebar: !state.showSidebar
     }

   default:
     return state;
  }
}
