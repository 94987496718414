import { createSelector } from "reselect";
import { getProp } from "../../utilities";

const categoriesDomainSelector = (state) => state.categories;

export const categoriesListSelector = createSelector(
  categoriesDomainSelector,
  (substate) => getProp(substate, ["data"], []),
);

export const categoriesIsFetchingSelector = createSelector(
  categoriesDomainSelector,
  (substate) => getProp(substate, ["isFetching"], true),
);

export const categoriesFlatCodelistSelector = createSelector(
  categoriesListSelector,
  (categoriesTree) => {
    const flatCategories = [];

    const traverseCategoriesTree = (categories) => {
      categories.forEach((category) => {
        flatCategories.push({
          value: category._id,
          label: category.name,
        });
        if (category.categories && category.categories.length) {
          traverseCategoriesTree(category.categories);
        }
      });
    };

    traverseCategoriesTree(categoriesTree);

    return flatCategories;
  },
);
