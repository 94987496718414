import { ContextBar, ContextBarItem } from "@sportnet/ui/ContextBar";
import { FilterPanel, SelectFilter } from "@sportnet/ui/FilterPanel";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, formatPrice, getProp } from "../../utilities";
import { connect } from "react-redux";
import { fetchAdminReceipts } from "./actions";
import { format } from "date-fns";
import { orderItemPropTypes } from "../OrderDetail/OrderItem";
import { routerParametersDefinition } from "../App/definitions";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import connectPagination from "../PaginationHoc";

const LIMIT = 50;

const ReceiptsList = ({
  pagination: { params, page, handleChangeParams },
  dispatch,
  acl,
  offset,
  total,
  isFetching,
  receipts,
  history,
}) => {
  const { ppo } = useAuth();

  const getReceipts = React.useCallback(
    (query, page = 1) => {
      const offset = page * LIMIT - LIMIT;
      const result = {
        offset,
        limit: LIMIT,
        ...query,
      };
      dispatch(fetchAdminReceipts(result, acl.adminListReceipts, ppo));
    },
    [ppo],
  );

  React.useEffect(() => {
    getReceipts(params, page);
  }, [getReceipts]);

  const handleChangeStatus = (value) => {
    params.statuses = value[value.length - 1];
    handleChangeParams(params);
    getReceipts(params, page);
  };

  const handleRedirect = (e, _id) => {
    e.stopPropagation();
    history.push(`/admin/${ppo}/receipt/${_id}`);
  };

  const handleChangePage = (offset) => {
    handleChangePage(offset / LIMIT + 1);
    getReceipts({ ...params, offset }, offset / LIMIT + 1);
  };

  const renderReceipts = (receipt) => {
    const {
      _id,
      supplier_name: supplierName,
      created_date: createdDate,
      paid_date: paidDate,
      totalprice: { amount, amount_vat_excl: amountVatExcl, currency },
    } = receipt;
    return (
      <Tr onClick={(e) => handleRedirect(e, _id)} key={receipt._id}>
        <Td center>{_id}</Td>
        <Td>{format(new Date(createdDate), "dd.MM.yyyy HH:mm")}</Td>
        <Td center>
          <b>{supplierName}</b>
        </Td>
        <Td center>
          {paidDate ? (
            <div>
              <Icon name="checked" success />
              <div>{format(new Date(paidDate), "dd.MM.yyyy HH:mm")}</div>
            </div>
          ) : (
            <Icon name="delete" danger />
          )}
        </Td>
        <Td right>
          <strong>
            {formatPrice(amount, currency)} {__("s DPH")}
          </strong>
          <br />
          {formatPrice(amountVatExcl, currency)} {__("bez DPH")}
        </Td>
      </Tr>
    );
  };
  const renderPager = () => {
    return (
      <ContextBar>
        <ContextBarItem>
          <Paginator
            total={total}
            limit={LIMIT}
            offset={offset}
            onChangeOffset={handleChangePage}
          />
        </ContextBarItem>
      </ContextBar>
    );
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__("Zoznam predpisov")}</HeaderBar.Header>
          </HeaderBar>
          <FilterPanel>
            <SelectFilter
              placeholder={__("Stav úhrady")}
              value={String(params.statuses)}
              items={[
                {
                  title: __("Uhradené"),
                  value: "paid",
                },
                {
                  title: __("Neuhradené"),
                  value: "unpaid",
                },
              ]}
              onChange={handleChangeStatus}
            />
          </FilterPanel>
        </>
      }
      bottomFixed={renderPager()}
    >
      <AppContext title={__("Zoznam predpisov")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th center>{__("Číslo predpisu")}</Th>
                <Th>{__("Dátum")}</Th>
                <Th center>{__("Odberateľ")}</Th>
                <Th>{__("Uhradené")}</Th>
                <Th right>{__("Celková cena")}</Th>
              </Tr>
            </Thead>

            <Tbody>{receipts.map(renderReceipts)}</Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

ReceiptsList.propTypes = {
  acl: PropTypes.shape({}).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  receipts: PropTypes.arrayOf(
    PropTypes.shape({
      ...orderItemPropTypes,
    }),
  ).isRequired,
  total: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: routerParametersDefinition,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    receiptsAdmin: { data, isFetching },
  } = state;
  return {
    nextOffset: getProp(data, ["next_offset"], 0),
    receipts: getProp(data, ["receipts"], []),
    offset: getProp(data, ["offset"], 0),
    total: getProp(data, ["total"], 0),
    isFetching,
    acl: state.userAcl.data,
  };
};

const withPagination = connectPagination()(ReceiptsList);
export default connect(mapStateToProps)(withPagination);
