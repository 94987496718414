import { NavLink } from "react-router-dom";
import { RemoteComponent } from "../../RemoteComponent";
import { __ } from "../../utilities";
import {
  appDefinition,
  appSpaceDefinition,
} from "../../containers/App/definitions";
import {
  applicationInfoSelector,
  appsSelector,
  appspacesSelector,
  organizationSelector,
} from "../../containers/App/selector";
import { connect } from "react-redux";
import { mb } from "@sportnet/ui/Themes/utilities";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import Loader from "@sportnet/ui/Loader";
import MediaManagerConnector from "@sportnet/media-manager-connector";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import TheLayout from "@sportnet/ui/TheLayout";
import VouchersForm from "../../containers/VoucherForm";
import apiSportnet from "../../service/apiSportnet";
import config from "../../config";
import styled, { withTheme } from "styled-components";

export const Gap = styled.div`
  padding-top: 0;
  position: relative;
  ${mb("m")} {
    padding-top: ${rem(15)};
  }
`;

const LoaderWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
`;

const RemoteComponentWrapper = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const RemoteComponentLoaderWrapper = styled.div`
  > ${RemoteComponentWrapper}:empty {
    & + div {
      display: flex;
    }
  }
`;

const VOUCHERS_FORM_NAME = "VOUCHER_FORM";

const Layout = ({
  acl,
  bottomFixed,
  topFixed,
  appspaces,
  activeAppspace,
  applicationInfo,
  apps,
  children,
}) => {
  const [mediaManagerOpened, setMediamanagerOpened] = React.useState(false);
  const [vouchersOpened, setVouchersOpened] = React.useState(false);
  const [categories, setCategoriesState] = React.useState([]);
  const [notificationCount, setNotificationCount] = React.useState(-1);

  const { authUser, accessToken, logout, app: appId, ppo } = useAuth();

  const mediaManagerConnector = React.useMemo(
    () => (
      <MediaManagerConnector
        mediaManagerBaseUrl={process.env.REACT_APP_MEDIA_MANAGER_URL}
        opened={mediaManagerOpened}
        appSpace={ppo}
        appId={process.env.REACT_APP_APP_ID}
        accessToken={accessToken}
        onClose={() => {
          setMediamanagerOpened(false);
        }}
        onSelectFiles={() => {}}
        onError={(e) => {
          setMediamanagerOpened(false);
          throw e;
        }}
        onUnauthorized={() => {
          setMediamanagerOpened(false);
          throw Error("Media manager unauthorized");
        }}
      />
    ),
    [mediaManagerOpened, ppo, accessToken],
  );

  const setCategories = () => {
    let isVoucherAdmin = authUser.appSpace?.roles?.vouchers;

    if (acl) {
      const baseUrl = `/admin/${ppo}`;
      const tempCategories = [
        {
          url: `${baseUrl}/orders`,
          acl: "adminListOrders",
          label: __("Objednávky"),
          icon: "order",
        },
        {
          url: `${baseUrl}/products`,
          acl: "adminListProducts",
          label: __("Produkty"),
          icon: "product",
        },
        {
          url: `${baseUrl}/categories`,
          acl: "adminGetCategoriesTree",
          label: __("Kategórie"),
          icon: "horizontal-tree",
        },
        {
          url: `${baseUrl}/sales-channels-categories`,
          acl: "adminSaleschannelGetCategoriesTree",
          label: __("Kategórie predajných kanálov"),
          icon: "vertical-tree",
        },
        {
          onClick: () => {
            setMediamanagerOpened(true);
          },
          label: "Media Manager",
          icon: "folder",
        },
        ...(isVoucherAdmin
          ? [
              {
                onClick: () => {
                  setVouchersOpened(true);
                },
                label: "Vouchre",
                icon: "shopping-basket",
              },
            ]
          : []),
        {
          url: `${baseUrl}/requests`,
          acl: "adminListRequests",
          label: __("Dotazy"),
          icon: "write",
        },
        {
          url: `${baseUrl}/create-receipts`,
          acl: "adminCreateReceipt",
          label: __("Vytvoriť predpis"),
          icon: "add-euro",
        },
        {
          url: `${baseUrl}/receipts-list`,
          acl: ["adminListReceipts", "adminListSupplierReceipts"],
          label: __("Zobraziť predpisy"),
          icon: "euro",
        },
        {
          url: "",
          label: __("Nastavenia"),
          icon: "settings",
          subitems: [
            {
              url: `${baseUrl}/catalogs`,
              acl: "adminGetListings",
              label: __("Produktové zoznamy"),
            },
            {
              url: `${baseUrl}/sale-channels`,
              acl: "adminListSaleschannels",
              label: __("Predajné kanály"),
            },
            {
              url: `${baseUrl}/price-levels`,
              acl: "adminListPricelevels",
              label: __("Cenové hladiny"),
            },
            {
              url: `${baseUrl}/partners`,
              acl: "adminListB2BPartners",
              label: __("Partneri"),
            },
            {
              url: `${baseUrl}/delivery-options`,
              acl: "adminGetSupplierDeliveryOptions",
              label: __("Doprava"),
            },
            {
              url: `${baseUrl}/mailchimp-options`,
              acl: "adminGetSupplierMailchimp",
              label: __("Mailchimp"),
            },
            {
              url: `${baseUrl}/packetery-options`,
              acl: "adminGetSupplierPacketery",
              label: __("Zásielkovňa"),
            },
            {
              url: `${baseUrl}/ecomail-options`,
              acl: "adminGetSupplierEcomail",
              label: __("Ecomail"),
            },
            {
              url: `${baseUrl}/order-forwarding`,
              acl: "adminGetSupplierOrderForwarding",
              label: __("Presmerovanie objednávok"),
            },
          ],
        },
        {
          url: `${baseUrl}/cards`,
          acl: "adminListCards",
          label: __("Karty"),
          icon: "card",
        },
        {
          url: ``,
          label: __("Importy"),
          icon: "import",
          subitems: [
            {
              url: `${baseUrl}/imports/products`,
              acl: "adminImportProducts",
              label: __("Import produktov "),
            },
            {
              url: `${baseUrl}/imports/prices`,
              acl: "adminImportPrices",
              label: __("Import cien"),
            },
          ],
        },
        {
          url: "",
          label: __("Exporty"),
          icon: "export",
          subitems: [
            {
              url: `${baseUrl}/export-orders`,
              acl: "adminExportOrders",
              label: __("Objednávky"),
            },
            {
              url: `${baseUrl}/export-items`,
              acl: "adminExportItems",
              label: __("Položky z objednávok"),
            },
          ],
        },
        {
          url: `${baseUrl}/admin-partners`,
          label: __("Partneri"),
          acl: "adminUpdateSupplier",
          icon: "group",
        },
      ];
      setCategoriesState(
        tempCategories.reduce((acc, i) => {
          if (i.acl && Array.isArray(i.acl)) {
            let isAllowed = false;
            i.acl.forEach((item) => {
              if (acl[item]) {
                isAllowed = true;
              }
            });
            if (isAllowed) {
              return [...acc, i];
            }
          }
          if (!i.acl || (i.acl && acl[i.acl])) {
            return [...acc, i];
          }
          return acc;
        }, []),
      );
    }
  };

  React.useEffect(() => {
    setCategories();
  }, [acl]);

  const getNotificationCount = React.useCallback(async () => {
    if (!!accessToken) {
      try {
        setNotificationCount(
          (await apiSportnet.meUnreadMessagesCount())?.count || 0,
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [accessToken]);

  React.useEffect(() => {
    getNotificationCount();
    setInterval(getNotificationCount, 300000);
  }, [getNotificationCount]);

  return (
    <TheLayout
      grant={authUser?.appSpace?.grant}
      accessToken={accessToken}
      navigation={categories}
      userName={authUser.appSpace?.displayName}
      notificationCount={notificationCount}
      userPictureSrc={authUser?.photoUrl}
      top={<div>{topFixed}</div>}
      bottom={<div>{bottomFixed}</div>}
      onLogout={() => logout()}
      app={applicationInfo}
      apps={apps}
      appSpace={activeAppspace}
      appSpaces={appspaces.map((as) => ({
        _id: as.app_space,
        name: as.org_profile?.name || "",
        organization_name: as.org_profile?.organization_name || "",
        logo_public_url: as.org_profile?.logo_public_url,
        organization_id: as.org_profile?._id || "",
      }))}
      renderNavigationLink={({ item, linkProps }) => (
        <NavLink exact to={item.url || ""} {...linkProps}>
          {linkProps.children}
        </NavLink>
      )}
      userSubName={authUser?.appSpace?.roles?.[config.APP_ID]}
    >
      {vouchersOpened && (
        <>
          <RemoteComponentLoaderWrapper>
            <RemoteComponentWrapper>
              <RemoteComponent
                url="https://vouchers.sportnet.online/lib"
                formName={VOUCHERS_FORM_NAME}
                appId={appId}
                appSpace={ppo}
                accessToken={accessToken}
                onClose={() => {
                  setVouchersOpened(false);
                }}
                render={(basefields) => {
                  return <></>;
                }}
                renderVoucherForm={(baseFields) => (
                  <>
                    <Segment
                      raised
                      header={
                        <SegmentHeader withSeparator size="xs">
                          {__("Všeobecné nastavenia")}
                        </SegmentHeader>
                      }
                    >
                      {baseFields}
                    </Segment>
                    <VouchersForm
                      appSpace={ppo}
                      formName={VOUCHERS_FORM_NAME}
                    />
                  </>
                )}
              />
            </RemoteComponentWrapper>
            <LoaderWrapper>
              <Loader size="xl" />
            </LoaderWrapper>
          </RemoteComponentLoaderWrapper>
        </>
      )}
      {mediaManagerConnector}
      {children}
    </TheLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    app: applicationInfoSelector(state),
    appspaces: appspacesSelector(state),
    applicationInfo: state.application.data,
    apps: appsSelector(state),
    activeAppspace: organizationSelector(state),
    acl: state.userAcl.data,
  };
};

Layout.propTypes = {
  bottomFixed: PropTypes.node,
  topFixed: PropTypes.node,
  children: PropTypes.node.isRequired,
  applicationInfo: appDefinition,
  acl: PropTypes.shape({}),
  theme: PropTypes.shape({
    sizes: PropTypes.shape({
      l: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  appspaces: PropTypes.arrayOf(appSpaceDefinition).isRequired,
  apps: PropTypes.arrayOf(appDefinition).isRequired,
};

Layout.defaultProps = {
  bottomFixed: null,
  topFixed: <div />,
  acl: null,
  applicationInfo: null,
};

Layout.contextTypes = {
  logout: PropTypes.func,
};

export default connect(mapStateToProps)(withTheme(Layout));
