import {
  RECEIVE_PRODUCTS_ERROR,
  RECEIVE_PRODUCTS_SUCCESS,
  RECEIVE_PRODUCT_SUCCESS,
  REQUEST_PRODUCT,
  REQUEST_PRODUCTS,
  SET_ACTIVE_PRODUCT_ID,
  SET_PARAMETER,
  SET_PARAMETERS,
} from "./constants";
import { preparedActiveParametersSelector } from "./selectors";
import Api from "../../service/apiEshop";

export const setParameter = (name, value) => ({
  type: SET_PARAMETER,
  payload: {
    name,
    value,
  },
});

export const setParameters = (value) => ({
  type: SET_PARAMETERS,
  payload: {
    value,
  },
});

const requestProducts = (offset, limit) => ({
  type: REQUEST_PRODUCTS,
  payload: {
    offset,
    limit,
  },
});

const receiveProductsSuccess = (data, query, parameters) => ({
  type: RECEIVE_PRODUCTS_SUCCESS,
  payload: {
    data,
    query,
    parameters,
  },
});

export const receiveProductsError = (error) => ({
  type: RECEIVE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const fetchProducts = (query) => (dispatch, getState) => {
  dispatch(requestProducts(query.offset, query.limit));
  const {
    selectedOrg: supplierId,
    products: { parameters },
  } = getState();

  const {
    active = [],
    withoutPhoto = [],
    ...params
  } = {
    ...query,
    ...preparedActiveParametersSelector(getState()),
  };

  if (active.includes("A") && active.length === 1) {
    params["active"] = true;
  } else if (active.includes("N") && active.length === 1) {
    params["active"] = false;
  }

  if (withoutPhoto.includes("T") && withoutPhoto.length === 1) {
    params["withoutPhoto"] = true;
  }

  return Api.adminListProducts(supplierId, params)
    .then((data) => {
      dispatch(receiveProductsSuccess(data, query, parameters));
    })
    .catch((error) => {
      console.error(error);
      dispatch(receiveProductsError(error));
    });
};

export const setActiveProductId = (id) => ({
  type: SET_ACTIVE_PRODUCT_ID,
  payload: {
    id,
  },
});

const requestProduct = (id) => ({
  type: REQUEST_PRODUCT,
  payload: {
    id,
  },
});

const receiveProductSuccess = (id, data) => ({
  type: RECEIVE_PRODUCT_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchProductDetail = (productId) => async (dispatch, getState) => {
  dispatch(requestProduct(productId));
  const { selectedOrg: supplierId } = getState();
  return Api.adminGetProductDetail(supplierId, productId)
    .then((data) => {
      dispatch(receiveProductSuccess(productId, data));
    })
    .catch((error) => {
      dispatch(receiveProductsError(error));
      throw error;
    });
};
