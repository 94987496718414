import {
  __,
  calculateOrderTotalpriceWithCredits,
  formatPrice,
  getProp,
} from "../../utilities";
import { connect } from "react-redux";
import { format } from "date-fns";
import { rem } from "polished";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr``;
const Th = styled.th`
  border: ${rem(1)} solid black;
  padding: ${rem(3)};
  white-space: nowrap;
`;

const LeftTh = styled(Th)`
  text-align: left;
`;

const MainTh = styled(LeftTh)`
  width: 100%;
`;

const Td = styled.td`
  border: ${rem(1)} solid black;
  padding: ${rem(3)};
`;

const NoWrapTd = styled(Td)`
  white-space: nowrap;
  text-align: center;
`;

const RigthTd = styled(NoWrapTd)`
  text-align: right;
`;

const InfoRow = styled.div`
  line-height: 1.3;
  word-break: break-word;
`;

class Orders extends PureComponent {
  renderOrder = (order) => {
    const { delivery, delivery_vat_excl: deliveryVatExcl } =
      calculateOrderTotalpriceWithCredits(order);
    const {
      _id,
      invoicenr,
      created_date: createdDate,
      company,
      business_id: businessId,
      vat_nr: vatNr,
      status: orderStatus,
      saleschannel,
      summary,
      paid_date: paidDate,
      paid_payload: paidPayload,
    } = order;
    const statusObj =
      this.props.orderStatuses.find((item) => item.value === orderStatus) || {};
    const statusLabel = getProp(statusObj, ["label"], orderStatus);
    return (
      <Tr key={_id}>
        <Td>
          <InfoRow>
            <b>
              {saleschannel}
              {": "}
              {_id}
            </b>
            {" | "}
            {format(new Date(createdDate), "dd.MM.yyyy HH:mm")}
            {" | "}
            <b>{statusLabel}</b>
            {" | Faktúra: "}
            {invoicenr}
          </InfoRow>
          <InfoRow>
            <b>{__("Odberateľ")}: </b>
            {company}
            {businessId && `, IČO: ${businessId}`}
            {vatNr && `, DIČ / IčDPH: ${vatNr}`}
          </InfoRow>
        </Td>
        <RigthTd>
          <b>
            {formatPrice(delivery, summary.currency)} {__("s DPH")}
          </b>
          <br />
          {formatPrice(deliveryVatExcl, summary.currency)} {__("bez DPH")}
        </RigthTd>
        <RigthTd>
          <b>
            {formatPrice(summary.total, summary.currency)} {__("s DPH")}
          </b>
          <br />
          {formatPrice(summary.totalBase, summary.currency)} {__("bez DPH")}
        </RigthTd>
        <NoWrapTd>
          {formatPrice(summary.paidbycredit, summary.currency)}
        </NoWrapTd>
        <NoWrapTd>
          <span title={paidDate}>{paidDate ? "✓" : "✖︎"}</span>{" "}
          {formatPrice(summary.paidbyother, summary.currency)}
          {paidPayload && (
            <div>
              {__("Platobná brána ID")}: {paidPayload.gpwebpay_ordernumber}
            </div>
          )}
        </NoWrapTd>
      </Tr>
    );
  };

  render() {
    const { orders } = this.props;
    return (
      <Table>
        <Thead>
          <Tr>
            <MainTh>{__("Objednávka")}</MainTh>
            {/* <LeftTh>{__('Odberateľ')}</LeftTh> */}
            <Th>{__("Doprava")}</Th>
            <Th>{__("Celkom")}</Th>
            <Th>
              {__("Zaplatené")}
              <br />
              {__("kreditmi")}
            </Th>
            <Th>
              {__("Zaplatené")}
              <br />
              {__("kartou")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{orders.map(this.renderOrder)}</Tbody>
      </Table>
    );
  }
}

Orders.propTypes = {
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      invoicenr: PropTypes.string.isRequired,
      created_date: PropTypes.string.isRequired,
      company: PropTypes.string,
      business_id: PropTypes.string,
      status: PropTypes.string,
      saleschannel: PropTypes.string.isRequired,
      summary: PropTypes.shape({}),
    }),
  ).isRequired,
};
const mapStateToProps = (state) => {
  const {
    purchase: { orderStatuses },
  } = state;
  return {
    orderStatuses: orderStatuses.codelist,
  };
};

export default connect(mapStateToProps)(Orders);
