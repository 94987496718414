import { catalogsReducer } from "./containers/Catalogs/reducer";
import { categoriesReducer } from "./containers/Categories/reducer";
import { combineReducers } from "redux";
import { countryCodesReducer } from "./containers/Options/DeliveryDetail/reducer";
import { reducer as formReducer } from "redux-form";
import { importDetailReducer } from "./containers/ImportDetail/reducer";
import { importsReducer } from "./containers/Imports/reducer";
import { listReducer } from "@sportnet/redux-list/ducks";
import { salesChannelsCategoriesReducer } from "./containers/SalesChannelsCategories/reducer";
import partnersReducer from "./containers/Partners/reducer";
import priceLevelsReducer from "./containers/PriceLevels/reducer";
import products from "./containers/Products/reducer";
import purchase from "./containers/Orders/reducer";
import receiptsAdmin from "./containers/ReceiptsList/reducer";
import saleChannelsReducer from "./containers/SaleChannels/reducer";
import selectedOrg, {
  applicationReducer,
  entitiesReducer,
  userAcl,
} from "./containers/App/reducer";

export default combineReducers({
  form: formReducer,
  selectedOrg,
  purchase,
  products,
  categories: categoriesReducer,
  imports: importsReducer,
  importDetail: importDetailReducer,
  receiptsAdmin,
  userAcl,
  application: applicationReducer,
  saleChannels: saleChannelsReducer,
  saleChannelsCategories: salesChannelsCategoriesReducer,
  priceLevels: priceLevelsReducer,
  partners: partnersReducer,
  catalogs: catalogsReducer,
  list: listReducer,
  entities: entitiesReducer,
  countryCodes: countryCodesReducer,
});
