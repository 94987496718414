import {
  RECEIVE_COUNTRY_CODES_FAILURE,
  RECEIVE_COUNTRY_CODES_SUCCESS,
  REQUEST_COUNTRY_CODES,
} from "./constants";
import { combineReducers } from "redux";

export const countryCodesReducer = (
  state = {
    data: [],
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_COUNTRY_CODES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_COUNTRY_CODES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
      };
    case RECEIVE_COUNTRY_CODES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({ countryCodes: countryCodesReducer });
