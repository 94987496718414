import * as React from "react";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";
import { __ } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchPriceLevelsList } from "../PriceLevels/actions";
import { fetchProductDetail, setActiveProductId } from "../Products/actions";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { formatProductToSend } from "../ProductForm/utils";
import { nonSharedSaleChanellsListSelector } from "../SaleChannels/selectors";
import { organizationProfileSelector } from "../App/selector";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Categories from "./Categories";
import ContextBar, {
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import Message from "@sportnet/ui/Message";
import Parameters from "./Parameters";
import Photogallery from "./Photogallery";
import ProductSettings from "./Settings";
import PropTypes from "prop-types";
import SalesChannelCategories from "./SalesChannelCategories";
import Segment from "@sportnet/ui/Segment";
import Tabber from "@sportnet/ui/Tabber";
import Variants from "./Variants";
import apiEshop from "../../service/apiEshop";
import styled, { css } from "styled-components";
import withPopups, { WithPopupsProps } from "../../components/WithPopups";

// TODO - ORGANIZATION

const StyledMessage = styled(Message)`
  > div {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  ${(props) =>
    (props.disabled || props.success) &&
    css`
      pointer-events: none;
    `}
`;

const tabs = [
  { name: "Základné informácie" },
  { name: "Fotogaléria", hash: "fotogaleria" },
  { name: "Parametre", hash: "parametre" },
  { name: "Varianty", hash: "varianty" },
  { name: "Kategórie", hash: "kategorie" },
  {
    name: "Kategórie predajných kanálov",
    hash: "kategorie-predajnych-kanalov",
  },
];

const mapStateToProps = (state) => ({
  organization: organizationProfileSelector(state),
  salesChannels: nonSharedSaleChanellsListSelector(state),
});

const Product = ({
  dispatch,
  alert,
  match: {
    params: { product_id: productId, appspace },
  },
  history,
}) => {
  const [activeTab, setActiveTab] = React.useState(
    tabs.find((t) => `#${t.hash}` === window.location.hash) || tabs[0],
  );
  const { ppo } = useAuth();
  const [success, setSuccess] = React.useState(false);
  const [product, setProduct] = React.useState();

  const fetchProduct = React.useCallback(async () => {
    if (productId !== "new") {
      try {
        const res = await apiEshop.adminGetProductDetail(ppo, productId);
        setProduct(res);
      } catch (error) {
        console.error(error);
        alert(__("Produkt sa nepodarilo načítať"));
      }
    }
  }, [productId]);

  React.useEffect(() => {
    (async () => await fetchProduct())();
  }, [fetchProduct]);

  React.useEffect(() => {
    if (productId !== "new") {
      dispatch(setActiveProductId(productId));
      dispatch(fetchProductDetail(productId));
    } else {
      dispatch(setActiveProductId(null));
    }
    dispatch(fetchSaleChannelsList(ppo));
    dispatch(fetchPriceLevelsList(ppo));
  }, [productId, dispatch, ppo]);

  const handleSubmit = async (values, form) => {
    if (success) return;

    const dataToSend = formatProductToSend({ ...product, ...values });

    const errorHandling = (error) => {
      const {
        details: { statusCode, name },
      } = error;
      if (statusCode === 409) {
        if (name === "DUPLICATE_VARIANT_CODE") {
          alert(__("Duplicitný kód variantu!"));
        } else {
          alert(__("Produkt so zadaným kódom už existuje"));
        }
      } else {
        alert(__("Chybne vyplenený formulár"));
      }
    };

    if (productId !== "new") {
      try {
        const update = await apiEshop.adminUpdateProduct(ppo, productId, {
          data: dataToSend,
        });
        setProduct(update);
        setSuccess(true);
      } catch (error) {
        console.error(error);
        errorHandling(error);
      } finally {
        form.setConfig("keepDirtyOnReinitialize", false);
        form.reset();
        form.setConfig("keepDirtyOnReinitialize", true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    } else {
      try {
        const res = await apiEshop.adminCreateProduct(ppo, {
          data: dataToSend,
        });
        form.setConfig("keepDirtyOnReinitialize", false);
        form.reset();
        form.setConfig("keepDirtyOnReinitialize", true);
        history.push(`/admin/${appspace}/products/${res._id}`);
        location.reload();
      } catch (error) {
        errorHandling(error);
      } finally {
        form.setConfig("keepDirtyOnReinitialize", false);
        form.reset();
        form.setConfig("keepDirtyOnReinitialize", true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
      mutators={{
        setVariants: (args, state, tools) => {
          tools.changeValue(state, "variants", () => args[0]);
        },
        setParams: (args, state, tools) => {
          tools.changeValue(state, "params", () => args[0]);
        },
      }}
      initialValues={
        productId === "new"
          ? {}
          : {
              ...(product || {}),
              tags: (product?.tags || []).reduce(
                (acc, k) => ({
                  ...acc,
                  [k.tag]: {
                    active: true,
                    valid_to: k.valid_to,
                  },
                }),
                {},
              ),
              globalListings: (product?.listings || []).reduce(
                (acc, k) => ({
                  ...acc,
                  [k.list_id]: true,
                }),
                {},
              ),
              salesChannelListings: (
                product?.saleschannels_listings || []
              ).reduce(
                (acc, k) => ({
                  ...acc,
                  [k]: true,
                }),
                {},
              ),
              globalCategories: (product?.categories || []).reduce(
                (acc, k) => ({
                  ...acc,
                  [`k${[k]}`]: true,
                }),
                {},
              ),
              salesChannelsCategories: (
                product?.saleschannels_categories || []
              ).reduce(
                (acc, k) => ({
                  ...acc,
                  [`k${[k]}`]: true,
                }),
                {},
              ),
              salesChannels: (product?.saleschannels || []).reduce(
                (acc, k) => ({
                  ...acc,
                  [k]: true,
                }),
                {},
              ),
              pictures: product?.pictures || [],
            }
      }
      render={({ handleSubmit, submitting, dirty }) => {
        return (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Layout
              topFixed={
                <HeaderBar>
                  <HeaderBar.Action
                    icon="back"
                    as={Link}
                    to={`/admin/${appspace}/products`}
                    title={__("Produkty")}
                  />
                  <HeaderBar.Header>
                    {productId !== "new"
                      ? __(`${"Produkt"}: ${product?.name}`)
                      : __(`Nový produkt`)}
                  </HeaderBar.Header>
                </HeaderBar>
              }
              bottomFixed={
                <ContextBar>
                  <ContextBarSpacer />
                  <ContextBarItem>
                    <StyledButton
                      primary={!success}
                      type="submit"
                      loading={submitting}
                      disabled={!success && !dirty}
                      icon={!!success && "check"}
                      success={success}
                    >
                      {__(!!success ? "Uložené" : "Uložiť")}
                    </StyledButton>
                  </ContextBarItem>
                </ContextBar>
              }
            >
              {productId !== "new" ? (
                <AppContext
                  title={__(`${"Produkt"}: ${product?.name}`)}
                  breadcrumbs={[
                    {
                      url: `/admin/${appspace}/products`,
                      name: __("Produkty"),
                    },
                    { name: __(`${"Produkt"}: ${product?.name}`) },
                  ]}
                />
              ) : (
                <AppContext
                  title={__(`Nový produkt`)}
                  breadcrumbs={[
                    {
                      url: `/admin/${appspace}/products`,
                      name: __("Produkty"),
                    },
                    { name: __(`Nový produkt`) },
                  ]}
                />
              )}
              <Tabber
                active={activeTab.name}
                onClickItem={(item) => {
                  const newHash = tabs.find((t) => t.name === item).hash;
                  if (newHash) {
                    window.location.hash = newHash;
                  } else {
                    window.history.replaceState(
                      {},
                      "",
                      window.location.href.split("#")[0],
                    );
                  }

                  setActiveTab(tabs.find((t) => t.name === item));
                }}
                items={tabs.map((t) => t.name)}
              >
                {dirty && (
                  <StyledMessage warning title="Pozor! Zmeny niesú uložené." />
                )}
                <Segment>
                  <ProductSettings hidden={activeTab.name !== tabs[0].name} />
                  <Photogallery hidden={activeTab.name !== tabs[1].name} />
                  <Parameters hidden={activeTab.name !== tabs[2].name} />
                  <Variants hidden={activeTab.name !== tabs[3].name} />
                  <Categories hidden={activeTab.name !== tabs[4].name} />
                  <SalesChannelCategories
                    hidden={activeTab.name !== tabs[5].name}
                  />
                </Segment>
              </Tabber>
            </Layout>
          </form>
        );
      }}
    />
  );
};

Product.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      product_id: PropTypes.string,
      appspace: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  product: PropTypes.shape({
    isFetching: PropTypes.bool,
    _id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    type: PropTypes.string,
    brand: PropTypes.string,
    active: PropTypes.bool,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
      }),
    ),
  }),
  ...WithPopupsProps,
};

Product.defaultProps = {
  salesChannels: [],
};

export default compose(connect(mapStateToProps), withPopups)(Product);
