import { __, getProp } from "../../utilities";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  line-height: 1.5;
`;

const Title = styled.span`
  font-weight: bold;
`;

const ItemInfo = ({ item, variant }) => {
  const params =
    item?.payload?.selectedParameters || item?.variant?.params || {};
  const card = item?.payload?.card || {};
  return (
    <Wrapper>
      <Title>{getProp(item, ["item_name"], "")}</Title>
      <div>
        {__("Kód výrobku: ")} {getProp(variant, ["code"], "")}
      </div>
      {getProp(item, ["product_code"]) && (
        <div>
          {__("Kód produktu:")} {getProp(item, ["product_code"], "")}
        </div>
      )}
      {Array.isArray(params)
        ? params.map((param) => {
            let value = param.value || "";

            if (!value) {
              <div key={param.key}>{param.key}</div>;
            }

            return (
              <div key={param.key}>
                {param.key}
                {`: ${value}`}
              </div>
            );
          })
        : Object.keys(params).map((param) => {
            const par = params[param];
            let value = par?.value?.label || par?.value?.value || "";

            if (value === `${card?.type} - ${card?.cardnr}`) {
              return <div key={param} />;
            }

            if (!value) {
              return <div key={param}>{getProp(par, ["label"], param)}</div>;
            }

            return (
              <div key={param}>
                {getProp(par, ["label"], param)}: {value}
              </div>
            );
          })}
      {!!card?.cardnr && (
        <div>
          {__("Karta: ")}
          {`${card.type} - ${card.cardnr}`}
        </div>
      )}
    </Wrapper>
  );
};

export const ItemDetailPropTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  picture: PropTypes.string,
  isSale: PropTypes.bool,
  isAvailable: PropTypes.bool,
  currency: PropTypes.string,
  isNew: PropTypes.bool,
  oldprice: PropTypes.number,
  isTop: PropTypes.bool,
  nrOfVariants: PropTypes.number,
};

ItemInfo.propTypes = {
  item: PropTypes.shape({
    ...ItemDetailPropTypes,
  }).isRequired,
  variant: PropTypes.shape({
    code: PropTypes.string,
    params: PropTypes.shape({
      label: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default ItemInfo;
