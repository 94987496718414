import { Field } from "react-final-form";
import { __ } from "@sportnet/ui/utilities";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import Label from "@sportnet/ui/Label/Label";
import React from "react";
import Skeleton from "@sportnet/ui/Skeleton";
import apiEshop from "../../../service/apiEshop";
import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${rem(32)};
  row-gap: ${rem(2)};
`;

const Listings = ({ loading }) => {
  const { ppo } = useAuth();
  const [listings, setListings] = React.useState([]);
  const [salesChannelListings, setSalesChannelListings] = React.useState([]);
  const [isFetchingListings, setIsFetchingListings] = React.useState(false);
  const [isFetchingSalesChannelListings, setIsFetchingSalesChannelListings] =
    React.useState(false);

  React.useEffect(() => {
    setIsFetchingListings(true);
    apiEshop
      .getCodelist("product_listing")
      .then((data) => {
        setListings(data.codelist);
      })
      .catch(() => {
        alert(__("Nepodarilo sa získať zoznam možných zaradení"));
      })
      .finally(() => {
        setIsFetchingListings(false);
      });
  }, []);

  React.useEffect(() => {
    setIsFetchingSalesChannelListings(true);
    apiEshop
      .adminGetListings(ppo)
      .then((data) => {
        setSalesChannelListings(data.listings || []);
      })
      .catch(() => {
        alert(__("Nepodarilo sa získať zoznam možných zaradení"));
      })
      .finally(() => {
        setIsFetchingSalesChannelListings(false);
      });
  }, [ppo]);

  return (
    <>
      {(loading ||
        (!loading &&
          !![...(listings || []), ...(salesChannelListings || [])].length)) && (
        <Row style={{ marginBottom: rem(8) }}>
          <Col>
            <Label>{__("Produktové zoznamy")}</Label>
          </Col>
        </Row>
      )}
      <FlexRow>
        {loading || isFetchingListings || isFetchingSalesChannelListings
          ? Array.from(Array(4)).map(() => (
              <Skeleton
                width={110}
                height={16}
                style={{ marginBottom: rem(20) }}
              />
            ))
          : !![...(listings || []), ...(salesChannelListings || [])].length && (
              <>
                {listings?.map((listing) => (
                  <Field
                    name={`globalListings.${listing.value}`}
                    type="checkbox"
                  >
                    {({ input: { checked, onChange } }) => (
                      <FormField
                        checked={checked}
                        onChange={onChange}
                        label={listing.label}
                        type="checkbox"
                      />
                    )}
                  </Field>
                ))}
                {salesChannelListings?.map((listing) => (
                  <Field
                    name={`salesChannelListings.${listing.value}`}
                    type="checkbox"
                  >
                    {({ input: { checked, onChange } }) => (
                      <FormField
                        checked={checked}
                        onChange={onChange}
                        label={listing.label}
                        type="checkbox"
                      />
                    )}
                  </Field>
                ))}
              </>
            )}
      </FlexRow>
    </>
  );
};

export default Listings;
