import * as React from "react";
import { Field, Form, useForm, useFormState } from "react-final-form";
import { __, formatPrice } from "../../../utilities";
import { rem } from "polished";
import BasicTable from "@sportnet/ui/BasicTable";
import Button from "@sportnet/ui/Button";
import Icon from "@sportnet/ui/Icon";
import Modal, { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import Segment from "@sportnet/ui/Segment";
import VariantForm from "./form";
import styled from "styled-components";

const Wrapper = styled.div`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
`;

const VariantsList = ({ hidden }) => {
  const [activeVariantIdx, setActiveVariantIdx] = React.useState(null);
  const [activeVariant, setActiveVariant] = React.useState(null);
  const {
    mutators: { setVariants },
  } = useForm();
  const { values } = useFormState();
  const textParams =
    values?.params?.filter((p) => !!p && p.type === "text") || [];
  const variants = values?.variants || [];

  const copyVariant = (variant) => {
    const code = window.prompt(__("Zadajte nový kód pre kópiu variantu"));
    if (code) {
      setVariants([
        ...variants,
        { ...JSON.parse(JSON.stringify(variant)), code },
      ]);
    }
  };

  const removeVariant = (index) => {
    const newVariants = [...variants].filter((_, idx) => idx !== index);
    setVariants(newVariants);
  };

  const createNewVariant = () => {
    const newVariant = {
      code: "NEW",
      availability: {
        available: false,
      },
      onsale: {
        status: false,
      },
      unitprice: {
        amount: 0,
        amount_vat_excl: 0,
        currency: "EUR",
      },
      vat_rate: 20,
      volume: null,
      weight: null,
      params: [...textParams],
    };
    setActiveVariant(newVariant);
    setActiveVariantIdx(variants.length);
  };

  return (
    <Wrapper $hidden={hidden}>
      <Segment raised>
        <Field name="variants">
          {({ input: { onChange } }) => (
            <>
              <Modal
                isOpen={activeVariant}
                handleClose={() => setActiveVariant(null)}
              >
                <Form
                  onSubmit={(values) => {
                    const newVariants = [...variants];
                    newVariants[activeVariantIdx] = values;

                    onChange([...newVariants]);
                    setActiveVariantIdx(null);
                    setActiveVariant(null);
                  }}
                  mutators={{
                    setPriceLevels: (args, state, tools) => {
                      tools.changeValue(state, "pricelevels", () => args[0]);
                    },
                    setOrderFragments: (args, state, tools) => {
                      tools.changeValue(state, "orderfragments", () => args[0]);
                    },
                    setParams: (args, state, tools) => {
                      tools.changeValue(state, "params", () => args[0]);
                    },
                  }}
                  initialValues={activeVariant}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <ModalContent>
                        {activeVariant && (
                          <VariantForm productParams={values.params || []} />
                        )}
                      </ModalContent>
                      <ModalActions>
                        <div />
                        <div>
                          <Button primary type="submit">
                            {__("Potvrdiť")}
                          </Button>
                          &nbsp;
                          <Button
                            type="button"
                            basic
                            onClick={() => {
                              setActiveVariant(null);
                              setActiveVariantIdx(null);
                            }}
                          >
                            {__("Zavrieť")}
                          </Button>
                        </div>
                      </ModalActions>
                    </form>
                  )}
                />
              </Modal>

              <BasicTable
                onReorder={(rows) => {
                  onChange(rows);
                }}
                onClickRow={(_row, idx, event) => {
                  if (
                    typeof event?.target?.className === "string" &&
                    !event?.target?.className?.includes("drag-handle-cell")
                  ) {
                    setActiveVariantIdx(idx);
                    setActiveVariant(variants[idx]);
                  }
                }}
                columns={[
                  { header: __("Kód") },
                  ...(variants?.some((v) => !!v?.ean)
                    ? [{ header: __("EAN") }]
                    : []),
                  ...(variants?.some(
                    (v) => !!v?.params && !!Object.keys(v.params).length,
                  )
                    ? [{ header: __("Parametre") }]
                    : []),
                  { header: __("Cena s DPH") },
                  ...(variants?.some((v) => !!v?.oldprice)
                    ? [{ header: __("Pôvodná cena") }]
                    : []),
                  ...(variants?.some((v) => !!v?.availability?.managed_stock)
                    ? [{ header: __("Sklad") }]
                    : []),
                  ...(variants?.some((v) => !!v?.onSale?.status)
                    ? [{ header: __("Akcia") }]
                    : []),
                  { header: __("") },
                ]}
                rows={variants}
                renderRow={(variant, idx) => {
                  const {
                    code,
                    ean,
                    oldprice,
                    unitprice: { amount, currency },
                    availability: { managed_stock, quantity },
                  } = variant;

                  const parsedParams = (variant.params || [])
                    .map((param, idx) => {
                      if (param.type === "text") {
                        return param.key;
                      }
                      return `${param.key}:${param.value}`;
                    })
                    .join(", ");

                  return [
                    code,
                    ...(variants?.some((v) => !!v?.ean) ? [ean] : []),
                    ...(variants?.some(
                      (v) => !!v?.params && !!Object.keys(v.params).length,
                    )
                      ? [parsedParams]
                      : []),
                    formatPrice(amount, currency),
                    ...(!!variants?.some((v) => !!v?.oldprice)
                      ? !!oldprice
                        ? [formatPrice(oldprice, currency)]
                        : ["-"]
                      : []),
                    ...(variants?.some((v) => !!v.availability.managed_stock)
                      ? managed_stock
                        ? [`${quantity || 0} ks`]
                        : ["-"]
                      : []),
                    ...(variants?.some((v) => !!v?.onSale?.status)
                      ? [variant?.onSale?.status ? <Icon name="check" /> : ""]
                      : []),
                    <div style={{ display: "flex" }}>
                      <div>
                        <Button
                          primary
                          icon="file"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyVariant(variant);
                          }}
                        >
                          {__("Kópia")}
                        </Button>
                      </div>
                      &nbsp;
                      <div>
                        <Button
                          danger
                          icon="close"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              !!window.confirm(
                                __(
                                  `Naozaj si prajete odstrániť variant s kódom ${code}`,
                                ),
                              )
                            ) {
                              removeVariant(idx);
                            }
                          }}
                        >
                          {__("Odstrániť")}
                        </Button>
                      </div>
                    </div>,
                  ];
                }}
              />
            </>
          )}
        </Field>
        <div style={{ marginTop: rem(16) }}>
          <Button onClick={createNewVariant} primary icon="plus" type="button">
            {__("Pridať nový variant")}
          </Button>
        </div>
      </Segment>
    </Wrapper>
  );
};

export default VariantsList;
