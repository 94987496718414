import { createSelector } from "reselect";
import { getProp } from "../../../utilities";

const categoriesDomainSelector = (state) => state.countryCodes;

export const countryCodesListSelector = createSelector(
  categoriesDomainSelector,
  (substate) => getProp(substate, ["data"], []),
);

export const countryCodesIsFetchingSelector = createSelector(
  categoriesDomainSelector,
  (substate) => getProp(substate, ["isFetching"], true),
);
