const formatProductAvailabilityToSend = (availability) => {
  const max_quantity =
    !!availability.max_quantity &&
    !Number.isNaN(availability.max_quantity) &&
    availability.max_quantity !== "" &&
    availability.max_quantity !== null
      ? Number(availability.max_quantity)
      : undefined;

  const quantity =
    !!availability.quantity &&
    !Number.isNaN(availability.quantity) &&
    availability.quantity !== "" &&
    availability.quantity !== null
      ? Number(availability.quantity)
      : availability.managed_stock
        ? 0
        : undefined;

  return {
    available: !!availability.available,
    quantity,
    onstock: availability.onstock,
    days: availability.days,
    at_date: availability.at_date,
    label: availability.label,
    managed_stock: availability.managed_stock,
    max_quantity,
  };
};

const formatProductPriceToSend = (price) => {
  return {
    amount: parseFloat(price.amount),
    amount_vat_excl: parseFloat(price.amount_vat_excl),
    currency: price.currency,
    vat_rate:
      price.vat_rate || price.vat_rate === 0
        ? parseFloat(price.vat_rate)
        : undefined,
  };
};

const formatProductVariantToSend = (variant) => {
  return {
    code: variant.code,
    unitprice: formatProductPriceToSend(variant.unitprice),
    vat_rate: parseFloat(variant.vat_rate),
    units: variant.units,
    ean: variant.ean,
    ...(variant.oldprice && { oldprice: parseFloat(variant.oldprice) }),
    onsale: variant.onsale,
    volume:
      typeof variant.volume !== "undefined" && variant.volume !== null
        ? Number(variant.volume)
        : null,
    weight:
      typeof variant.weight !== "undefined" && variant.weight !== null
        ? Number(variant.weight)
        : null,
    availability: formatProductAvailabilityToSend(variant.availability),
    params:
      variant.params?.map((p) => {
        if (p.value === null || p.value === undefined) {
          delete p.value;
        }
        return p;
      }) || [],
    pricelevels: variant.pricelevels,
    picture: variant.picture,
    ...(variant.orderfragments && variant.orderfragments.length
      ? { orderfragments: variant.orderfragments }
      : {}),
  };
};

export const formatProductToSend = (data) => {
  const variants =
    data.variants && data.variants.map(formatProductVariantToSend);

  return {
    redirect: data.redirect ? data.redirect : undefined,
    meta: data.meta,

    code: data.code,

    type: data.type,

    name: data.name,

    perex: data.perex,

    url: data.url || undefined,

    brand: data.brand,

    smarttags: data.smarttags,

    description: data.description,

    pictures: data.pictures,

    active: data.active === true,

    sfz_credit: data.sfz_credit,

    sfz_credit_only: data.sfz_credit_only,

    params:
      data.params?.map((p) => {
        const transformedParam = { ...p };

        return transformedParam;
      }) || [],

    tags: data.tags
      ? Object.keys(data.tags || {}).reduce((acc, k) => {
          if (data.tags[k].active) {
            return [...acc, { tag: k, valid_to: data.tags[k].valid_to }];
          }
          return acc;
        }, [])
      : undefined, //

    variants,

    categories: data.globalCategories
      ? Object.keys(data.globalCategories || {}).reduce((acc, i) => {
          if (data.globalCategories[i]) {
            const k = i.substring(1);
            return [...acc, Number(k)];
          }
          return acc;
        }, [])
      : data.categories || [],

    saleschannels: data.salesChannels
      ? Object.keys(data.salesChannels || {}).reduce((acc, k) => {
          if (data.salesChannels[k]) {
            return [...acc, k];
          }
          return acc;
        }, [])
      : data.saleschannels || [],

    saleschannels_categories: data.salesChannelsCategories
      ? Object.keys(data.salesChannelsCategories || {}).reduce((acc, i) => {
          if (data.salesChannelsCategories[i]) {
            const k = i.substring(1);
            return [...acc, Number(k)];
          }
          return acc;
        }, [])
      : data.saleschannels_categories || [],

    saleschannels_listings: data.salesChannelListings
      ? Object.keys(data.salesChannelListings || {}).reduce((acc, k) => {
          if (data.salesChannelListings[k]) {
            return [...acc, k];
          }
          return acc;
        }, [])
      : data.saleschannels_listings || [],

    listings: data.globalListings
      ? Object.keys(data.globalListings || {}).reduce((acc, k) => {
          if (data.globalListings[k]) {
            return [...acc, { list_id: k }];
          }
          return acc;
        }, [])
      : data.listings || [],
  };
};
