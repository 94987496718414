import { __ } from "../../../utilities";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import FormGroup from "@sportnet/ui/FormGroup";
import Label from "@sportnet/ui/Label/Label";
import React from "react";
import Select from "@sportnet/ui/TheSelect";
import apiEshop from "../../../service/apiEshop";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: ${rem(16)};
`;

const RedirectInput = ({ value, onChange, name }) => {
  const { ppo } = useAuth();
  const [options, setOptions] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const timer = React.useRef(null);

  const handleChangeInput = React.useCallback(
    async (q) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(async () => {
        setIsFetching(true);
        try {
          const res = await apiEshop.adminListProducts(ppo, { q });
          setOptions(
            (res.products || []).map((product) => {
              return {
                label: product.name,
                url: product.url,
                value: product._id,
              };
            }),
          );
        } catch (err) {
          console.error(err);
          alert(
            __(
              "Vyskytla sa neočakávaná chyba - nepodarilo sa získať zoznam produktov.",
            ),
          );
        } finally {
          setIsFetching(false);
        }
      }, 250);
    },
    [ppo, timer],
  );

  React.useEffect(() => {
    handleChangeInput(value?.url || "");
  }, [value, handleChangeInput]);

  // cleanup
  React.useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <Wrapper>
      <FormGroup>
        <Label>{__("Presmerovanie")}</Label>
        <Select
          value={value?._id ? { value: value._id, label: value.url } : ""}
          options={options}
          onChange={(value) => {
            if (value) {
              onChange({
                _id: value.value,
                type: "product",
                url: value.url,
              });
            } else {
              onChange(undefined);
            }
          }}
          loading={isFetching}
          onChangeInput={(value) => {
            handleChangeInput(value);
          }}
        />
      </FormGroup>
    </Wrapper>
  );
};

export default RedirectInput;
