import { Field, Form, useForm, useFormState } from "react-final-form";
import { __ } from "../../../utilities";
import { rem } from "polished";
import BasicTable from "@sportnet/ui/BasicTable";
import Button from "@sportnet/ui/Button";
import Modal from "@sportnet/ui/Modal";
import ParametersForm from "./form";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import styled from "styled-components";

const Wrapper = styled.div`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
`;

const Values = styled.div`
  display: flex;
  gap: ${rem(4)};
  flex-wrap: wrap;
`;

const Parameters = ({ hidden }) => {
  const {
    mutators: { setParams },
  } = useForm();
  const { values } = useFormState();
  const params = values?.params || [];

  const [newParam, setNewParam] = React.useState(null);

  const createNewParam = (values) => {
    const newParams = [...params];
    const idx = newParams.findIndex((p) => p.key === values.key);

    if (values.type === "text") {
      if (idx === -1) {
        newParams.push({
          key: values.key,
          type: values.type,
        });
      }
    } else {
      if (idx > -1) {
        const existingValueIdx = newParams[idx].values.findIndex(
          (v) => v.key === values.value,
        );
        if (existingValueIdx === -1) {
          newParams[idx].values.push({ key: values.value });
        }
      } else {
        newParams.push({
          key: values.key,
          ...(!!values.type && { type: values.type }),
          values: [{ key: values.value }],
        });
      }
    }

    setParams(newParams);
    setNewParam(null);
  };

  return (
    <Wrapper $hidden={hidden}>
      <Segment raised>
        <Field name="params">
          {({ input: { value, onChange } }) => (
            <BasicTable
              onReorder={(rows) => {
                setParams(rows);
              }}
              columns={[
                { header: __("Názov") },
                { header: __("Hodnoty") },
                { header: "", width: 128 },
              ]}
              rows={value || []}
              renderRow={({ key, type, values }, idx) => [
                key,
                <Values>
                  {!!values?.length
                    ? values.map((e) => (
                        <Button
                          basic
                          icon="trash"
                          type="button"
                          onClick={() => {
                            if (
                              confirm(
                                `Naozaj si prajete odstrániť parameter ${key}:${e.key}`,
                              )
                            ) {
                              const newParams = [...params];
                              const newValues = [...values];
                              newParams[idx].values = newValues.filter(
                                (v) => v.key !== e.key,
                              );
                              // setParams(newParams);
                              onChange(newParams);
                            }
                          }}
                        >
                          {e.key}
                        </Button>
                      ))
                    : type === "text" && <>{__("Textový parameter")}</>}
                </Values>,
                <Button
                  danger
                  icon="trash"
                  type="button"
                  onClick={() => {
                    if (
                      confirm(
                        `Naozaj si prajete odstrániť parameter ${key} a všetky jeho hodnoty?`,
                      )
                    ) {
                      const newParams = [...params].filter(
                        (param) => param.key !== key,
                      );
                      // change("params", newParams);
                      onChange(newParams);
                    }
                  }}
                >
                  {__("Odstrániť")}
                </Button>,
              ]}
            />
          )}
        </Field>
        <div style={{ marginTop: rem(16) }}>
          <Button
            onClick={() => setNewParam({ label: "", type: "", values: [] })}
            primary
            icon="plus"
            type="button"
          >
            {__("Pridať nový parameter")}
          </Button>
        </div>

        <Form
          onSubmit={createNewParam}
          render={({ handleSubmit }) => {
            return (
              <Modal
                isOpen={!!newParam}
                handleClose={() => setNewParam(null)}
                size="xs"
              >
                <form onSubmit={handleSubmit}>
                  <ParametersForm
                    params={params}
                    handleClose={() => setNewParam(null)}
                  />
                </form>
              </Modal>
            );
          }}
        />
      </Segment>
    </Wrapper>
  );
};

export default Parameters;
