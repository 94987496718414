import { ContextBar, ContextBarItem } from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import {
  __,
  calculateOrderTotalpriceWithCredits,
  formatPrice,
  getProp,
} from "../../utilities";
import {
  activeParametersSelector,
  getCurrentOrdersSelector,
  ordersIsFetchingByActiveParametersSelector,
} from "./selectors";
import { connect } from "react-redux";
import {
  fetchCodelistOrderStatus,
  fetchOrders,
  setParameters,
} from "./actions";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { format } from "date-fns";
import { orderItemPropTypes } from "../OrderDetail/OrderItem";
import { routerParametersDefinition } from "../App/definitions";
import { saleChanellsListSelector } from "../SaleChannels/selectors";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import OrdersFilter from "./OrdersFilter";
import Paginator from "@sportnet/ui/Paginator";
import PaymentInfo from "./PaymentInfo";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import connectPagination from "../PaginationHoc";
import styled from "styled-components";

const LIMIT = 100;

export const Status = styled.div`
  padding: 0.5em 1.5em;
  color: ${(props) => props.fontColor && props.theme[props.fontColor]};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: inline-table;
  background-color: ${({ color }) => color};
  color: #fff;
  @media print {
    color: black;
  }
`;
export const StyledStatus = styled(Status)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Orders = ({
  dispatch,
  activeParameters,
  saleChannels,
  pagination,
  orderStatuses,
  offset,
  total,
  history,
  isFetching,
  orders,
}) => {
  const { ppo } = useAuth();

  React.useEffect(() => {
    if (ppo) {
      dispatch(fetchSaleChannelsList(ppo));
    }
    dispatch(fetchCodelistOrderStatus());
    getOrders(pagination.page);
  }, [ppo, dispatch, activeParameters]);

  const getSaleChannelLabel = (id) => {
    const channel = saleChannels.find((i) => {
      return i._id === id;
    });
    if (channel) {
      return channel.name;
    }
    return id;
  };

  const getOrders = (page = 1) => {
    const offset = page * LIMIT - LIMIT;
    const result = {
      offset,
      limit: LIMIT,
    };
    dispatch(fetchOrders(result));
  };

  const handleChangePage = (offset) => {
    pagination.handleChangePage(offset / LIMIT + 1);
    getOrders(offset / LIMIT + 1);
  };

  const renderOrderTableContent = (order) => {
    const {
      _id,
      invoicenr,
      company,
      person_name: personName,
      person_surname: personSurname,
      phone,
      status,
      created_date: createdDate,
      saleschannel,
    } = order;

    const {
      amount,
      amount_vat_excl: amountVatExcl,
      currency,
    } = calculateOrderTotalpriceWithCredits(order);
    const statusObj = orderStatuses.find((item) => item.value === status) || {};
    const color = getProp(statusObj, ["payload", "color"]);
    const statusLabel =
      statusObj?.deliveryLabel?.[order.delivery?.type] || statusObj.label;
    return (
      <Tr
        key={_id}
        onClick={() => {
          history.push(`/admin/${ppo}/orders/${_id}`);
        }}
      >
        <Td>
          <StyledStatus color={color}>{statusLabel}</StyledStatus>
        </Td>
        <Td center>
          {_id}
          <br />
          <strong style={{ color: "#64B660" }}>{invoicenr}</strong>
        </Td>
        <Td>{saleschannel && getSaleChannelLabel(saleschannel)}</Td>
        <Td>{format(new Date(createdDate), "dd.MM.yyyy HH:mm")}</Td>
        <Td>
          <b>{company}</b>
          <br />
          {`${personName} ${personSurname}`}
          <br />
          {`${__("Telefón")}: ${phone}`}
        </Td>
        <Td center>
          <PaymentInfo order={order} />
        </Td>
        <Td right>
          <b>
            {formatPrice(amount, currency)} {__("s DPH")}
          </b>
          <br />
          {formatPrice(amountVatExcl, currency)} {__("bez DPH")}
        </Td>
      </Tr>
    );
  };
  const renderPager = () => {
    return (
      <ContextBar>
        <ContextBarItem>
          <Paginator
            total={total}
            limit={LIMIT}
            offset={offset}
            onChangeOffset={(e) => {
              handleChangePage(e);
            }}
          />
        </ContextBarItem>
      </ContextBar>
    );
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__("Objednávky")}</HeaderBar.Header>
          </HeaderBar>
          <OrdersFilter
            orderStatuses={orderStatuses}
            parameters={activeParameters}
            salesChannels={saleChannels}
            setParameters={(parameters) => {
              handleChangePage(0);
              dispatch(setParameters(parameters));
            }}
          />
        </>
      }
      bottomFixed={renderPager()}
    >
      <AppContext title={__("Objednávky")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching && orders.length === 0}>
          <Table>
            <Thead>
              <Tr>
                <Th center>{__("Stav")}</Th>
                <Th center>{__("Číslo objednávky / faktúry")}</Th>
                <Th>{__("Predajný kanál")}</Th>
                <Th>{__("Dátum")}</Th>
                <Th>{__("Odberateľ")}</Th>
                <Th center>{__("Uhradené")}</Th>
                <Th right>{__("Celková cena")}</Th>
              </Tr>
            </Thead>
            <Tbody>{orders.map(renderOrderTableContent)}</Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

Orders.propTypes = {
  pagination: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      ...orderItemPropTypes,
    }),
  ).isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
  total: PropTypes.number.isRequired,
  saleChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  activeParameters: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: routerParametersDefinition,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    purchase: {
      orders: { data, q, status },
      orderStatuses,
    },
    selectedOrg,
  } = state;
  return {
    nextOffset: getProp(data, ["nextOffset"], 0),
    orders: getCurrentOrdersSelector(state),
    offset: getProp(data, ["offset"], 0),
    total: getProp(data, ["total"], 0),
    orderStatuses: orderStatuses.codelist,
    org: selectedOrg,
    isFetching: ordersIsFetchingByActiveParametersSelector(state),
    q,
    status,
    saleChannels: saleChanellsListSelector(state),
    activeParameters: activeParametersSelector(state),
  };
};

const withPagination = connectPagination()(Orders);
export default connect(mapStateToProps)(withPagination);
