import { __ } from ".";

export const required = (value) => {
  if (value || value === 0) {
    return null;
  }
  return __("Pole je povinné");
};

export const isNumber = (value) => {
  if (value && typeof value !== "number") {
    return __("Zadajte prosím číslo.");
  }
  return null;
};

export const isInteger = (value) => {
  if (!value || Number.isInteger(Number(value))) {
    return null;
  }
  return __("Hodnota musí byť celé číslo");
};

export const isFloat = (value) => {
  if (value && typeof value !== "number") {
    return __("Zadajte prosím desatinné alebo celé číslo.");
  }
  return null;
};

export const parseToNumber = (i) => {
  return !!i && !Number.isNaN(Number(i)) ? Number(i) : i;
};

export const parseToFloat = (i) => {
  if (i.endsWith(".") || i.endsWith(".0")) {
    return i;
  }
  if (i.endsWith(",")) {
    return i.replace(",", ".");
  }
  return !!i && !Number.isNaN(Number(i)) ? Number(i) : i;
};

export const gte = (min) => (value) => {
  return Number(value) >= min
    ? undefined
    : `${__("Hodnota musí byť vačšia alebo rovná")} ${min}`;
};

export const gt = (min) => (value) => {
  return Number(value) > min
    ? undefined
    : `${__("Hodnota musí byť vačšia ako")} ${min}`;
};

export const lte = (max) => (value) => {
  return Number(value) <= max
    ? undefined
    : `${__("Hodnota musí byť menšia alebo rovná")} ${max}`;
};
