import { Field, useFormState } from "react-final-form";
import { ModalActions } from "@sportnet/ui/Modal";
import { __ } from "../../../utilities";
import { required } from "../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Button from "@sportnet/ui/Button";
import FormField from "@sportnet/ui/FormField";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import apiEshop from "../../../service/apiEshop";

const existingParam = (existingValues) => (value) => {
  if (
    !existingValues?.find(
      (v) => v.value && v.value.toLowerCase() === value?.toLowerCase(),
    )
  ) {
    return null;
  }
  return __("Parameter už existuje.");
};

const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

const ParametersForm = ({ handleClose, params }) => {
  const [globalParams, setGlobalParams] = React.useState([]);
  const [globalValues, setGlobalValues] = React.useState([]);
  const [fetchingParams, setFetchingParams] = React.useState(false);
  const [fetchingValues, setFetchingValues] = React.useState(false);
  const { values } = useFormState();
  const { ppo } = useAuth();

  const fetchGlobalValues = React.useCallback(async () => {
    if (!!ppo && !!values?.key) {
      try {
        setFetchingValues(true);
        const res = await apiEshop.adminListParamValueLabels(ppo, values.key);
        setGlobalValues(
          (res?.values || []).map((p) => ({ value: p.label, label: p.label })),
        );
      } catch (e) {
        console.error(e);
      } finally {
        setFetchingValues(false);
      }
    }
  }, [ppo, values?.key]);

  const fetchGlobalParamLabels = React.useCallback(async () => {
    if (ppo) {
      try {
        setFetchingParams(true);
        const res = await apiEshop.adminListParamLabels(ppo);
        setGlobalParams(
          (res?.params || []).map((p) => ({ value: p.label, label: p.label })),
        );
      } catch (e) {
        console.error(e);
      } finally {
        setFetchingParams(false);
      }
    }
  }, [ppo]);

  React.useEffect(() => {
    (async () => await fetchGlobalValues())();
  }, [fetchGlobalValues]);

  React.useEffect(() => {
    (async () => await fetchGlobalParamLabels())();
  }, [fetchGlobalParamLabels]);

  return (
    <>
      <Segment
        header={
          <SegmentHeader withSeparator>{__("Nový parameter")}</SegmentHeader>
        }
      >
        <Field validate={required} name="key">
          {({ input: { value, onChange }, meta: { touched, error } }) => (
            <FormField
              required
              value={value}
              onChange={onChange}
              loading={fetchingParams}
              label={__("Názov")}
              type="theselectsimple"
              options={globalParams}
              createNewOption={(newValue) => {
                const option = {
                  value: newValue,
                  label: newValue,
                };
                setGlobalParams((prevOptions) => [...prevOptions, option]);
                return option;
              }}
              error={touched && error}
            />
          )}
        </Field>
        <Field name="type">
          {({ input: { value, name, onChange } }) => (
            <FormField
              type="checkbox"
              name={name}
              checked={value === "text"}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange("text");
                } else {
                  onChange(undefined);
                }
              }}
              label={__("Textový parameter")}
            />
          )}
        </Field>
        {values?.type !== "text" && (
          <Field
            validate={composeValidators(
              required,
              existingParam(params?.[values?.key]?.values),
            )}
            name="value"
          >
            {({ input: { value, onChange }, meta: { touched, error } }) => (
              <FormField
                required
                value={value}
                onChange={onChange}
                loading={fetchingValues}
                label={__("Hodnota")}
                type="theselectsimple"
                options={globalValues || []}
                createNewOption={(newValue) => {
                  const option = {
                    value: newValue,
                    label: newValue,
                  };
                  setGlobalValues((prevOptions) => [...prevOptions, option]);
                  return option;
                }}
                error={touched && error}
              />
            )}
          </Field>
        )}
      </Segment>
      <ModalActions>
        <Button basic type="reset" onClick={handleClose}>
          {__("Zavrieť")}
        </Button>

        <Button primary type="submit">
          {__("Pridať")}
        </Button>
      </ModalActions>
    </>
  );
};

export default ParametersForm;
