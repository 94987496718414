import { RECEIVE_FAILURE, RECEIVE_SUCCESS, REQUEST_DETAIL } from './constants';


export const importDetailReducer = (
    state = {
        data: {},
        isFetching: false,
    },
    action
) => {
    switch (action.type) {
        case REQUEST_DETAIL:
            return {
                ...state,
                isFetching: true,
            };
        case RECEIVE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isFetching: false,
            };
        case RECEIVE_FAILURE:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};