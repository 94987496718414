import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const priceLevelsDomainSelector = state => state.priceLevels;

export const priceLevelsListSelector = createSelector(
  priceLevelsDomainSelector,
  substate => getProp(substate, ['data'], []),
);

export const pricelevelCodelistSelector = createSelector(
  priceLevelsListSelector,
  list =>
    list.map(pricelevel => ({ value: pricelevel._id, label: pricelevel.name })),
);

export const priceLevelsIsFetchingSelector = createSelector(
  priceLevelsDomainSelector,
  substate => getProp(substate, ['isFetching'], true),
);
