import {
  __,
  calculateOrderTotalpriceGroups,
  formatPrice,
  getProp,
} from "../../utilities";
import { connect } from "react-redux";
import { fetchCodelistOrderStatus } from "../Orders/actions";
import { format } from "date-fns";
import { rem } from "polished";
import Api from "../../service/apiEshop";
import ApiSportnet from "../../service/apiSportnet";
import Loader from "@sportnet/ui/Loader";
import OrderSummary from "../../components/OrderSummary";
import Orders from "./Orders";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  font-family: sans-serif;
  font-size: ${rem(10)};
`;

const ReceiptId = styled.div`
  font-size: ${rem(22)};
  margin: ${rem(10)} 0;
`;

const LayoutTable = styled.table`
  width: 100%;
`;

const InfoRow = styled.div`
  line-height: 1.3;
`;

const Header = styled.div`
  font-size: ${rem(18)};
  margin: ${rem(5)} 0;
  font-weight: bold;
`;
const Body = styled.div``;

const SupplierWrapper = styled.div``;

const LayoutTd = styled.td`
  width: 50%;
`;

const TopCenteredTd = styled(LayoutTd)`
  vertical-align: top;
`;

const Spacer = styled.div`
  ${({ height }) => css`
    height: ${rem(height || 16)};
  `};
`;

const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class ReceiptDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      detail: {},
      statuses: [
        {
          label: "Neuhradený",
          value: "unpaid",
          payload: {
            color: "red",
          },
        },
        {
          label: "Uhradený",
          value: "paid",
          payload: {
            color: "green",
          },
        },
      ],
      supplierDetail: {},
      isFetching: true,
    };

    this.getRecieptDetail = this.getRecieptDetail.bind(this);
    this.getSupplierDetail = this.getSupplierDetail.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  getSupplierDetail(supplierId) {
    return ApiSportnet.organizationInvoiceProfile(supplierId).catch((e) => {
      console.error(e);
    });
  }

  getRecieptDetail(acl) {
    const {
      match: {
        params: { reciept_id: recieptId },
      },
      selectedOrg,
    } = this.props;
    if (acl.adminReceiptDetail) {
      return Api.adminReceiptDetail(recieptId, selectedOrg).catch((e) => {
        throw e;
      });
    }
    return Api.adminSupplierReceiptDetail(recieptId, selectedOrg).catch((e) => {
      throw e;
    });
  }

  init = async () => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      acl,
    } = this.props;
    try {
      await dispatch(fetchCodelistOrderStatus());
      const detail = await this.getRecieptDetail(acl);
      const supplierDetail = await this.getSupplierDetail(detail.supplier_id);
      this.setState({
        detail,
        supplierDetail,
        isFetching: false,
      });
      setTimeout(() => {
        window.print();
      }, 0);
    } catch (e) {
      console.error(e);
      alert(__("Predpis sa nepodarilo nájsť"));
      this.props.history.push(`/admin/${appspace}/receipts-list`);
    }
  };

  render() {
    if (this.state.isFetching) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }
    const {
      statuses,
      detail: {
        supplier_name: supplierName,
        created_date: createdDate,
        status,
        paid_date: paidDate,
        orders,
      },
      supplierDetail,
    } = this.state;
    const { reciept_id: receiptId } = this.props.match.params;

    const totalSummary = (orders || []).reduce(
      (t, order) => {
        const newTotal = {
          currency: order.summary.currency,
          paidbycredit: t.paidbycredit + order.summary.paidbycredit,
          paidbyother:
            order.paid_date && order.payment.type === "GPWEBPAY"
              ? t.paidbyother + order.summary.paidbyother
              : t.paidbyother,
          pricetopay: t.pricetopay + order.summary.pricetopay,
          totalBase: t.totalBase + order.summary.totalBase,
          total: t.total + order.summary.total,
          _taxRates: Object.assign({}, t._taxRates),
        };

        // zmergujeme data pre taxrate
        order.summary.taxRates.forEach((tr) => {
          // kluc bude string
          const vrk = String(tr.vatRate);
          // hladame v newTotal.taxRates, ak je, pripocitame, ak nie je pridame
          if (!newTotal._taxRates[vrk]) {
            newTotal._taxRates[vrk] = Object.assign({}, tr);
          } else {
            newTotal._taxRates[vrk].base += tr.base;
            newTotal._taxRates[vrk].tax += tr.tax;
          }
        });

        return newTotal;
      },
      {
        currency: "",
        paidbycredit: 0,
        paidbyother: 0,
        pricetopay: 0,
        _taxRates: {},
        totalBase: 0,
        total: 0,
      },
    );

    totalSummary.taxRates = Object.keys(totalSummary._taxRates)
      .map((tr) => {
        return totalSummary._taxRates[tr];
      })
      .sort((a, b) => {
        return a.vatRate > b.vatRate;
      });

    const saleschannelGroups = (orders || []).reduce((g, o) => {
      if (o.saleschannel in g) {
        g[o.saleschannel].orders.push(o);
        return g;
      }
      return Object.assign(g, {
        [o.saleschannel]: { orders: [o] },
      });
    }, {});

    const saleschannelSummary = Object.keys(saleschannelGroups).map((k) => {
      saleschannelGroups[k].saleschannel = k;
      saleschannelGroups[k].priceGroups = {};
      saleschannelGroups[k].orders.forEach((order) => {
        // spocitame pricegroups a mergneme na hlavne grupy
        calculateOrderTotalpriceGroups(order).forEach((priceGroup) => {
          if (priceGroup.group in saleschannelGroups[k].priceGroups) {
            saleschannelGroups[k].priceGroups[priceGroup.group].amount +=
              priceGroup.amount;
            saleschannelGroups[k].priceGroups[
              priceGroup.group
            ].amount_vat_excl += priceGroup.amount_vat_excl;
          } else {
            saleschannelGroups[k].priceGroups[priceGroup.group] = priceGroup;
          }
        });
      });
      return saleschannelGroups[k];
    });

    const actualStatus = statuses.find((stat) => stat.value === status) || {};

    return (
      <Wrapper>
        <LayoutTable>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top", width: "50%" }}>
                <ReceiptId>
                  {__("Predpis ")}
                  <strong>{receiptId}</strong>
                </ReceiptId>
                <br />
                <InfoRow>
                  <strong>{__("Stav: ")}</strong>
                  {actualStatus.label}
                </InfoRow>
                <InfoRow>
                  <strong>{__("Dátum vytvorenia: ")}</strong>
                  {format(new Date(createdDate), "dd.MM.yyyy HH:mm")}
                </InfoRow>
                {paidDate && (
                  <InfoRow>
                    <strong>{__("Uhradené: ")}</strong>
                    {format(new Date(paidDate), "dd.MM.yyyy HH:mm")}
                  </InfoRow>
                )}
              </td>
              <td>
                <SupplierWrapper>
                  <Header>{__("Prevádzkovateľ eshopu")}</Header>
                  <Body>
                    <div>
                      <b>SFZ Marketing, s.r.o.</b>
                    </div>
                    <div>Tomášikova 30C, 821 01 Bratislava </div>
                    <div>
                      <b>IČO:</b> 46320768
                    </div>
                    <div>
                      <b>IČ DPH:</b> SK2023327196
                    </div>
                  </Body>
                </SupplierWrapper>
                <SupplierWrapper>
                  <Header>{__("Dodávateľ")}</Header>
                  <Body>
                    {supplierName ? (
                      <div>
                        <b>{supplierName}</b>
                      </div>
                    ) : (
                      <div>
                        {getProp(supplierDetail, ["full_name"]) ||
                          getProp(supplierDetail, ["name"])}
                      </div>
                    )}
                    <div>
                      {getProp(supplierDetail, ["invoice_address", "street"]) &&
                        `${getProp(supplierDetail, [
                          "invoice_address",
                          "street",
                        ])} `}
                      {getProp(supplierDetail, ["invoice_address", "number"]) &&
                        `${getProp(supplierDetail, [
                          "invoice_address",
                          "number",
                        ])}, `}
                      {getProp(supplierDetail, ["invoice_address", "zip"]) &&
                        `${getProp(supplierDetail, [
                          "invoice_address",
                          "zip",
                        ])} `}
                      {getProp(supplierDetail, ["invoice_address", "city"]) &&
                        `${getProp(supplierDetail, [
                          "invoice_address",
                          "city",
                        ])} `}
                    </div>
                    {getProp(supplierDetail, ["business_id"]) && (
                      <div>
                        <b>IČO:</b> {getProp(supplierDetail, ["business_id"])}
                      </div>
                    )}
                    {getProp(supplierDetail, ["vat_nr"]) && (
                      <div>
                        <b>IČ DPH:</b> {getProp(supplierDetail, ["vat_nr"])}
                      </div>
                    )}
                  </Body>
                </SupplierWrapper>
              </td>
            </tr>
          </tbody>
        </LayoutTable>
        <Spacer height={10} />
        <Orders orders={orders || []} />
        <Spacer height={10} />
        <LayoutTable>
          <tbody>
            <tr>
              <TopCenteredTd>
                {saleschannelSummary.map((sc) => {
                  return (
                    <div key={sc.saleschannel}>
                      <Header>
                        {__("Predajný kanál")}: {sc.saleschannel}
                      </Header>
                      {Object.values(sc.priceGroups).map((g) => {
                        const style = {};
                        if (g.group === "other") {
                          style.fontSize = "120%";
                          style.fontWeight = "bold";
                        }
                        return (
                          <div key={g.group} style={style}>
                            <strong>{g.group}: </strong>
                            {formatPrice(g.amount_vat_excl, g.currency)}{" "}
                            {__("bez DPH")} ({formatPrice(g.amount, g.currency)}{" "}
                            {__("s DPH")})
                          </div>
                        );
                      })}
                      <Spacer height={10} />
                    </div>
                  );
                })}
              </TopCenteredTd>
              <TopCenteredTd>
                <OrderSummary summary={totalSummary} printTotal={false} />
              </TopCenteredTd>
            </tr>
          </tbody>
        </LayoutTable>
      </Wrapper>
    );
  }
}

ReceiptDetail.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({}) }).isRequired,
  dispatch: PropTypes.func.isRequired,
  acl: PropTypes.shape({}).isRequired,
  selectedOrg: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { userAcl } = state;
  return {
    selectedOrg: state.selectedOrg,
    acl: userAcl.data,
  };
};

export default connect(mapStateToProps)(ReceiptDetail);
