import * as React from "react";
import { Field, useFormState } from "react-final-form";
import { __, removeAccents } from "../../../utilities";
import { theme as defaultContentTheme } from "@sportnet/content/theme/theme";
import { rem } from "polished";
import { required } from "../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Col, { Row } from "@sportnet/ui/Grid";
import Editor from "@sportnet/content/editor";
import Features from "./Features";
import FormField from "@sportnet/ui/FormField";
import FormGroup from "@sportnet/ui/FormGroup";
import Label from "@sportnet/ui/Label/Label";
import Listings from "./Listings";
import RedirectInput from "./RedirectInput";
import Segment from "@sportnet/ui/Segment";
import Skeleton from "@sportnet/ui/Skeleton";
import TagmanagerConnector from "@sportnet/tagmanager-connector/SmarttagsConnector";
import apiEshop from "../../../service/apiEshop";
import defaultWidgets from "@sportnet/content/editor/defaultWidgets";
import styled, { ThemeProvider } from "styled-components";

const Wrapper = styled.div`
  display: ${({ $hidden }) => ($hidden ? "none" : "block")};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${rem(32)};
  row-gap: ${rem(2)};
`;

const availableWidgets = [
  "imagead",
  "document",
  "externalArticle",
  "photogallery",
  "photo",
  "multiblock",
  "table",
  "social",
  "video",
  "faq",
  "hr",
  "iframe",
  "banners",
  "textBlock",
];

const ProductSettings = ({ loading = false, hidden = false }) => {
  const [isFetchingProductTypeCodelist, setIsFetchingProductTypeCodelist] =
    React.useState(false);
  const [productTypeCodelist, setProductTypeCodelist] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [isFetchingBrands, setIsFetchingBrands] = React.useState(false);
  const [salesChannels, setSalesChannels] = React.useState([]);
  const [isFetchingSalesChannels, setIsFetchingSalesChannels] =
    React.useState(false);
  const { app, ppo, accessToken } = useAuth();
  const { values } = useFormState();

  React.useEffect(() => {
    setIsFetchingBrands(true);
    apiEshop
      .adminListBrands(ppo)
      .then((data) => {
        setBrands(
          data.codelist?.map((item) => ({
            label: `${item.label} (${item?.payload?.count || 0})`,
            value: item.value,
          })),
        );
      })
      .catch(() => {
        alert(__("Nepodarilo sa získať zoznam značiek"));
      })
      .finally(() => {
        setIsFetchingBrands(false);
      });
  }, [ppo]);

  React.useEffect(() => {
    setIsFetchingProductTypeCodelist(true);
    apiEshop
      .getCodelist("product_type")
      .then(({ codelist }) => {
        setProductTypeCodelist(codelist);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam dostupných typov produktov."));
      })
      .finally(() => {
        setIsFetchingProductTypeCodelist(false);
      });
  }, []);

  const handleCreateNewBrand = (inputValue) => {
    const newOption = { value: inputValue, label: `${inputValue} (0)` };
    setBrands([...brands, newOption]);
    return newOption;
  };

  React.useEffect(() => {
    setIsFetchingSalesChannels(true);
    apiEshop
      .adminListSaleschannels(ppo)
      .then((data) => {
        setSalesChannels(data.saleschannels || []);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
      })
      .finally(() => {
        setIsFetchingSalesChannels(false);
      });
  }, [ppo]);

  return (
    <Wrapper $hidden={hidden}>
      <Segment raised>
        <Row style={{ marginBottom: rem(8) }}>
          <Col>
            <Label>{__("Nastavenia")}</Label>
          </Col>
        </Row>
        <FlexRow>
          {loading ? (
            <>
              <Skeleton
                width={110}
                height={16}
                style={{ marginBottom: rem(20) }}
              />
              <Skeleton
                width={166}
                height={16}
                style={{ marginBottom: rem(20) }}
              />
              <Skeleton
                width={147}
                height={16}
                style={{ marginBottom: rem(20) }}
              />
            </>
          ) : (
            <>
              <Field name="active" type="checkbox">
                {({ input: { checked, onChange } }) => (
                  <FormField
                    checked={checked}
                    onChange={onChange}
                    label={__("Aktívny produkt")}
                    type="checkbox"
                  />
                )}
              </Field>
              <Field name="sfz_credit" type="checkbox">
                {({ input: { checked, onChange } }) => (
                  <FormField
                    checked={checked}
                    onChange={onChange}
                    label={__("Platba možná SFZ kreditmi")}
                    type="checkbox"
                  />
                )}
              </Field>
              <Field name="sfz_credit_only" type="checkbox">
                {({ input: { checked, onChange } }) => (
                  <FormField
                    checked={checked}
                    onChange={onChange}
                    disabled={!values?.sfz_credit}
                    label={__("Platba len SFZ kreditmi")}
                    type="checkbox"
                  />
                )}
              </Field>
            </>
          )}
        </FlexRow>
        <Row style={{ marginBottom: rem(8) }}>
          <Col>
            <Label>{__("Predajné kanály")}</Label>
          </Col>
        </Row>
        <FlexRow>
          {loading || isFetchingSalesChannels
            ? Array.from(Array(2)).map(() => (
                <Skeleton
                  width={110}
                  height={16}
                  style={{ marginBottom: rem(20) }}
                />
              ))
            : salesChannels.map((channel) => (
                <Field name={`salesChannels.${channel._id}`} type="checkbox">
                  {({ input: { checked, onChange } }) => (
                    <FormField
                      checked={checked}
                      onChange={onChange}
                      label={channel.name}
                      type="checkbox"
                    />
                  )}
                </Field>
              ))}
        </FlexRow>
        <Listings />
        <Row>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={110}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(20) }}
                />
              </>
            ) : (
              <Field name="name" validate={required}>
                {({ input: { value, onChange }, meta: { touched, error } }) => (
                  <FormField
                    value={value}
                    label={"Názov produktu"}
                    onChange={onChange}
                    required
                    error={touched && error}
                    placeholder={__("Sem napíšte názov produktu...")}
                  />
                )}
              </Field>
            )}
          </Col>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={92}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(20) }}
                />
              </>
            ) : (
              <Field name="type" validate={required}>
                {({ input: { value, onChange }, meta: { touched, error } }) => (
                  <FormField
                    required
                    value={value}
                    onChange={onChange}
                    label={__("Typ produktu")}
                    type="theselectsimple"
                    error={touched && error}
                    options={productTypeCodelist}
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={39}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="brand">
                {({ input: { value, onChange } }) => (
                  <FormField
                    label={__("Značka")}
                    name="brand"
                    type="theselectsimple"
                    value={value}
                    onChange={onChange}
                    options={brands}
                    loading={isFetchingBrands}
                    createNewOption={handleCreateNewBrand}
                  />
                )}
              </Field>
            )}
          </Col>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={72}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="code">
                {({ input: { value, onChange } }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("Kód produktu")}
                    type="text"
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={56}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="url">
                {({ input: { value, onChange } }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    placeholder={__(
                      'Bude vyplnené automaticky (príklad: "url")',
                    )}
                    info={__(
                      'Relatívna URL, na ktorej bude produkt dostupný (príklad: "url-noveho-produktu"). Hodnota bude predvyplnená automaticky po prvom uložení.',
                    )}
                    label={__("URL")}
                    parse={(i) => removeAccents(i)}
                  />
                )}
              </Field>
            )}
          </Col>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={56}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="smarttags">
                {({ input: { value, onChange } }) => (
                  <TagmanagerConnector
                    appId={app}
                    appspace={ppo}
                    accessToken={accessToken}
                  >
                    {(
                      getSmarttagsKeys,
                      getSmarttagsValues,
                      getSmarttagsByFts,
                    ) => (
                      <FormField
                        getSmarttagsValues={getSmarttagsValues}
                        getSmarttagsKeys={getSmarttagsKeys}
                        getSmarttagsByFts={getSmarttagsByFts}
                        value={value}
                        onChange={onChange}
                        name="smarttags"
                        type="smarttag"
                        label={__("Smarttagy")}
                      />
                    )}
                  </TagmanagerConnector>
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={56}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="redirect">
                {({ input: { value, onChange, name } }) => (
                  <RedirectInput
                    value={value}
                    onChange={onChange}
                    name={name}
                  />
                )}
              </Field>
            )}
          </Col>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={56}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="meta.title">
                {({ input: { value, onChange, name } }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("Meta Title")}
                    type="text"
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: rem(10) }}>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={72}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={178}
                  borderRadius={4}
                  style={{ marginBottom: rem(3) }}
                />
                <Skeleton
                  width={77}
                  height={13.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
              </>
            ) : (
              <Field name="perex">
                {({ input: { value, onChange } }) => (
                  <FormField
                    value={value}
                    loading={isFetchingProductTypeCodelist}
                    onChange={onChange}
                    type="textarea"
                    counter={{ words: true, characters: true }}
                    label="Krátky popis"
                    placeholder={__("Krátky popis")}
                  />
                )}
              </Field>
            )}
          </Col>
          <Col xs={12} s={6}>
            {loading ? (
              <>
                <Skeleton
                  width={56}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={40}
                  borderRadius={4}
                  style={{ marginBottom: rem(30) }}
                />
              </>
            ) : (
              <Field name="meta.description">
                {({ input: { value, onChange, name } }) => (
                  <FormField
                    value={value}
                    onChange={onChange}
                    label={__("Meta Description")}
                    type="textarea"
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {loading ? (
              <>
                <Skeleton
                  width={72}
                  height={14.5}
                  borderRadius={4}
                  style={{ marginBottom: rem(5) }}
                />
                <Skeleton
                  width={"100%"}
                  height={176}
                  borderRadius={4}
                  style={{ marginBottom: rem(10) }}
                />
              </>
            ) : (
              <Field name="description">
                {({ input: { value, onChange } }) => (
                  <FormGroup>
                    <Label>{__("Detailný popis produktu")}</Label>
                    <ThemeProvider theme={defaultContentTheme}>
                      <Editor
                        key={Math.random(10)}
                        appId={app}
                        appSpace={ppo}
                        contentDivider="default"
                        token={accessToken}
                        initialValue={value || []}
                        availableWidgets={defaultWidgets.filter((i) =>
                          availableWidgets.includes(i.type),
                        )}
                        onChange={onChange}
                      />
                    </ThemeProvider>
                  </FormGroup>
                )}
              </Field>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: rem(8) }}>
          <Col>
            <Features />
          </Col>
        </Row>
      </Segment>
    </Wrapper>
  );
};

export default ProductSettings;
