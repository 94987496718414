import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Link } from "react-router-dom";
import { __, getProp } from "../../../utilities";
import { connect } from "react-redux";
import {
  countryCodesIsFetchingSelector,
  countryCodesListSelector,
} from "./selectors";
import { fetchCountryCodes } from "./actions";
import { rem } from "polished";
import Api from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Checkbox from "@sportnet/ui/Checkbox";
import Col, { Row } from "@sportnet/ui/Grid";
import FormGroup from "@sportnet/ui/FormGroup";
import Header from "@sportnet/ui/Header";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../../components/Layout";
import Loader from "@sportnet/ui/Loader";
import Price from "@sportnet/ui/Price";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Segment from "@sportnet/ui/Segment";
import Select from "@sportnet/ui/TheSelectSimple";
import round from "lodash/round";
import styled from "styled-components";

const StyledLabel = styled(Label)`
  + div {
    height: ${rem(14)};
  }
`;

class DeliveryDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deliveryOptions: [],
      type: "",
      name: "",
      creditpay: false,
      excludeFromFutbalnetShop: false,
      amount: 0,
      free_shipping: "",
      amount_vat_excl: 0,
      vat_rate: 20,
      currency: "EUR",
      volumetric_pricelist: [],
      isSubmitting: false,
      success: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeVolumetricPricelist =
      this.handleChangeVolumetricPricelist.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      org,
    } = this.props;
    const { dispatch } = this.props;
    dispatch(fetchCountryCodes());
    Api.adminGetSupplierDeliveryOptions(org)
      .then((response) => {
        const { items } = response;
        const obj = items[params.delivery_id];
        if (params.delivery_id !== "new") {
          const vp = obj.volumetric_pricelist || [];
          // pridame jedno nove na koniec
          vp.push({});
          this.setState({
            deliveryOptions: items,
            type: obj.type,
            name: obj.name,
            creditpay: obj.creditpay,
            excludeFromFutbalnetShop: obj.excludeFromFutbalnetShop,
            amount: obj.price.amount,
            amount_vat_excl: obj.price.amount_vat_excl,
            vat_rate: obj.price.vat_rate,
            currency: obj.price.currency,
            free_shipping:
              obj.free_shipping_from === null ||
              typeof obj.free_shipping_from === "undefined"
                ? ""
                : obj.free_shipping_from,
            volumetric_pricelist: vp,
            availableCountries: obj.availableCountries,
          });
        } else {
          this.setState({
            deliveryOptions: items,
            volumetric_pricelist: [{}],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAvailableSize(sizeName) {
    const sizesInOrder = ["xs", "s", "m", "l", "xl"];
    const currentIndex = sizesInOrder.findIndex((item) => {
      return item === sizeName;
    });
    let availableSize = null;
    for (let i = currentIndex; i >= 0; i--) {
      if (this.props[sizesInOrder[i]]) {
        availableSize = this.props[sizesInOrder[i]];
        break;
      }
    }
    return (100 / this.columns) * availableSize;
  }

  handleCopy = () => {
    const {
      org,
      match: {
        params: { appspace, delivery_id },
      },
    } = this.props;
    const currentItem = this.state.deliveryOptions[delivery_id];
    Api.adminUpdateDeliveryOptions(org, {
      data: {
        items: [
          ...this.state.deliveryOptions.map((i) => ({
            ...i,
            volumetric_pricelist: i.volumetric_pricelist.filter(
              (p) => Object.keys(p).length,
            ),
          })),
          {
            ...currentItem,
            type: `${currentItem.type}_COPY`,
            volumetric_pricelist: currentItem.volumetric_pricelist.filter(
              (i) => Object.keys(i).length,
            ),
          },
        ],
      },
    })
      .then(() => {
        this.props.history.push(`/admin/${appspace}/delivery-options`);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 400) {
          if (error.response.name === "DUPLICATE_TYPE") {
            alert(__("Kópia tejto dopravy s dočasným typom už existuje"));
          } else {
            alert(__("Nesprávne vyplnená položka"));
          }
        } else {
          alert(
            delivery_id === "new"
              ? __("Pri vytváraní spôsobu dopravy nastala chyba")
              : __("Pri aktualizovaní spôsobu dopravy nastala chyba"),
          );
        }
      });
  };

  handleChangePrice(p) {
    this.setState({ ...p });
  }

  handleChange(e) {
    const {
      target: { name, value },
    } = e;
    if (name === "amount") {
      const vatRate = this.state.vat_rate;
      if (value !== "" && vatRate && vatRate !== "") {
        this.setState({
          amount_vat_excl: round(
            parseFloat(value) / (1 + parseFloat(vatRate) / 100),
            2,
          ),
        });
      } else {
        this.setState({
          amount_vat_excl: "",
        });
      }
    } else if (name === "amount_vat_excl") {
      const { vatRate } = this.state;
      if (value !== "" && vatRate && vatRate !== "") {
        this.setState({
          amount: round(parseFloat(value) * (1 + parseFloat(vatRate) / 100), 2),
        });
      } else {
        this.setState({
          amount: "",
        });
      }
    }
    this.setState({
      [name]: value,
    });
  }

  handleRemove() {
    const {
      org,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { params } = this.props.match;
    const { deliveryOptions } = this.state;
    const arrayToSend = [...deliveryOptions];
    arrayToSend.splice(params.delivery_id, 1);
    const conf = window.confirm(__("Naozaj chcete odstrániť spôsob dopravy?"));
    if (conf) {
      Api.adminUpdateDeliveryOptions(org, { data: { items: arrayToSend } })
        .then(() => {
          this.props.history.push(`/admin/${appspace}/delivery-options`);
        })
        .catch((error) => {
          console.error(error);
          alert(__("Pri odstraňovaní spôsobu dopravy nastala nečakaná chyba"));
        });
    }
  }

  toggleCreditPay = () => {
    this.setState({ creditpay: !this.state.creditpay });
  };

  toggleFutbalnetShopExclude = () => {
    this.setState({
      excludeFromFutbalnetShop: !this.state.excludeFromFutbalnetShop,
    });
  };

  handleSave() {
    const {
      org,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { params } = this.props.match;
    const {
      type,
      name,
      creditpay,
      excludeFromFutbalnetShop,
      amount,
      currency,
      deliveryOptions,
      availableCountries,
    } = this.state;
    const arrayToSend = [...deliveryOptions];
    let errors = 0;
    if (type === "") {
      errors += 1;
    }
    if (name === "") {
      errors += 1;
    }
    if (amount === "") {
      errors += 1;
    }
    if (this.state.vat_rate === "") {
      errors += 1;
    }
    if (this.state.amount_vat_excl === "") {
      errors += 1;
    }
    if (errors === 0) {
      const data = {
        type,
        name,
        creditpay,
        excludeFromFutbalnetShop,
        price: {
          amount: parseFloat(amount),
          amount_vat_excl: parseFloat(this.state.amount_vat_excl),
          currency,
          vat_rate: parseFloat(this.state.vat_rate),
        },
        volumetric_pricelist: this.state.volumetric_pricelist.reduce(
          (all, p) => {
            const item = p;
            const w = parseFloat(p.weight);
            const v = parseFloat(p.volume);
            if (!Number.isNaN(w) && !Number.isNaN(v) && w > 0 && v > 0) {
              item.weight = w;
              item.volume = v;
              if (!p.price) {
                item.price = {
                  amount: 0,
                  amount_vat_excl: 0,
                  vat_rate: 20,
                  currency: "EUR",
                };
              }
              all.push(p);
            }
            return all;
          },
          [],
        ),
        availableCountries: !!availableCountries?.length
          ? availableCountries
          : undefined,
      };

      if (this.state.free_shipping === "") {
        data.free_shipping_from = null;
      } else {
        data.free_shipping_from = parseFloat(this.state.free_shipping);
      }

      if (params.delivery_id === "new") {
        arrayToSend.push(data);
      } else {
        arrayToSend[params.delivery_id] = data;
      }

      this.setState({
        isSubmitting: true,
      });

      Api.adminUpdateDeliveryOptions(org, { data: { items: arrayToSend } })
        .then(() => {
          if (params.delivery_id === "new") {
            this.props.history.push(`/admin/${appspace}/delivery-options`);
          } else {
            this.setState({ success: true });
            setTimeout(() => {
              this.setState({ success: false });
            }, 3000);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 400) {
            if (error.response.name === "DUPLICATE_TYPE") {
              alert(__("Doprava so zadaným typom už existuje"));
            } else {
              alert(__("Nesprávne vyplnená položka"));
            }
          } else {
            alert(
              params.delivery_id === "new"
                ? __("Pri vytváraní spôsobu dopravy nastala chyba")
                : __("Pri aktualizovaní spôsobu dopravy nastala chyba"),
            );
          }
        })
        .finally(() => {
          this.setState({
            isSubmitting: false,
          });
        });
    } else {
      alert(__("Neboli vypnlené všetky povinné údaje"));
    }
  }

  handleChangeVolumetricPricelist(index, name, value) {
    let v = value;
    if (["weight", "volume"].includes(name)) {
      v = value.replace(/[^0-9,.]/, "").replace(",", ".");
    }

    const chvp = [...this.state.volumetric_pricelist];
    chvp[index][name] = v;

    // ak sme upravili posledne, tak doplnime nove prazdne
    if (index === chvp.length - 1) {
      chvp.push({});
    }

    this.setState({
      volumetric_pricelist: chvp,
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    const { type, name, creditpay, amount, excludeFromFutbalnetShop } =
      this.state;
    const { countryCodes, isFetchingCountryCodes } = this.props;

    const title =
      params.delivery_id !== "new"
        ? `${__("Spôsob dopravy")}: ${name}`
        : __("Nový spôsob dopravy");

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${params.appspace}/delivery-options`}
              title={__("Spôsoby dopravy")}
            />
            <HeaderBar.Header>{title}</HeaderBar.Header>
          </HeaderBar>
        }
        bottomFixed={
          <ContextBar>
            <ContextBarItem>
              {params.delivery_id !== "new" && (
                <Button danger onClick={this.handleRemove}>
                  {__("Odstrániť")}
                </Button>
              )}
            </ContextBarItem>
            <ContextBarSpacer />
            {params.delivery_id !== "new" && (
              <>
                <ContextBarItem>
                  <Button primary onClick={this.handleCopy}>
                    {__("Kopírovať")}
                  </Button>
                </ContextBarItem>
                &nbsp;
              </>
            )}
            <ContextBarItem>
              <Button
                primary={!this.state.success}
                success={!!this.state.success}
                onClick={this.handleSave}
              >
                {params.delivery_id === "new" ? (
                  <>{__("Pridať")}</>
                ) : this.state.isSubmitting ? (
                  <Loader color={"#fff"} />
                ) : this.state.success ? (
                  __("Uložené")
                ) : (
                  __("Uložiť")
                )}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        {params.delivery_id !== "new" ? (
          <AppContext
            title={__(`${name}`)}
            breadcrumbs={[
              {
                url: `/admin/${params.appspace}/delivery-options`,
                name: __("Spôsoby dopravy"),
              },
              { name: __(`${name}`) },
            ]}
          />
        ) : (
          <AppContext
            title={__(`${__("Nový spôsob dopravy")}`)}
            breadcrumbs={[
              {
                url: `/admin/${params.appspace}/delivery-options`,
                name: __("Spôsoby dopravy"),
              },
              { name: __(`${__("Nový spôsob dopravy")}`) },
            ]}
          />
        )}
        <Segment>
          <Segment raised>
            <Row>
              <Col xs={12} s={6} m={6}>
                <FormGroup>
                  {/*TODO: prestylovat label v sportnet.ui*/}
                  <StyledLabel
                    info={__("Aktuálne podporované typy: ZASIELKOVNA")}
                  >
                    {__("Typ")}
                  </StyledLabel>
                  <Input
                    name="type"
                    value={type}
                    placeholder="Zadajte typ"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={6} m={6}>
                <FormGroup>
                  <Label>{__("Názov")}</Label>
                  <Input
                    name="name"
                    value={name}
                    placeholder="Zadajte názov"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={6}>
                <Price
                  amount={amount || 0}
                  amountVatExcl={this.state.amount_vat_excl || 0}
                  vatRate={this.state.vat_rate || 20}
                  onChange={(price) => {
                    this.handleChangePrice({
                      amount: price.amount,
                      amount_vat_excl: price.amountVatExcl,
                      vat_rate: price.vatRate,
                      currency: price.currency,
                    });
                  }}
                />
              </Col>
              <Col xs={12} s={6}>
                <FormGroup>
                  <Label>{__("Doprava zdarma od")}</Label>
                  <Input
                    name="free_shipping"
                    type="number"
                    value={this.state.free_shipping}
                    placeholder="Doprava zdarma nad túto hranicu"
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} s={6} m={6}>
                <FormGroup>
                  <Label
                    info={__(
                      "Ak nie je zvolená žiadna krajina, spôsob doručenia nie je geograficky obmmedzený.",
                    )}
                  >
                    {__("Podporované krajiny")}
                  </Label>

                  <Select
                    name="availableCountries"
                    options={countryCodes}
                    loading={isFetchingCountryCodes}
                    value={this.state.availableCountries || []}
                    onChange={(value) => {
                      this.handleChange({
                        target: {
                          name: "availableCountries",
                          value,
                        },
                      });
                    }}
                    multiple
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} s={6} m={6}>
                <FormGroup>
                  <Label>
                    <Checkbox
                      checked={creditpay}
                      onChange={this.toggleCreditPay}
                    />
                    {__("Možné uhradiť kreditmi")}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label>
                    <Checkbox
                      checked={excludeFromFutbalnetShop}
                      onChange={this.toggleFutbalnetShopExclude}
                    />
                    {__("Nepoužívať na futbalnet.shop")}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Segment>
          <Segment raised>
            <Header size="xs">{__("Cenník prepravy")}</Header>
            {this.state.volumetric_pricelist.map((item, index) => {
              return (
                <Segment raised key={index.toString()}>
                  <Row>
                    <Col xs={12} s={6} m={3}>
                      <FormGroup>
                        <Label>{__("Hmotnosť do [kg]")}</Label>
                        <Input
                          name="weight"
                          value={item.weight || ""}
                          placeholder="kg"
                          onChange={(e) => {
                            this.handleChangeVolumetricPricelist(
                              index,
                              e.target.name,
                              e.target.value,
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} s={6} m={3}>
                      <FormGroup>
                        <Label>{__("Objem do [m3]")}</Label>
                        <Input
                          name="volume"
                          value={item.volume || ""}
                          placeholder="m3"
                          onChange={(e) => {
                            this.handleChangeVolumetricPricelist(
                              index,
                              e.target.name,
                              e.target.value,
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} s={6}>
                      <Price
                        amount={getProp(item, ["price", "amount"], 0)}
                        amountVatExcl={getProp(
                          item,
                          ["price", "amount_vat_excl"],
                          0,
                        )}
                        vatRate={getProp(item, ["price", "vat_rate"], 20)}
                        onChange={(price) => {
                          this.handleChangeVolumetricPricelist(index, "price", {
                            amount: price.amount,
                            amount_vat_excl: price.amountVatExcl,
                            vat_rate: price.vatRate,
                            currency: price.currency,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Segment>
              );
            })}
          </Segment>
        </Segment>
      </Layout>
    );
  }
}

DeliveryDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      delivery_id: PropTypes.string,
    }),
  }).isRequired,
  org: PropTypes.string.isRequired,
};

DeliveryDetail.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    org: state.selectedOrg,
    countryCodes: countryCodesListSelector(state),
    isFetchingCountryCodes: countryCodesIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(DeliveryDetail);
