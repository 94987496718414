import { __ } from "../../utilities";
import DefaultFilter from "@sportnet/ui/Filter";
import PropTypes from "prop-types";
import React from "react";

const paidOptionCodelist = [
  {
    label: __("Platené kreditom"),
    value: "paidbycredit",
  },
  {
    label: __("Platené kartou"),
    value: "paidbyother",
  },
];

const paidCodelist = [
  {
    label: __("Uhradená"),
    value: "true",
  },
  {
    label: __("Neuhradená"),
    value: "false",
  },
];

const OrdersFilter = ({
  salesChannels,
  setParameters,
  parameters,
  orderStatuses,
}) => {
  const salesChannelsCodelist = salesChannels.map((sc) => ({
    value: sc._id,
    label: sc.name,
  }));

  return (
    <DefaultFilter
      value={{
        statuses:
          parameters.statuses &&
          parameters.statuses.map(
            (s) => orderStatuses.find((os) => os.value === s) || {},
          ),
        saleschannels:
          parameters.saleschannels &&
          parameters.saleschannels.map(
            (s) => salesChannelsCodelist.find((sc) => sc.value === s) || {},
          ),
        paidOption: paidOptionCodelist.filter((o) => parameters[o.value]),
        paid:
          parameters.paid &&
          paidCodelist.filter((p) => p.value === parameters.paid),
        q: parameters.q,
        createdDateFrom: parameters.createdDateFrom,
        createdDateTo: parameters.createdDateTo,
      }}
      onChange={(nextFilterValue) => {
        setParameters({
          q: nextFilterValue.q || "",
          createdDateFrom: nextFilterValue.createdDateFrom
            ? new Date(nextFilterValue.createdDateFrom).toJSON()
            : "",
          createdDateTo: nextFilterValue.createdDateTo
            ? new Date(nextFilterValue.createdDateTo).toJSON()
            : "",
          statuses: nextFilterValue.statuses
            ? nextFilterValue.statuses.map((s) => s.value)
            : undefined,
          saleschannels: nextFilterValue.saleschannels
            ? nextFilterValue.saleschannels.map((s) => s.value)
            : undefined,
          paidbycredit: !!(nextFilterValue.paidOption || []).find(
            (o) => o.value === "paidbycredit",
          ),
          paidbyother: !!(nextFilterValue.paidOption || []).find(
            (o) => o.value === "paidbyother",
          ),
          paid:
            nextFilterValue.paid && nextFilterValue.paid.length > 0
              ? nextFilterValue.paid[nextFilterValue.paid.length - 1].value
              : undefined,
        });
      }}
      filters={[
        {
          type: "query",
          name: "q",
          placeholder: __(
            "Zadajte číslo objednávky, firmu, osobu, email alebo telefón",
          ),
        },
        {
          type: "select",
          options: orderStatuses,
          label: __("Stav objednávky"),
          name: "statuses",
          multiple: true,
        },
        {
          type: "select",
          options: salesChannelsCodelist,
          label: __("Predajný kanál"),
          name: "saleschannels",
          multiple: true,
        },
        {
          type: "date",
          label: __("Dátum prijatia od"),
          name: "createdDateFrom",
        },
        {
          type: "date",
          label: __("Dátum prijatia do"),
          name: "createdDateTo",
        },
        {
          type: "select",
          options: paidOptionCodelist,
          label: __("Spôsob úhrady"),
          name: "paidOption",
          multiple: true,
        },
        {
          type: "select",
          options: paidCodelist,
          label: __("Stav platby"),
          name: "paid",
          multiple: true,
        },
      ]}
    />
  );
};

OrdersFilter.propTypes = {
  setParameters: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    paid: PropTypes.string,
    q: PropTypes.string,
    createdDateFrom: PropTypes.string,
    createdDateTo: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.string),
    saleschannels: PropTypes.arrayOf(PropTypes.string),
    paidbycredit: PropTypes.bool,
    paidbyother: PropTypes.bool,
  }).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default OrdersFilter;
