import {
  RECEIVE_CATEGORIES_FAILURE,
  RECEIVE_CATEGORIES_SUCCESS,
  REQUEST_CATEGORIES,
} from './constants';
import apiEshop from '../../service/apiEshop';

const requestCategories = () => ({
  type: REQUEST_CATEGORIES,
});

const receiveCategories = data => ({
  type: RECEIVE_CATEGORIES_SUCCESS,
  payload: {
    data,
  },
});

const failureCategories = error => ({
  type: RECEIVE_CATEGORIES_FAILURE,
  payload: {
    error,
  },
});

export const fetchCategories = appspace => {
  return async dispatch => {
    dispatch(requestCategories());
    try {
      const res = await apiEshop.adminGetCategoriesTree(appspace);
      dispatch(receiveCategories(res.categories));
    } catch (e) {
      dispatch(failureCategories(e.description || e));
    }
  };
};
