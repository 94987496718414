import { __, formatPrice } from "../../utilities/index";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  @media print {
    page-break-inside: auto;
  }
  text-align: right;
`;

const TaxRateWrapper = styled.div`
  @media print {
    page-break-inside: avoid;
  }
  margin-bottom: 10px;
`;

const TaxTable = styled.table`
  margin: 0 0 0 auto;
  border-collapse: collapse;
  border: 1px solid black;
  th,
  td {
    border: 1px solid black;
    text-align: right;
    padding: 5px;
  }
`;

const TotalPriceTitle = styled.div`
  font-size: ${({ theme }) => theme.lg};
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
  @media print {
    color: black;
  }
`;

const PriceToPayTitle = styled.div`
  font-size: ${({ theme }) => theme.lg};
  font-weight: bold;
  color: ${({ theme }) => theme.secondary};
  @media print {
    color: black;
  }
`;

const renderTaxRates = (taxRates, currency) => {
  return (
    <div style={{ textAlign: "right" }}>
      <TaxTable>
        <thead>
          <tr>
            <th>{__("Sadzba DPH")}</th>
            <th>{__("Základ dane")}</th>
            <th>{__("Daň")}</th>
            <th>{__("Obrat")}</th>
          </tr>
        </thead>
        <tbody>
          {(taxRates || []).map((taxRate) => {
            return (
              <tr key={`vat-${taxRate.vatRate}`}>
                <td>{taxRate.vatRate}%</td>
                <td>{formatPrice(taxRate.base, currency)}</td>
                <td>{formatPrice(taxRate.tax, currency)}</td>
                <td>{formatPrice(taxRate.base + taxRate.tax, currency)}</td>
              </tr>
            );
          })}
        </tbody>
      </TaxTable>
    </div>
  );
};

const renderSummary = (summary, { printTotal = true } = {}) => {
  return (
    <Wrapper>
      <TaxRateWrapper>
        {renderTaxRates(summary.taxRates, summary.currency)}
      </TaxRateWrapper>
      <TotalPriceTitle>
        {__("Cena celkom: ")}
        {formatPrice(summary.total, summary.currency)}
      </TotalPriceTitle>
      {__("Cena celkom bez DPH: ")}
      {formatPrice(summary.totalBase, summary.currency)}
      <br />
      <br />
      {__("Uhradené kreditom: ")}
      {formatPrice(summary.paidbycredit, summary.currency)}
      <br />
      <span style={{ fontSize: "120%", fontWeight: "bold" }}>
        {__("Uhradené prevodom: ")}
        {formatPrice(summary.paidbyother, summary.currency)}
      </span>
      <br />
      <br />
      {printTotal && (
        <PriceToPayTitle>
          {__("Celkom k úhrade: ")}
          {formatPrice(summary.pricetopay, summary.currency)}
        </PriceToPayTitle>
      )}
    </Wrapper>
  );
};

const OrderSummary = ({ summary, printTotal }) => {
  return renderSummary(summary, { printTotal });
};

export default OrderSummary;
