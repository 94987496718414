import { applyMiddleware, compose, createStore } from "redux";
import { withExtraArgument } from "redux-thunk";
import apiEshop from "./service/apiEshop";
import apiSportnet from "./service/apiSportnet";
import paygateApi from "./service/paygateApi";
import rootReducer from "./reducer";

if (process.env.REACT_APP_SPORTNET_API_BASE_URL) {
  apiSportnet.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
} else {
  throw Error("REACT_APP_SPORTNET_API_BASE_URL not set");
}

if (process.env.REACT_APP_API_BASE_URL) {
  apiEshop.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error("REACT_APP_API_BASE_URL not set");
}

if (process.env.REACT_APP_PAYGATE_API_BASE_URL) {
  paygateApi.setBaseUrl(String(process.env.REACT_APP_PAYGATE_API_BASE_URL));
} else {
  throw Error("REACT_APP_PAYGATE_API_BASE_URL not set");
}

apiEshop.onResponseUnauthorized = () => {
  window.location.href = "/";
};

let composeEnhancers = compose;
if (typeof window !== "undefined") {
  const devToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  composeEnhancers = devToolsCompose
    ? devToolsCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
}

const thunk = withExtraArgument({
  apiEshop,
  apiSportnet,
});

const enhancer = composeEnhancers(applyMiddleware(thunk));

export default function configureStore() {
  return createStore(rootReducer, enhancer);
}
