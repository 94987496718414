import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, slugify } from "../../utilities";
import { connect } from "react-redux";
import { fetchPriceLevelsList, updatePriceLevelsList } from "./actions";
import {
  priceLevelsIsFetchingSelector,
  priceLevelsListSelector,
} from "./selectors";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import FormField from "@sportnet/ui/FormField";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";

const PriceLevels = ({ data, dispatch, isFetching }) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const { ppo } = useAuth();
  const [sidebarItem, setSidebarItem] = React.useState({ _id: "", name: "" });

  React.useEffect(() => {
    dispatch(fetchPriceLevelsList(ppo));
  }, []);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (sidebarItem.name.trim() !== "") {
      const dataToSend = [...data, sidebarItem];
      await saveData(dataToSend);
    } else {
      window.alert(__("Zadajte názov cenovej hladiny"));
    }
  };
  const removePriceLevel = async (idx) => {
    if (window.confirm(__("Skutočne si želáte odstrániť cenovú hladinu?"))) {
      const filteredData = [...data];
      filteredData.splice(idx, 1);
      await saveData(filteredData);
    }
  };
  const saveData = async (data) => {
    await dispatch(updatePriceLevelsList(ppo, data));
    dispatch(fetchPriceLevelsList(ppo));
    setSidebarVisible(false);
    setSidebarItem({ _id: "", name: "" });
  };
  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Zoznam cenových hladín")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button primary onClick={() => setSidebarVisible(true)}>
              {__("Pridať cenovú hladinu")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Zoznam cenových hladín")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__("ID")}</Th>
                <Th>{__("Názov")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, idx) => {
                return (
                  <Tr key={item._id}>
                    <Td>{item._id}</Td>
                    <Td>{item.name}</Td>
                    <Td center>
                      <Button
                        danger
                        icon="close"
                        onClick={() => removePriceLevel(idx)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>

      <Sidebar
        visible={sidebarVisible}
        header={__("Cenová hladina")}
        onClose={() => {
          setSidebarVisible(false);
        }}
      >
        <Segment>
          <form onSubmit={onFormSubmit}>
            <FormField
              label={__("ID")}
              value={sidebarItem._id}
              disabled
              onChange={() => {}}
            />
            <FormField
              label={__("Názov cenovej hladiny")}
              name="name"
              value={sidebarItem.name}
              onChange={(e) => {
                const slug = slugify(e.target.value);
                setSidebarItem({
                  _id: slug,
                  name: e.target.value,
                });
              }}
            />
            <Button primary type="submit">
              {__("Uložiť")}
            </Button>
          </form>
        </Segment>
      </Sidebar>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    data: priceLevelsListSelector(state),
    isFetching: priceLevelsIsFetchingSelector(state),
  };
};

PriceLevels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PriceLevels);
