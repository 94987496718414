import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const catalogsDomainSelector = state => state.catalogs;

export const catalogsListSelector = createSelector(
  catalogsDomainSelector,
  substate => getProp(substate, ['data'], []),
);

export const catalogsIsFetchingSelector = createSelector(
  catalogsDomainSelector,
  substate => getProp(substate, ['isFetching'], true),
);
