import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Select = styled.select`
  color: ${({ theme }) => theme.primary};
  box-sizing: border-box;
  border: ${rem(1.5)} solid #ccc;
  width: ${({ isMenu }) => (isMenu ? 'inherit' : '100%')};
  background-color: white;
  padding: ${rem(6)} ${rem(12)};
  margin-top: ${rem(5)};
  height: ${rem(34)};
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${({ theme }) => theme.secondary} !default;
    outline: 0;
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  color: ${props => props.fontColor && props.theme[props.fontColor]};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Option = styled.option`
  max-width: 100% !important;
  padding: ${rem(5)} ${rem(12)};
  background-color: ${({ theme }) => theme.separatorColorLighter};
  max-height: ${rem(200)};
  overflow-y: scroll;
  &:hover {
    background-color: #cbcdd1;
  }
`;

const SelectInput = props => {
  const { disabled, list, selected, isMenu, products } = props;
  return (
    <Select
      disabled={disabled}
      onChange={props.onChange}
      isMenu={isMenu}
      value={selected}
    >
      {!isMenu && !products && <Option value="" />}
      {list.map(item => {
        const { label, value } = item;
        return (
          <Option key={label} value={value}>
            {' '}{label}{' '}
          </Option>
        );
      })}
    </Select>
  );
};

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  isMenu: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onChange: PropTypes.func,
  selected: PropTypes.string,
  products: PropTypes.bool,
};

SelectInput.defaultProps = {
  disabled: false,
  selected: '',
  isMenu: false,
  products: false,
  list: [],
  onChange: () => { },
};

export default SelectInput;
