import { RECEIVE_FAILURE, RECEIVE_SUCCESS, REQUEST_DETAIL } from './constants';
import apiEshop from '../../service/apiEshop';

const requestDetail = () => ({
  type: REQUEST_DETAIL,
});

const receiveSuccess = data => ({
  type: RECEIVE_SUCCESS,
  payload: {
    data,
  },
});

const receiveFailure = error => ({
  type: RECEIVE_FAILURE,
  payload: {
    error,
  },
});

export const fetchDetail = (appSpace, id) => {
  return async dispatch => {
    dispatch(requestDetail());
    try {
      const res = await apiEshop.adminImportDetail(id, appSpace);
      dispatch(receiveSuccess(res));
    } catch (e) {
      dispatch(receiveFailure(e.description || e));
    }
  };
};
