import {
  FAILURE_SALES_CHANNELS_CATEGORIES,
  RECEIVE_SALES_CHANNELS_CATEGORIES,
  REQUEST_SALES_CHANNELS_CATEGORIES,
} from './constants';
import apiEshop from '../../service/apiEshop';

const requestSalesChannelsCategories = () => ({
  type: REQUEST_SALES_CHANNELS_CATEGORIES,
});

const receiveSalesChannelsCategories = data => ({
  type: RECEIVE_SALES_CHANNELS_CATEGORIES,
  payload: {
    data,
  },
});

const failureSalesChannelsCategories = error => ({
  type: FAILURE_SALES_CHANNELS_CATEGORIES,
  payload: {
    error,
  },
});

export const fetchSalesChannelsCategories = (appspace, saleschannel) => {
  return async dispatch => {
    dispatch(requestSalesChannelsCategories());
    try {
      const res = await apiEshop.adminSaleschannelGetCategoriesTree(
        appspace,
        saleschannel,
      );
      dispatch(receiveSalesChannelsCategories(res.categories));
    } catch (e) {
      dispatch(failureSalesChannelsCategories(e.description || e));
    }
  };
};
