import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { IMPORTS_LIST, SIDEBAR_CLOSE, SIDEBAR_OPEN, TOGGLE_SIDEBAR } from './constants';
import { normalizeEntities } from '../../utilities';
import { updateEntities } from '../App/actions';
import apiEshop from '../../service/apiEshop';

export const sidebarOpen = () => ({
  type: SIDEBAR_OPEN
})

export const sidebarClose = ()=> ({
  type: SIDEBAR_CLOSE
})

export const toggleSidebar = ()=> ({
  type: TOGGLE_SIDEBAR
})

export const loadImports = parameters => {
  return async (dispatch, getState) => {
    const { selectedOrg: appSpace } = getState();
    const { imports, total, limit, offset } = await apiEshop.adminListImports(
      appSpace,
      parameters.importCategory,
      parameters
    );

    const { entities, results } = normalizeEntities('imports', imports);
    dispatch(updateEntities(entities));
    return {
      total,
      limit,
      offset,
      results,
    };
  };
};

export const loadImportsList = () => {
  return (dispatch, getState) => {
    const parameters = getListParameters(IMPORTS_LIST)(getState());
    return dispatch(
      commit.action({
        listName: IMPORTS_LIST,
        load: async () => {
          try {
            const { results, limit, offset, total } = await dispatch(
              loadImports(parameters),
            );
            return {
              total,
              limit,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
