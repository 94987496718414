import { __ } from "../../utilities";
import { connect } from "react-redux";
import { rem } from "polished";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Col, { Row } from "@sportnet/ui/Grid";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import apiEshop from "../../service/apiEshop";

const OrderForwarding = () => {
  const [url, setUrl] = React.useState("");
  const [api_key, setApiKey] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);

  const { ppo } = useAuth();

  React.useEffect(() => {
    getSettings();
  }, []);

  const getSettings = () => {
    setIsFetching(true);
    apiEshop
      .adminGetSupplierOrderForwarding(ppo)
      .then((res) => {
        setUrl(res.url);
        setApiKey(res.api_key);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const submitForm = () => {
    setIsFetching(true);
    apiEshop
      .adminUpdateOrderForwarding(ppo, {
        data: {
          api_key,
          url,
        },
      })
      .then((res) => {
        setUrl(res.url);
        setApiKey(res.api_key);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Presmerovanie objednávok")}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext title={__("Presmerovanie objednávok")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching}>
          <Segment>
            <div style={{ fontSize: rem(14) }}>
              {__(
                "Toto nastavenie slúži na konfiguráciu preposielania objednávok. Zadajte prosím URL, na ktorú sa pri objednávke zašle POST request autorizovaný hlavičkou Authorization, ktorá bude obsahovať vami zadaný API_KEY.",
              )}
            </div>
          </Segment>
          <Row>
            <Col xs={12} s={6}>
              <FormGroup>
                <Label>{__("URL")}</Label>
                <Input
                  name="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} s={6}>
              <FormGroup>
                <Label>{__("API kľúč")}</Label>
                <Input
                  name="api_key"
                  value={api_key}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={12} s={6}>
              <FormGroup>
                <Button onClick={submitForm} primary>
                  {__("Uložiť")}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(OrderForwarding);
