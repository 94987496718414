import { rem } from 'polished';
import styled from 'styled-components';

const Body = styled.div`
  background-color: white;
  font-size: ${({ theme }) => theme.sm};
  padding: ${rem(32)};
  border-radius: ${({ theme }) => theme.borderRadius};
  @media print {
    padding: 0;
    box-shadow: none;
  }
`;

export default Body;
