import { __ } from "../../utilities";
import { connect } from "react-redux";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Col, { Row } from "@sportnet/ui/Grid";
import DateTimePicker from "@sportnet/ui/DatePickers/DateTimePicker";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import apiEshop from "../../service/apiEshop";
import styled from "styled-components";

const StyledRow = styled(Row)`
  align-items: center;
`;

const ExportOrders = () => {
  const [date_from, setDateFrom] = React.useState(null);
  const [date_to, setDateTo] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const { ppo } = useAuth();

  const handleClickExport = async () => {
    setIsFetching(true);
    const params = {};
    if (date_from) {
      params.fromDate = date_from.toJSON();
    }
    if (date_to) {
      params.toDate = date_to.toJSON();
    }

    try {
      const { url } = await apiEshop.adminExportItems(ppo, params);
      window.location.href = url;
    } catch (e) {
      alert(__("Nepodarilo sa exportovať položky z objednávok"));
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>
            {__("Export položky z objednávok")}
          </HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext title={__("Export položky z objednávok")} />
      <Segment noBottomGutter>
        <Segment raised>
          <StyledRow>
            <Col xs={12} m={5}>
              <FormGroup>
                <Label>{__("Dátum od")}</Label>
                <DateTimePicker
                  isClearable
                  value={date_from && new Date(date_from)}
                  onChange={(e) => {
                    setDateFrom(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} m={5}>
              <FormGroup>
                <Label>{__("Dátum do")}</Label>
                <DateTimePicker
                  isClearable
                  value={date_to && new Date(date_to)}
                  onChange={(e) => {
                    setDateTo(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} m={2}>
              <Button primary onClick={handleClickExport} loading={isFetching}>
                {__("Exportovať položky z objednávok")}
              </Button>
            </Col>
          </StyledRow>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ExportOrders);
