import {
  RECEIVE_CATEGORIES_FAILURE,
  RECEIVE_CATEGORIES_SUCCESS,
  REQUEST_CATEGORIES,
} from './constants';

export const categoriesReducer = (
  state = {
    data: [],
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
      };
    case RECEIVE_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
