import { Link } from "react-router-dom";
import { PanelBody, PanelHeader, PanelsWrapper } from "../OrderDetail";
import { StyledStatus } from "../Orders";
import {
  __,
  calculateOrderTotalpriceGroups,
  calculateOrderTotalpriceWithCredits,
  formatPrice,
  getProp,
} from "../../utilities";
import { connect } from "react-redux";
import { fetchCodelistOrderStatus } from "../Orders/actions";
import { format } from "date-fns";
import { organizationProfileSelector } from "../App/selector";
import { rem } from "polished";
import Api from "../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Body from "../../components/Body";
import Button from "@sportnet/ui/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Loader from "@sportnet/ui/Loader";
import OrderSummary from "../../components/OrderSummary";
import PropTypes from "prop-types";
import ProtectedContent from "../ProtectedContent";
import React, { PureComponent } from "react";
import Statuses from "../Orders/Statuses";
import apiSportnet from "../../service/apiSportnet";
import styled from "styled-components";

const PaidWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  font-weight: ${({ header }) => (header ? "bold" : "normal")};
  cursor: ${({ header }) => (header ? "default" : "pointer")};
  display: flex;
  border-bottom: ${rem(1)} solid #ddd;
  padding: ${rem(10)} 0;
  &:hover {
    background-color: ${({ header }) => (header ? "" : "#e5e5e5")};
  }
  ${({ warning }) => (warning ? "color: red;" : "")};
`;

const Cell = styled.div`
  flex: 0 0 ${100 / 8}%;
  padding: 0 ${rem(5)};
`;

const CellRight = styled(Cell)`
  text-align: right;
`;

const TotalPrice = styled.div`
  padding: ${rem(15)} 0;
  text-align: right;
`;

const TotalPriceSummary = styled.div`
  padding: ${rem(15)} 0;
  text-align: left;
  float: left;
`;

const Panel = styled.div`
  flex: 1;
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};
  padding: 0 ${rem(15)};
  margin: ${rem(10)} 0;
  ${({ theme }) => theme.eshopMedia.md`
    margin: 0;
    flex: 0 0 calc(33% - ${rem(10)});
  `};
`;

const ListPanel = styled.div`
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};
  margin: ${rem(10)} 0;
  padding: 0 ${rem(15)};
  flex: 1;
`;

export const StyledStatusXS = styled(StyledStatus)`
  text-align: center;
  width: auto;
  height: auto;
  padding: 0 5px;
`;

class ReceiptDetail extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      detail: {},
      statuses: [
        {
          label: "Neuhradený",
          value: "unpaid",
          payload: {
            color: "red",
          },
        },
        {
          label: "Uhradený",
          value: "paid",
          payload: {
            color: "green",
          },
        },
      ],
      supplierDetail: {},
    };

    this.getRecieptDetail = this.getRecieptDetail.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.getSupplierDetail = this.getSupplierDetail.bind(this);
    this.handleClickPrint = this.handleClickPrint.bind(this);
  }

  componentDidMount() {
    const { acl, dispatch } = this.props;
    if (Object.keys(acl).length !== 0) this.getRecieptDetail(acl);
    dispatch(fetchCodelistOrderStatus());
  }

  componentDidUpdate(prevProps) {
    if (this.props.acl !== prevProps.acl) {
      this.getRecieptDetail(this.props.acl);
    }
  }

  getSupplierDetail(supplierId) {
    apiSportnet
      .organizationInvoiceProfile(supplierId)
      .then((response) => {
        this.setState({
          supplierDetail: response,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getRecieptDetail(acl) {
    const {
      match: {
        params: { reciept_id: recieptId, appspace },
      },
      selectedOrg,
    } = this.props;
    this.setState({
      isFetching: true,
    });
    if (acl.adminReceiptDetail) {
      Api.adminReceiptDetail(recieptId, selectedOrg)
        .then((response) => {
          this.setState({
            detail: response,
            isFetching: false,
          });
          this.getSupplierDetail(response.supplier_id);
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            isFetching: false,
          });
          alert(__("Predpis sa nepodarilo nájsť"));
          this.props.history.push(`/admin/${appspace}/orders`);
        });
    } else {
      Api.adminSupplierReceiptDetail(recieptId, selectedOrg)
        .then((response) => {
          this.setState({
            detail: response,
            isFetching: false,
          });
          this.getSupplierDetail(response.supplier_id);
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            isFetching: false,
          });
          alert(__("Predpis sa nepodarilo nájsť"));
          this.props.history.push(`/admin/${appspace}/orders`);
        });
    }
  }

  handleClickPrint() {
    const {
      match: {
        params: { reciept_id: receiptId, appspace },
      },
    } = this.props;
    window.open(`/admin/${appspace}/receipt/${receiptId}/print`);
  }

  handleChangeStatus() {
    const {
      detail: { _id, status },
    } = this.state;
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    const data = {};
    if (status === "paid") {
      data.status = "unpaid";
    } else {
      data.status = "paid";
    }
    Api.adminChangeReceiptStatus(_id, appspace, { data })
      .then(() => {
        this.getRecieptDetail(this.props.acl);
      })
      .catch((err) => {
        console.error(err);
        alert("Zmena stavu predpisu zlyhala");
        this.props.history.push(`/admin/${appspace}/orders`);
      });
  }

  handleRedirect(_id, appSpace = null) {
    const {
      match: {
        params: { appspace },
      },
    } = this.props;
    if (appSpace && appSpace !== appspace) {
      window.location.href = `/admin/${appSpace}/orders/${_id}`;
    } else {
      this.props.history.push(`/admin/${appspace}/orders/${_id}`);
    }
  }

  renderOrder(order) {
    const { delivery, delivery_vat_excl: deliveryVatExcl } =
      calculateOrderTotalpriceWithCredits(order);
    const {
      _id,
      appSpace,
      invoicenr,
      created_date: createdDate,
      company,
      business_id: businessId,
      status: orderStatus,
      saleschannel,
      summary,
      paid_date: paidDate,
      paid_payload: paidPayload,
    } = order;
    const statusObj =
      this.props.orderStatuses.find((item) => item.value === orderStatus) || {};
    const color = getProp(statusObj, ["payload", "color"]);
    const statusLabel = getProp(statusObj, ["label"], orderStatus);
    return (
      <Wrapper
        key={_id}
        onClick={() => this.handleRedirect(_id, appSpace)}
        warning={!paidDate}
      >
        <Cell>
          {saleschannel}: {_id}
          <br />
          <StyledStatusXS color={color}>{statusLabel}</StyledStatusXS>
        </Cell>
        <Cell>{invoicenr}</Cell>
        <Cell>
          {company}
          <br />
          IČO: {businessId}
        </Cell>
        <Cell>{format(new Date(createdDate), "dd.MM.yyyy HH:mm")}</Cell>
        <CellRight>
          <b>
            {formatPrice(delivery, summary.currency)} {__("s DPH")}
          </b>
          <br />
          {formatPrice(deliveryVatExcl, summary.currency)} {__("bez DPH")}
        </CellRight>
        <CellRight>
          <b>
            {formatPrice(summary.total, summary.currency)} {__("s DPH")}
          </b>
          <br />
          {formatPrice(summary.totalBase, summary.currency)} {__("bez DPH")}
        </CellRight>
        <CellRight>
          {formatPrice(summary.paidbycredit, summary.currency)}
        </CellRight>
        <CellRight>
          <span title={paidDate} style={{ color: paidDate ? "green" : "red" }}>
            {paidDate ? "✓" : "✖︎"}
          </span>{" "}
          {formatPrice(summary.paidbyother, summary.currency)}
          {paidPayload && (
            <div>
              {__("Platobná brána ID")}: {paidPayload.gpwebpay_ordernumber}
            </div>
          )}
        </CellRight>
      </Wrapper>
    );
  }

  render() {
    const {
      statuses,
      detail: {
        supplier_name: supplierName,
        created_date: createdDate,
        status,
        paid_date: paidDate,
        orders,
      },
      supplierDetail,
      isFetching,
    } = this.state;

    let actualStatus = {};

    const totalSummary = (orders || []).reduce(
      (t, order) => {
        const newTotal = {
          currency: order.summary.currency,
          paidbycredit: t.paidbycredit + order.summary.paidbycredit,
          paidbyother:
            order.paid_date && order.payment.type === "GPWEBPAY"
              ? t.paidbyother + order.summary.paidbyother
              : t.paidbyother,
          pricetopay: t.pricetopay + order.summary.pricetopay,
          totalBase: t.totalBase + order.summary.totalBase,
          total: t.total + order.summary.total,
          _taxRates: { ...t._taxRates },
        };

        // zmergujeme data pre taxrate
        order.summary.taxRates.forEach((tr) => {
          // kluc bude string
          const vrk = String(tr.vatRate);
          // hladame v newTotal.taxRates, ak je, pripocitame, ak nie je pridame
          if (!newTotal._taxRates[vrk]) {
            newTotal._taxRates[vrk] = { ...tr };
          } else {
            newTotal._taxRates[vrk].base += tr.base;
            newTotal._taxRates[vrk].tax += tr.tax;
          }
        });

        return newTotal;
      },
      {
        currency: "",
        paidbycredit: 0,
        paidbyother: 0,
        pricetopay: 0,
        _taxRates: {},
        totalBase: 0,
        total: 0,
      },
    );

    totalSummary.taxRates = Object.keys(totalSummary._taxRates)
      .map((tr) => {
        return totalSummary._taxRates[tr];
      })
      .sort((a, b) => {
        return a.vatRate > b.vatRate;
      });

    const saleschannelGroups = (orders || []).reduce((g, o) => {
      if (o.saleschannel in g) {
        g[o.saleschannel].orders.push(o);
        return g;
      }
      return Object.assign(g, {
        [o.saleschannel]: { orders: [o] },
      });
    }, {});

    const saleschannelSummary = Object.keys(saleschannelGroups).map((k) => {
      saleschannelGroups[k].saleschannel = k;
      saleschannelGroups[k].priceGroups = {};
      saleschannelGroups[k].orders.forEach((order) => {
        // spocitame pricegroups a mergneme na hlavne grupy
        calculateOrderTotalpriceGroups(order).forEach((priceGroup) => {
          if (priceGroup.group in saleschannelGroups[k].priceGroups) {
            saleschannelGroups[k].priceGroups[priceGroup.group].amount +=
              priceGroup.amount;
            saleschannelGroups[k].priceGroups[
              priceGroup.group
            ].amount_vat_excl += priceGroup.amount_vat_excl;
          } else {
            saleschannelGroups[k].priceGroups[priceGroup.group] = priceGroup;
          }
        });
      });
      return saleschannelGroups[k];
    });

    statuses.find((stat) => {
      if (stat.value === status) {
        actualStatus = stat;
        return true;
      }
      return false;
    });

    const tableHeader = (
      <Wrapper header>
        <Cell>{__("Číslo objednávky")}</Cell>
        <Cell>{__("Číslo faktúry")}</Cell>
        <Cell>{__("Odberateľ")}</Cell>
        <Cell>{__("Dátum vytvorenia")}</Cell>
        <CellRight>{__("Doprava")}</CellRight>
        <CellRight>{__("Celkom")}</CellRight>
        <CellRight>{__("Zaplatené kreditmi")}</CellRight>
        <CellRight>{__("Zaplatené kartou")}</CellRight>
      </Wrapper>
    );

    const title = `${__("Detail predpisu ")} ${
      this.props.match.params.reciept_id
    }`;

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${this.props.match.params.appspace}/receipts-list`}
              title={__("Zoznam predpisov")}
            />
            <HeaderBar.Header>{title}</HeaderBar.Header>
            <HeaderBar.Spacer />
            <HeaderBar.Item>
              <Button onClick={this.handleClickPrint}>
                <Icon name="printTool" xxl />
              </Button>
            </HeaderBar.Item>
          </HeaderBar>
        }
      >
        <AppContext
          title={title}
          breadcrumbs={[
            {
              url: `/admin/${this.props.match.params.appspace}/receipts-list`,
              name: __("Zoznam predpisov"),
            },
            {
              name: __(
                `${__("Detail predpisu ")} ${
                  this.props.match.params.reciept_id
                }`,
              ),
            },
          ]}
        />
        <Body>
          <div style={{ position: "relative" }}>
            {isFetching && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 50%)",
                  }}
                >
                  <Loader size="xxl" />
                </div>
              </div>
            )}
            <PanelsWrapper>
              <Panel>
                <PanelHeader>{__("Stav:")}</PanelHeader>
                <PanelBody>
                  <StyledStatus
                    color={getProp(actualStatus, ["payload", "color"])}
                  >
                    {actualStatus.label}
                  </StyledStatus>
                </PanelBody>
              </Panel>

              <Panel>
                <ProtectedContent rule="adminListReceipts">
                  <PanelHeader>{__("Zmeniť stav predpisu na:")}</PanelHeader>
                  <PanelBody>
                    {status === "unpaid" && (
                      <Statuses
                        statuses={[
                          {
                            label: "Uhradený",
                            value: "paid",
                            payload: {
                              color: "green",
                            },
                          },
                        ]}
                        handleChange={this.handleChangeStatus}
                        selected=""
                      />
                    )}
                  </PanelBody>
                </ProtectedContent>
              </Panel>

              <Panel>
                <PanelHeader>{__("Odberateľ")}</PanelHeader>
                <PanelBody>
                  {supplierName ? (
                    <div>{supplierName}</div>
                  ) : (
                    <div>
                      {getProp(supplierDetail, ["full_name"]) ||
                        getProp(supplierDetail, ["name"])}
                    </div>
                  )}
                  <div>
                    {getProp(supplierDetail, ["invoice_address", "street"]) &&
                      `${getProp(supplierDetail, [
                        "invoice_address",
                        "street",
                      ])} `}
                    {getProp(supplierDetail, ["invoice_address", "number"]) &&
                      `${getProp(supplierDetail, [
                        "invoice_address",
                        "number",
                      ])}, `}
                    {getProp(supplierDetail, ["invoice_address", "zip"]) &&
                      `${getProp(supplierDetail, ["invoice_address", "zip"])} `}
                    {getProp(supplierDetail, ["invoice_address", "city"]) &&
                      `${getProp(supplierDetail, [
                        "invoice_address",
                        "city",
                      ])} `}
                  </div>
                  {getProp(supplierDetail, ["business_id"]) && (
                    <div>
                      <b>IČO:</b> {getProp(supplierDetail, ["business_id"])}
                    </div>
                  )}
                  {getProp(supplierDetail, ["vat_nr"]) && (
                    <div>
                      <b>IČ DPH:</b> {getProp(supplierDetail, ["vat_nr"])}
                    </div>
                  )}
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader>{__("Dodávateľ")}</PanelHeader>
                <PanelBody>
                  <div>SFZ Marketing, s.r.o.</div>
                  <div>Tomášikova 30C, 821 01 Bratislava </div>
                  <div>
                    <b>IČO:</b> 46320768
                  </div>
                  <div>
                    <b>DIČ:</b> 2023327196
                  </div>
                  <div>
                    <b>IČ DPH:</b> SK2023327196
                  </div>
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader>{__("Dátum vytvorenia:")}</PanelHeader>
                <PanelBody>
                  {createdDate ? (
                    format(new Date(createdDate), "dd.MM.yyyy HH:mm")
                  ) : (
                    <Icon name="delete" danger />
                  )}
                </PanelBody>
              </Panel>

              <Panel>
                <PanelHeader>{__("Uhradené:")}</PanelHeader>
                <PanelBody>
                  {paidDate ? (
                    <PaidWrapper>
                      <Icon name="checked" success />
                      &nbsp;&nbsp;
                      {format(new Date(paidDate), "dd.MM.yyyy HH:mm")}
                    </PaidWrapper>
                  ) : (
                    <Icon name="delete" danger />
                  )}
                </PanelBody>
              </Panel>

              <ListPanel>
                <PanelHeader>{__("Objednávky:")}</PanelHeader>
                <PanelBody>
                  {tableHeader}
                  {orders && orders.map(this.renderOrder)}

                  <TotalPriceSummary>
                    {saleschannelSummary.map((sc) => {
                      return (
                        <div key={sc.saleschannel}>
                          <h2>
                            {__("Predajný kanál")}: {sc.saleschannel}
                          </h2>
                          {Object.values(sc.priceGroups).map((g) => {
                            const style = {};
                            if (g.group === "other") {
                              style.fontSize = "120%";
                              style.fontWeight = "bold";
                            }
                            return (
                              <div key={g.group} style={style}>
                                <strong>{g.group}: </strong>
                                {formatPrice(
                                  g.amount_vat_excl,
                                  g.currency,
                                )}{" "}
                                {__("bez DPH")} (
                                {formatPrice(g.amount, g.currency)}{" "}
                                {__("s DPH")})
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </TotalPriceSummary>

                  <TotalPrice>
                    <OrderSummary summary={totalSummary} printTotal={false} />
                  </TotalPrice>
                </PanelBody>
              </ListPanel>
            </PanelsWrapper>
          </div>
        </Body>
      </Layout>
    );
  }
}

ReceiptDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  acl: PropTypes.shape({}).isRequired,
  selectedOrg: PropTypes.string.isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    userAcl,
    purchase: { orderStatuses },
  } = state;
  return {
    selectedOrg: state.selectedOrg,
    acl: userAcl.data,
    orderStatuses: orderStatuses.codelist,
    organization: organizationProfileSelector(state),
  };
};

export default connect(mapStateToProps)(ReceiptDetail);

//TODO - organization
