class Api {

  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split("&").forEach(function (part) {
        let item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters)
          .forEach(function(parameterName) {
            const parameter = parameters.$queryParameters[parameterName];
            queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch(transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

   /**
    * HTTP Request
    * @method
    * @param {string} method - http method
    * @param {string} url - url to do request
    * @param {object} body - body parameters / object
    * @param {object} headers - header parameters
    * @param {object} queryParameters - querystring parameters
    */
  request(method, url, body, headers, queryParameters, form, checkFor401 = true) {
    const queryParams = queryParameters && Object.keys(queryParameters).length ? this.serializeQueryParams(queryParameters) : null ;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
        this.headers.forEach(h => {
            headers.append(h.name, h.value);
        });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
  * Set base url
  * @method
  * @param {string} baseUrl
  */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
  * Set Headers
  * @method
  * @param headers - array of headers
  */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
  * Redirects a user to a given url
  * @method
  * @param {string} url
  */
  redirect(url) {
    window.location = url;
  }

      /**
      * Set Token
      * @method
      * @param {string} token - token's value
      */
      setToken(token) {
        this.token = token;
      }


    /**
    * Set Auth headers
    * @method
    * @param {object} headerParams - headers object
    */
    appendAuthHeaders(headerParams) {
      const headers = new Headers(headerParams);
      if (this.token) {
         headers.append('Authorization', `Bearer ${this.token}`);
      }
      return headers;
    }

    /**
     * Create new payment
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.paygate - Central payment system (Paygate) RESTful API specification.

       * @param {} app - Central payment system (Paygate) RESTful API specification.

       * @param {} ppo - Central payment system (Paygate) RESTful API specification.

       * @param {} parameters.body - New payment parameters
     */
     startNewPayment(app,ppo,parameters = {}) {
      let path = '/pay/{app}/{ppo}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');


            if(typeof parameters['paygate'] !== 'undefined'){
              queryParameters['paygate'] = parameters['paygate'];
            }

            queryParameters['paygate'] = this.transformParameter(queryParameters['paygate']);
        
        


     
        
          path = path.replace('{app}', app);
        


     
        
          path = path.replace('{ppo}', ppo);
        


     
        
        
          if(typeof parameters['body'] !== 'undefined'){
            body = parameters['body'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Redirect payment to callback URL with standardized response
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paymentId - Central payment system (Paygate) RESTful API specification.

     */
     rurlPayment(paymentId,parameters = {}) {
      let path = '/rurl/{paymentId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'text/html');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{paymentId}', paymentId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get payments by externalId
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} app - Central payment system (Paygate) RESTful API specification.

       * @param {} ppo - Central payment system (Paygate) RESTful API specification.

       * @param {} externalId - Central payment system (Paygate) RESTful API specification.

     */
     getPaymentsByExternalId(app,ppo,externalId,parameters = {}) {
      let path = '/payments/{app}/{ppo}/{externalId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{app}', app);
        


     
        
          path = path.replace('{ppo}', ppo);
        


     
        
          path = path.replace('{externalId}', externalId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get payment by paymentId
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} app - Central payment system (Paygate) RESTful API specification.

       * @param {} ppo - Central payment system (Paygate) RESTful API specification.

       * @param {} paymentId - Central payment system (Paygate) RESTful API specification.

     */
     getPayment(app,ppo,paymentId,parameters = {}) {
      let path = '/payment/{app}/{ppo}/{paymentId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{app}', app);
        


     
        
          path = path.replace('{ppo}', ppo);
        


     
        
          path = path.replace('{paymentId}', paymentId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Refund payment by paymentId
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} app - Central payment system (Paygate) RESTful API specification.

       * @param {} ppo - Central payment system (Paygate) RESTful API specification.

       * @param {} paymentId - Central payment system (Paygate) RESTful API specification.

       * @param {} parameters.body - Refund parameters
     */
     refundPayment(app,ppo,paymentId,parameters = {}) {
      let path = '/payment/{app}/{ppo}/{paymentId}/refund';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{app}', app);
        


     
        
          path = path.replace('{ppo}', ppo);
        


     
        
          path = path.replace('{paymentId}', paymentId);
        


     
        
        
          if(typeof parameters['body'] !== 'undefined'){
            body = parameters['body'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Proxy pay24 URL to POST
     * @method
     * @param {object} parameters - method options and parameters
     */
     pay24go(parameters = {}) {
      let path = '/24pay/go';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'text/html');
        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * pay24 NURL handler
     * @method
     * @param {object} parameters - method options and parameters
     */
     pay24NurlHandler(parameters = {}) {
      let path = '/24pay/nurl/{paymentId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * comgate NURL handler
     * @method
     * @param {object} parameters - method options and parameters
     */
     comgateNurlHandler(parameters = {}) {
      let path = '/comgate/nurl';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
}

export default new Api();
export { Api };
