import { Link } from "react-router-dom";
import { Panel } from "../OrderDetail";
import { Row } from "react-flexbox-grid";
import { SCol } from "../../components/Forms/Grid";
import { __, getProp, getRequestStatus, getRequestType } from "../../utilities";
import { connect } from "react-redux";
import { format } from "date-fns";
import { organizationProfileSelector } from "../App/selector";
import { rem } from "polished";
import ApiEshop from "../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Body from "../../components/Body";
import Button from "../../components/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Label from "../../components/Forms/Label";
import Layout from "../../components/Layout";
import OrderItemList from "../OrderDetail/OrderItemList";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SelectInput from "../../components/Forms/SelectInput";
import Textarea from "../../components/Forms/TextArea";
import styled from "styled-components";

const WrapperNote = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTextarea = styled(Textarea)`
  color: #515151;
`;

const TitleHeader = styled.div`
  display: inline-block;
  font-weight: bold;
`;

const PanelHeader = styled(TitleHeader)`
  display: block;
  padding: ${rem(15)} 0;
  border-bottom: ${rem(1)} solid #ddd;
  font-size: ${rem(14)};
`;

const RequestNote = styled.span`
  font-size: ${rem(14)};
`;

const PanelBody = styled.div`
  padding: ${rem(15)} 0;
`;

const PanelBodyNote = styled(PanelBody)`
  word-break: break-word;
  padding: 0.625rem 0.3125rem;
  background-color: beige;
  padding: ${rem(15)};
  margin: ${rem(10)};
  margin-bottom: 0;
  border-radius: ${rem(4)};
  align-self: ${({ start }) => (start ? "flex-start" : "flex-end")};
  max-width: 60%;
`;

export const getList = () =>
  ApiEshop.getCodelistRequestStatus()
    .then((response) => {
      return response.codelist.reduce((prev, status) => {
        return { ...prev, [status.value]: status };
      }, {});
    })
    .catch((error) => {
      console.error(error);
    });

class RequestDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      requestDetail: {
        note: "",
        person: {
          name: "",
          surname: "",
          phone: "",
          email: "",
        },
        type: "",
        created_date: "",
      },
      statuses: {},
      newStatus: "",
      newSubStatus: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    getList().then((res) => {
      this.setState({
        statuses: res,
      });
    });
  }

  getData() {
    const {
      match: {
        params: { request_id: requestId, supplier_id: supplierId },
      },
    } = this.props;
    ApiEshop.adminRequestDetail(supplierId, requestId)
      .then((response) => {
        if (response.status_log) {
          response.status_log.reverse();
        }
        this.setState(
          {
            requestDetail: response,
            newStatus: response.status,
            newSubStatus: response.substatus,
          },
          () => {
            const { order_id: orderId, products } = response;
            ApiEshop.adminGetOrderDetail(supplierId, orderId)
              .then((res) => {
                const arr = [];
                const itemsList = [];
                const { items } = res;
                items.forEach((item) => {
                  const { item_id: itemId } = item;
                  arr[itemId] = {
                    ...item,
                  };
                });
                products.forEach((product) => {
                  const { item_id: itemId } = product;
                  const index = arr.indexOf(itemId);
                  if (index) {
                    const obj = arr[itemId];
                    obj.quantity = product.quantity;
                    itemsList.push(obj);
                  }
                });
                this.setState({
                  items: itemsList,
                });
              })
              .catch((err) => {
                console.error(err);
              });
          },
        );
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          alert(__("Tento dotaz nebol nájdený!"));
        } else if (error.response.status === 403) {
          alert(__("K tomuto dotazu nemáte prístup!"));
        } else {
          alert(__("Došlo k neočakavanej chybe"));
        }
      });
  }

  handleChangeStatus() {
    const {
      match: {
        params: { supplier_id: supplierId, request_id: requestId },
      },
    } = this.props;
    const { newNote, newStatus, newSubStatus } = this.state;
    const data = {
      status: newStatus,
    };
    if (newSubStatus) {
      data.substatus = newSubStatus;
    }
    if (newNote) {
      data.note = newNote;
    }
    if (data && data.status) {
      ApiEshop.adminChangeRequestStatus(supplierId, requestId, { data })
        .then(() => {
          this.getData();
          this.setState({
            newNote: "",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert(__("Nebol určený nový stav"));
    }
  }

  handleChangeSelect(e, type) {
    if (type === "newStatus" && e.target.value !== "closed") {
      this.setState({
        [type]: e.target.value,
        newSubStatus: "",
      });
    } else {
      this.setState({
        [type]: e.target.value,
      });
    }
  }

  handleChange(e) {
    const { target } = e;
    const { name } = target;
    this.setState({
      [name]: target.value,
    });
  }

  render() {
    const {
      requestDetail: {
        status_log: statusLog,
        note,
        status,
        created_date: createdDate,
        type,
        person: { name, surname, email, phone },
        order_id: orderId,
      },
      newNote,
      newStatus,
      newSubStatus,
      statuses,
    } = this.state;
    const {
      match: {
        params: { appspace, request_id: requestId },
      },
    } = this.props;

    const availableStatuses = [];
    const currentStatus = statuses[status];
    let isFinalStatus = false;
    if (currentStatus) {
      availableStatuses.push({
        value: currentStatus.value,
        label: currentStatus.label,
      });
      const follows = getProp(currentStatus, ["payload", "follows"], []);
      isFinalStatus = follows.length === 0;
      follows.forEach((state) => {
        availableStatuses.push({
          value: state,
          label: statuses[state].label,
        });
      });
    }

    const availableSubstatuses = [];
    const selectedStatus = statuses[newStatus];
    if (selectedStatus) {
      if (selectedStatus.substatus && selectedStatus.substatus[type]) {
        Object.keys(selectedStatus.substatus[type]).forEach((substatusId) => {
          const substatus = selectedStatus.substatus[type][substatusId];
          availableSubstatuses.push({
            value: substatusId,
            label: substatus.label,
          });
        });
      }
    }
    const title = `${getRequestType(type)} ${__("č.")} ${requestId}`;

    return (
      <Layout
        topFixed={
          <HeaderBar>
            <HeaderBar.Action
              icon="back"
              as={Link}
              to={`/admin/${appspace}/requests`}
              title={__("Dotazy")}
            />
            <HeaderBar.Header>{title}</HeaderBar.Header>
          </HeaderBar>
        }
      >
        <AppContext
          title={title}
          breadcrumbs={[
            {
              url: `/admin/${appspace}/requests`,
              name: __("Dotazy"),
            },
            { name: title },
          ]}
        />
        <Body>
          <Panel>
            <PanelHeader>{__("Kontaktná osoba")}</PanelHeader>
            <PanelBody>
              <div>{name}</div>
              <div>{surname && surname}</div>
              <div>{email && email}</div>
              <div>{phone && phone}</div>
            </PanelBody>
          </Panel>

          <Panel>
            <PanelHeader>{__("Objednávka")}</PanelHeader>
            <PanelBody>
              <div>
                <b>
                  {__("Číslo objednávky")}
                  {`: `}
                </b>
                <Link to={`/admin/${appspace}/orders/${orderId}`}>
                  {orderId}
                </Link>
              </div>
              <PanelHeader>{__("Produkty")}</PanelHeader>
              {getProp(this.state, ["items"]) && (
                <OrderItemList items={getProp(this.state, ["items"], [])} />
              )}
            </PanelBody>
          </Panel>
          <Panel style={{ paddingBottom: rem(10) }}>
            <PanelHeader>{__("Stav dotazu")}</PanelHeader>
            {createdDate && (
              <WrapperNote>
                <div style={{ textAlign: "center", marginTop: rem(10) }}>
                  <RequestNote>
                    {format(new Date(createdDate), "dd.MM.yyyy HH:mm")} -{" "}
                    {statuses && (
                      <strong>{getRequestStatus("new", statuses)}</strong>
                    )}
                  </RequestNote>
                </div>
                <PanelBodyNote>
                  <span>
                    <i>{`${note}`}</i>
                  </span>
                </PanelBodyNote>
              </WrapperNote>
            )}

            {statusLog &&
              statusLog.map((item, i) => {
                const {
                  new_status: newStatusLogStatus,
                  change_date: changeDate,
                  note: statusLogNote,
                  new_substatus: newSubstatus,
                } = item;
                return (
                  <WrapperNote>
                    <div style={{ textAlign: "center", marginTop: rem(10) }}>
                      <RequestNote>
                        {format(new Date(changeDate), "dd.MM.yyyy HH:mm")} -{" "}
                        <strong>
                          {getRequestStatus(newStatusLogStatus, statuses)}
                          {newSubstatus && (
                            <>
                              {`: `}
                              {getRequestStatus(
                                newSubstatus,
                                getProp(
                                  statuses,
                                  [newStatusLogStatus, "substatus", type],
                                  "",
                                ),
                              )}
                            </>
                          )}
                        </strong>
                      </RequestNote>
                    </div>
                    {statusLogNote ? (
                      <PanelBodyNote start>
                        <span>
                          <i>{`${statusLogNote}`}</i>
                        </span>
                      </PanelBodyNote>
                    ) : (
                      <br />
                    )}
                  </WrapperNote>
                );
              })}
          </Panel>

          {!isFinalStatus && (
            <Panel>
              <PanelBody>
                <Row>
                  <SCol xs={12} sm={6}>
                    <Label>
                      {__("Nový stav *")}
                      <SelectInput
                        list={availableStatuses}
                        selected={newStatus}
                        name="newStatus"
                        onChange={(e) =>
                          this.handleChangeSelect(e, "newStatus")
                        }
                      />
                    </Label>
                  </SCol>
                  <SCol xs={12} sm={6}>
                    {availableSubstatuses.length > 0 && (
                      <Label>
                        {__("Podstav")}
                        <SelectInput
                          list={availableSubstatuses}
                          selected={newSubStatus}
                          name="newSubStatus"
                          onChange={(e) =>
                            this.handleChangeSelect(e, "newSubStatus")
                          }
                        />
                      </Label>
                    )}
                  </SCol>

                  <SCol xs={12}>
                    <Label>
                      {__("Popis")}
                      <StyledTextarea
                        name="newNote"
                        value={newNote}
                        onChange={this.handleChange}
                      />
                    </Label>
                  </SCol>
                </Row>
                <div style={{ textAlign: "center" }}>
                  <Button secondary lg onClick={this.handleChangeStatus}>
                    {__("Uložiť")}
                  </Button>
                </div>
              </PanelBody>
            </Panel>
          )}
        </Body>
      </Layout>
    );
  }
}

RequestDetail.propTypes = {
  params: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    organization: organizationProfileSelector(state),
  };
};

export default connect(mapStateToProps)(RequestDetail);

//TODO - organization
