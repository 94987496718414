import { rem } from "polished";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const Label = styled.label`
  position: relative;
  text-transform: uppercase;
  padding: 0.4em 1.5em;
  border: ${rem(1)} solid ${({ color }) => color};
  color: ${(props) => props.fontColor && props.theme[props.fontColor]};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius};
  display: inline-block;
  background-color: ${({ active, color }) => (active ? color : "transparent")};
  color: ${({ active, color }) => (active ? "#fff" : color)};
  margin: 0 ${rem(3)};
  input {
    display: none;
  }
  &:hover {
    opacity: 0.75;
  }
`;

const Circle = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${rem(8)};
  width: ${rem(10)};
  height: ${rem(10)};
  border-radius: 100%;
  background-color: ${({ checked, color }) => (checked ? "#fff" : color)};
  cursor: pointer;
`;
const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
`;

const Statuses = ({
  forceActive,
  handleChange,
  statuses,
  selected,
  deliveryType,
}) => {
  const renderStatuses = (status) => {
    const {
      value,
      label,
      deliveryLabel,
      payload: { color },
    } = status;

    const checked = selected === value;
    return (
      <Label
        htmlFor={value}
        key={value}
        color={color}
        active={checked || forceActive}
      >
        <Button
          id={value}
          value={value}
          checked={checked}
          onClick={handleChange}
        >
          <Circle color={color} checked={checked} />
          {deliveryLabel?.[deliveryType] || label}
        </Button>
      </Label>
    );
  };

  return <div>{statuses.map(renderStatuses)}</div>;
};

Statuses.propTypes = {
  forceActive: PropTypes.bool,
  selected: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Statuses.defaultProps = {
  forceActive: false,
};

export default Statuses;
