import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class ProtectedContent extends PureComponent {
  render() {
    const { acl, rule, children } = this.props;
    if (acl[rule]) {
      return <div>{children}</div>;
    }
    return null;
  }
}

ProtectedContent.propTypes = {
  children: PropTypes.node.isRequired,
  rule: PropTypes.string.isRequired,
  acl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    acl: state.userAcl.data,
  };
};

export default connect(mapStateToProps)(ProtectedContent);
