import { rem } from 'polished';
import styled from 'styled-components';

const Textarea = styled.textarea`
  width: 100%;
  height: ${rem(150)};
  padding: ${rem(6)} ${rem(12)};
  box-sizing: border-box;
  border: ${rem(1.5)} solid #ccc;
  background-color: #fff;
  resize: none;
  margin-top: ${rem(5)};
  color: ${({ theme }) => theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  &:focus {
    border-color: ${({ theme }) => theme.secondary};
    outline: 0;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

export default Textarea;
