import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { normalizeEntities } from '../../utilities';
import { replaceEntities } from '../App/actions';
import apiEshop from '../../service/apiEshop';

export const LIST_SUPPLIERS = 'PARTNERS';

export const loadSuppliers = ({ appSpace }) => async dispatch => {
  const { suppliers } = await apiEshop.adminListSuppliers(appSpace);
  const { entities, results } = normalizeEntities('suppliers', suppliers);
  dispatch(replaceEntities(entities));
  return {
    results,
    total: results.length,
  };
};

export const loadSuppliersList = () => async (dispatch, getState) => {
  const parameters = getListParameters(LIST_SUPPLIERS)(getState());
  return dispatch(
    commit.action({
      listName: LIST_SUPPLIERS,
      load: async () => {
        try {
          const { results, total } = await dispatch(loadSuppliers(parameters));
          return {
            total,
            results,
          };
        } catch (e) {
          throw new CommitError(e);
        }
      },
    }),
  );
};
