import {
  RECEIVE_PRICE_LEVELS_FAILURE,
  RECEIVE_PRICE_LEVELS_SUCCESS,
  REQUEST_PRICE_LEVELS,
} from './constants';

import Api from '../../service/apiEshop';

const requestPriceLevels = () => ({
  type: REQUEST_PRICE_LEVELS,
});

const receivePriceLevelsSuccess = (data) => ({
  type: RECEIVE_PRICE_LEVELS_SUCCESS,
  payload: {
    data,
  },
});

const receivePriceLevelsFailure = (error) => ({
  type: RECEIVE_PRICE_LEVELS_FAILURE,
  payload: {
    error,
  },
});

export const fetchPriceLevelsList = (appspace) => {
  return async dispatch => {
    dispatch(requestPriceLevels());
    try {
      const response = await Api.adminListPricelevels(appspace);
      dispatch(receivePriceLevelsSuccess(response.pricelevels));
    } catch (e) {
      dispatch(receivePriceLevelsFailure(e.description || e));
    }
  };
};

export const updatePriceLevelsList = (appspace, data) => {
  return async dispatch => {
    dispatch(requestPriceLevels());
    try {
      await Api.adminUpdatePricelevels(appspace, {
        data: {
          pricelevels: data,
        }
      });
    } catch (e) {
      dispatch(receivePriceLevelsFailure(e.description || e));
    }
  }
}