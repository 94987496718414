import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';

export const FORM_NAME = 'SET_ACTIVITY_PUBLICATION_OPERATION';

const SetActivityPublicationOperationForm = ({ handleSubmit, org }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={FormFieldRedux}
        name="active"
        type="checkbox"
        label={__('Aktívne')}
      />
    </Form>
  );
};

SetActivityPublicationOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  org: state.selectedOrg
})

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps)
)(SetActivityPublicationOperationForm);
