const getPreviewUrlPattern = (settings) => {
  if (settings && settings.domain) {
    if (settings.previewUrlPattern) {
      return settings.previewUrlPattern.replace("{{domain}}", settings.domain);
    }
    return `https://${settings.domain}/previews/{{previewId}}`;
  }

  return null;
};

const getArticleUrlPattern = (settings) => {
  if (settings && settings.domain) {
    if (settings.articleUrlPattern) {
      return settings.articleUrlPattern.replace("{{domain}}", settings.domain);
    }
    return `https://${settings.domain}/{{uri}}`;
  }

  return null;
};

const getSectionUrlPattern = (settings) => {
  if (settings && settings.domain) {
    if (settings.sectionUrlPattern) {
      return settings.sectionUrlPattern.replace("{{domain}}", settings.domain);
    }
    return `https://${settings.domain}/{{uri}}`;
  }

  return null;
};

const CMS_APP_BASE_URL =
  process.env.REACT_APP_CMS_APP_BASE_URL ?? "https://cms.sportnet.online";

const getCmsUrl = (appId, appSpace, contentDivider, settings) => {
  const previewUrlPattern = getPreviewUrlPattern(settings);
  const articleUrlPattern = getArticleUrlPattern(settings);
  const sectionUrlPattern = getSectionUrlPattern(settings);
  const articleLayouts = settings?.articleLayouts || [];
  const parentUrl = "";
  const settingsParamValue = btoa(
    JSON.stringify({
      parentUrl,
      previewUrlPattern,
      articleUrlPattern,
      sectionUrlPattern,
      articleLayouts,
    }),
  );
  const searchParams = new URLSearchParams();
  searchParams.set("settings", settingsParamValue);

  return `${CMS_APP_BASE_URL}/app/${appId}/${appSpace}/${contentDivider}?${searchParams.toString()}`;
};

export default getCmsUrl;
