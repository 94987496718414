import {
  CLEAR_ACL,
  RECEIVE_ACL_ERROR,
  RECEIVE_ACL_SUCCESS,
  REMOVE_ACTIVE_APPSPACE,
  REPLACE_ENTITIES,
  REQUEST_ACL,
  SET_ACTIVE_APPSPACE,
  SET_APPLICATION_INFO,
  SET_APPS,
  SET_APPSPACES,
  SET_BREADCRUMBS,
  SET_SELECTED_ORG,
  TOGGLE_SIDEBAR,
  UPDATE_ENTITIES,
} from "./constants";
import { entitiesSelector } from "./selector";
import { getListParameters, initialize, setParams } from "@sportnet/redux-list";
import { normalizeEntities } from "../../utilities";
import ApiEshop from "../../service/apiEshop";

export const setSelectedOrg = (payload) => ({
  type: SET_SELECTED_ORG,
  payload,
});

export const setActiveAppspace = (data) => ({
  type: SET_ACTIVE_APPSPACE,
  payload: {
    data,
  },
});

export const setApps = (apps) => ({
  type: SET_APPS,
  payload: {
    apps,
  },
});

export const setAppspaces = (appspaces) => ({
  type: SET_APPSPACES,
  payload: {
    appspaces,
  },
});

// acl
export const clearAcl = () => ({
  type: CLEAR_ACL,
});

export const requestAcl = () => ({
  type: REQUEST_ACL,
});

export const receiveAclSuccess = (payload) => ({
  type: RECEIVE_ACL_SUCCESS,
  payload,
});

export const receiveAclError = (payload) => ({
  type: RECEIVE_ACL_ERROR,
  payload,
});

export const fetchAcl = (supplierId) => async (dispatch) => {
  dispatch(requestAcl());
  try {
    const data = await ApiEshop.adminGetUserAclForSupplier(supplierId);
    dispatch(receiveAclSuccess(data));
    return data;
  } catch (error) {
    dispatch(receiveAclError(error));
  }
  return {};
};

export const setApplicationInfo = (info) => ({
  type: SET_APPLICATION_INFO,
  payload: {
    info,
  },
});

export const removeActiveAppspace = () => ({
  type: REMOVE_ACTIVE_APPSPACE,
});

export const setBreadcrumbs = (breadcrumbs) => ({
  type: SET_BREADCRUMBS,
  payload: {
    breadcrumbs,
  },
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const initializeOrSetListParams = ({ listName, params }) => {
  return (dispatch, getState) => {
    const reduxListParams = getListParameters(listName)(getState());
    if (Object.keys(reduxListParams).length === 0) {
      return dispatch(
        initialize({
          listName,
          initialParams: params,
        }),
      );
    }
    return dispatch(
      setParams({
        listName,
        parameters: params,
      }),
    );
  };
};

export const updateEntitiesUtility = (oldEntities, entitiesToUpdate) => {
  return Object.keys(entitiesToUpdate).reduce((acc, entityName) => {
    if (entityName in oldEntities) {
      acc[entityName] = Object.keys(entitiesToUpdate[entityName]).reduce(
        (innerAcc, entityId) => {
          if (entityId in oldEntities[entityName]) {
            innerAcc[entityId] = {
              ...oldEntities[entityName][entityId],
              ...entitiesToUpdate[entityName][entityId],
            };
          } else {
            innerAcc[entityId] = entitiesToUpdate[entityName][entityId];
          }
          return innerAcc;
        },
        {},
      );
    } else {
      acc[entityName] = entitiesToUpdate[entityName];
    }
    return acc;
  }, {});
};

export const updateEntities = (entities) => {
  return (dispatch, getState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: UPDATE_ENTITIES,
      payload: {
        entities: nextEntities,
      },
    });
  };
};

export const replaceEntities = (entities) => {
  return {
    type: REPLACE_ENTITIES,
    payload: {
      entities,
    },
  };
};

export const loadCodelistOrderStatus = () => async (dispatch) => {
  try {
    const { codelist } = await ApiEshop.getCodelistOrderStatus();
    const { entities } = normalizeEntities("codelists", [
      { _id: "orderStatus", items: codelist },
    ]);
    dispatch(replaceEntities(entities));
  } catch (e) {
    console.error(e);
  }
};
