import { __ } from "../../utilities";
import { useAuth } from "@sportnet/auth-react";
import DefaultFilter from "@sportnet/ui/Filter";
import PropTypes from "prop-types";
import React from "react";
import TagmanagerConnector, {
  smarttagsToUrl,
  urlToSmarttags,
} from "@sportnet/tagmanager-connector";

const withoutPhotoCodelist = [
  {
    label: __("Len produkty bez fotky"),
    value: "T",
  },
];

const activeCodelist = [
  {
    label: __("Aktívne produkty"),
    value: "A",
  },
  {
    label: __("Neaktívne produkty"),
    value: "N",
  },
];

const ProductsFilter = ({
  brands,
  salesChannels,
  setParameters,
  parameters,
  categoriesCodelist,
  omitSidebarFilters = false,
}) => {
  const { accessToken, ppo, app } = useAuth();

  return (
    <TagmanagerConnector appId={app} appspace={ppo} accessToken={accessToken}>
      {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
        <DefaultFilter
          value={{
            q: parameters.q,
            brands:
              parameters.brands &&
              parameters.brands.map(
                (b) => brands.find((c) => c.value === b) || {},
              ),
            saleschannels:
              parameters.saleschannels &&
              parameters.saleschannels.map(
                (b) => salesChannels.find((c) => c.value === b) || {},
              ),
            withoutPhoto:
              parameters.withoutPhoto &&
              parameters.withoutPhoto.map(
                (b) => withoutPhotoCodelist.find((c) => c.value === b) || {},
              ),
            active:
              parameters.active &&
              parameters.active.map(
                (b) => activeCodelist.find((c) => c.value === b) || {},
              ),
            smarttagsInclude: urlToSmarttags(parameters.smarttagsInclude),
            smarttagsExclude: urlToSmarttags(parameters.smarttagsExclude),
            categories:
              parameters.categories &&
              parameters.categories.map(
                (i) => categoriesCodelist.find((c) => c.value === i) || {},
              ),
          }}
          onChange={(nextFilterValue) => {
            setParameters({
              q: nextFilterValue.q || "",
              brands: nextFilterValue.brands
                ? nextFilterValue.brands.map((s) => s.value)
                : undefined,
              saleschannels: nextFilterValue.saleschannels
                ? nextFilterValue.saleschannels.map((s) => s.value)
                : undefined,
              categories: nextFilterValue.categories
                ? nextFilterValue.categories.map((c) => c.value)
                : undefined,
              withoutPhoto: nextFilterValue.withoutPhoto
                ? nextFilterValue.withoutPhoto.map((s) => s.value)
                : undefined,
              active: nextFilterValue.active
                ? nextFilterValue.active.map((s) => s.value)
                : undefined,
              smarttagsInclude: nextFilterValue.smarttagsInclude
                ? smarttagsToUrl(nextFilterValue.smarttagsInclude)
                : undefined,
              smarttagsExclude: nextFilterValue.smarttagsExclude
                ? smarttagsToUrl(nextFilterValue.smarttagsExclude)
                : undefined,
            });
          }}
          filters={[
            {
              type: "query",
              name: "q",
              placeholder: __("Zadajte názov produktu"),
            },
            ...(!omitSidebarFilters
              ? [
                  {
                    type: "select",
                    options: categoriesCodelist,
                    label: __("Kategória"),
                    name: "categories",
                    multiple: true,
                  },
                  {
                    type: "select",
                    options: brands,
                    label: __("Značka"),
                    name: "brands",
                    multiple: true,
                  },
                  {
                    type: "select",
                    options: salesChannels,
                    label: __("Predajný kanál"),
                    name: "saleschannels",
                    multiple: true,
                  },
                  {
                    type: "select",
                    options: withoutPhotoCodelist,
                    label: __("Fotka produktu"),
                    name: "withoutPhoto",
                    multiple: true,
                  },
                  {
                    type: "select",
                    options: activeCodelist,
                    label: __("Aktívny produkt"),
                    name: "active",
                    multiple: true,
                  },
                  {
                    type: "smarttag",
                    label: "Obsahuje smart:tag",
                    name: "smarttagsInclude",
                    getSmarttagsKeys,
                    getSmarttagsValues,
                    getSmarttagsByFts,
                  },
                  {
                    type: "smarttag",
                    label: "Neobsahuje smart:tag",
                    name: "smarttagsExclude",
                    getSmarttagsKeys,
                    getSmarttagsValues,
                    getSmarttagsByFts,
                  },
                ]
              : []),
          ]}
        />
      )}
    </TagmanagerConnector>
  );
};

ProductsFilter.propTypes = {
  setParameters: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    q: PropTypes.string,
    brands: PropTypes.arrayOf(PropTypes.string),
    saleschannels: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.number),
    withoutPhoto: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.arrayOf(PropTypes.string),
    smarttagsInclude: PropTypes.string,
    smarttagsExclude: PropTypes.string,
  }).isRequired,
  appSpace: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  salesChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categoriesCodelist: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  omitSidebarFilters: PropTypes.bool,
};

ProductsFilter.defaultProps = {
  omitSidebarFilters: false,
};

export default React.memo(ProductsFilter);
