import { __ } from '../../utilities';
import { rem } from 'polished';
import OrderItem, { orderItemPropTypes } from './OrderItem';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  page-break-inside: auto;
`;

const Thead = styled.thead``;
const Tbody = styled.tbody``;

const Tr = styled.tr``;
const Th = styled.th`
  border: ${rem(1)} solid black;
  padding: ${rem(3)};
  white-space: nowrap;
`;

const LeftTh = styled(Th)`
  text-align: left;
  width: 100%;
`;

class Products extends PureComponent {
  renderItem(item) {
    return <OrderItem key={item.item_id} item={item} />;
  }

  render() {
    const { items } = this.props;
    return (
      <Table>
        <Thead>
          <Tr>
            <LeftTh>{__('Produkt')}</LeftTh>
            <Th>{__('Počet')}</Th>
            <Th>
              {__('Cena')}
              <br />
              {__('za ks')}
            </Th>
            <Th>
              {__('Spolu')}
              <br />
              {__('bez DPH')}
            </Th>
            <Th>
              {__('Spolu')}
              <br />
              {__('s DPH')}
            </Th>
            <Th>
              {__('Uhradené')}
              <br />
              {__('kreditmi')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>{items.map(this.renderItem)}</Tbody>
      </Table>
    );
  }
}

Products.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...orderItemPropTypes,
    }),
  ).isRequired,
};

export default Products;
