import { Col, Row } from 'react-flexbox-grid';
import { rem } from 'polished';
import styled from 'styled-components';

export const SRow = styled(Row)`
    padding: ${rem(16)} 0;
`;

export const SCol = styled(Col)`
    padding-bottom: ${rem(16)};
`;
