import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __ } from "../../utilities";
import { connect } from "react-redux";
import { format } from "date-fns";
import { useAuth } from "@sportnet/auth-react";
import ApiEshop from "../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import DefaultFilter from "@sportnet/ui/Filter";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import Loader from "@sportnet/ui/Loader";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";

const CardsList = ({ limit }) => {
  const [cardsList, setCardsList] = React.useState([]);
  const [nextOffset, setNextOffset] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isPosting, setIsPosting] = React.useState(false);
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const { ppo } = useAuth();

  const loadCards = React.useCallback(() => {
    setIsFetching(true);
    ApiEshop.adminListCards(ppo, {
      limit,
      offset,
      ...filter,
    })
      .then((cards) => {
        setCardsList(cards.cards);
        setTotal(cards.total);
        setNextOffset(cards.next_offset);
      })
      .catch((e) => {
        window.alert(e);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [offset, limit, ppo, filter]);

  React.useEffect(() => {
    loadCards();
  }, [loadCards]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPosting(true);
    const form = e.target;
    const data = {
      org_regnr: form.org_regnr.value,
      type: form.type.value,
      cardnr: form.cardnr.value,
      validTo: new Date(form.validTo.value),
    };
    ApiEshop.adminCreateNewCard(ppo, { data })
      .then((newCard) => {
        const newCards = [...cardsList];
        newCards.unshift(newCard);
        if (newCards.length > limit) {
          newCards.pop();
        }
        setCardsList(newCards);
        loadCards();
        form.org_regnr.value = "";
        form.cardnr.value = "";
      })
      .catch((error) => {
        const {
          details: { statusCode },
        } = error;
        if (statusCode === 409) {
          alert(__("Zadaná karta je už registrovaná"));
        } else {
          alert(error);
        }
      })
      .finally(() => {
        setIsPosting(false);
        setSidebarVisible(false);
      });
  };

  const handleDelete = (cardIndex) => {
    const c = window.confirm(__("Vymazať kartu?"));
    if (!c) {
      return;
    }
    const newCards = [...cardsList];
    const deletedCards = newCards.splice(cardIndex, 1);
    setCardsList(newCards);

    ApiEshop.adminDeleteCard(ppo, deletedCards[0]._id)
      .then(() => {
        loadCards();
      })
      .catch(() => {
        alert(__("Kartu sa nepodarilo zmazať"));
      });
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__("Zoznam kariet")}</HeaderBar.Header>
          </HeaderBar>
          <DefaultFilter
            filters={[
              {
                type: "query",
                name: "q",
                placeholder: __("Zadajte číslo karty alebo IČO"),
              },
            ]}
            value={filter}
            onChange={setFilter}
          />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              total={total}
              limit={limit}
              nextOffset={nextOffset}
              loading={isFetching}
              step={100}
              offset={offset}
              onChangeOffset={setOffset}
              onChangeLimit={() => {}}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              onClick={() => {
                setSidebarVisible(true);
              }}
            >
              {__("Pridať novú kartu")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Zoznam kariet")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching && cardsList.length === 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__("IČO")}</Th>
                <Th>{__("Typ karty")}</Th>
                <Th>{__("Číslo karty")}</Th>
                <Th>{__("Dátum platnosti")}</Th>
                <Th>{__("Dátum registrácie")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {cardsList &&
                cardsList.map((card, cardIndex) => {
                  return (
                    <Tr key={card._id}>
                      <Td>{card.org_regnr}</Td>
                      <Td>{card.type}</Td>
                      <Td>{card.cardnr}</Td>
                      <Td>
                        {!!card.validTo &&
                          format(new Date(card.validTo), "dd.MM.yyyy")}
                      </Td>
                      <Td>
                        {format(new Date(card.created_date), "dd.MM.yyyy, p")}
                      </Td>
                      <Td right>
                        <Button
                          danger
                          icon="trash"
                          onClick={() => {
                            handleDelete(cardIndex);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
      <Sidebar
        visible={sidebarVisible}
        header={__("Karty")}
        onClose={() => {
          setSidebarVisible(false);
        }}
      >
        <form onSubmit={handleSubmit}>
          <Segment>
            <FormGroup>
              <Label>{__("Typ karty")}</Label>
              <Input
                type="text"
                name="type"
                defaultValue="Slovnaft"
                disabled={isPosting}
              />
            </FormGroup>

            <FormGroup>
              <Label>{__("IČO")}</Label>
              <Input type="text" name="org_regnr" disabled={isPosting} />
            </FormGroup>

            <FormGroup>
              <Label>{__("Číslo karty")}</Label>
              <Input type="text" name="cardnr" disabled={isPosting} />
            </FormGroup>

            <FormGroup>
              <Label>{__("Dátum platnosti")}</Label>
              <Input type="date" name="validTo" disabled={isPosting} />
            </FormGroup>

            <Button primary disabled={isPosting}>
              {__("Pridať")}
            </Button>
            {isPosting && <Loader />}
          </Segment>
        </form>
      </Sidebar>
    </Layout>
  );
};

CardsList.propTypes = {
  limit: PropTypes.number,
};

CardsList.defaultProps = {
  limit: 100,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(CardsList);
