import { Panel, PanelBody, PanelHeader } from ".";
import { __ } from "@sportnet/ui/utilities";
import { format } from "date-fns";
import { getProp } from "@sportnet/utilities";
import { useAuth } from "@sportnet/auth-react";
import Button from "@sportnet/ui/Button";
import Icon from "../../components/Icon";
import React from "react";
import apiEshop from "../../service/apiEshop";
import paygateApi from "../../service/paygateApi";
import styled from "styled-components";

const PaidWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentInfo = ({ detail, setInitialState }) => {
  const { app, ppo } = useAuth();
  const [paidDate, setPaidDate] = React.useState();
  const [payments, setPayments] = React.useState([]);

  const loadPaygatePayments = React.useCallback(() => {
    Promise.all([
      paygateApi
        .getPaymentsByExternalId(app, ppo, `O-${detail._id}`)
        .then(({ items }) => {
          return items;
        }),
      paygateApi
        .getPaymentsByExternalId(app, ppo, `P-${detail.purchase_id}`)
        .then(({ items }) => {
          return items;
        }),
    ])
      .then((ii) => ii.reduce((r, i) => [...r, ...i]))
      .then((payments) =>
        setPayments(
          payments.sort((a, b) => (a.createdDT < b.createdDT ? -1 : 1)),
        ),
      )
      .catch((e) => {
        console.error(e);
      });
  }, [ppo, app, detail.purchase_id, detail._id]);

  React.useEffect(() => {
    // loadPaygatePayments();
  }, [loadPaygatePayments]);

  React.useEffect(() => {
    if (!!detail) {
      if (!detail.paid_date) {
        const idx = payments.findIndex(
          (p) => p.externalId === `P-${detail.purchase_id}` && !!p.paidDT,
        );
        if (idx > -1) {
          setPaidDate(payments[idx].paidDT);
        }
        // const res = await apiEshop.getProductDetail(detail.purchase_id);
      } else {
        setPaidDate(detail.paid_date);
      }
    }
  }, [detail, payments]);

  const onOrderSetPaid = () => {
    if (
      window.confirm(__("Skutočne si prajete potvrdiť zaplatenie objednávky?"))
    ) {
      apiEshop
        .adminSetOrderPaid(ppo, detail._id, {
          data: {},
        })
        .then(() => {
          setInitialState();
        })
        .catch((e) => {
          alert(`Chyba: ${e}`);
        });
    }
  };

  return (
    <Panel>
      <PanelHeader>{__("Úhrada:")}</PanelHeader>
      <PanelBody>
        {paidDate && [
          <PaidWrapper>
            <Icon name="checked" success style={{ color: "green" }} />
            &nbsp;&nbsp;
            {`${format(new Date(paidDate), "dd.MM.yyyy HH:mm")}`}
          </PaidWrapper>,
          <PaidWrapper>
            {getProp(detail, ["paid_payload", "gpwebpay_ordernumber"]) &&
              `Platobná brána ID: ${getProp(detail, [
                "paid_payload",
                "gpwebpay_ordernumber",
              ])}`}
          </PaidWrapper>,
        ]}
        {!paidDate && getProp(detail, ["payment", "type"]) !== "GPWEBPAY" && (
          <PaidWrapper>
            <Button primary icon="close" onClick={onOrderSetPaid}>
              {__("Označiť ako uhradené")}
            </Button>
          </PaidWrapper>
        )}
        {!paidDate && getProp(detail, ["payment", "type"]) === "GPWEBPAY" && (
          <PaidWrapper style={{ color: "red" }}>
            <Icon name="delete" danger />
            &nbsp;
            {__("NEUHRADENÁ")}
          </PaidWrapper>
        )}
      </PanelBody>
    </Panel>
  );
};

export default PaymentInfo;
