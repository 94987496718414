import {
  RECEIVE_CATALOGS_FAILURE,
  RECEIVE_CATALOGS_SUCCESS,
  RECEIVE_CATALOG_SUCCESS,
  REQUEST_CATALOGS,
} from './constants';

export const catalogsReducer = (
  state = {
    data: [],
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_CATALOGS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CATALOGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
      };
    case RECEIVE_CATALOG_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case RECEIVE_CATALOGS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
