import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { rem } from 'polished';
import { required } from '../../utilities/validations';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';


export const FORM_NAME = 'IMPORTS_CREATE_NEW_IMPORT';

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const ErrorStyle = styled.div`
  font-size: 0.6875rem;
  color: #e94141;
  margin-top: ${rem(0.187)};
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const FileInput = ({ 
  input: { value: omitValue, onChange, onBlur, ...inputProps }, 
  meta: { touched, error , warning }, 
  ...props 
}) => {
  return (
      <FormGroup>
      <Label>{props.label}</Label>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      <ErrorStyle>{touched ? error : ''}</ErrorStyle>
      </FormGroup>
  );
};

const CreateNewImportForm = ({ handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
    <Field
      label={__('Oddelovač')}
      name="delimiter"
      component={FormFieldRedux}
      type="theselect"
      options={[
        { value: ',', label: __('Čiarka ,') },
        { value: ';', label: __('Bodkočiarka ;') },
        { value: '\t', label: __('Tabelátor <tab>') }
      ]}
      required
      validate={[required]}
     />
     <Field
       component={FileInput}
       name="uploadFile"
       label={__('Súbor')}
       required
       validate={[required]}
       type="file"
     >
      </Field>
    </Form>
  )

}

CreateNewImportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {}
}

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps)
)(CreateNewImportForm)
