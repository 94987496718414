import Button from '@sportnet/ui/Button';
import Icon from '@sportnet/ui/Icon';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const CenteredModalContent = styled(ModalContent)`
  text-align: center;
`;

class AlertModal extends React.PureComponent {
  render() {
    const {
      isOpen,
      children,
      handleClose,
      theme,
      success,
      warning,
    } = this.props;
    return (
      <Modal centered size="xs" isOpen={isOpen} handleClose={handleClose}>
        <CenteredModalContent>
          {success ? (
            <Icon name="check-circle" size="xl" color={theme.color.success} />
          ) : warning ? (
            <Icon name="error" size="xl" color={theme.color.warning} />
          ) : (
            <Icon name="error" size="xl" color={theme.color.danger} />
          )}
          <br />
          {children}
        </CenteredModalContent>
        <ModalActions>
          <div />
          <Button onClick={handleClose}>OK</Button>
        </ModalActions>
      </Modal>
    );
  }
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  success: PropTypes.bool,
  warning: PropTypes.bool,
};

AlertModal.defaultProps = {
  children: null,
  success: false,
  warning: false,
};

export default withTheme(AlertModal);
