import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { REQUESTS_LIST } from './constants';
import { normalizeEntities } from '../../utilities';
import { updateEntities } from '../App/actions';
import apiEshop from '../../service/apiEshop';

export const loadRequests = parameters => {
  return async (dispatch, getState) => {
    const { selectedOrg: appSpace } = getState();
    const { requests, total, limit, offset } = await apiEshop.adminListRequests(
      appSpace,
      parameters,
    );
    const { entities, results } = normalizeEntities('requests', requests);
    dispatch(updateEntities(entities));
    return {
      total,
      limit,
      offset,
      results,
    };
  };
};

export const loadRequestsList = () => {
  return (dispatch, getState) => {
    const parameters = getListParameters(REQUESTS_LIST)(getState());
    return dispatch(
      commit.action({
        listName: REQUESTS_LIST,
        load: async () => {
          try {
            const { results, limit, offset, total } = await dispatch(
              loadRequests(parameters),
            );
            return {
              total,
              limit,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
