import { __ } from "@sportnet/ui/utilities";
import {
  calculateOrderTotalpriceWithCredits,
  formatPrice,
} from "../../utilities";
import { format } from "date-fns";
import { useAuth } from "@sportnet/auth-react";
import Icon from "../../components/Icon";
import Loader from "@sportnet/ui/Loader";
import React from "react";
import paygateApi from "../../service/paygateApi";

const PaymentInfo = ({ order }) => {
  const [loading, setLoading] = React.useState(true);
  const [paidDate, setPaidDate] = React.useState();
  const { app, ppo } = useAuth();

  const getPaidDate = React.useCallback(async () => {
    if (!order.paid_date) {
      try {
        setLoading(true);
        const { items = [] } = await paygateApi.getPaymentsByExternalId(
          app,
          ppo,
          `P-${order.purchase_id}`,
        );

        const idx = items.findIndex(
          (p) => p.externalId === `P-${order.purchase_id}` && !!p.paidDT,
        );
        if (idx > -1) {
          setPaidDate(items[idx].paidDT);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setPaidDate(order.paid_date);
    }
  }, [order?.purchase_id, app, ppo, order?.paid_date]);

  React.useEffect(() => {
    getPaidDate();
  }, [getPaidDate]);

  const { amount, currency, byCredit } =
    calculateOrderTotalpriceWithCredits(order);

  const restOfMoney = amount - byCredit;

  if (loading) {
    return <Loader />;
  }

  return paidDate ? (
    <div>
      <Icon name="checked" />
      &nbsp;
      <span title={format(new Date(paidDate), "dd.MM.yyyy HH:mm:ss")}>
        {format(new Date(paidDate), "dd.MM.yyyy")}
        {order.paidPayload && (
          <div>
            {__("Platobná brána ID")}: {order.paidPayload.gpwebpay_ordernumber}
          </div>
        )}
      </span>
      <div>
        {`${__("Kreditmi:")} ${formatPrice(byCredit, currency)} / ${__(
          "Kartou:",
        )} ${formatPrice(restOfMoney, currency)}`}
      </div>
    </div>
  ) : (
    <div style={{ color: "red" }}>
      <Icon name="delete" danger />
      &nbsp;
      {__("NEUHRADENÁ")}
      <div>
        {`${__("Kreditmi:")} ${formatPrice(byCredit, currency)} / ${__(
          "Kartou:",
        )} -${formatPrice(restOfMoney, currency)}`}
      </div>
    </div>
  );
};

export default PaymentInfo;
