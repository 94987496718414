const namespace = "APP";

export const SET_SELECTED_ORG = `${namespace}/SET_SELECTED_ORG`;
export const REQUEST_ACL = `${namespace}/REQUEST_ACL`;
export const RECEIVE_ACL_SUCCESS = `${namespace}/RECEIVE_ACL_SUCCESS`;
export const RECEIVE_ACL_ERROR = `${namespace}/RECEIVE_ACL_ERROR`;
export const CLEAR_ACL = `${namespace}/CLEAR_ACL`;
export const SET_APPLICATION_INFO = `${namespace}/SET_APPLICATION_INFO`;

export const SET_ACTIVE_APPSPACE = `${namespace}/SET_ACTIVE_APPSPACE`;
export const REMOVE_ACTIVE_APPSPACE = `${namespace}/REMOVE_ACTIVE_APPSPACE`;
export const SET_BREADCRUMBS = `${namespace}/SET_BREADCRUMBS`;
export const SET_APPS = `${namespace}/SET_APPS`;
export const SET_APPSPACES = `${namespace}/SET_APPSPACES`;

export const AUTH_USER_AUTHORIZED = `${namespace}/AUTH_USER_AUTHORIZED`;
export const AUTH_USER_UNAUTHORIZED = `${namespace}/AUTH_USER_UNAUTHORIZED`;

export const TOGGLE_SIDEBAR = `${namespace}/TOGGLE_SIDEBAR`;

export const UPDATE_ENTITIES = `${namespace}/UPDATE_ENTITIES`;
export const REPLACE_ENTITIES = `${namespace}/REPLACE_ENTITIES`;
