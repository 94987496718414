import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { __ } from "../../utilities";
import { connect } from "react-redux";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { fetchSalesChannelsCategories } from "./actions";
import { getProp } from "@sportnet/utilities";
import { isSubmitting, submit } from "redux-form";
import {
  nonSharedSaleChanellsListSelector,
  saleChanellsIsFetchingSelector,
} from "../SaleChannels/selectors";
import {
  saleChanellsCategoriesIsFetchingSelector,
  saleChanellsCategoriesListSelector,
} from "./selectors";
import { useAuth } from "@sportnet/auth-react";
import { withTheme } from "styled-components";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import CategoryForm from "./form";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import NotFound from "@sportnet/ui/NotFound";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";
import Tabber from "@sportnet/ui/Tabber";
import Tree from "@sportnet/ui/Tree";
import apiEshop from "../../service/apiEshop";

const CATEGORY_FORM_NAME = "CATEGORY_FORM";

const SalesChannelsCategoriesList = ({
  dispatch,
  salesChannels,
  categories,
  isFetchingCategories,
  isFetchingSalesChannels,
  isSubmittingForm,
}) => {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [parent, setParent] = React.useState(null);
  const { ppo } = useAuth();
  // const [name, setName] = React.useState("");
  const [update, setUpdate] = React.useState(false);
  const [loading, setLoading] = React.useState(
    isFetchingSalesChannels || isFetchingCategories || isFetching,
  );
  // const [selectedParameters, setSelectedParameters] = React.useState({});
  const [selectedCategory, setSelectedCategory] = React.useState({});
  const [selectedChannel, setSelectedChannel] = React.useState(null);
  const [treeData, setTreeData] = React.useState([]);
  const [parameters, setParameters] = React.useState([]);

  const selectedChannelId = React.useMemo(() => {
    if (selectedChannel) {
      return salesChannels.find((item) => {
        return item.name === selectedChannel;
      })._id;
    }
  }, [selectedChannel, salesChannels]);

  const loadSalesChannelCategories = React.useCallback(
    (saleschannel) => {
      dispatch(fetchSalesChannelsCategories(ppo, saleschannel._id));
    },
    [ppo],
  );

  const transformTreeData = React.useCallback((categories) => {
    const treeData = categories.map((category) => {
      const object = {
        _id: String(category._id),
        title: category.name || category.title,
        expanded: true,
      };
      if (category.categories) {
        object.children = transformTreeData(category.categories);
      } else if (category.children) {
        object.children = transformTreeData(category.children);
      }
      return object;
    });
    setTreeData(treeData);
    return treeData;
  }, []);

  const setInitialState = React.useCallback(
    (salesChannels) => {
      const saleschannel = salesChannels[0];
      setSelectedChannel(getProp(saleschannel, ["name"], null));
      if (saleschannel) {
        loadSalesChannelCategories(saleschannel);
      }
    },
    [loadSalesChannelCategories],
  );

  React.useEffect(() => {
    if (salesChannels.length > 0) {
      setInitialState(salesChannels);
    }
  }, [setInitialState, salesChannels]);

  React.useEffect(() => {
    dispatch(fetchSaleChannelsList(ppo));
    apiEshop
      .adminListParamLabels(ppo, {
        all: false,
      })
      .then((res) => {
        setParameters(res.params);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [ppo]);

  React.useEffect(() => {
    if (!isFetchingCategories) {
      transformTreeData(categories);
    }
  }, [categories, isFetchingCategories]);

  React.useEffect(() => {
    setInitialState(salesChannels);
  }, [salesChannels, setInitialState]);

  React.useEffect(() => {
    if (!!selectedChannel) {
      const channel = salesChannels.find((i) => i.name === selectedChannel);
      loadSalesChannelCategories(channel);
    }
  }, [selectedChannel, loadSalesChannelCategories]);

  const deleteCategory = async (category) => {
    const channel = salesChannels.find((i) => i.name === selectedChannel);
    if (window.confirm(__("Skutočne si želáte odstrániť kategóriu?"))) {
      setLoading(true);
      try {
        await apiEshop.adminSaleschannelDeleteCategory(
          category._id,
          ppo,
          channel._id,
        );
        dispatch(fetchSalesChannelsCategories(ppo, channel._id));
      } catch (e) {
        alert(__("Kategóriu sa nepodarilo zmazať"));
      } finally {
        setLoading(false);
      }
    }
  };

  const submitForm = async ({
    name,
    displayName = "",
    url = "",
    searchParams = [],
    meta = {},
    description = [],
    priority,
    hidden = false,
  }) => {
    const data = {
      name,
      hidden,
      displayName,
      url,
      searchParams,
      meta,
      description,
      priority,
    };
    const channel = salesChannels.find((i) => i.name === selectedChannel);
    setLoading(true);
    try {
      if (update) {
        // uprava kategorie
        await apiEshop.adminSaleschannelUpdateCategory(
          update._id,
          ppo,
          channel._id,
          {
            data,
          },
        );
      } else if (parent) {
        // pridanie podkategorie
        await apiEshop.adminSaleschannelCreateCategory(
          parent._id,
          ppo,
          channel._id,
          {
            data,
          },
        );
      } else {
        // pridanie kategorie do rootu
        await apiEshop.adminSaleschannelCreateRootCategory(ppo, channel._id, {
          data,
        });
      }

      setInitialState(salesChannels);
      setSelectedCategory({});
      setSidebarVisible(false);
      dispatch(fetchSalesChannelsCategories(ppo, channel._id));
    } catch (e) {
      console.error(e);
      alert(__("Kategóriu sa nepodarilo uložiť"));
    } finally {
      setLoading(false);
    }
  };

  const moveCategory = async (data) => {
    const { node, nextParentNode } = data;
    const channel = salesChannels.find((i) => i.name === selectedChannel);
    setLoading(true);
    try {
      let parentId = 0;
      if (nextParentNode) {
        parentId = nextParentNode._id;
      }
      await apiEshop.adminSaleschannelMoveCategory(
        node._id,
        parentId,
        ppo,
        channel._id,
      );
      dispatch(fetchSalesChannelsCategories(ppo, channel._id));
    } catch (e) {
      alert(__("Kategóriu nebolo možné presunúť"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>
            {__("Kategórie predajných kanálov")}
          </HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        selectedChannel && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                icon="plus"
                onClick={() => {
                  setSidebarVisible(true);
                }}
              >
                {__("Pridať hlavnú kategóriu")}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <AppContext title={__("Kategórie predajných kanálov")} />

      {salesChannels.length > 0 && (
        <Tabber
          active={selectedChannel}
          items={salesChannels.map((channel) => channel.name)}
          onClickItem={(e) => {
            setSelectedChannel(e);
          }}
        />
      )}
      <Segment noBottomGutter>
        <Segment raised loading={loading}>
          {!selectedChannel && !loading && (
            <NotFound title={__("Vyberte prosím predajný kanál")} />
          )}
          {selectedChannel && !loading && categories.length === 0 && (
            <NotFound title={__("Nenašli sa žiadne kategórie")} />
          )}
          {selectedChannel && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <Tree
                canDrag
                onMoveNode={moveCategory}
                buttons={[
                  {
                    icon: "plus",
                    onClick: (category) => {
                      setSidebarVisible(true);
                      setParent(category);
                    },
                  },
                  {
                    icon: "pencil",
                    onClick: (category) => {
                      setIsFetching(true);
                      apiEshop
                        .adminSaleschannelGetCategories(
                          category._id,
                          ppo,
                          selectedChannelId,
                        )
                        .then((categoryData) => {
                          setSidebarVisible(true);
                          setUpdate(category);
                          setSelectedCategory(categoryData.category);
                          // setName(category.title);
                          // setSelectedParameters(
                          //   categoryData.category.searchParams
                          //     ? categoryData.category.searchParams.reduce(
                          //         (acc, item) => {
                          //           return { ...acc, [item]: 1 };
                          //         },
                          //         {},
                          //       )
                          //     : {},
                          // );
                          setIsFetching(false);
                        })
                        .catch((e) => {
                          setIsFetching(false);
                          console.error(e);
                          alert(
                            __(
                              "Vyskytla sa neočakávaná chyba. Nebolo možné načítať kategóriu.",
                            ),
                          );
                        });
                    },
                  },
                  {
                    icon: "trash",
                    onClick: deleteCategory,
                  },
                ]}
                treeData={treeData}
                onChange={setTreeData}
                activeNodeId={selectedChannel}
              />
            </div>
          )}
        </Segment>
      </Segment>

      <Sidebar
        visible={sidebarVisible}
        onClose={() => {
          setSelectedCategory({});
          setSidebarVisible(false);
        }}
        header={__("Kategória")}
      >
        <Segment>
          {!isFetching && sidebarVisible && (
            <CategoryForm
              parameters={parameters}
              selectedChannel={selectedChannel}
              selectedChannelId={selectedChannelId}
              selectedCategory={selectedCategory}
              organization={ppo}
              form={CATEGORY_FORM_NAME}
              onSubmit={submitForm}
              isSubmitting={isSubmittingForm}
              initialValues={selectedCategory}
            />
          )}
          <FormGroup>
            <Button
              primary
              onClick={() => dispatch(submit(CATEGORY_FORM_NAME))}
              loading={isSubmittingForm}
            >
              {__("Uložiť")}
            </Button>
          </FormGroup>
        </Segment>
      </Sidebar>
    </Layout>
  );
};

SalesChannelsCategoriesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }),
  }).isRequired,
  isFetchingSalesChannels: PropTypes.bool.isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
  isSubmittingForm: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    salesChannels: nonSharedSaleChanellsListSelector(state),
    categories: saleChanellsCategoriesListSelector(state),
    isFetchingSalesChannels: saleChanellsIsFetchingSelector(state),
    isFetchingCategories: saleChanellsCategoriesIsFetchingSelector(state),
    isSubmittingForm: isSubmitting(CATEGORY_FORM_NAME)(state),
  };
};

export default connect(mapStateToProps)(withTheme(SalesChannelsCategoriesList));
