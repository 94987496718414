import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  isNumber,
  lte,
  parseToFloat,
  required,
} from '../../../../utilities/validations';
import { pricelevelCodelistSelector } from '../../../PriceLevels/selectors';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Input from '@sportnet/ui/Input';
import PropTypes from 'prop-types';
import React from 'react';

export const FORM_NAME = 'SET_PRICELEVEL_OPERATION';

const lte100 = lte(100);

const SetPricelevelOperationForm = ({ handleSubmit, priceLevelsCodelist }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label={__('Cenová hladina')}
        name="pricelevel"
        component={FormFieldRedux}
        parse={item => (item ? item.value : '')}
        format={value => {
          return value
            ? priceLevelsCodelist.find(p => p.value === value) || null
            : null;
        }}
        type="theselect"
        options={priceLevelsCodelist}
        required
        validate={[required]}
      />
      <Field
        component={FormFieldRedux}
        name="multiplier"
        label={__('Výška zľavy oproti bežnej cene (v %)')}
        placeholder="10"
        required
        validate={[isNumber, required, lte100]}
        parse={parseToFloat}
      >
        <input />
        <Input.Button>%</Input.Button>
      </Field>
    </Form>
  );
};

SetPricelevelOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  priceLevelsCodelist: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => {
  return {
    priceLevelsCodelist: pricelevelCodelistSelector(state),
  };
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(SetPricelevelOperationForm);
