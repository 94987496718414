import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import icons from "./icons";
import styled, { css, withTheme } from "styled-components";

const Wrapper = styled.svg`
  display: inline-block;
  ${props => {
    const { theme } = props;
    let size = !props.width && !props.height ? theme.md : props.width;
    if (props.sm) size = theme.sm;
    if (props.md) size = theme.md;
    if (props.lg) size = theme.lg;
    if (props.xl) size = theme.xl;
    if (props.xxl) size = theme.xxl;
    return css`
      width: ${size};
      height: ${size};
    `;
  }}
`;

class Icon extends PureComponent {

  constructor(props) {
    super(props);
    this.getColor = this.getColor.bind(this);
  }

  getColor(props) {
    const { theme } = this.props;
    let color = theme.fontColor;
    if (props.primary) color = theme.fontColor;
    if (props.primaryLight) color = theme.primaryLight;
    if (props.fontColorPrimary) color = theme.fontColorPrimary;
    if (props.lightGray) color = theme.lightGray;
    if (props.secondary) color = theme.secondary;
    if (props.warning) color = theme.warning;
    if (props.success) color = theme.success;
    if (props.danger) color = theme.danger;
    if (props.white) color = theme.white;
    if (props.black) color = theme.black;
    if (props.fill) color = props.fill;
    return color;
  }

  render() {
    const color = this.getColor(this.props);
    return (
      <Wrapper {...this.props}>
        {icons[this.props.name](color)}
      </Wrapper>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
  primary: PropTypes.bool,
  primaryLight: PropTypes.bool,
  fontColorPrimary: PropTypes.bool,
  lightGray: PropTypes.bool,
  secondary: PropTypes.bool,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  white: PropTypes.bool,
  black: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xxl: PropTypes.bool,
};

Icon.defaultProps = {
  primary: false,
  primaryLight: false,
  fontColorPrimary: false,
  lightGray: false,
  secondary: false,
  warning: false,
  success: false,
  danger: false,
  white: false,
  black: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
}

export default withTheme(Icon);
export { default as icons } from "./icons";