import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pricelevelCodelistSelector } from '../../../PriceLevels/selectors';
import { required } from '../../../../utilities/validations';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';

export const FORM_NAME = 'DELETE_PRICELEVEL_OPERATION';

const DeletePricelevelOperationForm = ({
  handleSubmit,
  priceLevelsCodelist,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label={__('Cenová hladina')}
        name="pricelevel"
        component={FormFieldRedux}
        parse={item => (item ? item.value : '')}
        format={value => {
          return value
            ? priceLevelsCodelist.find(p => p.value === value) || null
            : null;
        }}
        type="theselect"
        options={priceLevelsCodelist}
        required
        validate={[required]}
      />
    </Form>
  );
};

DeletePricelevelOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  priceLevelsCodelist: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => {
  return {
    priceLevelsCodelist: pricelevelCodelistSelector(state),
  };
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(DeletePricelevelOperationForm);
