import { __ } from '../../utilities';
import { compose } from 'redux';
import DefaultFilter from '@sportnet/ui/Filter';
import PropTypes from 'prop-types';
import React from 'react';

const OrdersFilter = ({ salesChannels, setQuery, query }) => {
  const salesChannelsCodelist = salesChannels.map(sc => ({
    value: sc._id,
    label: sc.name,
  }));

  return (
    <DefaultFilter
      value={{
        q: query.q,

        saleschannels: query.saleschannels.length
          ? query.saleschannels.map(
              s => salesChannelsCodelist.find(sc => sc.value === s) || {},
            )
          : undefined,
      }}
      onChange={nextFilterValue => {
        setQuery({
          q: nextFilterValue.q || '',

          saleschannels: nextFilterValue.saleschannels
            ? nextFilterValue.saleschannels.map(s => s.value)
            : [],
        });
      }}
      filters={[
        {
          type: 'query',
          name: 'q',
          placeholder: __(
            'Zadajte číslo objednávky, firmu, osobu, email alebo telefón',
          ),
        },
        {
          type: 'select',
          options: salesChannelsCodelist,
          label: __('Predajný kanál'),
          name: 'saleschannels',
          multiple: true,
        },
      ]}
    />
  );
};

OrdersFilter.propTypes = {
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.shape({
    q: PropTypes.string,
    saleschannels: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default compose(React.memo)(OrdersFilter);
