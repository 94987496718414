import {
  RECEIVE_PRICE_LEVELS_FAILURE,
  RECEIVE_PRICE_LEVELS_SUCCESS,
  REQUEST_PRICE_LEVELS,
} from './constants';

const priceLevelsReducer = (state = {
  data: [],
  isFetching: false,
}, action) => {
  switch (action.type) {
    case REQUEST_PRICE_LEVELS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PRICE_LEVELS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
      };
    case RECEIVE_PRICE_LEVELS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default priceLevelsReducer;