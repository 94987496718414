import { ContextBar, ContextBarItem } from "@sportnet/ui/ContextBar";
import { IMPORTS_LIST, IMPORTS_LIST_CONFIG } from "./constants";
import { __, getImportStatus } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { format } from "date-fns";
import { getListTotal, isCommiting } from "@sportnet/redux-list";
import { importsShowSidebarSelector, listImportsSelector } from "./selectors";
import { initializeOrSetListParams } from "../App/actions";
import { isSubmitting, reset, submit } from "redux-form";
import { loadImportsList, sidebarClose, toggleSidebar } from "./actions";
import { rem } from "polished";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import BasicTable from "@sportnet/ui/BasicTable";
import Button from "@sportnet/ui/Button";
import CreateNewImportForm, { FORM_NAME } from "./forms";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "@sportnet/ui/Icon";
import Layout from "../../components/Layout";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";
import apiEshop from "../../service/apiEshop";
import styled, { withTheme } from "styled-components";
import useQuery from "@sportnet/query-hoc/useQuery";
import withPopups, { WithPopupsProps } from "../../components/WithPopups";

const SButton = styled(Button)`
  margin-top: ${rem(10)};
`;

const Imports = ({
  history,
  location: { pathname, search },
  setParams,
  loadImports,
  isFetching,
  total,
  imports,
  theme,
  showSidebar,
  sidebarClose,
  toggleSidebar,
  submitForm,
  resetForm,
  isFormSubmitting,
  alert,
  confirm,
  match: {
    params: { appspace, importCategory },
  },
  org,
  dispatch,
}) => {
  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      history.push({
        pathname,
        search: newSearch,
      }),
    IMPORTS_LIST_CONFIG,
  );

  const handleSubmit = (data) => {
    const payload = {
      data: {
        delimiter: data.delimiter.value,
        body: data.uploadFile,
      },
    };
    apiEshop
      .adminCreateImport(appspace, importCategory, payload)
      .then((res) => {
        resetForm();
        loadImports()
          .then((res) => {
            confirm("Import uložený!").then(() => {});
          })
          .catch(async (error) => {
            await alert(__(`Nepodarilo sa obnoviť zoznam súborov!`));
          });
      })
      .catch(async (error) => {
        await alert(__(`Import sa nepodarilo uložiť!`));
      });
  };

  const getCategoryLabel = () => {
    let result = null;
    switch (importCategory) {
      case "prices":
        result = __("Nový import cien");
        break;
      case "products":
        result = __("Nový import produktov");
        break;
      default:
        result = __("Nový import neznámej kategórie");
    }
    return result;
  };

  const getPageTitle = () => {
    let result = null;
    switch (importCategory) {
      case "prices":
        result = __("Importy cien");
        break;
      case "products":
        result = __("Importy produktov");
        break;
      default:
        result = __("Importy");
    }
    return result;
  };

  const getCreateButtonTitle = () => {
    let result = null;
    switch (importCategory) {
      case "prices":
        result = __("Pridať import cien");
        break;
      case "products":
        result = __("Pridať import produktov");
        break;
      default:
        result = __("Pridať import");
    }
    return result;
  };

  const getStatusIcon = (importStatus) => {
    let result = null;
    switch (importStatus) {
      case "pending":
        result = <Icon name="play-circle" success color="blue" />;
        break;
      case "processing":
        result = <Icon name="pause-circle" success color="red" />;
        break;
      case "done":
        result = <Icon name="check-upload" success color="green" />;
        break;
      case "error":
        result = <Icon name="error" success color="red" />;
        break;
      default:
        result = <Icon name="pause-circle" success />;
    }
    return result;
  };

  React.useEffect(() => {
    (async () => {
      try {
        await setParams({
          listName: IMPORTS_LIST,
          params: {
            ...query,
            importCategory,
          },
        });
        await loadImports();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query, loadImports, setParams, importCategory]);

  const isLoading = isFetching && imports.length === 0;

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{getPageTitle()}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              loading={isLoading}
              total={total}
              limit={query.limit}
              offset={query.offset}
              onChangeOffset={(offset) => {
                setQuery({ offset });
              }}
            />
          </ContextBarItem>
          <ContextBarItem>
            <Button
              primary
              icon="plus"
              onClick={() => {
                toggleSidebar();
              }}
            >
              {getCreateButtonTitle()}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={getPageTitle()} />
      <Sidebar
        visible={showSidebar}
        onClose={() => {
          sidebarClose();
        }}
        header={getCategoryLabel()}
      >
        <Segment>
          <CreateNewImportForm onSubmit={handleSubmit} />
          <SButton primary onClick={submitForm}>
            {__("Pridať")}
          </SButton>
        </Segment>
      </Sidebar>

      <Segment noBottomGutter>
        <Segment raised loading={isLoading}>
          <BasicTable
            columns={[
              { id: "date", header: __("Dátum a čas") },
              { id: "userFileName", header: __("Súbor") },
              { id: "status", header: __("Stav") },
              { id: "icon" },
            ]}
            onClickRow={(item) => {
              history.push(`/admin/${appspace}/import/${item._id}`);
            }}
            rows={imports}
            renderRow={(item) => [
              format(new Date(item.created_date), "dd.MM.yyyy HH:mm:ss"),
              item.userFileName,
              getImportStatus(item.status),
              getStatusIcon(item.status),
            ]}
            rowKey="_id"
          />
        </Segment>
      </Segment>
    </Layout>
  );
};

Imports.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
      importCategory: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  setParams: PropTypes.func.isRequired,
  loadImports: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  org: PropTypes.string.isRequired,
  theme: PropTypes.shape({}).isRequired,
  imports: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      category: PropTypes.string,
      created_date: PropTypes.date,
      status: PropTypes.string,
      delimiter: PropTypes.string,
      file: PropTypes.string,
    }),
  ).isRequired,
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  sidebarClose: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  ...WithPopupsProps,
};

const mapStateToProps = (state) => {
  return {
    isFetching: isCommiting(IMPORTS_LIST)(state) || false,
    total: getListTotal(IMPORTS_LIST)(state) || 0,
    imports: listImportsSelector(IMPORTS_LIST)(state),
    org: state.selectedOrg,
    showSidebar: importsShowSidebarSelector(state),
    isFormSubmitting: isSubmitting(FORM_NAME)(state) || false,
  };
};

const mapDispatchToProps = {
  setParams: initializeOrSetListParams,
  loadImports: loadImportsList,
  toggleSidebar: toggleSidebar,
  sidebarClose: sidebarClose,
  submitForm: () => submit(FORM_NAME),
  resetForm: () => reset(FORM_NAME),
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(withTheme(Imports));
