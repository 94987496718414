import { __, getProp } from "../../utilities";
import { connect } from "react-redux";
import {
  fetchCodelistOrderStatus,
  fetchOrderDetail,
  setActiveOrderId,
} from "../Orders/actions";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { format } from "date-fns";
import { getOrderByIdSelector } from "../Orders/selectors";
import { orderItemPropTypes } from "./OrderItem";
import { rem } from "polished";
import { saleChanellsListSelector } from "../SaleChannels/selectors";
import Loader from "@sportnet/ui/Loader";
import OrderSummary from "../../components/OrderSummary";
import Products from "./Products";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  font-family: sans-serif;
  font-size: ${rem(10)};
`;

const OrderId = styled.div`
  font-size: ${rem(22)};
  margin: ${rem(10)} 0;
`;

const LayoutTable = styled.table`
  width: 100%;
`;

const InfoRow = styled.div``;

const Header = styled.div`
  font-size: ${rem(18)};
  margin: ${rem(5)} 0;
  font-weight: bold;
`;
const Body = styled.div``;

const SupplierWrapper = styled.div``;

const LayoutTd = styled.td`
  width: 50%;
`;

const TopCenteredTd = styled(LayoutTd)`
  vertical-align: top;
`;

const Spacer = styled.div`
  ${({ height }) => css`
    height: ${rem(height || 16)};
  `};
`;

const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class OrderPrint extends PureComponent {
  async componentDidMount() {
    const {
      dispatch,
      orderStatuses,
      match: {
        params: { order_id: orderId, appspace },
      },
    } = this.props;
    dispatch(setActiveOrderId(orderId));
    try {
      await Promise.all([
        orderStatuses.length === 0 && dispatch(fetchCodelistOrderStatus()),
        dispatch(fetchSaleChannelsList(appspace)),
        dispatch(fetchOrderDetail(orderId)),
      ]);
      setTimeout(() => {
        window.print();
      }, 0);
    } catch (e) {
      alert(__("Objednávku sa nepodarilo nájsť alebo k nej nemáte prístup"));
      this.props.history.push(`/admin/${appspace}/orders`);
    }
  }

  getStatusObj(orderStatuses, status) {
    return orderStatuses.find((item) => item.value === status) || {};
  }

  getSaleChannelLabel(id) {
    if (!id) return null;
    const channel = this.props.saleChannels.find((i) => {
      return i._id === id;
    });
    if (channel) {
      return channel.name;
    }
    return id;
  }

  renderPacketeryAddress = (address) => {
    return (
      <div>
        <div>
          <b>{__("Zásielkovňa")}</b>
        </div>
        {address.place}
        <div>
          {address.street && address.street}
          {address.zip && ` ${address.zip}`}
          {address.city && ` ${address.city}`}
          {address.country && ` ${address.country}`}
        </div>
      </div>
    );
  };

  renderAddress(address, delivery) {
    if (delivery && delivery.type === "ZASIELKOVNA" && delivery.payload) {
      return this.renderPacketeryAddress(delivery.payload);
    }
    return (
      <div>
        {address.company}
        {address.person && <div>{address.person}</div>}
        <div>
          {address.street && address.street}
          {address.number && ` ${address.number} ,`}
          {address.zip && ` ${address.zip}`}
          {address.city && ` ${address.city}`}
        </div>
      </div>
    );
  }

  renderContact(contact) {
    return (
      <div>
        {contact.email && (
          <div>
            <b>{__("Email:")}</b>
            &nbsp;
            {contact.email}
          </div>
        )}
        {contact.phone && (
          <div>
            <b>{__("Telefón:")}</b>
            &nbsp;
            {contact.phone}
          </div>
        )}
      </div>
    );
  }

  render() {
    if (
      !this.props.detail ||
      (this.props.detail.isFetching && !this.props.detail._id)
    ) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    const {
      detail,
      detail: {
        _id: oderId,
        delivery,
        delivery_address: deliveryAddress,
        invoice_address: invoiceAddress,
        payment,
        status,
        note,
        supplier,
        supplier: { invoice_address: supplier_address = {} },
        items,
        summary,
        created_date: createdDate,
        status_log: statusLog = [],
        invoicenr,
        business_id: businessId,
        vat_nr: vatNr,
        paid_date: paidDate,
        saleschannel,
        voucher,
      },
      orderStatuses,
    } = this.props;

    const statusObj = this.getStatusObj(orderStatuses, status);
    const statusLabel = getProp(statusObj, ["label"], status);
    const reason = getProp(statusLog, [0, "reason"]);

    return (
      <Wrapper>
        <LayoutTable>
          <tbody>
            <tr>
              <td colSpan={2}>
                <OrderId>
                  {__("Objednávka ")}
                  <strong>{oderId}</strong>
                </OrderId>
              </td>
            </tr>
            <tr>
              <LayoutTd>
                <InfoRow>
                  <strong>{__("Stav: ")}</strong>
                  {statusLabel}
                  {reason && ` (${reason})`}
                </InfoRow>
                <InfoRow>
                  <strong>{__("Dátum vytvorenia: ")}</strong>
                  {format(new Date(createdDate), "dd.MM.yyyy HH:mm")}
                </InfoRow>
                {saleschannel && (
                  <InfoRow>
                    <strong>{__("Predajný kanál: ")}</strong>
                    {this.getSaleChannelLabel(saleschannel)}
                  </InfoRow>
                )}
                {invoicenr && (
                  <InfoRow>
                    <strong>{__("Číslo faktúry: ")}</strong>
                    {invoicenr}
                  </InfoRow>
                )}
              </LayoutTd>
              <LayoutTd>
                <SupplierWrapper>
                  <Body>
                    <b>{supplier.name}</b>
                    {supplier.contact_person && (
                      <div>{supplier.contact_person}</div>
                    )}
                    <div>
                      {supplier_address.street && supplier_address.street}
                      {supplier_address.number &&
                        ` ${supplier_address.number} ,`}
                      {supplier_address.zip && ` ${supplier_address.zip}`}
                      {supplier_address.city && ` ${supplier_address.city}`}
                    </div>
                    {supplier.business_id && (
                      <div>
                        <b>IČO:</b>
                        &nbsp;
                        {supplier.business_id}
                      </div>
                    )}
                    {supplier.vat_nr && (
                      <div>
                        <b>DIČ/IČ DPH:</b>
                        &nbsp;
                        {supplier.vat_nr}
                      </div>
                    )}
                    {this.renderContact(supplier)}
                  </Body>
                </SupplierWrapper>
              </LayoutTd>
            </tr>
          </tbody>
        </LayoutTable>
        <Spacer height={10} />
        <LayoutTable>
          <tbody>
            <tr>
              <TopCenteredTd>
                <div>
                  <Header>{__("Odberateľ")}</Header>
                  <Body>
                    {this.renderAddress(invoiceAddress)}
                    {businessId && (
                      <div>
                        <b>IČO:</b>
                        &nbsp;
                        {businessId}
                      </div>
                    )}
                    {vatNr && (
                      <div>
                        <b>DIČ/IČ DPH:</b>
                        &nbsp;
                        {vatNr}
                      </div>
                    )}
                    {this.renderContact(detail)}
                  </Body>
                </div>
              </TopCenteredTd>
              <TopCenteredTd>
                <div>
                  <Header>{__("Dodacia adresa")}</Header>
                  <Body>
                    {this.renderAddress(deliveryAddress, delivery)}
                    {this.renderContact(detail)}
                  </Body>
                </div>
              </TopCenteredTd>
            </tr>
          </tbody>
        </LayoutTable>
        <Spacer height={10} />
        <LayoutTable>
          <tbody>
            <tr>
              <TopCenteredTd>
                <div>
                  <InfoRow>
                    <strong>{__("Spôsob doručenia: ")}</strong>
                    {delivery.name}
                  </InfoRow>
                  <InfoRow>
                    <strong>{__("Spôsob platby: ")}</strong>
                    {payment.name}
                  </InfoRow>
                  <InfoRow>
                    <strong>{__("Uhradené: ")}</strong>
                    {paidDate
                      ? format(new Date(createdDate), "dd.MM.yyyy HH:mm")
                      : __("Nie")}
                  </InfoRow>
                  {note && (
                    <InfoRow>
                      <strong>{__("Poznámka:")}</strong>
                      {note}
                    </InfoRow>
                  )}
                </div>
              </TopCenteredTd>
            </tr>
          </tbody>
        </LayoutTable>
        <Spacer height={10} />
        <Products
          items={[
            ...items,
            ...(voucher
              ? [
                  {
                    item_id: voucher._id,
                    item_name: __("Voucher"),
                    totalprice: {
                      amount: 0,
                      amount_vat_excl: 0,
                    },
                    quantity: 1,
                    variant: {
                      code: voucher._id,
                      unitprice: {
                        amount: 0,
                        amount_vat_excl: 0,
                      },
                    },
                  },
                ]
              : []),
          ]}
        />
        <Spacer height={10} />
        {summary && <OrderSummary summary={summary} />}
      </Wrapper>
    );
  }
}

const AddressProptypes = {
  company: PropTypes.string,
  person: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
};

OrderPrint.propTypes = {
  detail: PropTypes.shape({
    paid_date: PropTypes.string,
    note: PropTypes.string,
    saleschannel: PropTypes.string,
    invoicenr: PropTypes.string,
    business_id: PropTypes.string,
    vat_nr: PropTypes.string,
    voucher: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    isFetching: PropTypes.bool,
    shipment_id: PropTypes.string,
    shipment_link: PropTypes.string,
    _id: PropTypes.number,
    delivery: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    delivery_address: PropTypes.shape({
      ...AddressProptypes,
    }),
    invoice_address: PropTypes.shape({
      ...AddressProptypes,
    }),
    payment: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    status: PropTypes.string,
    supplier: PropTypes.shape({
      name: PropTypes.string,
      vat_nr: PropTypes.string,
      business_id: PropTypes.string,
      legal_form: PropTypes.string,
      phone: PropTypes.string,
      contact_person: PropTypes.string,
      email: PropTypes.string,
      invoice_address: PropTypes.shape({
        ...AddressProptypes,
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...orderItemPropTypes,
      }),
    ),
    summary: PropTypes.shape({
      taxRates: PropTypes.arrayOf(
        PropTypes.shape({
          base: PropTypes.number,
          tax: PropTypes.number,
          vatRate: PropTypes.number,
        }),
      ),
      totalBase: PropTypes.number,
      total: PropTypes.number,
      tprice: PropTypes.number,
      tpriceBase: PropTypes.number,
      currency: PropTypes.string,
      paidbycredit: PropTypes.number,
      paidbyother: PropTypes.number,
      pricetopay: PropTypes.number,
    }),
    created_date: PropTypes.string,
    status_log: PropTypes.arrayOf(
      PropTypes.shape({
        change_date: PropTypes.string,
        old_status: PropTypes.string,
        new_status: PropTypes.string,
        admin_user: PropTypes.shape({
          display_name: PropTypes.string,
        }),
        auth_user: PropTypes.shape({
          display_name: PropTypes.string,
        }),
      }),
    ),
    log: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        message: PropTypes.string,
        payload: PropTypes.object,
      }),
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  orderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
      payload: PropTypes.shape({
        color: PropTypes.string,
      }),
    }),
  ).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      order_id: PropTypes.string,
      appspace: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  saleChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

OrderPrint.defaultProps = {
  detail: null,
};

const mapStateToProps = (state, ownProps) => {
  const { order_id: orderId } = ownProps.match.params;
  const {
    purchase: { orderStatuses },
    userAcl,
  } = state;
  return {
    detail: getOrderByIdSelector(state, orderId),
    orderStatuses: orderStatuses.codelist,
    acl: userAcl.data,
    saleChannels: saleChanellsListSelector(state),
  };
};

export default connect(mapStateToProps)(OrderPrint);
