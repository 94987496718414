import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const importDetailDomainSelector = state => {
    return state.importDetail
}

export const importDetailSelector = createSelector(
    importDetailDomainSelector,
    //substate => getProp(substate, ['data'], {}),
    substate => substate.data
);

export const importDetailIsFetchingSelector = createSelector(
    importDetailDomainSelector,
    substate => getProp(substate, ['isFetching'], true),
);