import OrderItem, { orderItemPropTypes } from './OrderItem';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media print {
    page-break-inside: auto;
  }
`;

class OrderItemList extends PureComponent {
  renderItem(item) {
    return <OrderItem key={item.item_id} item={item} />;
  }

  render() {
    const { items } = this.props;
    return <Wrapper>{items.map(this.renderItem)}</Wrapper>;
  }
}

OrderItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...orderItemPropTypes,
    }),
  ).isRequired,
};

export default OrderItemList;
