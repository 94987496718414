import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import { RECEIPT_ORDERS_LIST } from './constants';
import { normalizeEntities } from '../../utilities';
import { updateEntities } from '../App/actions';
import apiEshop from '../../service/apiEshop';

export const loadOrders = parameters => {
  return async (dispatch, getState) => {
    const { selectedOrg: appSpace } = getState();
    const params = {};

    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }

    if (parameters.offset || parameters.offset === 0) {
      params.offset = parameters.offset;
    }

    if (parameters.q) {
      params.q = parameters.q;
    }

    if (parameters.statuses && parameters.statuses.length) {
      params.statuses = parameters.statuses;
    }

    if (parameters.saleschannels && parameters.saleschannels.length) {
      params.saleschannels = parameters.saleschannels;
    }

    if ('receiptReady' in parameters) {
      params.receiptReady = parameters.receiptReady;
    }

    if (parameters.paid === 'true') {
      params.paid = true;
    } else if (parameters.paid === 'false') {
      params.paid = false;
    }

    if (parameters.paidOption && parameters.paidOption.length) {
      if (parameters.paidOption.includes('paidbycredit')) {
        params.paidbycredit = true;
      }
      if (parameters.paidOption.includes('paidbyother')) {
        params.paidbyother = true;
      }
    }

    if (parameters.createdDate) {
      params.createdDate = parameters.createdDate;
    }

    const { orders, total, limit, offset } = await apiEshop.adminListOrders(
      appSpace,
      params,
    );
    const { entities, results } = normalizeEntities('orders', orders);
    dispatch(updateEntities(entities));
    return {
      total,
      limit,
      offset,
      results,
    };
  };
};

export const loadReceiptOrdersList = () => {
  return (dispatch, getState) => {
    const parameters = getListParameters(RECEIPT_ORDERS_LIST)(getState());
    return dispatch(
      commit.action({
        listName: RECEIPT_ORDERS_LIST,
        load: async () => {
          try {
            const { results, limit, offset, total } = await dispatch(
              loadOrders({
                ...parameters,
                receiptReady: true,
              }),
            );
            return {
              total,
              limit,
              offset,
              results,
            };
          } catch (e) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
