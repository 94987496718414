import {
  AUTH_USER_AUTHORIZED,
  AUTH_USER_UNAUTHORIZED,
  CLEAR_ACL,
  RECEIVE_ACL_ERROR,
  RECEIVE_ACL_SUCCESS,
  REMOVE_ACTIVE_APPSPACE,
  REQUEST_ACL,
  SET_ACTIVE_APPSPACE,
  SET_APPLICATION_INFO,
  SET_APPS,
  SET_APPSPACES,
  SET_BREADCRUMBS,
  SET_SELECTED_ORG,
  TOGGLE_SIDEBAR,
} from "./constants";
import { getProp } from "@sportnet/utilities";

const initialState = null;

const selectedOrg = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ORG:
      return action.payload;
    case REMOVE_ACTIVE_APPSPACE:
      return initialState;
    default:
      return state;
  }
};

const initialAclState = {
  data: null,
  error: null,
  isFetching: false,
  authUser: null,
};

export const userAcl = (state = initialAclState, action) => {
  switch (action.type) {
    case REQUEST_ACL:
      return { ...state, isFetching: true };
    case RECEIVE_ACL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isFetching: false,
      };
    case RECEIVE_ACL_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case CLEAR_ACL:
      return {
        ...state,
        data: null,
        error: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const applicationReducer = (
  state = {
    data: null,
    breadcrumbs: [],
    organization: null,
    sidebarVisible: false,
    apps: [],
    appspaces: [],
  },
  action,
) => {
  switch (action.type) {
    case AUTH_USER_AUTHORIZED:
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    case AUTH_USER_UNAUTHORIZED:
      return {
        ...state,
        authUser: {},
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarVisible: !state.sidebarVisible,
      };
    case SET_ACTIVE_APPSPACE:
      return {
        ...state,
        organization: action.payload.data,
      };
    case REMOVE_ACTIVE_APPSPACE:
      return {
        ...state,
        organization: null,
      };
    case SET_APPLICATION_INFO:
      return {
        ...state,
        data: action.payload.info,
      };
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload.breadcrumbs,
      };
    case SET_APPS:
      return {
        ...state,
        apps: action.payload.apps,
      };
    case SET_APPSPACES:
      return {
        ...state,
        appspaces: action.payload.appspaces,
      };

    default:
      return state;
  }
};

export default selectedOrg;

export const entitiesReducer = (
  state = {
    requests: {},
    orders: {},
    codelists: {},
  },
  action,
) => {
  if (action.payload && action.payload.entities) {
    return Object.keys(action.payload.entities).reduce(
      (acc, entity) => {
        acc[entity] = Object.keys(action.payload.entities[entity]).reduce(
          (innerAcc, id) => {
            innerAcc[id] = action.payload.entities[entity][id];
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};
