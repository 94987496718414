import {
  RECEIVE_CATALOGS_FAILURE,
  RECEIVE_CATALOGS_SUCCESS,
  RECEIVE_CATALOG_SUCCESS,
  REQUEST_CATALOGS,
} from './constants';
import apiEshop from '../../service/apiEshop';

const requestCatalogs = () => ({
  type: REQUEST_CATALOGS,
});

const receiveCatalogsSuccess = data => ({
  type: RECEIVE_CATALOGS_SUCCESS,
  payload: {
    data,
  },
});

const receiveCatalogsFailure = error => ({
  type: RECEIVE_CATALOGS_FAILURE,
  payload: {
    error,
  },
});

const receiveCatalogSuccess = () => ({
  type: RECEIVE_CATALOG_SUCCESS,
});

export const fetchCatalogs = appspace => {
  return async dispatch => {
    dispatch(requestCatalogs());
    try {
      const res = await apiEshop.adminGetListings(appspace);
      dispatch(receiveCatalogsSuccess(res.listings));
    } catch (e) {
      dispatch(receiveCatalogsFailure(e.description || e));
    }
  };
};

export const postCatalog = (appspace, data) => {
  return async dispatch => {
    dispatch(requestCatalogs());
    try {
      await apiEshop.adminCreateListing(appspace, {
        data,
      });
      dispatch(receiveCatalogSuccess());
    } catch (e) {
      dispatch(receiveCatalogsFailure(e.description || e));
    }
  };
};

export const editCatalog = (appspace, id, data) => {
  return async dispatch => {
    dispatch(requestCatalogs());
    try {
      await apiEshop.adminUpdateListing(id, appspace, {
        data,
      });
      dispatch(receiveCatalogSuccess());
    } catch (e) {
      dispatch(receiveCatalogsFailure(e.description || e));
    }
  };
};

export const removeCatalog = (appspace, id) => {
  return async dispatch => {
    dispatch(requestCatalogs());
    try {
      await apiEshop.adminDeleteListing(id, appspace);
      dispatch(receiveCatalogSuccess());
    } catch (e) {
      dispatch(receiveCatalogsFailure(e.description || e));
    }
  };
};
