import { __ } from "../../utilities";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import Tabber from "@sportnet/ui/Tabber";

const Tabs = ({
  history,
  location: { pathname },
  match: {
    params: { salesChannelId, appspace },
  },
}) => {
  const tabs = {
    [`/admin/${appspace}/sale-channels/${salesChannelId}`]: {
      label: __("Všeobecné nastavenia"),
    },
  };

  if (salesChannelId !== "new") {
    tabs[`/admin/${appspace}/sale-channels/${salesChannelId}/domain`] = {
      label: __("Nastavenie domény"),
    };
  }

  if (!(pathname in tabs)) {
    return null;
  }

  return (
    <Tabber
      active={tabs[pathname].label}
      onClickItem={(itemName) => {
        const nextUrl = Object.keys(tabs).find(
          (t) => tabs[t].label === itemName,
        );
        if (nextUrl) {
          history.push(nextUrl);
        }
      }}
      items={Object.keys(tabs).map((key) => tabs[key].label)}
    />
  );
};

Tabs.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      salesChannelId: PropTypes.string.isRequired,
      appspace: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Tabs);
