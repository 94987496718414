const namespace = 'Products';

export const REQUEST_PRODUCTS = `${namespace}/REQUEST_PRODUCTS`;
export const RECEIVE_PRODUCTS_SUCCESS = `${namespace}/RECEIVE_PRODUCTS_SUCCESS`;
export const RECEIVE_PRODUCTS_ERROR = `${namespace}/RECEIVE_PRODUCTS_ERROR`;
export const SET_ACTIVE_PRODUCT_ID = `${namespace}/SET_ACTIVE_PRODUCT_ID`;
export const REQUEST_PRODUCT = `${namespace}/REQUEST_PRODUCT`;
export const RECEIVE_PRODUCT_SUCCESS = `${namespace}/RECEIVE_PRODUCT_SUCCESS`;
export const SET_PARAMETER = `${namespace}/SET_PARAMETER`;
export const SET_PARAMETERS = `${namespace}/SET_PARAMETERS`;
