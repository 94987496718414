import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, formatPrice } from "../../../utilities";
import { connect } from "react-redux";
import { routerParametersDefinition } from "../../App/definitions";
import { useAuth } from "@sportnet/auth-react";
import Api from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";

const Delivery = ({ history, dispatch }) => {
  const [deliveryOptions, setDeliveryOptions] = React.useState([]);
  const { ppo } = useAuth();

  const handleGetSupplierDeliveryOptions = React.useCallback(() => {
    Api.adminGetSupplierDeliveryOptions(ppo)
      .then((response) => {
        setDeliveryOptions(response.items);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    handleGetSupplierDeliveryOptions();
  }, [handleGetSupplierDeliveryOptions]);

  const handleCreateNewDelivery = () => {
    history.push(`/admin/${ppo}/delivery-options/new`);
  };

  const handleEditDelivery = (id) => {
    history.push(`/admin/${ppo}/delivery-options/${id}`);
  };

  const renderOption = (option, index) => {
    const {
      type,
      name,
      price: { amount, currency },
      creditpay,
    } = option;
    return (
      <Tr
        key={type}
        onClick={() => handleEditDelivery(index)}
        style={{ cursor: "pointer" }}
      >
        <Td>{type}</Td>
        <Td xs={12} md={3}>
          {name}
        </Td>
        <Td xs={12} md={3}>
          {creditpay ? "Áno" : "Nie"}
        </Td>
        <Td xs={12} md={3}>
          {formatPrice(amount, currency)}
        </Td>
      </Tr>
    );
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Spôsoby dopravy")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button primary onClick={handleCreateNewDelivery}>
              {__("Pridať spôsob dopravy")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Spôsoby dopravy")} />
      <Segment noBottomGutter>
        <Segment raised>
          <Table>
            <Thead>
              <Tr>
                <Th>{__("Typ")}</Th>
                <Th>{__("Názov dopravy")}</Th>
                <Th>{__("Možná úhrada kreditom")}</Th>
                <Th>{__("Cena s DPH")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {deliveryOptions && deliveryOptions.map(renderOption)}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

Delivery.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: routerParametersDefinition,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Delivery);
