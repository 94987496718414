import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const saleChannelsCategoriesDomainSelector = state =>
  state.saleChannelsCategories;

export const saleChanellsCategoriesListSelector = createSelector(
  saleChannelsCategoriesDomainSelector,
  substate => getProp(substate, ['data'], []),
);

export const saleChanellsCategoriesIsFetchingSelector = createSelector(
  saleChannelsCategoriesDomainSelector,
  substate => getProp(substate, ['isFetching'], true),
);
