class Api {

  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split("&").forEach(function (part) {
        let item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters)
          .forEach(function(parameterName) {
            const parameter = parameters.$queryParameters[parameterName];
            queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch(transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

   /**
    * HTTP Request
    * @method
    * @param {string} method - http method
    * @param {string} url - url to do request
    * @param {object} body - body parameters / object
    * @param {object} headers - header parameters
    * @param {object} queryParameters - querystring parameters
    */
  request(method, url, body, headers, queryParameters, form, checkFor401 = true) {
    const queryParams = queryParameters && Object.keys(queryParameters).length ? this.serializeQueryParams(queryParameters) : null ;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
        this.headers.forEach(h => {
            headers.append(h.name, h.value);
        });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
  * Set base url
  * @method
  * @param {string} baseUrl
  */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
  * Set Headers
  * @method
  * @param headers - array of headers
  */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
  * Redirects a user to a given url
  * @method
  * @param {string} url
  */
  redirect(url) {
    window.location = url;
  }

      /**
      * Set Token
      * @method
      * @param {string} token - token's value
      */
      setToken(token) {
        this.token = token;
      }


    /**
    * Set Auth headers
    * @method
    * @param {object} headerParams - headers object
    */
    appendAuthHeaders(headerParams) {
      const headers = new Headers(headerParams);
      if (this.token) {
         headers.append('Authorization', `Bearer ${this.token}`);
      }
      return headers;
    }

    /**
     * All orders
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.updatedAfter - Eshop RESTful API specification.

     */
     appListOrders(parameters = {}) {
      let path = '/app/orders';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['updatedAfter'] !== 'undefined'){
              queryParameters['updatedAfter'] = parameters['updatedAfter'];
            }

            queryParameters['updatedAfter'] = this.transformParameter(queryParameters['updatedAfter']);
        
        


            if(typeof parameters['updatedAfter'] === 'undefined') {
              throw Error('Missing required parameter: updatedAfter');
            }
     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All receipts
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.statuses - Eshop RESTful API specification.

     */
     appListReceipts(parameters = {}) {
      let path = '/app/receipts';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     publicListSaleschannels(appSpace,parameters = {}) {
      let path = '/saleschannels/{appSpace}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get eshop's saleschannel settings (saleschannel detail)
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelGetSettings(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Fetch URL map for Given URL
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} urlPath - Eshop RESTful API specification.

     */
     getBaseContentByUrl(urlPath,parameters = {}) {
      let path = '/urlmap/{url_path}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{url_path}', urlPath);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Fetch URL map for Given URL
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} urlPath - Eshop RESTful API specification.

     */
     getContentByUrl(appSpace,saleschannel,urlPath,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/urlmap/{url_path}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{url_path}', urlPath);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get listings for eshop's saleschannel
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelGetListings(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/listings';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * https://support.google.com/google-ads/answer/6053288
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelProductsFeedGoogleAds(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/feed/google-ads';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};


        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * https://developers.facebook.com/docs/marketing-api/dynamic-product-ads/product-catalog#create-product-catalog
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelProductsFeedFacebook(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/feed/facebook';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};


        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get list of products based on varios filters. Multiple search parameter values are separated with pipe.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.businessNr - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.tags - Eshop RESTful API specification.

       * @param {} parameters.listings - saleschannel listings !
       * @param {} parameters.brands - Eshop RESTful API specification.

       * @param {} parameters.categories - saleschannel categories !
       * @param {} parameters.exclude - Eshop RESTful API specification.

       * @param {} parameters.sorter - Sorter v tvare `field` (ASC) a `-field` (DESC)
       * @param {} parameters.params - Vyhladavac podla parametrov v tvare napriklad Farba:cervena|Farba:zelena|Velkost:M. V tomto pripade system zgrupy podla nazvu parametra a medzi hodnotami rovnakeho parametra hlada OR a medzi roznymi aprametrami je AND
     */
     saleschannelSearchProducts(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/products';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     

            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['tags'] !== 'undefined'){
              queryParameters['tags'] = parameters['tags'];
            }

            queryParameters['tags'] = this.transformParameter(queryParameters['tags']);
        
        


     

            if(typeof parameters['listings'] !== 'undefined'){
              queryParameters['listings'] = parameters['listings'];
            }

            queryParameters['listings'] = this.transformParameter(queryParameters['listings']);
        
        


     

            if(typeof parameters['brands'] !== 'undefined'){
              queryParameters['brands'] = parameters['brands'];
            }

            queryParameters['brands'] = this.transformParameter(queryParameters['brands']);
        
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     

            if(typeof parameters['exclude'] !== 'undefined'){
              queryParameters['exclude'] = parameters['exclude'];
            }

            queryParameters['exclude'] = this.transformParameter(queryParameters['exclude']);
        
        


     
              queryParameters['sorter'] = "-categoryPriority.priority";

            if(typeof parameters['sorter'] !== 'undefined'){
              queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);
        
        


     

            if(typeof parameters['params'] !== 'undefined'){
              queryParameters['params'] = parameters['params'];
            }

            queryParameters['params'] = this.transformParameter(queryParameters['params']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} productId - Product id
       * @param {} parameters.businessNr - Eshop RESTful API specification.

     */
     saleschannelGetProductDetail(appSpace,saleschannel,productId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/products/{product_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{product_id}', productId);
        


     

            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} productId - Product id
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.businessNr - Eshop RESTful API specification.

     */
     saleschannelGetRelatedProducts(appSpace,saleschannel,productId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/products/{product_id}/related';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{product_id}', productId);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelGetRootCategories(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} categoryId - Eshop RESTful API specification.

     */
     saleschannelGetCategories(appSpace,saleschannel,categoryId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{category_id}', categoryId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} categoryId - Eshop RESTful API specification.

     */
     saleschannelGetCategorySearchParams(appSpace,saleschannel,categoryId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/categories/{category_id}/searchparams';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{category_id}', categoryId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     saleschannelGetCategoriesTree(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/categories-tree';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} cartId - Cart identifier
       * @param {} voucherId - Voucher identifier
     */
     saleschannelCheckVoucher(appSpace,saleschannel,cartId,voucherId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/cart/{cart_id}/voucher/{voucher_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{cart_id}', cartId);
        


     
        
          path = path.replace('{voucher_id}', voucherId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} cartId - Cart identifier
       * @param {} voucherId - Voucher identifier
     */
     saleschannelRemoveVoucher(appSpace,saleschannel,cartId,voucherId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/cart/{cart_id}/voucher/{voucher_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{cart_id}', cartId);
        


     
        
          path = path.replace('{voucher_id}', voucherId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} cartId - Cart identifier
     */
     saleschannelGetPurchaseOptions(appSpace,saleschannel,cartId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/cart/{cart_id}/purchase';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{cart_id}', cartId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Create new order
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} cartId - Cart identifier
       * @param {} parameters.data - Order details
     */
     saleschannelCommitPurchase(appSpace,saleschannel,cartId,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/cart/{cart_id}/purchase';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
          path = path.replace('{cart_id}', cartId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.categories - Filter brands only for this category and its subcategories
       * @param {} parameters.active - Filter brands only for active products
     */
     saleschannelGetBrands(appSpace,saleschannel,parameters = {}) {
      let path = '/saleschannel/{appSpace}/{saleschannel}/brands';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     

            if(typeof parameters['active'] !== 'undefined'){
              queryParameters['active'] = parameters['active'];
            }

            queryParameters['active'] = this.transformParameter(queryParameters['active']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} downloadId - Eshop RESTful API specification.

     */
     getDownloaderFile(downloadId,parameters = {}) {
      let path = '/downloader/{download_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};


        
          path = path.replace('{download_id}', downloadId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.year - Rok cerpania kreditov
     */
     publicCreditsReport(parameters = {}) {
      let path = '/reports/public/credits';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');


            if(typeof parameters['year'] !== 'undefined'){
              queryParameters['year'] = parameters['year'];
            }

            queryParameters['year'] = this.transformParameter(queryParameters['year']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

     */
     adminListB2BPartners(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - Request body
     */
     adminCreateNewB2BPartner(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} businessId - ICO partnera
     */
     adminGetB2BPartner(appSpace,businessId,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners/{businessId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{businessId}', businessId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} businessId - ICO partnera
       * @param {} parameters.data - Request body
     */
     adminUpdateB2BPartner(appSpace,businessId,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners/{businessId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{businessId}', businessId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} businessId - ICO partnera
     */
     adminDeleteB2BPartner(appSpace,businessId,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners/{businessId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{businessId}', businessId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} groupId - Group ID
     */
     adminDeleteGroupPartner(appSpace,groupId,parameters = {}) {
      let path = '/admin/{appSpace}/b2b/partners/{groupId}/group';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{groupId}', groupId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.orgRegnr - Eshop RESTful API specification.

       * @param {} parameters.q - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

     */
     adminListCards(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/cards';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     

            if(typeof parameters['orgRegnr'] !== 'undefined'){
              queryParameters['org_regnr'] = parameters['orgRegnr'];
            }

            queryParameters['org_regnr'] = this.transformParameter(queryParameters['org_regnr']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - Request body
     */
     adminCreateNewCard(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/cards';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} cardId - Eshop RESTful API specification.

     */
     adminDeleteCard(appSpace,cardId,parameters = {}) {
      let path = '/admin/{appSpace}/cards/{card_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{card_id}', cardId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminListSaleschannels(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminCreateSaleschannel(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminGetSaleschannelDetail(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateSaleschannel(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminDeleteSaleschannel(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminListPricelevels(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/pricelevels';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - Full list of pricelevels to save to supplier settings
     */
     adminUpdatePricelevels(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/pricelevels';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.redirect - Eshop RESTful API specification.

       * @param {} parameters.operation - Eshop RESTful API specification.

       * @param {} parameters.ordernumber - Eshop RESTful API specification.

       * @param {} parameters.merordernum - Eshop RESTful API specification.

       * @param {} parameters.md - Eshop RESTful API specification.

       * @param {} parameters.prcode - Eshop RESTful API specification.

       * @param {} parameters.srcode - Eshop RESTful API specification.

       * @param {} parameters.resulttext - Eshop RESTful API specification.

       * @param {} parameters.userparam1 - Eshop RESTful API specification.

       * @param {} parameters.addinfo - Eshop RESTful API specification.

       * @param {} parameters.digest - Eshop RESTful API specification.

       * @param {} parameters.digest1 - Eshop RESTful API specification.

     */
     gpwebpayResult(parameters = {}) {
      let path = '/payments/gpwebpay/result';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            if(typeof parameters['redirect'] !== 'undefined'){
              queryParameters['redirect'] = parameters['redirect'];
            }

            queryParameters['redirect'] = this.transformParameter(queryParameters['redirect']);
        
        


     

            if(typeof parameters['operation'] !== 'undefined'){
              queryParameters['OPERATION'] = parameters['operation'];
            }

            queryParameters['OPERATION'] = this.transformParameter(queryParameters['OPERATION']);
        
        


            if(typeof parameters['operation'] === 'undefined') {
              throw Error('Missing required parameter: operation');
            }
     

            if(typeof parameters['ordernumber'] !== 'undefined'){
              queryParameters['ORDERNUMBER'] = parameters['ordernumber'];
            }

            queryParameters['ORDERNUMBER'] = this.transformParameter(queryParameters['ORDERNUMBER']);
        
        


            if(typeof parameters['ordernumber'] === 'undefined') {
              throw Error('Missing required parameter: ordernumber');
            }
     

            if(typeof parameters['merordernum'] !== 'undefined'){
              queryParameters['MERORDERNUM'] = parameters['merordernum'];
            }

            queryParameters['MERORDERNUM'] = this.transformParameter(queryParameters['MERORDERNUM']);
        
        


     

            if(typeof parameters['md'] !== 'undefined'){
              queryParameters['MD'] = parameters['md'];
            }

            queryParameters['MD'] = this.transformParameter(queryParameters['MD']);
        
        


     

            if(typeof parameters['prcode'] !== 'undefined'){
              queryParameters['PRCODE'] = parameters['prcode'];
            }

            queryParameters['PRCODE'] = this.transformParameter(queryParameters['PRCODE']);
        
        


            if(typeof parameters['prcode'] === 'undefined') {
              throw Error('Missing required parameter: prcode');
            }
     

            if(typeof parameters['srcode'] !== 'undefined'){
              queryParameters['SRCODE'] = parameters['srcode'];
            }

            queryParameters['SRCODE'] = this.transformParameter(queryParameters['SRCODE']);
        
        


            if(typeof parameters['srcode'] === 'undefined') {
              throw Error('Missing required parameter: srcode');
            }
     

            if(typeof parameters['resulttext'] !== 'undefined'){
              queryParameters['RESULTTEXT'] = parameters['resulttext'];
            }

            queryParameters['RESULTTEXT'] = this.transformParameter(queryParameters['RESULTTEXT']);
        
        


     

            if(typeof parameters['userparam1'] !== 'undefined'){
              queryParameters['USERPARAM1'] = parameters['userparam1'];
            }

            queryParameters['USERPARAM1'] = this.transformParameter(queryParameters['USERPARAM1']);
        
        


     

            if(typeof parameters['addinfo'] !== 'undefined'){
              queryParameters['ADDINFO'] = parameters['addinfo'];
            }

            queryParameters['ADDINFO'] = this.transformParameter(queryParameters['ADDINFO']);
        
        


     

            if(typeof parameters['digest'] !== 'undefined'){
              queryParameters['DIGEST'] = parameters['digest'];
            }

            queryParameters['DIGEST'] = this.transformParameter(queryParameters['DIGEST']);
        
        


            if(typeof parameters['digest'] === 'undefined') {
              throw Error('Missing required parameter: digest');
            }
     

            if(typeof parameters['digest1'] !== 'undefined'){
              queryParameters['DIGEST1'] = parameters['digest1'];
            }

            queryParameters['DIGEST1'] = this.transformParameter(queryParameters['DIGEST1']);
        
        


            if(typeof parameters['digest1'] === 'undefined') {
              throw Error('Missing required parameter: digest1');
            }
     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.orderNumber - Number of paygate payment
       * @param {} parameters.data - 
     */
     gpwebpayRefundPayment(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/payments/gpwebpay/refund';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     

            if(typeof parameters['orderNumber'] !== 'undefined'){
              queryParameters['order_number'] = parameters['orderNumber'];
            }

            queryParameters['order_number'] = this.transformParameter(queryParameters['order_number']);
        
        


            if(typeof parameters['orderNumber'] === 'undefined') {
              throw Error('Missing required parameter: orderNumber');
            }
     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.purchaseId - Either `purchase_id` or `order_id` is required
       * @param {} parameters.orderId - Either `purchase_id` or `order_id` is required
       * @param {} parameters.callbackUrl - Eshop RESTful API specification.

       * @param {} parameters.redirect - If true, server generates `Location` header
     */
     createPayment(parameters = {}) {
      let path = '/payment';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');


            if(typeof parameters['purchaseId'] !== 'undefined'){
              queryParameters['purchase_id'] = parameters['purchaseId'];
            }

            queryParameters['purchase_id'] = this.transformParameter(queryParameters['purchase_id']);
        
        


     

            if(typeof parameters['orderId'] !== 'undefined'){
              queryParameters['order_id'] = parameters['orderId'];
            }

            queryParameters['order_id'] = this.transformParameter(queryParameters['order_id']);
        
        


     

            if(typeof parameters['callbackUrl'] !== 'undefined'){
              queryParameters['callback_url'] = parameters['callbackUrl'];
            }

            queryParameters['callback_url'] = this.transformParameter(queryParameters['callback_url']);
        
        


     

            if(typeof parameters['redirect'] !== 'undefined'){
              queryParameters['redirect'] = parameters['redirect'];
            }

            queryParameters['redirect'] = this.transformParameter(queryParameters['redirect']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} paymentId - Eshop RESTful API specification.

       * @param {} parameters.test - Run in testing environment
       * @param {} parameters.data - Request body
     */
     paygateGpwebpayRefundPayment(paymentId,parameters = {}) {
      let path = '/paygate/gpwebpay/refund/{payment_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{payment_id}', paymentId);
        


     

            if(typeof parameters['test'] !== 'undefined'){
              queryParameters['test'] = parameters['test'];
            }

            queryParameters['test'] = this.transformParameter(queryParameters['test']);
        
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
     */
     eshopRequestList(orderId,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}/requests';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Vytvorenie nového dotazu pre konkrétnu objednávku.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
       * @param {} parameters.data - Request body
     */
     eshopCreateRequest(orderId,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}/requests';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} requestId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
     */
     eshopRequestDetail(requestId,parameters = {}) {
      let path = '/eshop/user/requests/{request_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{request_id}', requestId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All supplier receipts
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.statuses - Eshop RESTful API specification.

     */
     adminListReceipts(appSpace,parameters = {}) {
      let path = '/superadmin/{appSpace}/receipts';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Detail of receipt
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} receiptId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminReceiptDetail(receiptId,appSpace,parameters = {}) {
      let path = '/superadmin/{appSpace}/receipts/{receipt_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{receipt_id}', receiptId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change receipt status
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} receiptId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminChangeReceiptStatus(receiptId,appSpace,parameters = {}) {
      let path = '/superadmin/{appSpace}/receipts/{receipt_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{receipt_id}', receiptId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All supplier receipts
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.statuses - Eshop RESTful API specification.

     */
     adminListSupplierReceipts(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/receipts';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Vytvorenie nového predpisu pre konkrétne objednávky.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - Request body
     */
     adminCreateReceipt(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/receipts';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Detail of receipt
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} receiptId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminSupplierReceiptDetail(receiptId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/receipts/{receipt_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{receipt_id}', receiptId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All user requests for suppliers
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

     */
     adminListRequests(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/requests';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Detail of user request
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} requestId - Eshop RESTful API specification.

     */
     adminRequestDetail(appSpace,requestId,parameters = {}) {
      let path = '/admin/{appSpace}/requests/{request_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{request_id}', requestId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change status of request
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} requestId - Eshop RESTful API specification.

       * @param {} parameters.data - Status change
     */
     adminChangeRequestStatus(appSpace,requestId,parameters = {}) {
      let path = '/admin/{appSpace}/requests/{request_id}/status';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{request_id}', requestId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All orders for user
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.statuses - Eshop RESTful API specification.

       * @param {} parameters.sorter - Sorter v tvare `field` (ASC) a `-field` (DESC)
     */
     userListOrders(parameters = {}) {
      let path = '/eshop/user/orders';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     
              queryParameters['sorter'] = "-created_date";

            if(typeof parameters['sorter'] !== 'undefined'){
              queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order detail for user
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
     */
     userGetOrderDetail(orderId,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order invoice PDF download ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
     */
     userGetOrderInvoicePdf(orderId,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}/invoice';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order confirmation PDF download ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
     */
     userGetOrderConfirmationPdf(orderId,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}/confirmation';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change order status for user
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} orderId - Eshop RESTful API specification.

       * @param {} status - Eshop RESTful API specification.

       * @param {} parameters.token - Token of order used for accessing order without auth user
       * @param {} parameters.data - 
     */
     userChangeOrderStatus(orderId,status,parameters = {}) {
      let path = '/eshop/user/orders/{order_id}/status/{status}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{order_id}', orderId);
        


     
        
          path = path.replace('{status}', status);
        


     

            if(typeof parameters['token'] !== 'undefined'){
              queryParameters['token'] = parameters['token'];
            }

            queryParameters['token'] = this.transformParameter(queryParameters['token']);
        
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     eshopUserCreditBalance(parameters = {}) {
      let path = '/eshop/user/credits';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} businessNr - Business nr
     */
     eshopUserCheckBusinessNr(businessNr,parameters = {}) {
      let path = '/eshop/user/organization/{businessNr}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{businessNr}', businessNr);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     eshopUserCards(parameters = {}) {
      let path = '/eshop/user/cards';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Import of products from CSV file
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} importCategory - Import category
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

     */
     adminListImports(appSpace,importCategory,parameters = {}) {
      let path = '/admin/{appSpace}/imports/{importCategory}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{importCategory}', importCategory);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Endpoint pre uload CVS suboru pre import produktov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} importCategory - Import category
       * @param {} parameters.data - 
     */
     adminCreateImport(appSpace,importCategory,parameters = {}) {
      let path = '/admin/{appSpace}/imports/{importCategory}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{importCategory}', importCategory);
        


     
        
        

            if(typeof parameters['data'] !== 'undefined'){
                form = parameters['data'];
            }

     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} importId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminImportDetail(importId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/import/{import_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{import_id}', importId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All order items for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.statuses - Eshop RESTful API specification.

       * @param {} parameters.excludeStatuses - Eshop RESTful API specification.

       * @param {} parameters.fromDate - Eshop RESTful API specification.

       * @param {} parameters.toDate - Eshop RESTful API specification.

       * @param {} parameters.format - Eshop RESTful API specification.

     */
     adminExportOrders(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/export';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     

            if(typeof parameters['excludeStatuses'] !== 'undefined'){
              queryParameters['exclude_statuses'] = parameters['excludeStatuses'];
            }

            queryParameters['exclude_statuses'] = this.transformParameter(queryParameters['exclude_statuses']);
        
        


     

            if(typeof parameters['fromDate'] !== 'undefined'){
              queryParameters['from_date'] = parameters['fromDate'];
            }

            queryParameters['from_date'] = this.transformParameter(queryParameters['from_date']);
        
        


     

            if(typeof parameters['toDate'] !== 'undefined'){
              queryParameters['to_date'] = parameters['toDate'];
            }

            queryParameters['to_date'] = this.transformParameter(queryParameters['to_date']);
        
        


     
              queryParameters['format'] = "xlsx";

            if(typeof parameters['format'] !== 'undefined'){
              queryParameters['format'] = parameters['format'];
            }

            queryParameters['format'] = this.transformParameter(queryParameters['format']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All order items for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.statuses - Eshop RESTful API specification.

       * @param {} parameters.excludeStatuses - Eshop RESTful API specification.

       * @param {} parameters.fromDate - Eshop RESTful API specification.

       * @param {} parameters.toDate - Eshop RESTful API specification.

       * @param {} parameters.format - Eshop RESTful API specification.

     */
     adminExportItems(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/export-items';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     

            if(typeof parameters['excludeStatuses'] !== 'undefined'){
              queryParameters['exclude_statuses'] = parameters['excludeStatuses'];
            }

            queryParameters['exclude_statuses'] = this.transformParameter(queryParameters['exclude_statuses']);
        
        


     

            if(typeof parameters['fromDate'] !== 'undefined'){
              queryParameters['from_date'] = parameters['fromDate'];
            }

            queryParameters['from_date'] = this.transformParameter(queryParameters['from_date']);
        
        


     

            if(typeof parameters['toDate'] !== 'undefined'){
              queryParameters['to_date'] = parameters['toDate'];
            }

            queryParameters['to_date'] = this.transformParameter(queryParameters['to_date']);
        
        


     
              queryParameters['format'] = "xlsx";

            if(typeof parameters['format'] !== 'undefined'){
              queryParameters['format'] = parameters['format'];
            }

            queryParameters['format'] = this.transformParameter(queryParameters['format']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All orders for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.statuses - Eshop RESTful API specification.

       * @param {} parameters.saleschannels - Eshop RESTful API specification.

       * @param {} parameters.sorter - Sorter v tvare `field` (ASC) a `-field` (DESC)
       * @param {} parameters.noReceipt - Eshop RESTful API specification.

       * @param {} parameters.receiptReady - Eshop RESTful API specification.

       * @param {} parameters.paid - If `true`, only orders which are paid are filtered, if `false`, only unpaid orders are filtered. If omited, both orders are included in result.

       * @param {} parameters.paidbycredit - If `true`, orders which have at least 1 item paid by credit are filtered.

       * @param {} parameters.paidbyother - If `true`, orders which have at least 1 item not paid by credit are filtered.

       * @param {} parameters.createdDate - Filters order which were created the same day as specified createdDate parameter.

       * @param {} parameters.createdDateFrom - Eshop RESTful API specification.

       * @param {} parameters.createdDateTo - Eshop RESTful API specification.

     */
     adminListOrders(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/orders';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['statuses'] !== 'undefined'){
              queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);
        
        


     

            if(typeof parameters['saleschannels'] !== 'undefined'){
              queryParameters['saleschannels'] = parameters['saleschannels'];
            }

            queryParameters['saleschannels'] = this.transformParameter(queryParameters['saleschannels']);
        
        


     
              queryParameters['sorter'] = "-created_date";

            if(typeof parameters['sorter'] !== 'undefined'){
              queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);
        
        


     

            if(typeof parameters['noReceipt'] !== 'undefined'){
              queryParameters['no_receipt'] = parameters['noReceipt'];
            }

            queryParameters['no_receipt'] = this.transformParameter(queryParameters['no_receipt']);
        
        


     

            if(typeof parameters['receiptReady'] !== 'undefined'){
              queryParameters['receiptReady'] = parameters['receiptReady'];
            }

            queryParameters['receiptReady'] = this.transformParameter(queryParameters['receiptReady']);
        
        


     

            if(typeof parameters['paid'] !== 'undefined'){
              queryParameters['paid'] = parameters['paid'];
            }

            queryParameters['paid'] = this.transformParameter(queryParameters['paid']);
        
        


     

            if(typeof parameters['paidbycredit'] !== 'undefined'){
              queryParameters['paidbycredit'] = parameters['paidbycredit'];
            }

            queryParameters['paidbycredit'] = this.transformParameter(queryParameters['paidbycredit']);
        
        


     

            if(typeof parameters['paidbyother'] !== 'undefined'){
              queryParameters['paidbyother'] = parameters['paidbyother'];
            }

            queryParameters['paidbyother'] = this.transformParameter(queryParameters['paidbyother']);
        
        


     

            if(typeof parameters['createdDate'] !== 'undefined'){
              queryParameters['createdDate'] = parameters['createdDate'];
            }

            queryParameters['createdDate'] = this.transformParameter(queryParameters['createdDate']);
        
        


     

            if(typeof parameters['createdDateFrom'] !== 'undefined'){
              queryParameters['createdDateFrom'] = parameters['createdDateFrom'];
            }

            queryParameters['createdDateFrom'] = this.transformParameter(queryParameters['createdDateFrom']);
        
        


     

            if(typeof parameters['createdDateTo'] !== 'undefined'){
              queryParameters['createdDateTo'] = parameters['createdDateTo'];
            }

            queryParameters['createdDateTo'] = this.transformParameter(queryParameters['createdDateTo']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order detail for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

     */
     adminGetOrderDetail(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order invoice PDF
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

     */
     adminGetOrderInvoicePdf(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/invoice';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get order confirmation PDF
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

     */
     adminGetOrderConfirmationPdf(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/confirmation';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change order status for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

       * @param {} status - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminChangeOrderStatus(appSpace,orderId,status,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/status/{status}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
        
          path = path.replace('{status}', status);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change shipment info for order
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminChangeOrderShipmentInfo(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/shipment-info';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get Packetery Label for Order
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.format - Eshop RESTful API specification.

     */
     adminGetOrderPacketeryLabel(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/packetery-label';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
              queryParameters['format'] = "A6 on A6";

            if(typeof parameters['format'] !== 'undefined'){
              queryParameters['format'] = parameters['format'];
            }

            queryParameters['format'] = this.transformParameter(queryParameters['format']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change payment info for order
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} orderId - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminSetOrderPaid(appSpace,orderId,parameters = {}) {
      let path = '/admin/{appSpace}/orders/{order_id}/paid';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{order_id}', orderId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
     */
     getPurchaseOptions(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}/purchase';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Create new order
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} parameters.data - Order details
     */
     commitPurchase(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}/purchase';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Create new cart and return its metadata
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
     */
     createNewCart(parameters = {}) {
      let path = '/eshop/cart';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get cart items and metadata
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} parameters.cached - Return cached version of the cart (dont compare availabilities / prices to database)
     */
     getCartDetail(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
              queryParameters['cached'] = true;

            if(typeof parameters['cached'] !== 'undefined'){
              queryParameters['cached'] = parameters['cached'];
            }

            queryParameters['cached'] = this.transformParameter(queryParameters['cached']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update cart metadata
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} parameters.data - 
     */
     updateCartMeta(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Add new item to cart
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} parameters.addToExistingItem - If `true`, item with the same product_id and variant_code is updated quantity and payload

       * @param {} parameters.data - 
     */
     addItemToCart(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
              queryParameters['addToExistingItem'] = true;

            if(typeof parameters['addToExistingItem'] !== 'undefined'){
              queryParameters['addToExistingItem'] = parameters['addToExistingItem'];
            }

            queryParameters['addToExistingItem'] = this.transformParameter(queryParameters['addToExistingItem']);
        
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete entire cart. Cart id is destructed
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
     */
     deleteCart(cartId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Change item quantity
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} itemId - Cart item identifier
       * @param {} parameters.data - 
     */
     updateCartItem(cartId,itemId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}/{item_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
        
          path = path.replace('{item_id}', itemId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete cart item
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} cartId - Cart identifier
       * @param {} itemId - Cart item identifier
     */
     deleteCartItem(cartId,itemId,parameters = {}) {
      let path = '/eshop/cart/{cart_id}/{item_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{cart_id}', cartId);
        


     
        
          path = path.replace('{item_id}', itemId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get list of products based on varios filters. Multiple search parameter values are separated with pipe.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.businessNr - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.appSpace - Eshop RESTful API specification.

       * @param {} parameters.tags - Eshop RESTful API specification.

       * @param {} parameters.listings - Eshop RESTful API specification.

       * @param {} parameters.brands - Eshop RESTful API specification.

       * @param {} parameters.categories - Eshop RESTful API specification.

       * @param {} parameters.exclude - Eshop RESTful API specification.

       * @param {} parameters.sorter - Sorter v tvare `field` (ASC) a `-field` (DESC)
       * @param {} parameters.params - Vyhladavac podla parametrov v tvare napriklad Farba:cervena|Farba:zelena|Velkost:M. V tomto pripade system zgrupy podla nazvu parametra a medzi hodnotami rovnakeho parametra hlada OR a medzi roznymi aprametrami je AND
     */
     searchProducts(parameters = {}) {
      let path = '/products';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['appSpace'] !== 'undefined'){
              queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);
        
        


     

            if(typeof parameters['tags'] !== 'undefined'){
              queryParameters['tags'] = parameters['tags'];
            }

            queryParameters['tags'] = this.transformParameter(queryParameters['tags']);
        
        


     

            if(typeof parameters['listings'] !== 'undefined'){
              queryParameters['listings'] = parameters['listings'];
            }

            queryParameters['listings'] = this.transformParameter(queryParameters['listings']);
        
        


     

            if(typeof parameters['brands'] !== 'undefined'){
              queryParameters['brands'] = parameters['brands'];
            }

            queryParameters['brands'] = this.transformParameter(queryParameters['brands']);
        
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     

            if(typeof parameters['exclude'] !== 'undefined'){
              queryParameters['exclude'] = parameters['exclude'];
            }

            queryParameters['exclude'] = this.transformParameter(queryParameters['exclude']);
        
        


     
              queryParameters['sorter'] = "-created_date";

            if(typeof parameters['sorter'] !== 'undefined'){
              queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);
        
        


     

            if(typeof parameters['params'] !== 'undefined'){
              queryParameters['params'] = parameters['params'];
            }

            queryParameters['params'] = this.transformParameter(queryParameters['params']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - Product id
       * @param {} parameters.businessNr - Eshop RESTful API specification.

     */
     getProductDetail(productId,parameters = {}) {
      let path = '/products/{product_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{product_id}', productId);
        


     

            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} productId - Product id
       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.businessNr - Eshop RESTful API specification.

     */
     getRelatedProducts(productId,parameters = {}) {
      let path = '/products/{product_id}/related';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{product_id}', productId);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['businessNr'] !== 'undefined'){
              queryParameters['businessNr'] = parameters['businessNr'];
            }

            queryParameters['businessNr'] = this.transformParameter(queryParameters['businessNr']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     getRootCategories(parameters = {}) {
      let path = '/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

     */
     getCategories(categoryId,parameters = {}) {
      let path = '/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} parameters.appSpace - Eshop RESTful API specification.

     */
     getCategorySearchParams(categoryId,parameters = {}) {
      let path = '/categories/{category_id}/searchparams';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     

            if(typeof parameters['appSpace'] !== 'undefined'){
              queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.appSpace - Filter out categories with no products in specified appSpace
     */
     getCategoriesTree(parameters = {}) {
      let path = '/categories-tree';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');


            if(typeof parameters['appSpace'] !== 'undefined'){
              queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} businessId - Eshop RESTful API specification.

       * @param {} parameters.fromDate - Eshop RESTful API specification.

       * @param {} parameters.toDate - Eshop RESTful API specification.

     */
     exportCreditItems(businessId,parameters = {}) {
      let path = '/export/{business_id}/credit-items';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{business_id}', businessId);
        


     

            if(typeof parameters['fromDate'] !== 'undefined'){
              queryParameters['from_date'] = parameters['fromDate'];
            }

            queryParameters['from_date'] = this.transformParameter(queryParameters['from_date']);
        
        


     

            if(typeof parameters['toDate'] !== 'undefined'){
              queryParameters['to_date'] = parameters['toDate'];
            }

            queryParameters['to_date'] = this.transformParameter(queryParameters['to_date']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Operation access for current user and supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetUser(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/user';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Operation access for current user and appSpace
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetUserAclForSupplier(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/acl';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * All products for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.limit - Eshop RESTful API specification.

       * @param {} parameters.offset - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.brands - Eshop RESTful API specification.

       * @param {} parameters.saleschannels - Eshop RESTful API specification.

       * @param {} parameters.withoutPhoto - Eshop RESTful API specification.

       * @param {} parameters.active - Eshop RESTful API specification.

       * @param {} parameters.smarttags - Eshop RESTful API specification.

       * @param {} parameters.categories - Eshop RESTful API specification.

     */
     adminListProducts(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/products';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
              queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);
        
        


     

            if(typeof parameters['offset'] !== 'undefined'){
              queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);
        
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['brands'] !== 'undefined'){
              queryParameters['brands'] = parameters['brands'];
            }

            queryParameters['brands'] = this.transformParameter(queryParameters['brands']);
        
        


     

            if(typeof parameters['saleschannels'] !== 'undefined'){
              queryParameters['saleschannels'] = parameters['saleschannels'];
            }

            queryParameters['saleschannels'] = this.transformParameter(queryParameters['saleschannels']);
        
        


     

            if(typeof parameters['withoutPhoto'] !== 'undefined'){
              queryParameters['withoutPhoto'] = parameters['withoutPhoto'];
            }

            queryParameters['withoutPhoto'] = this.transformParameter(queryParameters['withoutPhoto']);
        
        


     

            if(typeof parameters['active'] !== 'undefined'){
              queryParameters['active'] = parameters['active'];
            }

            queryParameters['active'] = this.transformParameter(queryParameters['active']);
        
        


     

            if(typeof parameters['smarttags'] !== 'undefined'){
              queryParameters['smarttags'] = parameters['smarttags'];
            }

            queryParameters['smarttags'] = this.transformParameter(queryParameters['smarttags']);
        
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Create new product
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminCreateProduct(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/products';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Bulk update products variants
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminProductsVariantsUpdate(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/products-variants';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Perform bulk operation on products variants. Suppported operations are:
  - SET_PRICELEVEL (Sets pricelevel price relative to current price for all product variants)
    - data: { "pricelevel": "string", "multiplier": "number" }
  - DELETE_PRICELEVEL (Deletes pricelevel price for all product variants)
    - data: { "pricelevel": "string" }
  - SET_DISCOUNT (Sets current price as oldPrice (if greater than new calculated price) and calculate current price from provided discount)
    - data: { "discount": "number" }
  - SET_SALESCHANNELS (Sets publication to saleschannels specified in array)
    - data: { "saleschannels": "string[]" }
  - SET_BRAND (Sets products brand)
    - data: { "brand": "string" }
  - SET_PUBLICATION_ACTIVITY (Sets products publication activity)
    - data: { "active": "boolean" }

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.q - Search string. It is converted to ascii, splitted by space. Returned items is all, that contains all of the search strings.
       * @param {} parameters.brands - Eshop RESTful API specification.

       * @param {} parameters.saleschannels - Eshop RESTful API specification.

       * @param {} parameters.withoutPhoto - Eshop RESTful API specification.

       * @param {} parameters.smarttags - Eshop RESTful API specification.

       * @param {} parameters.categories - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminBulkProductOperation(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/bulk-product-operation';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     

            if(typeof parameters['q'] !== 'undefined'){
              queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);
        
        


     

            if(typeof parameters['brands'] !== 'undefined'){
              queryParameters['brands'] = parameters['brands'];
            }

            queryParameters['brands'] = this.transformParameter(queryParameters['brands']);
        
        


     

            if(typeof parameters['saleschannels'] !== 'undefined'){
              queryParameters['saleschannels'] = parameters['saleschannels'];
            }

            queryParameters['saleschannels'] = this.transformParameter(queryParameters['saleschannels']);
        
        


     

            if(typeof parameters['withoutPhoto'] !== 'undefined'){
              queryParameters['withoutPhoto'] = parameters['withoutPhoto'];
            }

            queryParameters['withoutPhoto'] = this.transformParameter(queryParameters['withoutPhoto']);
        
        


     

            if(typeof parameters['smarttags'] !== 'undefined'){
              queryParameters['smarttags'] = parameters['smarttags'];
            }

            queryParameters['smarttags'] = this.transformParameter(queryParameters['smarttags'], 'joinUsingPipes');
        
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Product detail for supplier
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} productId - Eshop RESTful API specification.

     */
     adminGetProductDetail(appSpace,productId,parameters = {}) {
      let path = '/admin/{appSpace}/products/{product_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{product_id}', productId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update product
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} productId - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateProduct(appSpace,productId,parameters = {}) {
      let path = '/admin/{appSpace}/products/{product_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{product_id}', productId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete product
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} productId - Eshop RESTful API specification.

     */
     adminDeleteProduct(appSpace,productId,parameters = {}) {
      let path = '/admin/{appSpace}/products/{product_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{product_id}', productId);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update product priority
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} productId - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateProductPriority(appSpace,productId,parameters = {}) {
      let path = '/admin/{appSpace}/products/{product_id}/priority';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{product_id}', productId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Parameters defined in products database
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.all - Display all parameters, from all appSpaces, not only current appspace
     */
     adminListParamLabels(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/params';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
              queryParameters['all'] = true;

            if(typeof parameters['all'] !== 'undefined'){
              queryParameters['all'] = parameters['all'];
            }

            queryParameters['all'] = this.transformParameter(queryParameters['all']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Parameters defined in products database
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} paramLabel - Value obtained from adminListParamLabels()
       * @param {} parameters.all - Display all parameters, from all appSpaces, not only current appspace
     */
     adminListParamValueLabels(appSpace,paramLabel,parameters = {}) {
      let path = '/admin/{appSpace}/params/{paramLabel}/values';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{paramLabel}', paramLabel);
        


     
              queryParameters['all'] = true;

            if(typeof parameters['all'] !== 'undefined'){
              queryParameters['all'] = parameters['all'];
            }

            queryParameters['all'] = this.transformParameter(queryParameters['all']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminListBrands(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/brands';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get list of supported codelists
     * @method
     * @param {object} parameters - method options and parameters
     */
     getCodelistList(parameters = {}) {
      let path = '/codelist';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.appSpace - Filter brands only for appSpace
       * @param {} parameters.categories - Filter brands only for this category and its subcategories
     */
     getCodelistBrands(parameters = {}) {
      let path = '/codelist/generic/brands';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');


            if(typeof parameters['appSpace'] !== 'undefined'){
              queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);
        
        


     

            if(typeof parameters['categories'] !== 'undefined'){
              queryParameters['categories'] = parameters['categories'];
            }

            queryParameters['categories'] = this.transformParameter(queryParameters['categories']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     getCodelistAclRoles(parameters = {}) {
      let path = '/codelist/generic/acl_roles';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     getCodelistOrderStatus(parameters = {}) {
      let path = '/codelist/generic/order_status';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
     */
     getCodelistRequestStatus(parameters = {}) {
      let path = '/codelist/generic/request_status';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} codelist - Codelist name
     */
     getCodelist(codelist,parameters = {}) {
      let path = '/codelist/{codelist}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{codelist}', codelist);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get packetery setup by appspace
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     getAppSpacePacketeryConfig(appSpace,parameters = {}) {
      let path = '/packetery/{appSpace}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get info whether mailinglist is setup by appspace
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     getAppSpaceMailinglist(appSpace,parameters = {}) {
      let path = '/mailinglist/{appSpace}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Request new user registration to appspace mailinglist
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     registerToAppSpaceMailinglist(appSpace,parameters = {}) {
      let path = '/mailinglist/{appSpace}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetSupplierDeliveryOptions(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/delivery-options';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update delivery options.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateDeliveryOptions(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/delivery-options';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetSupplierPacketery(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/packetery';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Updates packetery setting.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdatePacketery(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/packetery';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetSupplierMailchimp(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/mailchimp';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Updates mailchimp setting.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateMailchimp(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/mailchimp';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetSupplierEcomail(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/ecomail';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Updates ecomail setting.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateEcomail(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/ecomail';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetSupplierOrderForwarding(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/order-forwarding';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Updates order forwarding setting.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateOrderForwarding(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/order-forwarding';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetRootCategories(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminCreateRootCategory(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetCategories(categoryId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update category.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateCategory(categoryId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminCreateCategory(categoryId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete category and all subcategories. Products are moved to parent category.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminDeleteCategory(categoryId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} destinationId - Destination can be equal to `0`. Category is then moved to root level.
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminMoveCategory(categoryId,destinationId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories/{category_id}/move/{destination_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{destination_id}', destinationId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetCategoriesTree(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/categories-tree';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminSaleschannelGetRootCategories(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminSaleschannelCreateRootCategory(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminSaleschannelGetCategories(categoryId,appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update category.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminSaleschannelUpdateCategory(categoryId,appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminSaleschannelCreateCategory(categoryId,appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete category and all subcategories. Products are moved to parent category.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminSaleschannelDeleteCategory(categoryId,appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories/{category_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} categoryId - Eshop RESTful API specification.

       * @param {} destinationId - Destination can be equal to `0`. Category is then moved to root level.
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminSaleschannelMoveCategory(categoryId,destinationId,appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories/{category_id}/move/{destination_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{category_id}', categoryId);
        


     
        
          path = path.replace('{destination_id}', destinationId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} saleschannel - Eshop RESTful API specification.

     */
     adminSaleschannelGetCategoriesTree(appSpace,saleschannel,parameters = {}) {
      let path = '/admin/{appSpace}/saleschannels/{saleschannel}/categories-tree';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{saleschannel}', saleschannel);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetListings(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/listings';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminCreateListing(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/listings';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('POST', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} listingId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminGetListing(listingId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/listings/{listing_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{listing_id}', listingId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Update listing.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} listingId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} parameters.data - 
     */
     adminUpdateListing(listingId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/listings/{listing_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{listing_id}', listingId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Delete listing. Products are removed from listing.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} listingId - Eshop RESTful API specification.

       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminDeleteListing(listingId,appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/listings/{listing_id}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{listing_id}', listingId);
        


     
        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('DELETE', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

     */
     adminListSuppliers(appSpace,parameters = {}) {
      let path = '/admin/{appSpace}/suppliers';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * 
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appSpace - Eshop RESTful API specification.

       * @param {} supplierId - Eshop RESTful API specification.

       * @param {} parameters.data - Supplier update
     */
     adminUpdateSupplier(appSpace,supplierId,parameters = {}) {
      let path = '/admin/{appSpace}/suppliers/{supplierId}';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        
          path = path.replace('{appSpace}', appSpace);
        


     
        
          path = path.replace('{supplierId}', supplierId);
        


     
        
        
          if(typeof parameters['data'] !== 'undefined'){
            body = parameters['data'];
          }


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('PUT', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
    /**
     * Get list of suppliers. Sorted by featured: -1, featuredOrder: 1, name: 1.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.featured - Eshop RESTful API specification.

     */
     listSuppliers(parameters = {}) {
      let path = '/suppliers';
      let body = {};
      let queryParameters = {};
      let headers = new Headers();
      let form = {};

        headers.append('Accept', 'application/json');


            if(typeof parameters['featured'] !== 'undefined'){
              queryParameters['featured'] = parameters['featured'];
            }

            queryParameters['featured'] = this.transformParameter(queryParameters['featured']);
        
        


     
      queryParameters = this.mergeQueryParams(parameters, queryParameters);

      return this.request('GET', `${this.baseUrl}${path}`, body, headers, queryParameters, form);
     }
}

export default new Api();
export { Api };
