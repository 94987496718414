import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { LIST_SUPPLIERS, loadSuppliersList } from "./actions";
import {
  SubmissionError,
  hasSubmitFailed,
  isDirty,
  isSubmitting,
  submit,
} from "redux-form";
import { __, revertReduxFormKeyify } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { deepCompare } from "@sportnet/utilities";
import { initializeOrSetListParams } from "../App/actions";
import { isCommiting } from "@sportnet/redux-list";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import FeaturedPartnersForm, { FORM_NAME } from "./form";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import SubmitButton from "@sportnet/ui/Button/Submit";
import apiEshop from "../../service/apiEshop";

const FeaturedPartners = ({
  appSpace,
  initList,
  loadSuppliers,
  isFormModified,
  submitForm,
  submitFailed,
  submitting,
}) => {
  React.useEffect(() => {
    (async () => {
      await initList({
        listName: LIST_SUPPLIERS,
        params: { appSpace },
      });
      await loadSuppliers();
    })();
  }, [appSpace, initList, loadSuppliers]);

  const handleSubmit = React.useCallback(
    async (values, dispatch, { initialValues }) => {
      const changes = Object.keys(values.suppliers).reduce((acc, key) => {
        const value = values.suppliers[key];
        const initialValue = initialValues.suppliers[key];
        const isModified =
          deepCompare(initialValue, value).findIndex((o) => o.type !== "U") >
          -1;
        if (isModified) {
          acc.push({
            supplierId: revertReduxFormKeyify(key),
            value: {
              featured: !!value.featured,
              featuredOrder: value.featuredOrder
                ? Number(value.featuredOrder)
                : 0,
            },
          });
        }
        return acc;
      }, []);

      try {
        const promises = changes.map(({ supplierId, value }) =>
          apiEshop.adminUpdateSupplier(appSpace, supplierId, { data: value })
        );
        await Promise.all(promises);
        await loadSuppliers();
      } catch (e) {
        const submissionError = {
          _error: __("Uloženie sa nepodarilo"),
        };
        window.alert(submissionError._error);
        throw new SubmissionError(submissionError);
      }
    },
    [appSpace, loadSuppliers]
  );

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Partneri")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <SubmitButton
              isSubmitting={submitting}
              isError={submitFailed}
              successText={__("Uložené")}
              disabled={!isFormModified}
              onClick={submitForm}
            >
              {__("Uložiť")}
            </SubmitButton>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Partneri")} />
      <FeaturedPartnersForm onSubmit={handleSubmit} />
    </Layout>
  );
};

FeaturedPartners.propTypes = {
  appSpace: PropTypes.string.isRequired,
  initList: PropTypes.func.isRequired,
  loadSuppliers: PropTypes.func.isRequired,
  isFormModified: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isListCommiting: isCommiting(LIST_SUPPLIERS)(state) || false,
  appSpace: state.selectedOrg,
  isFormModified: isDirty(FORM_NAME)(state) || false,
  submitFailed: hasSubmitFailed(FORM_NAME)(state) || false,
  submitting: isSubmitting(FORM_NAME)(state) || false,
});

const mapDispatchToProps = {
  initList: initializeOrSetListParams,
  loadSuppliers: loadSuppliersList,
  submitForm: () => submit(FORM_NAME),
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FeaturedPartners
);
