import { __ } from "../../../utilities";
import { connect } from "react-redux";
import { useAuth } from "@sportnet/auth-react";
import ApiEshop from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SubmitButton from "@sportnet/ui/Button/Submit";

const Mailchimp = () => {
  const [loading, setLoading] = React.useState(true);
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [mailchimpSettings, setMailchimpSettings] = React.useState({
    api_key: "",
    list_id: "",
  });
  const { ppo } = useAuth();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const newSettings = await ApiEshop.adminGetSupplierMailchimp(ppo);
        setMailchimpSettings(newSettings);
      } catch (e) {
        console.error(e);
        alert(__("Nemáte právo upravovať mailchimp"));
      } finally {
        setLoading(false);
      }
    })();
  }, [ppo]);

  const handleSave = async () => {
    setSubmitting(true);
    setSubmitFailed(false);
    try {
      const newSettings = await ApiEshop.adminUpdateMailchimp(ppo, {
        data: mailchimpSettings,
      });
      setMailchimpSettings(newSettings);
    } catch (e) {
      setSubmitFailed(true);
      console.error(e);
      alert(__("Uloženie nastavení mailchimp sa nepodarilo"));
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeValue = (e) => {
    const {
      target: { name, value },
    } = e;
    setMailchimpSettings((prev) => ({ ...(prev || {}), [name]: value }));
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Nastavenia mailchimp")}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext title={__("Nastavenia mailchimp")} />
      <Segment noBottomGutter>
        <Segment raised loading={loading}>
          <Row>
            <Col xs={12} m={6}>
              <FormField
                label={__("API kľúč")}
                name="api_key"
                placeholder={__("V manuále mailchimp ako API KEY")}
                value={mailchimpSettings.api_key || ""}
                onChange={handleChangeValue}
              />
            </Col>
            <Col xs={12} m={6}>
              <FormField
                label={__("Id zoznamu")}
                name="list_id"
                placeholder={__("V manuále mailchimp ako LIST ID")}
                value={mailchimpSettings.list_id || ""}
                onChange={handleChangeValue}
              />
            </Col>
          </Row>
          <SubmitButton
            isError={submitFailed}
            isSubmitting={submitting}
            successText={__("Uložené!")}
            onClick={handleSave}
          >
            {__("Uložiť")}
          </SubmitButton>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(Mailchimp);
