import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import apiEshop from '../../../../service/apiEshop';

export const FORM_NAME = 'SET_SALESCHANNELS_OPERATION';

const SetSaleschannelsOperationForm = ({ handleSubmit, org }) => {
  const [salesChannels, setSalesChannels] = React.useState([]);
  const [isFetchingSalesChannels, setIsFetchingSalesChannels] =
    React.useState(false);

  React.useEffect(() => {
    setIsFetchingSalesChannels(true);
    apiEshop
      .adminListSaleschannels(org)
      .then((data) => {
        setSalesChannels(data.saleschannels || []);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
      })
      .finally(() => {
        setIsFetchingSalesChannels(false);
      });
  }, [org]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={FormFieldRedux}
        name="saleschannels"
        type="theselectsimple"
        label={__('Predajné kanály')}
        loading={isFetchingSalesChannels}
        multiple
        options={salesChannels.map(i => ({label: i.name, value: i._id}))}
      />
    </Form>
  );
};

SetSaleschannelsOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  org: state.selectedOrg
})

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps)
)(SetSaleschannelsOperationForm);
