import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __ } from "../../utilities";
import { connect } from "react-redux";
import { fetchSaleChannelsList } from "./actions";
import {
  nonSharedSaleChanellsListSelector,
  saleChanellsIsFetchingSelector,
} from "./selectors";
import { useAuth } from "@sportnet/auth-react";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import apiEshop from "../../service/apiEshop";

const SaleChannelsList = ({ dispatch, data, isFetching, history }) => {
  const { ppo } = useAuth();

  React.useEffect(() => {
    dispatch(fetchSaleChannelsList(ppo));
  }, [dispatch, ppo]);

  const removeSaleChannel = async (saleChannelId) => {
    if (!window.confirm(__("Skutočne si želáte odstrániť predajný kanál?"))) {
      return;
    }
    try {
      await apiEshop.adminDeleteSaleschannel(ppo, saleChannelId);
      await dispatch(fetchSaleChannelsList(ppo));
    } catch (e) {
      window.alert(__("Vymazanie predajného kanálu sa nepodarilo"));
    }
  };

  const handleClickSalesChannel = (salesChannelId) => {
    history.push(`/admin/${ppo}/sale-channels/${salesChannelId}`);
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Zoznam predajných kanálov")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              disabled={isFetching}
              onClick={() => {
                handleClickSalesChannel("new");
              }}
            >
              {__("Pridať predajný kanál")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Zoznam predajných kanálov")} />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching && data.length === 0}>
          <Table>
            <Thead>
              <Tr>
                <Th>{__("ID")}</Th>
                <Th>{__("Názov")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, idx) => {
                return (
                  <Tr
                    key={item._id}
                    onClick={() => handleClickSalesChannel(item._id)}
                  >
                    <Td>{item._id}</Td>
                    <Td>{item.name}</Td>
                    <Td center>
                      <Button
                        danger
                        icon="close"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeSaleChannel(item._id);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    data: nonSharedSaleChanellsListSelector(state),
    isFetching: saleChanellsIsFetchingSelector(state),
  };
};

SaleChannelsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(SaleChannelsList);
