import {
  RECEIVE_PRODUCTS_ERROR,
  RECEIVE_PRODUCTS_SUCCESS,
  RECEIVE_PRODUCT_SUCCESS,
  REQUEST_PRODUCT,
  REQUEST_PRODUCTS,
  SET_ACTIVE_PRODUCT_ID,
  SET_PARAMETER,
  SET_PARAMETERS,
} from "./constants";
import { getProp, serializeSearchParameters } from "../../utilities";

const ordersInitialState = {
  activeId: null,
  data: {
    nextOffset: null,
    total: 0,
    offset: 0,
  },
  byId: {},
  byParameters: {},
  parameters: {
    q: "",
  },
  error: null,
};

const productsReducer = (state = ordersInitialState, action) => {
  const serializedParameters = serializeSearchParameters(
    getProp(action, ["payload", "parameters"], state.parameters)
  );
  switch (action.type) {
    case SET_PARAMETER:
      if (action.payload.value === false) {
        return {
          ...state,
          parameters: {
            ...Object.keys(state.parameters).reduce((acc, next) => {
              if (next === action.payload.name) {
                return acc;
              }
              return { ...acc, [next]: state.parameters[next] };
            }, {}),
          },
        };
      }
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_PARAMETERS:
      return {
        ...state,
        parameters: action.payload.value,
      };
    case REQUEST_PRODUCTS:
      return {
        ...state,
        byParameters: {
          ...state.byParameters,
          [serializedParameters]: {
            ...getProp(state.byParameters, [serializedParameters], {}),
            [`${action.payload.offset}-${
              action.payload.offset + action.payload.limit
            }`]: {
              ...getProp(state.byParameters, [
                serializedParameters,
                `${action.payload.offset}-${
                  action.payload.offset + action.payload.limit
                }`,
              ]),
              isFetching: true,
            },
          },
        },
      };
    case RECEIVE_PRODUCTS_SUCCESS: {
      const {
        data: { offset, limit, total, products, next_offset: nextOffset },
      } = action.payload;
      return {
        ...state,
        data: {
          offset,
          limit,
          total,
          nextOffset,
        },
        byId: {
          ...state.byId,
          ...action.payload.data.products.reduce((acc, next) => {
            return { ...acc, [next._id]: next };
          }, {}),
        },
        byParameters: {
          ...state.byParameters,
          [serializedParameters]: {
            ...getProp(state.byParameters, [serializedParameters], {}),
            [`${offset}-${offset + limit}`]: {
              ids: products.map((item) => {
                return item._id;
              }),
              isFetching: false,
              total,
              nextOffset,
            },
          },
        },
        error: null,
      };
    }
    case RECEIVE_PRODUCTS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case SET_ACTIVE_PRODUCT_ID:
      return {
        ...state,
        activeId: action.payload.id,
      };
    case REQUEST_PRODUCT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...getProp(state.byId, [action.payload.id], {}),
            isFetching: true,
          },
        },
      };
    case RECEIVE_PRODUCT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload.data,
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
};

export default productsReducer;
