import { NumberParam } from '@sportnet/query-hoc/useQuery';

export const REQUESTS_LIST = 'REQUESTS_LIST';

export const REQUESTS_LIST_CONFIG = {
  parameters: {
    limit: NumberParam(100),
    offset: NumberParam(0),
  },
};
