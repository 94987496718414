import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100vh;
  }

  html {
    font-size: 100%;
    font-family: 'Barlow', sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  div {
    @media print {
      overflow: visible !important;
    }
  }

  body {
    background-color: #f5f5f5;
    @media print {
      background-color: white;
    }
  }

  .container-fluid {
    padding: 0 !important;
  }
`;
export default GlobalStyles;
