import { Field, useFormState } from "react-final-form";
import { __ } from "@sportnet/ui/utilities";
import { rem } from "polished";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import Label from "@sportnet/ui/Label/Label";
import React from "react";
import apiEshop from "../../../../service/apiEshop";

const Features = () => {
  const [tags, setTags] = React.useState([]);
  const [, setIsFetchingTags] = React.useState(false);
  const { values } = useFormState();

  React.useEffect(() => {
    setIsFetchingTags(true);
    apiEshop
      .getCodelist("product_tag")
      .then((data) => {
        setTags(data.codelist);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam tagov"));
      })
      .finally(() => {
        setIsFetchingTags(false);
      });
  }, []);

  return (
    <Row>
      <Col>
        <Label style={{ marginBottom: rem(7) }}>
          {__("Vlastnosti produktu")}
        </Label>
      </Col>
      {tags.map((i, idx) => {
        return (
          <React.Fragment key={idx}>
            <Col xs={12}>
              <Field name={`tags.${i.value}.active`} type="checkbox">
                {({ input: { checked, onChange } }) => (
                  <FormField
                    label={i.label}
                    checked={checked}
                    onChange={onChange}
                    type="checkbox"
                  />
                )}
              </Field>
            </Col>
            {values?.tags?.[i.value]?.active && (
              <Field name={`tags.${i.value}.validTo`}>
                {({ input: { value, onChange } }) => (
                  <Col xs={12}>
                    <FormField
                      label={__("Dátum platnosti do")}
                      type="dateTime"
                      value={value}
                      onChange={onChange}
                    />
                  </Col>
                )}
              </Field>
            )}
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default Features;
