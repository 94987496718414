import {
  RECEIVE_RECEIPTS_ERROR_ADMIN,
  RECEIVE_RECEIPTS_SUCCESS_ADMIN,
  REQUEST_RECEIPTS_ADMIN,
} from './constants';

const receiptsInitialState = {
  isFetching: false,
  data: {},
  error: null,
  q: '',
  status: '',
};

const receiptsAdminReducer = (state = receiptsInitialState, action) => {
  switch (action.type) {
    case REQUEST_RECEIPTS_ADMIN:
      return { ...state, isFetching: true };
    case RECEIVE_RECEIPTS_SUCCESS_ADMIN: {
      return {
        ...state,
        data: action.payload,
        error: null,
        isFetching: false,
      };
    }
    case RECEIVE_RECEIPTS_ERROR_ADMIN:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default receiptsAdminReducer;
