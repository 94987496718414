import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import {
  DeleteIcon,
  KeyWord,
  KeyWordButton,
} from "../../components/Forms/KeyWords";
import { __, getProp } from "../../utilities";
import {
  categoriesIsFetchingSelector,
  categoriesListSelector,
} from "./selectors";
import { connect } from "react-redux";
import { fetchCategories } from "./actions";
import { submit } from "redux-form";
import { withTheme } from "styled-components";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import CategoryForm from "./form";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";
import Tree from "@sportnet/ui/Tree";
import apiEshop from "../../service/apiEshop";

const CATEGORY_FORM_NAME = "CATEGORY_FORM";

class Categories extends PureComponent {
  constructor(props) {
    super(props);
    this.initialState = {
      sidebarVisible: false,
      parent: null,
      name: "",
      priority: "0",
      selectedParameters: {},
      selectedCategory: {},
      update: false,
      loading: false,
    };
    this.state = {
      ...this.initialState,
      parameters: [],
    };
    this.submitForm = this.submitForm.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.moveCategory = this.moveCategory.bind(this);
  }
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;

    dispatch(fetchCategories(appspace));
    apiEshop
      .adminListParamLabels(appspace)
      .then((res) => {
        this.setState({
          parameters: res.params,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async deleteCategory(category) {
    const {
      match: {
        params: { appspace },
      },
      dispatch,
    } = this.props;
    if (window.confirm(__("Skutočne si želáte odstrániť kategóriu?"))) {
      this.setState({
        loading: true,
      });
      try {
        await apiEshop.adminDeleteCategory(category._id, appspace);
        this.setState((currentState) => ({
          ...currentState,
          ...this.initialState,
        }));
        dispatch(fetchCategories(appspace));
      } catch (e) {
        this.setState({
          loading: false,
        });
        alert(__("Kategóriu sa nepodarilo zmazať"));
      }
    }
  }

  submitForm = async ({
    name,
    priority,
    url = "",
    searchParams = [],
    meta = {},
  }) => {
    const data = {
      name,
      priority: priority ? Number(priority) : null,
      url,
      searchParams,
      meta,
    };
    const { parent, update } = this.state;
    const {
      match: {
        params: { appspace },
      },
      dispatch,
    } = this.props;
    this.setState({
      loading: true,
    });
    try {
      if (update) {
        // uprava kategorie
        await apiEshop.adminUpdateCategory(update._id, appspace, {
          data,
        });
      } else if (parent) {
        // pridanie podkategorie
        await apiEshop.adminCreateCategory(parent._id, appspace, {
          data,
        });
      } else {
        // pridanie kategorie do rootu
        await apiEshop.adminCreateRootCategory(appspace, {
          data,
        });
      }
      this.setState((currentState) => ({
        ...currentState,
        ...this.initialState,
      }));
      dispatch(fetchCategories(appspace));
    } catch (e) {
      this.setState({
        loading: false,
      });
      alert(__("Kategóriu sa nepodarilo uložiť"));
    }
  };

  async moveCategory(data) {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { node, nextParentNode } = data;
    this.setState({
      loading: true,
    });
    try {
      let parentId = 0;
      if (nextParentNode) {
        parentId = nextParentNode._id;
      }
      await apiEshop.adminMoveCategory(node._id, parentId, appspace);
      dispatch(fetchCategories(appspace));
    } catch (e) {
      alert(__("Kategóriu nebolo možné presunúť"));
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  renderTreeData(categories) {
    return categories.map((category) => {
      const object = {
        _id: String(category._id),
        title: category.name || category.title,
        expanded: true,
      };
      if (category.categories) {
        object.children = this.renderTreeData(category.categories);
      } else if (category.children) {
        object.children = this.renderTreeData(category.children);
      }
      return object;
    });
  }

  renderParamKeyWord(paramName) {
    return (
      <KeyWord key={paramName}>
        <KeyWordButton
          sm
          inverse
          primary
          onClick={() => {
            this.setState((currentState) => {
              const selectedParameters = { ...currentState.selectedParameters };
              delete selectedParameters[paramName];
              return {
                selectedParameters,
              };
            });
          }}
        >
          {paramName}
          <DeleteIcon name="delete" sm />
        </KeyWordButton>
        &nbsp;
      </KeyWord>
    );
  }

  render() {
    const {
      org: supplierId,
      categories,
      isFetchingCategories,
      match: {
        params: { appspace },
      },
    } = this.props;
    const { sidebarVisible, parameters, isFetching } = this.state;

    if (supplierId) {
      return (
        <Layout
          topFixed={
            <HeaderBar>
              <HeaderBar.Header>{__("Kategórie")}</HeaderBar.Header>
            </HeaderBar>
          }
          bottomFixed={
            <ContextBar>
              <ContextBarSpacer />
              <ContextBarItem>
                <Button
                  primary
                  icon="plus"
                  onClick={() => {
                    this.setState({
                      sidebarVisible: true,
                    });
                  }}
                >
                  {__("Pridať hlavnú kategóriu")}
                </Button>
              </ContextBarItem>
            </ContextBar>
          }
        >
          <AppContext title={__("Kategórie")} />
          <Segment noBottomGutter>
            <Segment raised loading={isFetchingCategories || isFetching}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                }}
              >
                <Tree
                  canDrag
                  onMoveNode={this.moveCategory}
                  buttons={[
                    {
                      icon: "plus",
                      onClick: (category) => {
                        this.setState({
                          sidebarVisible: true,
                          parent: category,
                        });
                      },
                    },
                    {
                      icon: "pencil",
                      onClick: (category) => {
                        this.setState({
                          isFetching: true,
                        });
                        apiEshop
                          .adminGetCategories(category._id, appspace)
                          .then((categoryData) => {
                            this.setState({
                              sidebarVisible: true,
                              update: category,
                              selectedCategory: categoryData.category,
                              name: category.title,
                              priority:
                                categoryData.category.priority ||
                                this.initialState.priority,
                              selectedParameters: categoryData.category
                                .searchParams
                                ? categoryData.category.searchParams.reduce(
                                    (acc, item) => {
                                      return { ...acc, [item]: 1 };
                                    },
                                    {},
                                  )
                                : {},
                              isFetching: false,
                            });
                          })
                          .catch((e) => {
                            this.setState({
                              isFetching: false,
                            });
                            console.error(e);
                            alert(
                              __(
                                "Vyskytla sa neočakávaná chyba. Nebolo možné načítať kategóriu.",
                              ),
                            );
                          });
                      },
                    },
                    {
                      icon: "trash",
                      onClick: this.deleteCategory,
                    },
                  ]}
                  treeData={this.renderTreeData(categories)}
                  onChange={() => {}}
                  activeNodeId={null}
                />
              </div>
            </Segment>
          </Segment>
          <Sidebar
            visible={sidebarVisible}
            onClose={() => {
              this.setState((currentState) => ({
                ...currentState,
                ...this.initialState,
                selectedCategory: {},
              }));
            }}
            header={__("Kategória")}
          >
            <Segment>
              {getProp(this.state, ["parent", "title"]) && (
                <FormGroup>
                  <Label>{__("Nadradená kategória")}</Label>
                  <Input disabled value={this.state.parent.title} />
                </FormGroup>
              )}

              {!isFetching && sidebarVisible && (
                <CategoryForm
                  parameters={parameters}
                  form={CATEGORY_FORM_NAME}
                  onSubmit={this.submitForm}
                  initialValues={this.state.selectedCategory}
                />
              )}

              <FormGroup>
                <Button
                  primary
                  onClick={() => {
                    this.props.dispatch(submit(CATEGORY_FORM_NAME));
                  }}
                >
                  {__("Uložiť")}
                </Button>
              </FormGroup>
            </Segment>
          </Sidebar>
        </Layout>
      );
    }
    return <p>Loading</p>;
  }
}

Categories.propTypes = {
  org: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetchingCategories: PropTypes.bool.isRequired,
};

Categories.defaultProps = {
  org: null,
};

const mapStateToProps = (state) => {
  return {
    categories: categoriesListSelector(state),
    isFetchingCategories: categoriesIsFetchingSelector(state),
    org: state.selectedOrg,
  };
};

export default connect(mapStateToProps)(withTheme(Categories));
