import { Field, Form, change, formValueSelector, reduxForm } from "redux-form";
import { __ } from "../../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { required } from "../../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField/redux-form";
import Price from "@sportnet/ui/Price";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import apiEshop from "../../../service/apiEshop";

export const FORM_NAME = "SALES_CHANNEL";

const SalesChannelForm = ({
  dispatch,
  handleSubmit,
  initialValues,
  payOnDeliveryActive,
  payOnDeliveryUnitPrice,
  deliveryOptions,
  appSpace,
}) => {
  const [salesChannelCategories, setSalesChannelCategories] = React.useState(
    [],
  );
  const { accessToken } = useAuth();
  const [
    isFetchingSalesChannelCategories,
    setIsFetchingSalesChannelCategories,
  ] = React.useState(false);

  const transformCategories = React.useCallback((items, prefix = "") => {
    return items.reduce((acc, i) => {
      return [
        ...acc,
        { ...i, name: `${prefix}${i.name}` },
        ...transformCategories(i.categories || [], `${prefix}${i.name} -> `),
      ];
    }, []);
  }, []);

  React.useEffect(() => {
    if (initialValues._id) {
      setIsFetchingSalesChannelCategories(true);
      apiEshop
        .adminSaleschannelGetCategoriesTree(appSpace, initialValues._id)
        .then((res) => {
          setSalesChannelCategories(transformCategories(res.categories));
        })
        .catch((e) => {
          console.error(e);
          alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
        })
        .finally(() => {
          setIsFetchingSalesChannelCategories(false);
        });
    }
  }, [appSpace, initialValues, transformCategories]);

  return (
    <Form onSubmit={handleSubmit}>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Všeobecné nastavenia")}
          </SegmentHeader>
        }
      >
        <Row>
          <Col s={12} m={6} l={3}>
            <Field
              label={__("Identifikátor")}
              name="_id"
              type="text"
              component={FormField}
              required
              validate={[required]}
              disabled={!!initialValues._id}
            />
          </Col>
          <Col s={12} m={6} l={3}>
            <Field
              label={__("Názov")}
              name="name"
              type="text"
              component={FormField}
              required
              validate={[required]}
            />
          </Col>
        </Row>
        {initialValues._id && (
          <Row>
            <Col s={12}>
              <Field
                component={FormField}
                name="meta.title"
                counter={{ characters: true }}
                label={__("Meta title")}
              />
            </Col>
            <Col s={12}>
              <Field
                component={FormField}
                type="textarea"
                name="meta.description"
                counter={{ characters: true }}
                label={__("Meta description")}
              />
            </Col>
          </Row>
        )}
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Nastavenia platieb")}
          </SegmentHeader>
        }
      >
        <Row>
          <Col>
            <Field
              label={__("Platba kartou online")}
              name="payments.standard_payment.active"
              type="checkbox"
              component={FormField}
            />
          </Col>
          <Col>
            <Field
              label={__(
                initialValues?.payments?.pay_at_store?.name ||
                  "Platba na predajni",
              )}
              name="payments.pay_at_store.active"
              type="checkbox"
              component={FormField}
            />
          </Col>
          <Col>
            <Field
              label={__(
                initialValues?.payments?.pay_by_transfer?.name ||
                  "Platba bankovým prevodom",
              )}
              name="payments.pay_by_transfer.active"
              type="checkbox"
              component={FormField}
            />
          </Col>
          <Col>
            <Field
              label={__(
                initialValues?.payments?.pay_on_delivery?.name ||
                  "Platba dobierkou",
              )}
              name="payments.pay_on_delivery.active"
              type="checkbox"
              component={FormField}
            />
            {payOnDeliveryActive && (
              <Price
                amount={payOnDeliveryUnitPrice?.amount}
                amountVatExcl={payOnDeliveryUnitPrice?.amount_vat_excl}
                vatRate={payOnDeliveryUnitPrice?.vat_rate}
                setAmount={(i) => {
                  dispatch(
                    change(
                      FORM_NAME,
                      `payments.pay_on_delivery.unitprice.amount`,
                      i,
                    ),
                  );
                }}
                setAmountVatExcl={(i) =>
                  dispatch(
                    change(
                      FORM_NAME,
                      `payments.pay_on_delivery.unitprice.amount_vat_excl`,
                      i,
                    ),
                  )
                }
                setVatRate={(i) => {
                  dispatch(
                    change(
                      FORM_NAME,
                      `payments.pay_on_delivery.unitprice.vat_rate`,
                      i,
                    ),
                  );
                }}
              />
            )}
          </Col>
        </Row>
      </Segment>

      {!!initialValues._id && (
        <Segment
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__("Nastavenia katalógov")}
            </SegmentHeader>
          }
        >
          <Field
            name="catalogs"
            component={FormField}
            type="theselectsimple"
            multiple
            info={__(
              "Tieto sekcie sa budú zobrazovať na hlavnej stránke vo vlastných blokoch. Ak sekcie nie su nastavené, na stránke sa zobrazujú bloky pre tagy Novinky, Top a Akcie",
            )}
            label={__("Sekcie zobrazované ako katalóg")}
            loading={isFetchingSalesChannelCategories}
            options={salesChannelCategories.map((i) => ({
              label: i.name,
              value: i._id,
            }))}
          />
        </Segment>
      )}

      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Nastavenia dopravy")}
          </SegmentHeader>
        }
      >
        <Row>
          <Col s={12} m={6}>
            <Field
              label={__("Povolené spôsoby dopravy")}
              name="deliveryOptionsTypes"
              type="theselect"
              component={FormField}
              options={deliveryOptions}
              placeholder={__("Všetky")}
              multiple
              parse={(items) => items.map((i) => i.value)}
              format={(values) => {
                if (!values) {
                  return [];
                }
                return values.reduce((acc, value) => {
                  const option = deliveryOptions.find((o) => o.value === value);
                  if (option) {
                    acc.push(option);
                  }
                  return acc;
                }, []);
              }}
            />
          </Col>
        </Row>
      </Segment>

      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Prezentácia")}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12} s={4}>
            <Field
              component={FormField}
              name="logo"
              label={__("Logo")}
              type="image"
              auth={{
                appId: process.env.REACT_APP_APP_ID,
                appSpace,
                token: accessToken,
              }}
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              component={FormField}
              name="logoSecondary"
              label={__("Sekundárne logo")}
              type="image"
              auth={{
                appId: process.env.REACT_APP_APP_ID,
                appSpace,
                token: accessToken,
              }}
            />
          </Col>
          <Col xs={12} s={4}>
            <Field
              component={FormField}
              name="favicon"
              label={__("Favicon")}
              type="image"
              auth={{
                appId: process.env.REACT_APP_APP_ID,
                appSpace,
                token: accessToken,
              }}
            />
          </Col>
          <Col xs={12}>
            <Field
              component={FormField}
              name="theme.footerImage"
              label={__("Obrázok v pätičke")}
              type="image"
              auth={{
                appId: process.env.REACT_APP_APP_ID,
                appSpace,
                token: accessToken,
              }}
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormField}
              name="theme.color.primary"
              label={__("Primárna farba")}
              type="colorInput"
            />
          </Col>
          <Col xs={12} s={6}>
            <Field
              component={FormField}
              name="theme.color.secondary"
              label={__("Sekundárna farba")}
              type="colorInput"
            />
          </Col>
        </Row>
      </Segment>

      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__("Analytiky")}
          </SegmentHeader>
        }
      >
        <Row>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Facebook pixel")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.facebookPixel.id"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Exponea")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.exponea.id"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Zendesk")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.zendesk.id"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Mailchimp")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Chimpstatic URL")}
                name="analytics.mailchimp.scriptUrl"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Ecomail")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Ecomail appId")}
                name="analytics.ecomail.appId"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Google AdWords")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.googleAdWords.id"
                type="text"
                component={FormField}
                placeholder="AW-000000000"
              />
              <Field
                label={__("Konverzie odosielať na")}
                name="analytics.googleAdWords.conversion.send_to"
                type="text"
                component={FormField}
              />
              <Field
                label={__("Návštevnosť odosielať na")}
                name="analytics.googleAdWords.page_view.send_to"
                type="text"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Google Analytics")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.googleAnalytics.id"
                type="text"
                component={FormField}
                placeholder="UA-000000000-0"
              />
              <Field
                label={__("Rozšírený elektronický obchod")}
                name="analytics.googleAnalytics.enhancedEcommerce"
                type="checkbox"
                component={FormField}
              />
            </Segment>
          </Col>
          <Col s={12} m={6} l={3}>
            <Segment
              secondary
              header={
                <SegmentHeader size="xs" withSeparator>
                  {__("Google Tag Manager")}
                </SegmentHeader>
              }
            >
              <Field
                label={__("Identifikátor")}
                name="analytics.googleTagManager.id"
                type="text"
                component={FormField}
                placeholder="GTM-0000000"
              />
            </Segment>
          </Col>
        </Row>
      </Segment>
    </Form>
  );
};

SalesChannelForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    _id: PropTypes.string,
  }),
  deliveryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  payOnDeliveryActive: PropTypes.bool.isRequired,
  appSpace: PropTypes.string.isRequired,
};

SalesChannelForm.defaultProps = { initialValues: {} };

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state) => {
  return {
    payOnDeliveryActive:
      selector(state, "payments.pay_on_delivery.active") || false,
    payOnDeliveryUnitPrice: selector(
      state,
      "payments.pay_on_delivery.unitprice",
    ),
  };
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
  connect(mapStateToProps),
)(SalesChannelForm);
