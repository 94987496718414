import { formatPrice, getProp } from "../../utilities";
import { rem } from "polished";
import ItemInfo from "./ItemInfo";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

const Tr = styled.tr``;
const Td = styled.td`
  border: ${rem(1)} solid black;
  padding: ${rem(3)};
`;

const TextStrike = styled.span`
  text-decoration: line-through;
`;

const CenteredTd = styled(Td)`
  text-align: center;
  white-space: nowrap;
`;

class OrderItem extends PureComponent {
  render() {
    const {
      item,
      item: {
        variant = {},
        variant: { unitprice = {} } = {},
        oldprice,
        totalprice = {},
        units,
        quantity = 0,
        paidbycredit = false,
        credits,
      },
    } = this.props;

    return (
      <Tr>
        <Td>
          <ItemInfo item={item} variant={variant} />
        </Td>
        <CenteredTd>
          {quantity}
          {units && ` ${units}`}
        </CenteredTd>
        <CenteredTd>
          {oldprice > 0 && (
            <TextStrike>
              {formatPrice(oldprice, unitprice.currency)}
              <br />
            </TextStrike>
          )}
          {formatPrice(getProp(unitprice, ["amount"], 0), unitprice.currency)}
        </CenteredTd>
        <CenteredTd>
          {formatPrice(
            getProp(totalprice, ["amount_vat_excl"], 0),
            totalprice.currency,
          )}
        </CenteredTd>
        <CenteredTd>
          {formatPrice(getProp(totalprice, ["amount"], 0), totalprice.currency)}
        </CenteredTd>
        <CenteredTd>
          {paidbycredit
            ? formatPrice(
                typeof credits === "undefined"
                  ? getProp(totalprice, ["amount"], 0)
                  : credits,
                totalprice.currency,
              )
            : formatPrice(0, totalprice.currency)}
        </CenteredTd>
      </Tr>
    );
  }
}

export const orderItemPropTypes = {
  item: PropTypes.shape({
    item_id: PropTypes.string.isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
    oldprice: PropTypes.number,
    quantity: PropTypes.number,
    totalprice: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      amount_vat_excl: PropTypes.number,
    }),
    variant: PropTypes.shape({
      params: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string.isRequired,
        }),
      }),
      unitprice: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string,
      }),
    }),
  }),
};

OrderItem.propTypes = {
  ...orderItemPropTypes,
};

export default OrderItem;
