import {
  RECEIVE_CODELIST_STATUSES_ERROR,
  RECEIVE_CODELIST_STATUSES_SUCCESS,
  RECEIVE_ORDERS_ERROR,
  RECEIVE_ORDERS_SUCCESS,
  RECEIVE_ORDER_SUCCESS,
  REQUEST_CODELIST_STATUSES,
  REQUEST_ORDER,
  REQUEST_ORDERS,
  SET_ACTIVE_ORDER_ID,
  SET_PARAMETER,
  SET_PARAMETERS,
} from "./constants";
import { addDays } from "date-fns";
import Api from "../../service/apiEshop";

export const requestOrders = (offset, limit) => ({
  type: REQUEST_ORDERS,
  payload: {
    offset,
    limit,
  },
});

export const receiveOrdersSuccess = (data, query, parameters) => ({
  type: RECEIVE_ORDERS_SUCCESS,
  payload: {
    data,
    query,
    parameters,
  },
});

export const receiveOrdersError = (payload) => ({
  type: RECEIVE_ORDERS_ERROR,
  payload,
});

export const fetchOrders = (query) => (dispatch, getState) => {
  dispatch(requestOrders(query.offset, query.limit));
  const {
    selectedOrg: supplierId,
    purchase: {
      orders: { parameters },
    },
  } = getState();
  const reducedParameters = Object.keys(parameters).reduce((acc, next) => {
    if (!parameters[next] || parameters[next].length === 0) {
      return acc;
    }
    return { ...acc, [next]: parameters[next] };
  }, {});
  const params = {
    ...query,
    ...reducedParameters,
  };
  if (params.createdDateTo) {
    params.createdDateTo = addDays(new Date(params.createdDateTo), 1).toJSON();
  }
  return Api.adminListOrders(supplierId, params)
    .then((data) => {
      dispatch(receiveOrdersSuccess(data, query, parameters));
    })
    .catch((error) => {
      dispatch(receiveOrdersError(error));
    });
};

export const requestCodelistStatuses = () => ({
  type: REQUEST_CODELIST_STATUSES,
});

export const receiveCodelistStatusesSuccess = (payload) => ({
  type: RECEIVE_CODELIST_STATUSES_SUCCESS,
  payload,
});

export const receiveCodelistStatusesError = (payload) => ({
  type: RECEIVE_CODELIST_STATUSES_ERROR,
  payload,
});

export const fetchCodelistOrderStatus = () => (dispatch) => {
  dispatch(requestCodelistStatuses());
  return Api.getCodelistOrderStatus()
    .then((data) => {
      const { codelist } = data;
      dispatch(receiveCodelistStatusesSuccess(codelist));
      return codelist;
    })
    .catch((error) => {
      dispatch(receiveCodelistStatusesError(error));
    });
};

export const setActiveOrderId = (id) => ({
  type: SET_ACTIVE_ORDER_ID,
  payload: {
    id,
  },
});

export const requestOrder = (id) => ({
  type: REQUEST_ORDER,
  payload: {
    id,
  },
});

export const receiveOrderDetailSuccess = (id, data) => ({
  type: RECEIVE_ORDER_SUCCESS,
  payload: {
    id,
    data,
  },
});

export const fetchOrderDetail = (orderId) => (dispatch, getState) => {
  dispatch(requestOrder(orderId));
  const { selectedOrg: supplierId } = getState();
  return Api.adminGetOrderDetail(supplierId, orderId)
    .then((data) => {
      dispatch(receiveOrderDetailSuccess(orderId, data));
      return data;
    })
    .catch((error) => {
      dispatch(receiveOrdersError(error));
      throw error;
    });
};

export const setParameter = (name, value) => ({
  type: SET_PARAMETER,
  payload: {
    name,
    value,
  },
});

export const setParameters = (value) => ({
  type: SET_PARAMETERS,
  payload: {
    value,
  },
});
