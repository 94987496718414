import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../../../utilities';
import { compose } from 'redux';
import {
  isNumber,
  lte,
  parseToFloat,
  required,
} from '../../../../utilities/validations';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Input from '@sportnet/ui/Input';
import PropTypes from 'prop-types';
import React from 'react';

export const FORM_NAME = 'SET_PRICELEVEL_OPERATION';

const lte100 = lte(100);

const SetPricelevelOperationForm = ({ handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={FormFieldRedux}
        name="discount"
        label={__('Výška zľavy oproti bežnej cene (v %)')}
        placeholder="10"
        required
        validate={[isNumber, required, lte100]}
        parse={parseToFloat}
      >
        <input />
        <Input.Button>%</Input.Button>
      </Field>
    </Form>
  );
};

SetPricelevelOperationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
  }),
)(SetPricelevelOperationForm);
