import { connect } from "react-redux";
import { fetchAcl, loadCodelistOrderStatus, setSelectedOrg } from "./actions";
import { routerParametersDefinition } from "./definitions";
import Loader from "@sportnet/ui/Loader";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  @media print {
    padding-left: 0;
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  @media print {
    padding: 0;
    background-color: white;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  @media print {
    div {
      display: block;
    }
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.getAcl = this.getAcl.bind(this);
  }

  componentDidMount() {
    const { appspace } = this.props.match.params;
    this.props.dispatch(setSelectedOrg(appspace));
    this.getAcl(appspace);
    this.props.dispatch(loadCodelistOrderStatus());
  }

  getAcl(appspace) {
    this.props.dispatch(fetchAcl(appspace));
  }

  render() {
    const { appspace } = this.props.match.params;
    const { acl } = this.props;

    const childrenElement = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        org: appspace,
      }),
    );

    // musi to byt tu, lebo som nevedel najst zacyklenie chyby... A hlavne tu sa az loaduje ACL...
    if (!acl || this.props.isFetchingAcl) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Div>
        <Wrapper>
          {this.props.acl && this.props.selectedOrg && (
            <Content>{childrenElement}</Content>
          )}
        </Wrapper>
      </Div>
    );
  }
}

App.propTypes = {
  acl: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  isFetchingAcl: PropTypes.bool,
  match: PropTypes.shape({
    params: routerParametersDefinition,
  }).isRequired,
};

App.defaultProps = {
  isFetchingAcl: true,
  acl: null,
};

const mapStateToProps = (state) => {
  const { userAcl } = state;
  return {
    selectedOrg: state.selectedOrg,
    acl: userAcl.data,
    isFetchingAcl: userAcl.isFetching,
    app: state.application,
  };
};

export default connect(mapStateToProps)(App);
