import * as React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { ThemeProvider } from "styled-components";
import { __, getProp } from "../../utilities";
import { change } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { theme as defaultContentTheme } from "@sportnet/content/theme/theme";
import { parseToNumber, required } from "../../utilities/validations";
import { useAuth } from "@sportnet/auth-react";
import Button from "@sportnet/ui/Button";
import FormFieldRedux from "@sportnet/ui/FormField/redux-form";
import FormGroup from "@sportnet/ui/FormGroup";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Modal, { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import ProductsSorter from "./ProductsSorter";
import PropTypes from "prop-types";
import Widgets from "@sportnet/content/editor";

const CategoryForm = ({
  searchParams,
  parameters,
  selectedChannel,
  selectedChannelId,
  selectedCategory,
  organization,
  form,
  dispatch,
  description,
}) => {
  const { accessToken } = useAuth();
  const [productSorterOpened, setProductSorterOpened] = React.useState(false);
  const [categoryDescriptionOpened, setCategoryDescriptionOpened] =
    React.useState(false);

  const toggleProductSorter = () => {
    setProductSorterOpened((prev) => !prev);
  };

  const toggleCategoryDescription = () => {
    setCategoryDescriptionOpened((prev) => !prev);
  };

  return (
    <>
      <FormGroup>
        <Button block onClick={toggleCategoryDescription} primary type="button">
          {__("Popis kategórie")}
        </Button>
      </FormGroup>
      {selectedChannel && (
        <>
          <FormGroup>
            <Button block onClick={toggleProductSorter} primary>
              {__("Zoradenie produktov")}
            </Button>
          </FormGroup>
          <FormGroup>
            <Label>{__("Predajný kanál")}</Label>
            <Input disabled value={selectedChannel} />
          </FormGroup>
        </>
      )}
      {getProp(parent, ["title"], "") && (
        <FormGroup>
          <Label>{__("Nadradená kategória")}</Label>
          <Input disabled value={parent.title} />
        </FormGroup>
      )}

      <Field
        required
        validate={[required]}
        component={FormFieldRedux}
        name="name"
        label={__("Názov kategórie")}
      />

      <Field
        component={FormFieldRedux}
        name="displayName"
        label={__("Názov kategórie v menu")}
      />

      <Field
        component={FormFieldRedux}
        name="hidden"
        label={__("Nezobrazovať v menu")}
        type="checkbox"
        info={__(
          "Položka sa nebude zobrazovať v menu. Bude ale stále prístupná na svojej URL.",
        )}
      />

      <Field
        placeholder={__('Bude vyplnené automaticky (príklad: "url")')}
        info={__(
          'Relatívna URL, na ktorej bude kategória dostupná (príklad: "url-novej-kategorie"). Hodnota bude predvyplnená automaticky po prvom uložení.',
        )}
        label={__("URL")}
        component={FormFieldRedux}
        name="url"
      />
      <Field
        component={FormFieldRedux}
        name="priority"
        label={__("Priorita")}
        format={(val) => (Number.isNaN(val) ? "" : val)}
        parse={parseToNumber}
      />
      <Field
        component={FormFieldRedux}
        name="meta.title"
        counter={{ characters: true }}
        label={__("Meta title")}
      />
      <Field
        component={FormFieldRedux}
        type="textarea"
        name="meta.description"
        counter={{ characters: true }}
        label={__("Meta description")}
      />

      <FormGroup>
        <Label>{__("Parametre")}</Label>
        <Field
          name="searchParams"
          component={FormFieldRedux}
          multiple
          type="theselectsimple"
          options={parameters
            .map((item) => {
              const exists = searchParams.includes(item);
              if (!exists) {
                return { label: item.label, value: item.label };
              }
              return null;
            })
            .filter((i) => i)}
        />
      </FormGroup>
      <Modal handleClose={toggleProductSorter} isOpen={productSorterOpened}>
        <ModalContent>
          {productSorterOpened && (
            <ProductsSorter
              saleschannel={selectedChannelId}
              appSpace={organization._id}
              categoryId={selectedCategory._id}
            />
          )}
        </ModalContent>
        <ModalActions>
          <div />
          &nbsp;
          <Button onClick={toggleProductSorter}>{__("Zavrieť")}</Button>
        </ModalActions>
      </Modal>
      <Modal
        handleClose={toggleCategoryDescription}
        isOpen={categoryDescriptionOpened}
      >
        <ModalContent>
          <ThemeProvider theme={defaultContentTheme}>
            {categoryDescriptionOpened && (
              <Widgets
                appId={process.env.REACT_APP_APP_ID}
                appSpace={organization._id}
                contentDivider="default"
                token={accessToken}
                initialValue={description || []}
                onChange={(i) => {
                  dispatch(change(form, "description", i));
                }}
              />
            )}
          </ThemeProvider>
        </ModalContent>
        <ModalActions>
          <div />
          &nbsp;
          <div>
            <Button primary onClick={toggleCategoryDescription} type="button">
              {__("OK")}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </>
  );
};

CategoryForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  parameters: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string }))
    .isRequired,
  searchParams: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedChannel: PropTypes.string,
  selectedChannelId: PropTypes.string,
  selectedCategory: PropTypes.shape({
    _id: PropTypes.string,
    description: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.form);
  return {
    searchParams: selector(state, "searchParams") || [],
    description: selector(state, "description") || [],
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({ destroyOnUnmount: true }),
)(CategoryForm);
