export const REQUEST_ORDERS = 'Orders/REQUEST_ORDERS';
export const RECEIVE_ORDERS_SUCCESS = 'Orders/RECEIVE_ORDERS_SUCCESS';
export const RECEIVE_ORDERS_ERROR = 'Orders/RECEIVE_ORDERS_ERROR';
export const REQUEST_CODELIST_STATUSES = 'Orders/REQUEST_CODELIST_STATUSES';
export const RECEIVE_CODELIST_STATUSES_SUCCESS =
  'Orders/RECEIVE_CODELIST_STATUSES_SUCCESS';
export const RECEIVE_CODELIST_STATUSES_ERROR =
  'Orders/RECEIVE_CODELIST_STATUSES_ERROR';
export const SET_ACTIVE_ORDER_ID = 'Order/SET_ACTIVE_ORDER_ID';
export const REQUEST_ORDER = 'Order/REQUEST_ORDER';
export const RECEIVE_ORDER_SUCCESS = 'Order/RECEIVE_ORDER_SUCCESS';
export const SET_PARAMETER = 'Order/SET_PARAMETER';
export const SET_PARAMETERS = 'Order/SET_PARAMETERS';
