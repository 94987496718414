import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { createRoot } from "react-dom/client";
import { setDefaultOptions } from "date-fns";
import { sk } from "date-fns/locale";
import { theme } from "./theme";
import AppWrapper from "./containers/AppWrapper";
import GlobalStyles from "./globalStyles";
import React from "react";
import configureStore from "./configureStore";

require("./svg.js");
setDefaultOptions({ locale: sk });
const store = configureStore();

const element = document.getElementById("root");
const root = createRoot(element);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <AppWrapper />
      </Router>
    </ThemeProvider>
  </Provider>,
);
