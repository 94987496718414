import { __, formatPrice, getProp } from "../../utilities";
import { rem } from "polished";
import ItemInfo from "./ItemInfo";
import MediaManagerImage from "@sportnet/ui/MediaManagerImage";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${rem(10)} 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColorLighter};

  @media print {
    padding: ${rem(8)} 0;
    page-break-inside: avoid;
  }
`;

export const PhotoWrappper = styled.div`
  display: none;
  ${({ theme }) => theme.eshopMedia.md`
    display: initial;
    flex: 0 0 10%;
  `} ${({ theme }) => theme.eshopMedia.lg`
    display: initial;
    flex: 0 0 10%;
  `}
  img {
    border: ${rem(1)} solid #f1f1f1;
  }
`;

export const Detail = styled.div`
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.eshopMedia.lg`
    align-items: center;
    flex-direction: row;
  `};
`;

export const DetailProduct = styled.div`
  width: 100%;
  ${({ theme }) => theme.eshopMedia.lg`
     flex: 0 0 70%;
  `};
`;

export const DetailPrice = styled.div`
  flex: 0 0 20%;
  ${({ theme }) => theme.eshopMedia.lg`
     flex: 0 0 30%;
  `};
`;

export const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 20%;
  text-align: right;
  ${({ theme }) => theme.eshopMedia.md`
    flex: 0 0 20%;
    margin-left: auto;
  `};
`;

const Title = styled.span`
  font-weight: bold;
`;

const TextStrike = styled.span`
  text-decoration: line-through;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

class OrderItem extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onChangeItemQuantity = this.onChangeItemQuantity.bind(this);
  }

  onClickDeleteItem() {
    const r = window.confirm(__("Naozaj chcete odstrániť produkt?"));
    if (r) {
      const { item, onClickDeleteItem } = this.props;
      onClickDeleteItem(item.item_id);
    }
  }

  onChangeItemQuantity(quantity) {
    const { item } = this.props;
    this.props.onChangeItemQuantity(item.item_id, quantity);
  }

  render() {
    const {
      item,
      item: {
        variant = {},
        variant: { unitprice = {} } = {},
        oldprice,
        totalprice = {},
        units,
        item_picture: itemPicture = {},
        quantity = 0,
        paidbycredit = false,
        credits,
      },
    } = this.props;
    let pictureUri = getProp(itemPicture, ["uri"]);
    const pictureMMUri = getProp(itemPicture, ["media_url"]);
    if (pictureMMUri) {
      pictureUri = pictureMMUri.replace("{size}", "80x80");
    } else if (!pictureUri.startsWith("http")) {
      pictureUri = `${process.env.REACT_APP_IMAGE_BASE_URL}/80x80/${pictureUri}`;
    }
    const variantPicture = getProp(variant, ["picture"]);
    return (
      <Wrapper>
        <PhotoWrappper>
          {variantPicture && (
            <MediaManagerImage
              picture={variantPicture}
              naturalHeight={80}
              naturalWidth={80}
              script="media"
            />
          )}
          {!variantPicture && pictureUri && <Img src={pictureUri} />}
        </PhotoWrappper>

        <Detail>
          <DetailProduct>
            <ItemInfo item={item} variant={variant} />
          </DetailProduct>

          <DetailPrice>
            {oldprice > 0 && (
              <TextStrike>
                {formatPrice(oldprice, unitprice.currency)}
                <br />
              </TextStrike>
            )}
            <Title>
              {formatPrice(
                getProp(unitprice, ["amount"], 0),
                unitprice.currency,
              )}
              {` / ${quantity}`}
              {` ${units || "ks"}`}
            </Title>
            <br />
            <span>
              {__("Sadzba DPH")}
              {": "}
              {unitprice?.vat_rate ?? variant?.vat_rate}
              {"%"}
            </span>
          </DetailPrice>
        </Detail>

        <TotalPrice>
          <Title>
            {formatPrice(
              getProp(totalprice, ["amount"], 0),
              totalprice.currency,
            )}{" "}
            {__("s DPH")}
          </Title>
          {paidbycredit && (
            <span>
              {__("Hradené kreditmi")}
              {":"}
              &nbsp;
              {formatPrice(
                typeof credits === "undefined"
                  ? getProp(totalprice, ["amount"], 0)
                  : credits,
                totalprice.currency,
              )}
            </span>
          )}
        </TotalPrice>
      </Wrapper>
    );
  }
}

export const orderItemPropTypes = {
  item: PropTypes.shape({
    item_id: PropTypes.string.isRequired,
    name: PropTypes.string,
    code: PropTypes.string,
    oldprice: PropTypes.number,
    quantity: PropTypes.number,
    totalprice: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      amount_vat_excl: PropTypes.number,
    }),
    item_picture: PropTypes.shape({
      uri: PropTypes.string,
    }),
    variant: PropTypes.shape({
      params: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string.isRequired,
        }),
      }),
      unitprice: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string,
      }),
    }),
  }),
};

OrderItem.propTypes = {
  ...orderItemPropTypes,
  onChangeItemQuantity: PropTypes.func,
  onClickDeleteItem: PropTypes.func,
};

OrderItem.defaultProps = {
  onChangeItemQuantity: () => {},
  onClickDeleteItem: () => {},
};

export default OrderItem;
