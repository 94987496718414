import { __ } from "../../../utilities";
import { em, lighten, rem } from "polished";
import { useForm, useFormState } from "react-final-form";
import Col, { Row } from "@sportnet/ui/Grid";
import Icon from "@sportnet/ui/Icon";
import Message from "@sportnet/ui/Message";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import Tooltip from "@sportnet/ui/Tooltip";
import styled, { useTheme } from "styled-components";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${rem(8)};
  row-gap: ${rem(8)};
`;

const Values = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

const ParamKey = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(4)};
  font-size: ${em(14)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

const ParamLabel = styled.div`
  background-color: ${({ theme, $selected, $missing }) =>
    !!$selected
      ? $missing
        ? theme.color.danger
        : theme.color.primary
      : "transparent"};
  color: ${({ theme, $selected }) => (!!$selected ? theme.white : theme.black)};
  border-radius: ${rem(4)};
  border: ${rem(1)} solid
    ${({ theme, $selected, $missing }) =>
      $selected
        ? $missing
          ? theme.color.danger
          : theme.color.primary
        : $missing
          ? theme.color.danger
          : theme.black};
  height: ${rem(28)};
  display: flex;
  align-items: center;
  padding: 0 ${rem(12)};
  font-size: ${em(14)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, $selected, $missing }) =>
      $selected
        ? lighten(0.04, $missing ? theme.color.danger : theme.color.primary)
        : "rgba(0,0,0,0.04)"};
  }
`;

const Params = ({ productParams = [] }) => {
  const theme = useTheme();
  const { values } = useFormState();
  const {
    mutators: { setParams },
  } = useForm();

  const variantParams = values?.params || [];

  const mergeParams = (paramsA = [], paramsB = []) => {
    return [
      ...paramsA,
      ...(paramsB
        ?.filter(
          (param) => !(paramsA.map((pp) => pp.key) || []).includes(param.key),
        )
        ?.map((p) => {
          return {
            key: p.key,
            ...(p.type && { type: p.type }),
            ...(!!p.value && {
              values: [
                {
                  key: p.value,
                },
              ],
            }),
            missing: !(paramsA?.map((pp) => pp.key) || []).includes(p.key),
          };
        }) || []),
    ];
  };

  const availableBasicParams = React.useMemo(() => {
    const basicProductParams = productParams.filter((p) => p.type !== "text");
    const basicVariantParams = variantParams.filter((p) => p.type !== "text");

    return mergeParams(basicProductParams, basicVariantParams);
  }, [productParams, variantParams]);

  const availableTextParams = React.useMemo(() => {
    const textProductParams = productParams.filter((p) => p.type === "text");
    const textVariantParams = variantParams.filter((p) => p.type === "text");

    return mergeParams(textProductParams, textVariantParams);
  }, [variantParams, productParams]);

  return (
    <Segment
      raised
      header={
        <SegmentHeader withSeparator size="s">
          {__("Parametre")}
        </SegmentHeader>
      }
    >
      <Wrapper>
        <Message
          warning
          title={
            !![...availableBasicParams, ...availableTextParams]?.length
              ? __("Zvoľte len tie parametre, ktorými sa variant líši.")
              : __("Pre tento produkt neexistujú žiadne parametre.")
          }
        />
        {availableBasicParams?.map((param) => (
          <Row>
            <Col>
              <Values>
                <ParamKey>
                  <b>{param.key}</b>
                  {!!param.missing && (
                    <Tooltip
                      visible={true}
                      content={
                        <>{__(`POZOR: Parameter ${param.key} neexistuje.`)}</>
                      }
                    >
                      <Icon name="info" size={16} color={theme.color.danger} />
                    </Tooltip>
                  )}
                </ParamKey>
                <FlexRow>
                  {param.values?.map((v) => (
                    <ParamLabel
                      $missing={v?.missing}
                      $selected={
                        !!variantParams?.find(
                          (p) => p.key === param.key && p.value === v.key,
                        )
                      }
                      onClick={() => {
                        if (
                          !!variantParams?.find(
                            (p) => p.key === param.key && p.value === v.key,
                          )
                        ) {
                          const newParams = [...variantParams].filter(
                            (p) => p.key !== param.key || p.value !== v.key,
                          );

                          setParams(newParams);
                        } else {
                          const newParams = [...variantParams];
                          const existingParamIdx = newParams?.findIndex(
                            (p) => p.key === param.key,
                          );

                          if (existingParamIdx > -1) {
                            newParams[existingParamIdx].value = v.key;
                          } else {
                            newParams.push({
                              key: param.key,
                              value: v.key,
                            });
                          }

                          setParams(newParams);
                        }
                      }}
                    >
                      {v.key}
                    </ParamLabel>
                  ))}
                </FlexRow>
              </Values>
            </Col>
          </Row>
        ))}

        {!!availableTextParams?.length && (
          <Row>
            <Col>
              <Values>
                <ParamKey>
                  <b>{__("Textové parametre")}</b>
                </ParamKey>
                <FlexRow>
                  {availableTextParams.map((param) => {
                    return (
                      <ParamLabel
                        $missing={param?.missing}
                        $selected={
                          !!variantParams?.find((p) => p.key === param.key)
                        }
                        onClick={() => {
                          if (
                            !!variantParams?.find((p) => p.key === param.key)
                          ) {
                            const newParams = [...variantParams].filter(
                              (p) => p.key !== param.key,
                            );

                            setParams(newParams);
                          } else {
                            const newParams = [...variantParams];

                            newParams.push({
                              key: param.key,
                              type: param.type,
                            });

                            setParams(newParams);
                          }
                        }}
                      >
                        {param.key}
                      </ParamLabel>
                    );
                  })}
                </FlexRow>
              </Values>
            </Col>
          </Row>
        )}
      </Wrapper>
    </Segment>
  );
};

export default Params;
