import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __ } from "../../utilities";
import { catalogsIsFetchingSelector, catalogsListSelector } from "./selectors";
import { connect } from "react-redux";
import {
  editCatalog,
  fetchCatalogs,
  postCatalog,
  removeCatalog,
} from "./actions";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { saleChanellsListSelector } from "../SaleChannels/selectors";
import { useAuth } from "@sportnet/auth-react";
import { withTheme } from "styled-components";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import DefaultWidgetPhoto from "@sportnet/content/editor/widgets/Photo";
import FormGroup from "@sportnet/ui/FormGroup";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Input from "@sportnet/ui/Input";
import Label from "@sportnet/ui/Label/Label";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import Sidebar from "@sportnet/ui/Sidebar";
import Tabber from "@sportnet/ui/Tabber";

const CatalogsList = ({ salesChannels, catalogs, dispatch }) => {
  const { accessToken, ppo } = useAuth();
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [selectedChannel, setSelectedChannel] = React.useState();
  const [form, setForm] = React.useState({
    name: "",
    redirectUri: "",
    buttonTitle: "",
    picture: {
      uri: "",
      name: "",
    },
  });

  const eligibleCatalogs = React.useMemo(() => {
    if (selectedChannel) {
      const channel = salesChannels.find((i) => i.name === selectedChannel);
      return catalogs.filter((catalog) => catalog.saleschannel === channel._id);
    }
    return [];
  }, [selectedChannel, catalogs]);

  React.useEffect(() => {
    dispatch(fetchSaleChannelsList(ppo));
    dispatch(fetchCatalogs(ppo));
    if (salesChannels.length > 0) {
      setInitialState(salesChannels);
    }
  }, [ppo, dispatch]);

  React.useEffect(() => {
    setInitialState(salesChannels);
  }, [salesChannels]);

  const setInitialState = (salesChannels) => {
    if (!!salesChannels.length) {
      setSelectedChannel(salesChannels[0].name);
    }
  };

  const handleRemove = (id, e) => {
    e.stopPropagation();
    if (window.confirm("Skutočne si želáte zmazať daný katalóg?")) {
      dispatch(removeCatalog(ppo, id)).then(() => {
        dispatch(fetchCatalogs(ppo));
      });
    }
  };

  const submitForm = () => {
    const {
      form: { name, redirectUri, buttonTitle, picture },
    } = form;

    if (!name || !redirectUri || !buttonTitle || !picture.uri) {
      alert("Vyplňte prosím všetky potrebné údaje");
    } else {
      const selectedChannel = salesChannels.find(
        (item) => item.name === selectedChannel,
      );
      const data = {
        name,
        redirectUri,
        buttonTitle,
        picture,
        saleschannel: selectedChannel._id,
      };
      if (form._id) {
        dispatch(editCatalog(ppo, form._id, data)).then(() => {
          resetForm();
          dispatch(fetchCatalogs(ppo));
        });
      } else {
        dispatch(postCatalog(ppo, data)).then(() => {
          resetForm();
          dispatch(fetchCatalogs(ppo));
        });
      }
    }
  };

  const resetForm = () => {
    setForm({
      name: "",
      redirectUri: "",
      buttonTitle: "",
      picture: {
        uri: "",
        name: "",
      },
    });
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>
              {__("Zoznam produktových zoznamov")}
            </HeaderBar.Header>
          </HeaderBar>
        </>
      }
      bottomFixed={
        selectedChannel && (
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                onClick={() => {
                  setSidebarVisible(true);
                }}
              >
                {__("Pridať produktový zoznam")}
              </Button>
            </ContextBarItem>
          </ContextBar>
        )
      }
    >
      <AppContext title={__("Zoznam produktových zoznamov")} />
      <Tabber
        active={selectedChannel}
        items={salesChannels.map((channel) => channel.name)}
        onClickItem={(e) => {
          setSelectedChannel(e);
        }}
      />
      {selectedChannel && (
        <Segment noBottomGutter>
          <Segment raised>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__("Názov katalógu")}</Th>
                  <Th>{__("URL")}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {eligibleCatalogs.map((catalog) => {
                  return (
                    <Tr
                      key={catalog._id}
                      onClick={() => {
                        setSidebarVisible(true);
                        setForm(catalog);
                      }}
                    >
                      <Td>{catalog.name}</Td>
                      <Td>{catalog.redirectUri}</Td>
                      <Td right>
                        <Button
                          icon="trash"
                          danger
                          onClick={(e) => handleRemove(catalog._id, e)}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Segment>
        </Segment>
      )}
      <Sidebar
        visible={sidebarVisible}
        header={__("Katalóg")}
        onClose={() => {
          setSidebarVisible(false);
          resetForm();
        }}
      >
        <Segment>
          <FormGroup>
            <Label>{__("Predajný kanál")}</Label>
            <Input disabled value={selectedChannel} />
          </FormGroup>

          <FormGroup>
            <Label>{__("Názov katalógu")}</Label>
            <Input
              value={form.name}
              onChange={(e) => {
                setForm((oldForm) => ({
                  ...oldForm,
                  name: e.target.value,
                }));
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__("URL")}</Label>
            <Input
              value={form.redirectUri}
              onChange={(e) => {
                setForm((oldForm) => ({
                  ...oldForm,
                  redirectUri: e.target.value,
                }));
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__("Tlačidlo")}</Label>
            <Input
              value={form.buttonTitle}
              onChange={(e) => {
                setForm((oldForm) => ({
                  ...oldForm,
                  buttonTitle: e.target.value,
                }));
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>{__("Fotografia")}</Label>
            <DefaultWidgetPhoto
              data={
                form.picture || {
                  uri: "",
                }
              }
              appSpace={ppo}
              appId={process.env.REACT_APP_APP_ID}
              accessToken={accessToken}
              onChange={(e) => {
                const uri =
                  e.uri ||
                  (e.public_url || "").replace(
                    "https://mediamanager.sportnet.online/media/",
                    "",
                  );
                setForm((oldForm) => ({
                  ...oldForm,
                  picture: {
                    ...oldForm.picture,
                    ...(e.picture || {}),
                    uri,
                    picture: e.picture || {},
                  },
                }));
              }}
            />
          </FormGroup>

          <FormGroup>
            <Button primary onClick={submitForm} block>
              {__("Uložiť")}
            </Button>
          </FormGroup>
        </Segment>
      </Sidebar>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    salesChannels: saleChanellsListSelector(state),
    catalogs: catalogsListSelector(state),
    isFetching: catalogsIsFetchingSelector(state),
  };
};

CatalogsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.shape({}).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  catalogs: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string,
      name: PropTypes.string,
      picture: PropTypes.shape({
        uri: PropTypes.string,
      }),
      redirectUri: PropTypes.string,
      saleschannel: PropTypes.string,
      _id: PropTypes.string,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(withTheme(CatalogsList));
