import { __ } from "../../../utilities";
import { connect } from "react-redux";
import { useAuth } from "@sportnet/auth-react";
import ApiEshop from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SubmitButton from "@sportnet/ui/Button/Submit";

const Packetery = () => {
  const [loading, setLoading] = React.useState(true);
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [packeterySettings, setPacketerySettings] = React.useState({
    apiKey: "",
    apiPassword: "",
    eshopName: "",
  });
  const { ppo } = useAuth();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const newSettings = await ApiEshop.adminGetSupplierPacketery(ppo);
        setPacketerySettings(newSettings);
      } catch (e) {
        console.error(e);
        alert(__("Nemáte právo upravovať zásielkovňu"));
      } finally {
        setLoading(false);
      }
    })();
  }, [ppo]);

  const handleSave = async () => {
    setSubmitting(true);
    setSubmitFailed(false);
    try {
      const newSettings = await ApiEshop.adminUpdatePacketery(ppo, {
        data: packeterySettings,
      });
      setPacketerySettings(newSettings);
    } catch (e) {
      setSubmitFailed(true);
      console.error(e);
      alert(__("Uloženie nastavení zásielkovne sa nepodarilo"));
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeValue = (e) => {
    const {
      target: { name, value },
    } = e;
    setPacketerySettings((prev) => ({ ...(prev || {}), [name]: value }));
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Nastavenia zásielkovne")}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext title={__("Nastavenia zásielkovne")} />
      <Segment noBottomGutter>
        <Segment raised loading={loading}>
          <Row>
            <Col xs={12} m={6}>
              <FormField
                label={__("API kľúč")}
                name="apiKey"
                value={packeterySettings.apiKey || ""}
                onChange={handleChangeValue}
              />
            </Col>
            <Col xs={12} m={6}>
              <FormField
                label={__("API heslo")}
                name="apiPassword"
                value={packeterySettings.apiPassword || ""}
                onChange={handleChangeValue}
              />
            </Col>
            <Col xs={12} m={6}>
              <FormField
                label={__("Dodávateľ")}
                name="eshopName"
                value={packeterySettings.eshopName || ""}
                onChange={handleChangeValue}
              />
            </Col>
          </Row>
          <SubmitButton
            isError={submitFailed}
            isSubmitting={submitting}
            successText={__("Uložené!")}
            onClick={handleSave}
          >
            {__("Uložiť")}
          </SubmitButton>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {};

export default connect(mapStateToProps)(Packetery);
