import { createSelector } from "reselect";
import { getProp } from "@sportnet/utilities";

const applicationDomainSelector = (state) => state.application;

export const applicationInfoSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.data,
);

export const organizationSelector = createSelector(
  applicationDomainSelector,
  (substate) => {
    return substate.organization;
  },
);

export const organizationProfileSelector = createSelector(
  organizationSelector,
  (substate) => {
    if (substate) {
      return { _id: substate.app_space };
    }
    return null;
  },
);

export const isSidebarVisibleSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.sidebarVisible,
);
export const aclSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.acl,
);

export const appsSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.apps,
);

export const appspacesSelector = createSelector(
  applicationDomainSelector,
  (substate) => substate.appspaces,
);

// Entities
export const entitiesSelector = (state) => {
  return state.entities;
};

export const entityTypeSelector = (type) =>
  createSelector(
    entitiesSelector,
    () => type,
    (entities, entityType) => entities[entityType],
  );

export const entitySelector = (type, id) =>
  createSelector(
    entityTypeSelector(type),
    () => id,
    (entityById, entityId) =>
      entityId in entityById ? entityById[entityId] : null,
  );

// Codelist

export const codelistItemsSelector = (id) =>
  createSelector(entitySelector("codelists", id), (codelist) =>
    getProp(codelist, ["items"], []).map(({ label, value }) => ({
      value,
      label: label || value,
    })),
  );

export const codelistItemsByValueSelector = (id) =>
  createSelector(codelistItemsSelector(id), (codelistItems) => {
    return codelistItems.reduce((acc, codelistItem) => {
      acc[codelistItem.value] = codelistItem;
      return acc;
    }, {});
  });

export const codelistLabelByValueSelector = (id) =>
  createSelector(codelistItemsSelector(id), (codelistItems) => {
    return codelistItems.reduce((acc, codelistItem) => {
      acc[codelistItem.value] = codelistItem.label || codelistItem.value;
      return acc;
    }, {});
  });
