import * as React from "react";
import { Route, Switch } from "react-router-dom";
import BasicSettings from "./BasicSettings";
import DomainSettings from "./DomainSettings";

export default function () {
  return (
    <Switch>
      <Route
        exact
        path="/admin/:appspace/sale-channels/:salesChannelId/domain"
        component={DomainSettings}
      />
      <Route
        exact
        path="/admin/:appspace/sale-channels/:salesChannelId"
        component={BasicSettings}
      />
    </Switch>
  );
}
