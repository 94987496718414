/**
 * These dependencies will be made available to the Remote Components.
 */
module.exports = {
  resolve: {
    react: require("react"),
    "react-redux": require("react-redux"),
    redux: require("redux"),
    "redux-form": require("redux-form"),
    "redux-thunk": require("redux-thunk"),
    "styled-components": require("styled-components"),
  },
};
