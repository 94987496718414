import { Field, reduxForm } from 'redux-form';
import { LIST_SUPPLIERS } from './actions';
import { __, reduxFormKeyify } from '../../utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isCommiting } from '@sportnet/redux-list';
import { listSuppliersSelector } from './selectors';
import BasicTable from '@sportnet/ui/BasicTable';
import CheckboxRedux from '@sportnet/ui/Checkbox/redux-form';
import InputRedux from '@sportnet/ui/Input/redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import Segment from '@sportnet/ui/Segment';

export const FORM_NAME = 'FEATURED_PARTNERS';

const FeaturedPartnersForm = ({
  handleSubmit,
  suppliers,
  isListCommiting,
  submitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Segment noBottomGutter>
        <Segment raised loading={isListCommiting || submitting} noBottomGutter>
          <BasicTable
            columns={[
              {
                id: 'check',
                header: 'TOP',
                width: 45,
              },
              {
                id: 'partner',
                header: __('Partner'),
              },
              {
                id: 'priority',
                header: __('Poradie'),
                width: 200,
              },
            ]}
            rows={suppliers}
            rowKey="_id"
            renderRow={supplier => [
              <Field
                name={`suppliers.${reduxFormKeyify(supplier._id)}.featured`}
                component={CheckboxRedux}
              />,
              supplier._id,
              <Field
                name={`suppliers.${reduxFormKeyify(
                  supplier._id,
                )}.featuredOrder`}
                component={InputRedux}
                type="number"
                placeholder="0"
              />,
            ]}
          />
        </Segment>
      </Segment>
    </form>
  );
};

FeaturedPartnersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  isListCommiting: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const suppliers = listSuppliersSelector(LIST_SUPPLIERS)(state);
  return {
    suppliers,
    isListCommiting: isCommiting(LIST_SUPPLIERS)(state) || false,
    initialValues: {
      suppliers: suppliers.reduce((acc, s) => {
        acc[reduxFormKeyify(s._id)] = {
          featured: !!s.featured,
          featuredOrder: String(s.featuredOrder || 0),
        };
        return acc;
      }, {}),
    },
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  }),
)(FeaturedPartnersForm);
