import { createSelector } from 'reselect';
import { getProp } from '../../utilities';

const saleChannelsDomainSelector = state => state.saleChannels;

export const saleChanellsListSelector = createSelector(
  saleChannelsDomainSelector,
  substate => getProp(substate, ['data'], []),
);

export const nonSharedSaleChanellsListSelector = createSelector(
  saleChanellsListSelector,
  substate => substate.filter(item => !item.shared),
);

export const saleChanellsIsFetchingSelector = createSelector(
  saleChannelsDomainSelector,
  substate => getProp(substate, ['isFetching'], true),
);
