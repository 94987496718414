import { createSelector } from "reselect";
import { entitiesSelector } from "../App/selector";
import { getListResults } from "@sportnet/redux-list";
import at from "@sportnet/utilities/at";

export const listImportsSelector = (listName) =>
  createSelector(entitiesSelector, getListResults(listName), (entities, ids) =>
    at(entities.imports, ids || [])
  );

const importsDomainSelector = (state) => {
  return state.imports;
};

export const importsShowSidebarSelector = createSelector(
  importsDomainSelector,
  (substate) => substate.showSidebar
);
