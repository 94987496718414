import { createSelector } from 'reselect';
import {
  formatSmarttags,
  getProp,
  serializeSearchParameters,
} from '../../utilities';

const productsDomainSelector = state => state.products;

const productsDataSelector = createSelector(
  productsDomainSelector,
  substate => substate.data,
);

const productsOffsetSelector = createSelector(
  productsDataSelector,
  substate => substate.offset || 0,
);

const productsLimitSelector = createSelector(
  productsDataSelector,
  substate => substate.limit || 100,
);

export const activeParametersSelector = createSelector(
  productsDomainSelector,
  substate => substate.parameters,
);

export const preparedActiveParametersSelector = createSelector(
  activeParametersSelector,
  parameters => {
    const params = Object.keys(parameters).reduce((acc, next) => {
      if (!parameters[next] || parameters[next].length === 0) {
        return acc;
      }
      return { ...acc, [next]: parameters[next] };
    }, {});
    const smarttags = formatSmarttags(
      params.smarttagsInclude,
      params.smarttagsExclude,
    );
    if (smarttags) {
      params.smarttags = smarttags;
    }
    return params;
  },
);

const productsByIdSelector = createSelector(
  productsDomainSelector,
  substate => substate.byId,
);

const activeProductIdSelector = createSelector(
  productsDomainSelector,
  substate => substate.activeId,
);

export const getProductByIdSelector = createSelector(
  productsByIdSelector,
  activeProductIdSelector,
  (products, id) => getProp(products, [id], null),
);

const productsByParametersSelector = createSelector(
  productsDomainSelector,
  substate => substate.byParameters,
);

const productsByActiveParametersSelector = createSelector(
  activeParametersSelector,
  productsByParametersSelector,
  (parameters, products) => products[serializeSearchParameters(parameters)],
);

const activeProductsByOffsetSelector = createSelector(
  productsByActiveParametersSelector,
  productsOffsetSelector,
  productsLimitSelector,
  (productsByParametersNames, offset, limit) =>
    getProp(productsByParametersNames, [`${offset}-${offset + limit}`]),
);

const productIdsByActiveParametersSelector = createSelector(
  activeProductsByOffsetSelector,
  offsetedProducts => getProp(offsetedProducts, ['ids'], []),
);

export const productsIsFetchingByActiveParametersSelector = createSelector(
  activeProductsByOffsetSelector,
  offsetedProducts => getProp(offsetedProducts, ['isFetching'], true),
);

export const getCurrentProductsSelector = createSelector(
  productIdsByActiveParametersSelector,
  productsByIdSelector,
  (ids, products) => {
    const eglibleProducts = [];
    ids.forEach(id => {
      if (products[id]) {
        eglibleProducts.push(products[id]);
      }
    });
    return eglibleProducts;
  },
);
