import { ModalActions, ModalContent } from "@sportnet/ui/Modal";
import { SubmissionError, isSubmitting, submit } from "redux-form";
import { __ } from "../../../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { getProp } from "@sportnet/utilities";
import { preparedActiveParametersSelector } from "../../../Products/selectors";
import Button from "@sportnet/ui/Button";
import PropTypes from "prop-types";
import React from "react";
import SetDiscountOperationForm, { FORM_NAME } from "./form";
import apiEshop from "../../../../service/apiEshop";
import withPopups, { WithPopupsProps } from "../../../../components/WithPopups";

class SetDiscountOperation extends React.PureComponent {
  handleSubmit = async (data) => {
    const { appSpace, productListParams, alert, handleClose, confirm } =
      this.props;
    if (
      !(await confirm(
        __("Naozaj chcete nastaviť zľavu na všetky vybrané produkty?")
      ))
    ) {
      return;
    }
    try {
      const { discount } = data;
      await apiEshop.adminBulkProductOperation(appSpace, {
        ...productListParams,
        data: {
          type: "SET_DISCOUNT",
          data: {
            discount,
          },
        },
      });
      alert(__("Operácia prebehla úspešne!"), { success: true }).then(
        handleClose
      );
    } catch (e) {
      const submissionError = {
        _error: __("Nastavenie zľavy sa nepodarilo."),
      };
      // check field errors
      const fieldErrors = getProp(e, ["details", "payload", "errors"], []);
      if (fieldErrors.length) {
        fieldErrors.forEach((fieldError) => {
          submissionError[fieldError.path.join(".")] = __(
            "Chybne vyplnené pole"
          );
        });
      }
      alert(submissionError._error);
      throw new SubmissionError(submissionError);
    }
  };

  handleClickSubmit = async () => {
    const { submitForm } = this.props;
    submitForm();
  };

  render() {
    const { handleClose, isFormSubmitting, children } = this.props;

    return (
      <>
        <ModalContent>
          {children}
          <SetDiscountOperationForm onSubmit={this.handleSubmit} />
        </ModalContent>
        <ModalActions>
          <Button onClick={handleClose}>{__("Zavrieť")}</Button>
          <Button
            onClick={this.handleClickSubmit}
            primary
            loading={isFormSubmitting}
            disabled={isFormSubmitting}
          >
            {__("Vykonať operáciu")}
          </Button>
        </ModalActions>
      </>
    );
  }
}

SetDiscountOperation.propTypes = {
  handleClose: PropTypes.func.isRequired,
  appSpace: PropTypes.string.isRequired,
  productListParams: PropTypes.shape({}).isRequired,
  submitForm: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  ...WithPopupsProps,
};

const mapStateToProps = (state) => {
  return {
    appSpace: state.selectedOrg,
    productListParams: preparedActiveParametersSelector(state),
    isFormSubmitting: isSubmitting(FORM_NAME)(state) || false,
  };
};

const mapDispatchToProps = {
  submitForm: () => submit(FORM_NAME),
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps)
)(SetDiscountOperation);
