import { createSelector } from 'reselect';
import { getProp, serializeSearchParameters } from '../../utilities';

const purchaseDomainSelector = state => state.purchase;
const ordersDomainSelector = createSelector(
  purchaseDomainSelector,
  substate => substate.orders,
);

const ordersDataSelector = createSelector(
  ordersDomainSelector,
  substate => substate.data,
);

const ordersOffsetSelector = createSelector(
  ordersDataSelector,
  substate => substate.offset || 0,
);

const ordersLimitSelector = createSelector(
  ordersDataSelector,
  substate => substate.limit || 100,
);

export const activeParametersSelector = createSelector(
  ordersDomainSelector,
  substate => substate.parameters,
);

const ordersByIdSelector = createSelector(
  ordersDomainSelector,
  substate => substate.byId,
);

const activeOrderIdSelector = createSelector(
  ordersDomainSelector,
  substate => substate.activeId,
);

export const getOrderByIdSelector = createSelector(
  ordersByIdSelector,
  activeOrderIdSelector,
  (orders, id) => getProp(orders, [id], null),
);

const ordersByParametersSelector = createSelector(
  ordersDomainSelector,
  substate => substate.byParameters,
);

const ordersByActiveParametersSelector = createSelector(
  activeParametersSelector,
  ordersByParametersSelector,
  (parameters, orders) => orders[serializeSearchParameters(parameters)],
);

const activeOrdersByOffsetSelector = createSelector(
  ordersByActiveParametersSelector,
  ordersOffsetSelector,
  ordersLimitSelector,
  (ordersByParametersNames, offset, limit) =>
    getProp(ordersByParametersNames, [`${offset}-${offset + limit}`]),
);

const orderIdsByActiveParametersSelector = createSelector(
  activeOrdersByOffsetSelector,
  offsetedOrders => getProp(offsetedOrders, ['ids'], []),
);

export const ordersIsFetchingByActiveParametersSelector = createSelector(
  activeOrdersByOffsetSelector,
  offsetedOrders => getProp(offsetedOrders, ['isFetching'], true),
);

export const getCurrentOrdersSelector = createSelector(
  orderIdsByActiveParametersSelector,
  ordersByIdSelector,
  (ids, orders) => {
    const eglibleOrders = [];
    ids.forEach(id => {
      if (orders[id]) {
        eglibleOrders.push(orders[id]);
      }
    });
    return eglibleOrders;
  },
);
