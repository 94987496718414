import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, getLabelofOpt, getProp } from "../../utilities";
import {
  activeParametersSelector,
  getCurrentProductsSelector,
  productsIsFetchingByActiveParametersSelector,
} from "./selectors";
import { categoriesFlatCodelistSelector } from "../Categories/selectors";
import { connect } from "react-redux";
import { fetchCategories } from "../Categories/actions";
import { fetchProducts, setParameters } from "./actions";
import { organizationProfileSelector } from "../App/selector";
import { rem } from "polished";
import { routerParametersDefinition } from "../App/definitions";
import ApiEshop from "../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "../../components/Icon";
import Layout from "../../components/Layout";
import Paginator from "@sportnet/ui/Paginator";
import ProductBulkOperations from "../ProductBulkOperations";
import ProductsFilter from "./ProductsFilter";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import connectPagination from "../PaginationHoc";
import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
  div {
    visibility: hidden;
  }
  &:hover {
    div {
      visibility: visible;
    }
  }
`;

const Img = styled.img`
  height: 100%;
  max-height: 100%;
`;

const Products = ({
  org,
  offset,
  total,
  activeParameters,
  isFetching,
  products,
  match: {
    params: { appspace },
  },
  categoriesCodelist,
  dispatch,
  history,
  pagination: { page },
}) => {
  const [codelist, setCodelist] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [salesChannels, setSalesChannels] = React.useState([]);

  const [bulkOperationsModalOpened, setBulkOperationsModalOpened] =
    React.useState(false);

  const [isFetchingBrands, setIsFetchingBrands] = React.useState(false);
  const [isFetchingSalesChannels, setIsFetchingSalesChannels] =
    React.useState(false);

  const [isRemovingProduct, setIsRemovingProduct] = React.useState(null);

  const limit = 100;

  React.useEffect(() => {
    if (org) {
      getProductsList(page);
    }
    getType("product_type");
    getBrands();
    getSalesChannels();
    dispatch(fetchCategories(appspace));
  }, []);

  React.useEffect(() => {
    getProductsList();
  }, [activeParameters]);

  const getProductsList = (page = 1) => {
    const offset = page * limit - limit;
    const data = {
      limit,
      offset,
    };
    dispatch(fetchProducts(data));
  };

  const getType = React.useCallback(async (type) => {
    try {
      const data = await ApiEshop.getCodelist(type);
      setCodelist((prevState) => [
        ...prevState,
        {
          type: "type",
          items: data.codelist,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getSalesChannels = React.useCallback(async () => {
    try {
      setIsFetchingSalesChannels(true);
      const res = await ApiEshop.adminListSaleschannels(org);
      setSalesChannels(
        (res.saleschannels || []).map((i) => ({
          label: i.name,
          value: i._id,
        })),
      );
      setIsFetchingSalesChannels(false);
    } catch (e) {
      alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
    } finally {
      setIsFetchingSalesChannels(false);
    }
  }, [org]);

  const getBrands = React.useCallback(async () => {
    try {
      setIsFetchingBrands(true);
      const data = await ApiEshop.adminListBrands(org);
      const arr = data.codelist;
      const newArr = [];
      arr.forEach((item) => {
        if (item.value !== "") {
          newArr.push(item);
        }
      });
      setBrands(newArr);
      setCodelist((prevState) => [
        ...prevState,
        {
          type: "brand",
          items: data.codelist,
        },
      ]);
    } catch (e) {
      console.error(e);
      alert(__("Nepodarilo sa získať zoznam značiek"));
    } finally {
      setIsFetchingBrands(false);
    }
  }, [org]);

  const handleRemove = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const conf = window.confirm(__("Určite chcete odstrániť produkt?"));
    if (conf) {
      try {
        setIsRemovingProduct(id);
        await ApiEshop.adminDeleteProduct(org, id);
        getProductsList(page);
      } catch (e) {
        console.error(e);
        alert(__("Produkt sa nepodarilo odstrániť"));
      } finally {
        setIsRemovingProduct(null);
      }
    }
  };

  const handleClickPage = (offset) => {
    getProductsList(offset / limit + 1);
  };

  const renderProduct = ({
    code,
    name,
    type,
    active,
    brand,
    _id,
    pictures = [],
  }) => {
    let pictureUri = null;
    if (pictures && pictures.length > 0) {
      const [picture] = pictures;
      const pictureMMUri = getProp(picture, ["media_url"]);
      pictureUri = getProp(picture, ["uri"]);
      if (pictureMMUri) {
        pictureUri = pictureMMUri.replace("{size}", "40x40");
      } else if (!pictureUri.startsWith("http")) {
        pictureUri = `${process.env.REACT_APP_IMAGE_BASE_URL}/40x40/${pictureUri}`;
      }
    }

    const typeLabel = getLabelofOpt(codelist, "type", type);
    return (
      <Tr
        key={_id}
        onClick={() => {
          history.push(`/admin/${appspace}/products/${_id}`);
        }}
      >
        <Td style={{ width: rem(48) }}>
          {!!pictureUri && (
            <ImageWrapper>
              <Img src={pictureUri} />
            </ImageWrapper>
          )}
        </Td>
        <Td>{name}</Td>
        <Td>{code && code}</Td>
        <Td>{typeLabel}</Td>
        <Td>{brand}</Td>
        <Td>
          {active ? <Icon name="checked" sm /> : <Icon name="delete" sm />}
        </Td>
        <Td right>
          <Button
            loading={isRemovingProduct === _id}
            icon="trash"
            danger
            onClick={(e) => handleRemove(_id, e)}
          />
        </Td>
      </Tr>
    );
  };

  const handleCloseBulkOperationsModal = () => {
    setBulkOperationsModalOpened(false);
    getProductsList(page);
  };

  const handleOpenBulkOperationsModal = () => {
    setBulkOperationsModalOpened(true);
  };

  const setProductsFilterParameters = (parameters) => {
    dispatch(setParameters(parameters));
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__("Produkty")}</HeaderBar.Header>
          </HeaderBar>
          {!isFetchingBrands && !isFetchingSalesChannels && (
            <ProductsFilter
              appSpace={appspace}
              brands={brands}
              salesChannels={salesChannels}
              categoriesCodelist={categoriesCodelist}
              parameters={activeParameters}
              setParameters={setProductsFilterParameters}
            />
          )}
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              offset={offset}
              total={total}
              limit={limit}
              onChangeOffset={handleClickPage}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              primary
              basic
              onClick={handleOpenBulkOperationsModal}
              disabled={isFetching}
            >
              {__("Hromadné operácie")}
            </Button>
          </ContextBarItem>
          <ContextBarItem>
            <Button
              primary
              onClick={() => history.push(`/admin/${appspace}/products/new`)}
              disabled={isFetching}
            >
              {__("Nový produkt")}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Produkty")} />
      <ProductBulkOperations
        isOpen={bulkOperationsModalOpened}
        handleClose={handleCloseBulkOperationsModal}
      />
      <Segment noBottomGutter>
        <Segment raised loading={isFetching}>
          <Table>
            <Thead>
              <Tr>
                <Th />
                <Th>{__("Produkt")}</Th>
                <Th>{__("Kód produktu")}</Th>
                <Th>{__("Typ")}</Th>
                <Th>{__("Značka")}</Th>
                <Th>{__("Aktívny")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>{products.map(renderProduct)}</Tbody>
          </Table>
        </Segment>
      </Segment>
    </Layout>
  );
};

Products.propTypes = {
  pagination: PropTypes.shape({
    params: PropTypes.shape({}).isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  org: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: routerParametersDefinition,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      code: PropTypes.string,
      type: PropTypes.string,
      brand: PropTypes.string,
      active: PropTypes.bool,
      pictures: PropTypes.arrayOf(
        PropTypes.shape({
          uri: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  activeParameters: PropTypes.shape({}).isRequired,
  categoriesCodelist: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => {
  const {
    products: { data },
  } = state;
  return {
    nextOffset: data.nextOffset,
    products: getCurrentProductsSelector(state),
    isFetching: productsIsFetchingByActiveParametersSelector(state),
    offset: data.offset,
    total: data.total,
    org: state.selectedOrg,
    organization: organizationProfileSelector(state),
    activeParameters: activeParametersSelector(state),
    categoriesCodelist: categoriesFlatCodelistSelector(state),
  };
};

// TODO - ORGANIZATION

const withPagination = connectPagination()(Products);
export default connect(mapStateToProps)(withPagination);
