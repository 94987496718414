import { __ } from "../../../utilities";
import { connect } from "react-redux";
import { useAuth } from "@sportnet/auth-react";
import ApiEshop from "../../../service/apiEshop";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Col, { Row } from "@sportnet/ui/Grid";
import FormField from "@sportnet/ui/FormField";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../../components/Layout";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import SubmitButton from "@sportnet/ui/Button/Submit";

const defaultSettings = {
  api_key: "",
  list_id: "",
  account_id: "",
};

const Ecomail = () => {
  const [loading, setLoading] = React.useState(true);
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [ecomailSettings, setEcomailSettings] = React.useState(defaultSettings);
  const { ppo } = useAuth();

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const newSettings = await ApiEshop.adminGetSupplierEcomail(ppo);
        setEcomailSettings({ ...defaultSettings, ...newSettings });
      } catch (e) {
        console.error(e);
        alert(__("Nemáte právo upravovať ecomail"));
      } finally {
        setLoading(false);
      }
    })();
  }, [ppo]);

  const handleSave = async () => {
    setSubmitting(true);
    setSubmitFailed(false);
    try {
      const newSettings = await ApiEshop.adminUpdateEcomail(ppo, {
        data: ecomailSettings,
      });
      setEcomailSettings(newSettings);
    } catch (e) {
      setSubmitFailed(true);
      console.error(e);
      alert(__("Uloženie nastavení ecomail sa nepodarilo"));
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangeValue = (e) => {
    const {
      target: { name, value },
    } = e;
    setEcomailSettings((prev) => ({ ...(prev || {}), [name]: value }));
  };

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Nastavenia ecomail")}</HeaderBar.Header>
        </HeaderBar>
      }
    >
      <AppContext title={__("Nastavenia ecomail")} />
      <Segment noBottomGutter>
        <Segment raised loading={loading}>
          <Row>
            <Col xs={12} m={6}>
              <FormField
                label={__("API kľúč")}
                name="api_key"
                placeholder={__("V manuále ecomail ako API KEY")}
                value={ecomailSettings.api_key || ""}
                onChange={handleChangeValue}
              />
            </Col>
            <Col xs={12} m={6}>
              <FormField
                label={__("Id zoznamu")}
                name="list_id"
                info={
                  <div style={{ maxWidth: "200px" }}>
                    {__(
                      "Do tohto zoznamu budú zaradení noví zákazníci, ktorí nakúpia Vaše produkty.",
                    )}
                  </div>
                }
                placeholder={__("V manuále ecomail ako LIST ID")}
                value={ecomailSettings.list_id || ""}
                onChange={handleChangeValue}
              />
            </Col>
          </Row>
          <SubmitButton
            isError={submitFailed}
            isSubmitting={submitting}
            successText={__("Uložené!")}
            onClick={handleSave}
          >
            {__("Uložiť")}
          </SubmitButton>
        </Segment>
      </Segment>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Ecomail);
