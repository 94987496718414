import {
  RECEIVE_CODELIST_STATUSES_ERROR,
  RECEIVE_CODELIST_STATUSES_SUCCESS,
  RECEIVE_ORDERS_ERROR,
  RECEIVE_ORDERS_SUCCESS,
  RECEIVE_ORDER_SUCCESS,
  REQUEST_CODELIST_STATUSES,
  REQUEST_ORDER,
  REQUEST_ORDERS,
  SET_ACTIVE_ORDER_ID,
  SET_PARAMETER,
  SET_PARAMETERS,
} from './constants';
import { combineReducers } from 'redux';
import { getProp, serializeSearchParameters } from '../../utilities';

const ordersInitialState = {
  activeId: null,
  data: {},
  byId: {},
  byParameters: {},
  parameters: {
    q: '',
  },
  error: null,
};

const ordersReducer = (state = ordersInitialState, action) => {
  const serializedParameters = serializeSearchParameters(
    getProp(action, ['payload', 'parameters'], state.parameters),
  );
  switch (action.type) {
    case SET_PARAMETER:
      if (action.payload.value === false) {
        return {
          ...state,
          parameters: {
            ...Object.keys(state.parameters).reduce((acc, next) => {
              if (next === action.payload.name) {
                return acc;
              }
              return { ...acc, [next]: state.parameters[next] };
            }, {}),
          },
        };
      }
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_PARAMETERS:
      return {
        ...state,
        parameters: action.payload.value,
      };
    case REQUEST_ORDERS:
      return {
        ...state,
        byParameters: {
          ...state.byParameters,
          [serializedParameters]: {
            ...getProp(state.byParameters, [serializedParameters], {}),
            [`${action.payload.offset}-${action.payload.offset +
              action.payload.limit}`]: {
              ...getProp(state.byParameters, [
                serializedParameters,
                `${action.payload.offset}-${action.payload.offset +
                  action.payload.limit}`,
              ]),
              isFetching: true,
            },
          },
        },
      };
    case RECEIVE_ORDERS_SUCCESS: {
      const {
        data: { offset, limit, total, orders, next_offset: nextOffset },
      } = action.payload;
      return {
        ...state,
        data: {
          offset,
          limit,
          total,
          nextOffset,
        },
        byId: {
          ...state.byId,
          ...action.payload.data.orders.reduce((acc, next) => {
            return { ...acc, [next._id]: next };
          }, {}),
        },
        byParameters: {
          ...state.byParameters,
          [serializedParameters]: {
            ...getProp(state.byParameters, [serializedParameters], {}),
            [`${offset}-${offset + limit}`]: {
              ids: orders.map(item => {
                return item._id;
              }),
              isFetching: false,
              total,
              nextOffset,
            },
          },
        },
        error: null,
      };
    }
    case RECEIVE_ORDERS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case SET_ACTIVE_ORDER_ID:
      return {
        ...state,
        activeId: action.payload.id,
      };
    case REQUEST_ORDER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...getProp(state.byId, [action.payload.id], {}),
            isFetching: true,
          },
        },
      };
    case RECEIVE_ORDER_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...action.payload.data,
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
};

const orderStatusesInitialState = {
  isFetching: false,
  codelist: [],
  error: null,
};

const orderStatusesReducer = (state = orderStatusesInitialState, action) => {
  switch (action.type) {
    case REQUEST_CODELIST_STATUSES:
      return { ...state, isFetching: true };
    case RECEIVE_CODELIST_STATUSES_SUCCESS:
      return {
        ...state,
        codelist: action.payload,
        error: null,
        isFetching: false,
      };
    case RECEIVE_CODELIST_STATUSES_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  orders: ordersReducer,
  orderStatuses: orderStatusesReducer,
});
