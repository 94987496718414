import {
  RECEIVE_GROUPS_FAILURE,
  RECEIVE_GROUPS_SUCCESS,
  RECEIVE_PARTNERS_FAILURE,
  RECEIVE_PARTNERS_SUCCESS,
  REQUEST_GROUPS,
  REQUEST_PARTNERS,
} from './constants';

import Api from '../../service/apiEshop';
import apiSportnet from '../../service/apiSportnet';

const requestPartners = () => ({
  type: REQUEST_PARTNERS,
});

const receivePartnersSuccess = (data) => ({
  type: RECEIVE_PARTNERS_SUCCESS,
  payload: {
    data: data.partners,
    offset: data.offset,
    limit: data.limit,
    nextOffset: data.next_offset,
  },
});

const receivePartnersFailure = (error) => ({
  type: RECEIVE_PARTNERS_FAILURE,
  payload: {
    error,
  },
});

export const fetchPartnersList = (appspace, offset = 0, limit = 50) => {
  return async dispatch => {
    dispatch(requestPartners());
    try {
      const response = await Api.adminListB2BPartners(appspace, {
        offset,
        limit,
      });
      dispatch(receivePartnersSuccess(response));
    } catch (e) {
      dispatch(receivePartnersFailure(e.description || e));
    }
  };
};

const requestGroups = () => ({
  type: REQUEST_GROUPS,
});

const receiveGroupsSuccess = (data) => ({
  type: RECEIVE_GROUPS_SUCCESS,
  payload: [...data.groups],
})

const receiveGroupsFailure = (error) => ({
  type: RECEIVE_GROUPS_FAILURE,
  payload: {
    error,
  },
});

export const fetchGroupsList = (appSpace) => {
  return async dispatch => {
    dispatch(requestGroups());
    try {
      const response = await apiSportnet.getPublicAppSpaceGroups(appSpace)
      dispatch(receiveGroupsSuccess(response));
    } catch (e) {
      dispatch(receiveGroupsFailure(e.description || e));
    }
  };
};
