import {
  RECEIVE_COUNTRY_CODES_FAILURE,
  RECEIVE_COUNTRY_CODES_SUCCESS,
  REQUEST_COUNTRY_CODES,
} from "./constants";
import apiSportnet from "../../../service/apiSportnet";

const requestCountryCodes = () => ({
  type: REQUEST_COUNTRY_CODES,
});

const receiveCountryCodes = (data) => {
  return {
    type: RECEIVE_COUNTRY_CODES_SUCCESS,
    payload: {
      data,
    },
  };
};

const failureCountryCodes = (error) => ({
  type: RECEIVE_COUNTRY_CODES_FAILURE,
  payload: {
    error,
  },
});

export const fetchCountryCodes = () => {
  return async (dispatch) => {
    dispatch(requestCountryCodes());
    try {
      const { codelist } = await apiSportnet.getCodelist("country");
      dispatch(receiveCountryCodes(codelist || []));
    } catch (e) {
      dispatch(failureCountryCodes(e.description || e));
    }
  };
};
