import * as React from "react";
import { Field, change, formValueSelector } from "redux-form";
import { __ } from "../../utilities";
import { connect } from "react-redux";
import { debounce } from "@sportnet/ui/utilities";
import { isFloat, parseToFloat, required } from "../../utilities/validations";
import FormField from "@sportnet/ui/FormField/redux-form";
import PropTypes from "prop-types";
import Segment from "@sportnet/ui/Segment";
import SegmentHeader from "@sportnet/ui/Segment/Header";
import apiEshop from "../../service/apiEshop";

const mapStateToProps = (state, props) => {
  const selector = formValueSelector(props.formName);
  return {
    payload: selector(state, "payload"),
  };
};

const mapDispatchToProps = {
  changeField: change,
};

const C = ({ payload, appSpace, formName, changeField }) => {
  const [products, setProducts] = React.useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = React.useState(false);

  const [brands, setBrands] = React.useState([]);
  const [isFetchingBrands, setIsFetchingBrands] = React.useState(false);

  const [salesChannels, setSalesChannels] = React.useState([]);
  const [isFetchingSalesChannels, setIsFetchingSalesChannels] =
    React.useState(false);

  const [salesChannelCategories, setSalesChannelCategories] = React.useState(
    []
  );
  const [
    isFetchingSalesChannelCategories,
    setIsFetchingSalesChannelCategories,
  ] = React.useState(false);

  const [productTags, setProductTags] = React.useState([]);
  const [isFetchingProductTags, setIsFetchingProductTags] =
    React.useState(false);

  React.useEffect(() => {
    setIsFetchingProductTags(true);
    apiEshop
      .getCodelist("product_tag")
      .then((res) => {
        setProductTags(res.codelist);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam tagov"));
      })
      .finally(() => {
        setIsFetchingProductTags(false);
      });
  }, []);

  React.useEffect(() => {
    setIsFetchingBrands(true);
    apiEshop
      .adminListBrands(appSpace)
      .then((res) => {
        setBrands(res.codelist);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam značiek"));
      })
      .finally(() => {
        setIsFetchingBrands(false);
      });
  }, [appSpace]);

  React.useEffect(() => {
    setIsFetchingSalesChannels(true);
    apiEshop
      .adminListSaleschannels(appSpace)
      .then((res) => {
        setSalesChannels(res.saleschannels);
      })
      .catch((e) => {
        console.error(e);
        alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
      })
      .finally(() => {
        setIsFetchingSalesChannels(false);
      });
  }, [appSpace]);

  const transformCategories = React.useCallback((items, prefix = "") => {
    return items.reduce((acc, i) => {
      return [
        ...acc,
        { ...i, name: `${prefix}${i.name}` },
        ...transformCategories(i.categories || [], `${prefix}${i.name} -> `),
      ];
    }, []);
  }, []);

  React.useEffect(() => {
    if (payload.matchSaleschannel && payload.matchSaleschannel.value) {
      setIsFetchingSalesChannelCategories(true);
      apiEshop
        .adminSaleschannelGetCategoriesTree(
          appSpace,
          payload.matchSaleschannel.value
        )
        .then((res) => {
          setSalesChannelCategories(transformCategories(res.categories));
        })
        .catch((e) => {
          console.error(e);
          alert(__("Nepodarilo sa získať zoznam predajných kanálov"));
        })
        .finally(() => {
          setIsFetchingSalesChannelCategories(false);
        });
    }
  }, [appSpace, payload, transformCategories]);

  const fetchProducts = React.useMemo(() => {
    return debounce(async (q) => {
      if (q.length >= 3) {
        setIsFetchingProducts(true);
        try {
          const res = await apiEshop.adminListProducts(appSpace, {
            limit: 100,
            offset: 0,
            q,
          });
          setProducts(res.products);
        } catch (e) {
          alert(__("Nepodarilo sa získať zoznam produktov"));
        } finally {
          setIsFetchingProducts(false);
        }
      }
    }, 300);
  }, [appSpace]);

  const handleAmountChange = (e) => {
    if (payload.vat_rate) {
      const amountVatExcl = parseFloat(
        (e.target.value / ((100 + payload.vat_rate) / 100)).toFixed(2)
      );
      changeField(formName, `payload.amount_vat_excl`, amountVatExcl);
    }
    return e;
  };

  return (
    <>
      <Segment
        raised
        header={
          <SegmentHeader size="xs" withSeparator>
            {__("Nastavenia zľavy")}
          </SegmentHeader>
        }
      >
        <Field
          validate={[isFloat]}
          name="payload.minAmount.amount"
          component={FormField}
          label={__("Minimálna výška objednávky v EUR")}
          parse={parseToFloat}
        />
        <Field
          validate={[isFloat]}
          name="payload.maxAmount.amount"
          component={FormField}
          label={__("Maximálna výška objednávky v EUR")}
          parse={parseToFloat}
        />
        <Field
          validate={[required]}
          name="payload.type"
          component={FormField}
          type="theselectsimple"
          label={__("Typ zľavy")}
          options={[
            { label: "Fix", value: "fix" },
            { label: "Percentuálna", value: "percent" },
          ]}
          clearable={false}
          onChange={(e) => {
            changeField(formName, "payload.amount", null);
            return e;
          }}
        />
        {payload.type === "fix" && (
          <>
            <Field
              validate={[required, isFloat]}
              name="payload.amount"
              component={FormField}
              label={__("Výška zľavy v EUR")}
              parse={parseToFloat}
              onChange={handleAmountChange}
            />
          </>
        )}
        {payload.type === "percent" && (
          <>
            <Field
              validate={[required, isFloat]}
              name="payload.amount"
              component={FormField}
              label={__("Výška zľavy v percentách")}
              parse={parseToFloat}
            />
          </>
        )}
      </Segment>
      <Segment
        raised
        header={
          <SegmentHeader size="xs" withSeparator>
            {__("Obmedzenie zľavy")}
          </SegmentHeader>
        }
      >
        <Field
          name="payload.matchSaleschannel"
          component={FormField}
          type="theselect"
          label={__("Platí len pre tento predajný kanál")}
          loading={isFetchingSalesChannels}
          options={salesChannels.map((i) => ({ label: i.name, value: i._id }))}
          onChange={(i) => {
            changeField(formName, "payload.includeSaleschannelCategories", []);
            changeField(formName, "payload.excludeSaleschannelCategories", []);
            return i;
          }}
        />
        <Field
          name="payload.includeProducts"
          component={FormField}
          type="theselect"
          multiple
          onChangeInput={fetchProducts}
          label={__("Platí len pre tieto produkty")}
          placeholder={__("Zadajte aspoň 3 znaky")}
          loading={isFetchingProducts}
          options={products.map((u) => ({
            label: `${u.name}`,
            value: u._id,
          }))}
        />
        <Field
          name="payload.excludeProducts"
          component={FormField}
          type="theselect"
          multiple
          onChangeInput={fetchProducts}
          label={__("Platí pre všetky produkty okrem týchto")}
          placeholder={__("Zadajte aspoň 3 znaky")}
          loading={isFetchingProducts}
          options={products.map((u) => ({
            label: `${u.name}`,
            value: u._id,
          }))}
        />
        <Field
          name="payload.includeProductTags"
          component={FormField}
          type="theselect"
          multiple
          label={__("Platí len pre produkty s týmito vlastnosťami")}
          loading={isFetchingProductTags}
          options={productTags}
        />
        <Field
          name="payload.excludeProductTags"
          component={FormField}
          type="theselect"
          multiple
          label={__("Platí pre produkty so všetky vlastnosťami okrem týchto")}
          loading={isFetchingProductTags}
          options={productTags}
        />
        <Field
          name="payload.includeBrands"
          component={FormField}
          type="theselect"
          multiple
          label={__("Platí len pre tieto značky")}
          loading={isFetchingBrands}
          options={brands}
        />
        <Field
          name="payload.excludeBrands"
          component={FormField}
          type="theselect"
          multiple
          label={__("Platí pre všetky značky okrem týchto")}
          loading={isFetchingBrands}
          options={brands}
        />
        {!!payload.matchSaleschannel && (
          <>
            <Field
              name="payload.includeSaleschannelCategories"
              component={FormField}
              type="theselect"
              multiple
              label={__("Platí len pre tieto kategórie predajného kanála")}
              loading={isFetchingSalesChannelCategories}
              options={salesChannelCategories.map((i) => ({
                label: i.name,
                value: i._id,
              }))}
            />
            <Field
              name="payload.excludeSaleschannelCategories"
              component={FormField}
              type="theselect"
              multiple
              label={__(
                "Platí pre všetky kategórie predajného kanála okrem týchto"
              )}
              loading={isFetchingSalesChannelCategories}
              options={salesChannelCategories.map((i) => ({
                label: i.name,
                value: i._id,
              }))}
            />
          </>
        )}
      </Segment>
    </>
  );
};

C.propTypes = {
  payload: PropTypes.shape({
    type: PropTypes.string.isRequired,
    vat_rate: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    amount_vat_excl: PropTypes.number.isRequired,
    matchSaleschannel: PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  }),
  appSpace: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  changeField: PropTypes.func.isRequired,
};

C.defaultProps = {
  payload: {
    matchSaleschannel: null,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(C);
