import { ContextBar, ContextBarItem } from "@sportnet/ui/ContextBar";
import { REQUESTS_LIST, REQUESTS_LIST_CONFIG } from "./constants";
import { __, getRequestStatus, getRequestType } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { format } from "date-fns";
import { getListTotal, isCommiting } from "@sportnet/redux-list";
import { initializeOrSetListParams } from "../App/actions";
import { listRequestsSelector } from "./selectors";
import { loadRequestsList } from "./actions";
import { useCodelistRequestStatus } from "../../hooks/useCodelistRequestStatus";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import BasicTable from "@sportnet/ui/BasicTable";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Layout from "../../components/Layout";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import useQuery from "@sportnet/query-hoc/useQuery";

const Requests = ({
  history,
  location: { pathname, search },
  setParams,
  loadRequests,
  isFetching,
  total,
  requests,
  match: {
    params: { appspace },
  },
  org,
}) => {
  const { statusesByValue } = useCodelistRequestStatus();

  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      history.push({
        pathname,
        search: newSearch,
      }),
    REQUESTS_LIST_CONFIG,
  );

  React.useEffect(() => {
    (async () => {
      try {
        await setParams({
          listName: REQUESTS_LIST,
          params: query,
        });
        await loadRequests();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query, loadRequests, setParams]);

  const isLoading = isFetching && requests.length === 0;

  return (
    <Layout
      topFixed={
        <HeaderBar>
          <HeaderBar.Header>{__("Dotazy")}</HeaderBar.Header>
        </HeaderBar>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              loading={isLoading}
              total={total}
              limit={query.limit}
              offset={query.offset}
              onChangeOffset={(offset) => {
                setQuery({ offset });
              }}
            />
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Dotazy")} />
      <Segment noBottomGutter>
        <Segment raised loading={isLoading}>
          <BasicTable
            columns={[
              { id: "date", header: __("Dátum") },
              { id: "type", header: __("Typ") },
              { id: "status", header: __("Stav") },
              { id: "id", header: __("Číslo") },
              { id: "creator", header: __("Vytvoril") },
            ]}
            onClickRow={(item) => {
              history.push(`/admin/${appspace}/requests/${org}/${item._id}`);
            }}
            rows={requests}
            renderRow={(item) => [
              format(new Date(item.created_date), "dd.MM.yyyy HH:mm"),
              getRequestType(item.type),
              getRequestStatus(item.status, statusesByValue),
              item._id,
              `${item.person.name} ${item.person.surname}`,
            ]}
            rowKey="_id"
          />
        </Segment>
      </Segment>
    </Layout>
  );
};

Requests.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  setParams: PropTypes.func.isRequired,
  loadRequests: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  org: PropTypes.string.isRequired,
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      created_date: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      person: PropTypes.shape({
        name: PropTypes.string,
        surname: PropTypes.string,
      }),
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: isCommiting(REQUESTS_LIST)(state) || false,
  total: getListTotal(REQUESTS_LIST)(state) || 0,
  requests: listRequestsSelector(REQUESTS_LIST)(state),
  org: state.selectedOrg,
});

const mapDispatchToProps = {
  setParams: initializeOrSetListParams,
  loadRequests: loadRequestsList,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(Requests);
