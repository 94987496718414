import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { __, getProp } from "../../utilities";

const Wrapper = styled.div`
  line-height: 1.3;
`;

const Title = styled.span`
  font-weight: bold;
  word-break: break-word;
`;

const ItemInfo = ({ item, variant }) => {
  const params =
    item?.payload?.selectedParameters || item?.variant?.params || {};
  const card = item?.payload?.card;

  return (
    <Wrapper>
      <Title>
        {getProp(variant, ["code"], "")
          ? `[${getProp(variant, ["code"], "")}] `
          : ""}
        {getProp(item, ["item_name"], "")}
      </Title>
      <br />
      {(() => {
        const elements = [];
        if (getProp(item, ["product_code"])) {
          elements.push(
            <span>
              {__("Kód produktu:")} {getProp(item, ["product_code"], "")}
            </span>,
          );
        }
        elements.push(
          ...(Array.isArray(params)
            ? params.map((param) => (
                <span key={param.key}>
                  {param.key}: {param.value}
                </span>
              ))
            : Object.keys(params).map((param) => {
                const par = params[param];
                return (
                  <span key={param}>
                    {getProp(par, ["label"], param)}:{" "}
                    {getProp(
                      par,
                      ["value", "label"],
                      getProp(par, ["value", "value"]),
                    )}
                  </span>
                );
              })),
          ...(!!card ? [`Karta: ${card.type} - ${card.cardnr}`] : []),
        );
        return elements;
      })().map((e, i) => {
        return [i > 0 ? " | " : null, e];
      })}
    </Wrapper>
  );
};

export const ItemDetailPropTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  picture: PropTypes.string,
  isSale: PropTypes.bool,
  isAvailable: PropTypes.bool,
  currency: PropTypes.string,
  isNew: PropTypes.bool,
  oldprice: PropTypes.number,
  isTop: PropTypes.bool,
  nrOfVariants: PropTypes.number,
};

ItemInfo.propTypes = {
  item: PropTypes.shape({
    ...ItemDetailPropTypes,
  }).isRequired,
  variant: PropTypes.shape({
    code: PropTypes.string,
    params: PropTypes.shape({
      label: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default ItemInfo;
