import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from "@sportnet/ui/ContextBar";
import { RECEIPT_ORDERS_LIST, RECEIPT_ORDERS_LIST_CONFIG } from "./constants";
import { Table, Tbody, Td, Th, Thead, Tr } from "@sportnet/ui/Table";
import { __, calculateOrderTotalprice } from "../../utilities";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchSaleChannelsList } from "../SaleChannels/actions";
import { format } from "date-fns";
import { formatPrice } from "@sportnet/utilities";
import { getListTotal, isCommiting } from "@sportnet/redux-list";
import { initializeOrSetListParams } from "../App/actions";
import { listOrdersSelector } from "./selectors";
import { loadReceiptOrdersList } from "./actions";
import { saleChanellsListSelector } from "../SaleChannels/selectors";
import AppContext from "@sportnet/ui/TheLayout/AppContext";
import Button from "@sportnet/ui/Button";
import Checkbox from "@sportnet/ui/Checkbox";
import HeaderBar from "@sportnet/ui/HeaderBar";
import Icon from "@sportnet/ui/Icon";
import Layout from "../../components/Layout";
import NotFound from "@sportnet/ui/NotFound";
import OrdersFilter from "./OrdersFilter";
import Paginator from "@sportnet/ui/Paginator";
import PropTypes from "prop-types";
import React from "react";
import Segment from "@sportnet/ui/Segment";
import apiEshop from "../../service/apiEshop";
import useQuery from "@sportnet/query-hoc/useQuery";

const ReceiptCreate = ({
  history,
  location: { pathname, search },
  setParams,
  loadOrders,
  isFetching,
  total,
  orders,
  match: {
    params: { appspace },
  },
  salesChannels,
  loadSalesChannels,
}) => {
  const [selectedOrders, setSelectedOrders] = React.useState({});

  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      history.push({
        pathname,
        search: newSearch,
      }),
    RECEIPT_ORDERS_LIST_CONFIG,
  );

  React.useEffect(() => {
    (async () => {
      try {
        await setParams({
          listName: RECEIPT_ORDERS_LIST,
          params: query,
        });
        await loadOrders();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query, loadOrders, setParams]);

  React.useEffect(() => {
    loadSalesChannels(appspace);
  }, [appspace, loadSalesChannels]);

  const isLoading = isFetching && orders.length === 0;

  const areAllOrdersSelectedOnThisPage =
    orders.length > 0 &&
    !orders.reduce((acc, o) => acc || !(o._id in selectedOrders), false);

  const handleSelectAll = () => {
    if (areAllOrdersSelectedOnThisPage) {
      setSelectedOrders((prev) => {
        const newState = { ...prev };
        orders.forEach(({ _id }) => {
          delete newState[_id];
        });
        return newState;
      });
    } else {
      setSelectedOrders((prev) => {
        const newState = { ...prev };
        orders.forEach(({ _id }) => {
          newState[_id] = true;
        });
        return newState;
      });
    }
  };

  const handleCreateReceipt = async () => {
    try {
      const data = {
        order_ids: Object.keys(selectedOrders).map((i) => Number(i)),
      };
      const { _id: receiptId } = await apiEshop.adminCreateReceipt(appspace, {
        data,
      });
      history.push(`/admin/${appspace}/receipt/${receiptId}`);
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        alert(__("Vybraná objednávka už má predpis"));
      } else {
        alert(__("Došlo k neočakavanej chybe"));
      }
    }
  };

  const handleOrderClick = (order) => {
    history.push(`/admin/${appspace}/orders/${order._id}`);
  };

  const handleOrderSelectToggle = (order) => {
    setSelectedOrders((prev) => {
      const newState = { ...prev };
      if (order._id in newState) {
        delete newState[order._id];
      } else {
        newState[order._id] = true;
      }
      return newState;
    });
  };

  return (
    <Layout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>{__("Vytvorenie predpisu")}</HeaderBar.Header>
          </HeaderBar>
          <OrdersFilter
            query={query}
            setQuery={setQuery}
            salesChannels={salesChannels}
          />
        </>
      }
      bottomFixed={
        <ContextBar>
          <ContextBarItem>
            <Paginator
              loading={isLoading}
              total={total}
              limit={query.limit}
              offset={query.offset}
              onChangeOffset={(offset) => {
                setQuery({ offset });
              }}
            />
          </ContextBarItem>
          <ContextBarSpacer />
          <ContextBarItem>
            {Object.keys(selectedOrders).length > 0 && (
              <Button primary onClick={handleCreateReceipt}>
                {__("Vytvoriť predpis pre označené objednávky")}
              </Button>
            )}
          </ContextBarItem>
        </ContextBar>
      }
    >
      <AppContext title={__("Vytvorenie predpisu")} />
      <Segment noBottomGutter>
        <Segment raised loading={isLoading}>
          <Table>
            <Thead>
              <Tr>
                <Th center width={40}>
                  <Checkbox
                    type="checkbox"
                    checked={areAllOrdersSelectedOnThisPage}
                    onChange={handleSelectAll}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Th>
                <Th center>{__("Číslo objednávky")}</Th>
                <Th>{__("Dátum")}</Th>
                <Th>{__("Odberateľ")}</Th>
                <Th center>{__("Uhradené")}</Th>
                <Th right>
                  {__("Celková cena")}
                  <br />
                  {__("Celková cena bez DPH")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order) => {
                const {
                  _id,
                  company,
                  person_name: personName,
                  person_surname: personSurname,
                  phone,
                  created_date: createdDate,
                  paid_date: paidDate,
                  saleschannel,
                } = order;
                const {
                  amount,
                  amount_vat_excl: amountvatExcl,
                  currency,
                } = calculateOrderTotalprice(order);
                return (
                  <Tr key={_id} onClick={() => handleOrderClick(order)}>
                    <Td>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox
                          type="checkbox"
                          checked={_id in selectedOrders}
                          onChange={() => handleOrderSelectToggle(order)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    </Td>
                    <Td>
                      {_id}
                      <br />
                      {saleschannel}
                    </Td>
                    <Td>{format(new Date(createdDate), "dd.MM.yyyy HH:mm")}</Td>
                    <Td>
                      <b>{company}</b>
                      <br />
                      {`${personName} ${personSurname}`}
                      <br />
                      {`${__("Telefón")}: ${phone}`}
                    </Td>
                    <Td center>
                      {paidDate ? (
                        <div>
                          <Icon name="checked" success />
                          <div>
                            {format(new Date(paidDate), "dd.MM.yyyy HH:mm")}
                          </div>
                        </div>
                      ) : (
                        <Icon name="delete" danger />
                      )}
                    </Td>
                    <Td right>
                      <b>{formatPrice(amount, currency)}</b>
                      <br />
                      {formatPrice(amountvatExcl, currency)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {orders.length === 0 && !isFetching && (
            <NotFound title={__("Nenašli sa žiadne položky")} icon="file" />
          )}
        </Segment>
      </Segment>
    </Layout>
  );
};

ReceiptCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  setParams: PropTypes.func.isRequired,
  loadOrders: PropTypes.func.isRequired,
  loadSalesChannels: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number,
      created_date: PropTypes.string,
      company: PropTypes.string,
      person_name: PropTypes.string,
      person_surname: PropTypes.string,
      phone: PropTypes.string,
      paid_date: PropTypes.string,
      saleschannel: PropTypes.string,
    }),
  ).isRequired,
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  isFetching: isCommiting(RECEIPT_ORDERS_LIST)(state) || false,
  total: getListTotal(RECEIPT_ORDERS_LIST)(state) || 0,
  orders: listOrdersSelector(RECEIPT_ORDERS_LIST)(state),
  salesChannels: saleChanellsListSelector(state),
});

const mapDispatchToProps = {
  setParams: initializeOrSetListParams,
  loadOrders: loadReceiptOrdersList,
  loadSalesChannels: fetchSaleChannelsList,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  React.memo,
)(ReceiptCreate);
